import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Qualification } from "../../../interfaces/Qualification";
import {
  addWeb2LoginRequest,
  changeWeb2Email,
  getSubscription,
  resetPassword,
  subscribeNotifs,
  updateOwnerProfile,
} from "../../../services/users";
import { authState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import Modal from "../../organisms/Modal";
import {
  EmailSchema,
  PasswordSchema,
  ProfileSchema,
  Web2Schema,
} from "./validation";

import { languageList } from "../../../assets/languages";
import useUserFetch from "../../hooks/useUserFetch";
import FormError from "../../organisms/FormError";

const PageWrapper = styled.div`
  min-width: 1100px;
  width: 75%;
  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flexdirection: column;
  height: 100%;
  justify-content: center;
  min-width: 1100px;
  width: 100%;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const StyledButton = styled.div`
  background-color: rgba(156, 39, 176, 0.04);
  border-radius: 4px;
  display: flex;
  align-items: center;

  p {
    text-align: center;
    padding: 9px 11px;
    margin: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  // @media only screen and (max-width: 1030px) {
  //   display: none;
  // }
`;

const ProfileHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;
const FormWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

type FormValues = {
  id?: string;
  name: string;
  notif_email: string;
  checkedNotif: boolean;
  preferred_language: string;
};
type Profile = {
  address?: string;
  email_notif?: boolean;
  nonce?: string;
  email?: string;
  notification_email?: string;
  remaining_energy?: number;
  role?: string;
  user_name?: string;
  hasPassword?: boolean;
  authorized_qualifications?: Qualification[];
  preferred_language: string;
};

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [web2Modal, setWeb2Modal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const setSnackbar = useContext(SnackbarContext);
  const [browserNotif, setBrowserNotif] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [profile, setProfile] = useState<Profile>({});
  const init = {
    name: profile.user_name ? profile.user_name : "",
    notif_email: profile.notification_email ? profile.notification_email : "",
    checkedNotif: profile.email_notif ? profile.email_notif : false,
    email: profile.email ? profile.email : "",
    preferred_language: profile.preferred_language,
  };

  const {
    data: subscriptionData,
    isLoading: subscriptionLoading,
    refetch,
  } = useQuery(
    ["fetchingSubscription", auth.accessToken],
    () => {
      if (auth.accessToken) {
        return getSubscription(auth.accessToken);
      }
    },
    {
      onSuccess: (resp) => {},
      onError: () => {},
    },
  );

  const subscriberData = subscriptionData || null;
  const navigate = useNavigate();
  useEffect(() => {
    async function checkRegistered() {
      if (!("serviceWorker" in navigator))
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
      const registration = await navigator.serviceWorker.getRegistrations();
      if (registration[0] && registration.length > 0) {
        const subscription =
          await registration[0].pushManager.getSubscription();
        if (
          subscription &&
          subscription.endpoint === subscriberData?.data.subscription.endpoint
        ) {
          setBrowserNotif(true);
        }
        setIsRegistered(true);
      }
    }
    checkRegistered();
  }, [subscriberData]);

  const handleSuccessUserFetch = (data) => {
    setProfile(data.user);
  };

  const handleSuccessAuthUpdate = (data) => {
    setAuth((auth) => ({ ...auth, email: data?.user?.email }));
  };

  const {
    user: userData,
    isLoading: isUserDataLoading,
    refetch: refetchUserData,
    isRefetching: isUserDataRefetching,
  } = useUserFetch(
    auth,
    "nft",
    auth.user_id,
    [handleSuccessUserFetch],
    [],
    handleSuccessAuthUpdate,
  );

  const {
    mutate: mutateUpdateOwnerProfile,
    isLoading: isMutateUpdateOwnerProfileLoading,
  } = useMutation(
    ["updateOwnerProfile", updateOwnerProfile],
    (body: any) => {
      return updateOwnerProfile(auth.accessToken, body);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully updated the profile.",
          type: "success",
        });
        setEditMode(false);
        refetchUserData();
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateSubscribe } = useMutation(
    ["updateProfile", subscribeNotifs],
    (values: any) => {
      return subscribeNotifs(auth.accessToken, auth.user_id, values);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: " Subscribed",
          type: "success",
        });
        mutateUpdateOwnerProfile({
          user_id: auth.user_id,
          user: { pushNotif: true },
        });
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateUpdatePassword,
    isLoading: isMutateUpdatePasswordLoading,
  } = useMutation(
    ["resetPassword", resetPassword],
    (values: any) => {
      return resetPassword(auth.accessToken, values);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "New password successfully saved",
          type: "success",
        });
        setPasswordModal(false);
      },
      onError: (e) => {
        let message = e.toString();
        message = message.slice(6);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        setPasswordModal(false);
      },
    },
  );

  const { mutate: mutateAddWeb2, isLoading: isMutateAddWeb2Loading } =
    useMutation(
      ["addWeb2", addWeb2LoginRequest],
      (values: any) => {
        return addWeb2LoginRequest(auth.accessToken, values);
      },
      {
        onSuccess: (resp) => {
          setSnackbar({
            title: "Success",
            content: "Confirmation Email sent successfully",
            type: "success",
          });
          setWeb2Modal(false);
          refetchUserData();
        },
        onError: (e) => {
          setSnackbar({
            title: "Error",
            content: e.toString(),
            type: "error",
          });
        },
      },
    );

  const { mutate: mutateChangeEmail, isLoading: isMutateChangeEmailLoading } =
    useMutation(
      ["ChangeMail", changeWeb2Email],
      (values: any) => {
        return changeWeb2Email(auth.accessToken, values);
      },
      {
        onSuccess: (resp) => {
          setSnackbar({
            title: "Success",
            content: "Confirmation Email sent successfully",
            type: "success",
          });
          setEmailModal(false);
          refetchUserData();
        },
        onError: (message) => {
          setSnackbar({
            title: "Error",
            content: message.toString(),
            type: "error",
          });
        },
      },
    );

  const handleSubmit = (values: FormValues) => {
    const body = {
      user_id: auth.user_id,
      user: {
        user_name: values.name,
        notification_email: values.notif_email,
        email_notif: values.checkedNotif,
        preferred_language: values.preferred_language,
      },
    };
    mutateUpdateOwnerProfile(body);

    // reset the user_name in the localStorage
    localStorage.setItem("user_name", values.name);

    return;
  };
  const handleSubmitPassword = (values) => {
    mutateUpdatePassword(values);
  };
  function urlBase64ToUint8Array(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  async function registerServiceWorker() {
    return navigator.serviceWorker
      .register(new URL("./worker.js", import.meta.url), { type: "module" })
      .catch(function (err) {});
  }
  async function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result,
      ) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== "granted") {
      }
      return permissionResult;
    });
  }

  const handleWeb2Submit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    mutateAddWeb2(data);
  };
  const handleEmailSubmit = async (values) => {
    const data = {
      email: values.email,
    };
    mutateChangeEmail(data);
  };
  const handleBrowserNotif = async (value) => {
    if (value === true) {
      if (!("serviceWorker" in navigator)) {
        if (!("PushManager" in window)) {
        }
      } else if (Notification.permission === "default") {
        const worker = await registerServiceWorker();
        const permission = await askPermission();
        if (permission === "granted" && worker) {
          const subscription = await createSubscription(worker);
          mutateSubscribe(subscription);
        }
      }
    }
    setBrowserNotif(value);
  };
  const createSubscription = async (worker) => {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.VAPID),
    };
    return worker.pushManager
      .subscribe(subscribeOptions)
      .then(function (subscription) {
        JSON.stringify(subscription);
        return subscription;
      });
  };

  if (isUserDataLoading || isUserDataRefetching) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <StyledContainer className="antialiased bg-mainBg flex-row">
      <PageWrapper>
        {profile && !isUserDataLoading && (
          <ProfileHeader className="mb-2">
            <p className="font-Inter text-xs text-black">
              <span
                className=" text-black hover:cursor-pointer hover:underline"
                onClick={() => navigate("/")}>
                {profile.role === "admin"
                  ? "Admin Dashboard"
                  : "Owner Dashboard"}
              </span>
              {" > " + "Profile "}
            </p>
            <p className="font-Inter text-4xl text-black font-bold my-2">
              Profile
            </p>
          </ProfileHeader>
        )}

        <FormWrapper>
          {profile && !isUserDataLoading && (
            <>
              <div
                className={`flex flex-col p-5 ${
                  editMode ? "bg-[#CE6F7A]" : "bg-[#d6d1dab8]"
                } rounded-[10] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] px-7 pb-6`}>
                {editMode && (
                  <Formik
                    initialValues={init}
                    enableReinitialize={true}
                    validationSchema={ProfileSchema}
                    onSubmit={(values) => handleSubmit(values)}>
                    {({ values, setFieldValue, touched, errors }) => {
                      return (
                        <Form>
                          <Grid
                            container
                            columnGap={3}
                            rowGap={1}
                            className="flex grow-0">
                            <Grid
                              item
                              xs={12}
                              className="flex items-center mt-5">
                              <div className="text-base font-bold text-white">
                                Display Name
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ width: "38%" }}
                                className="bg-white rounded-md"
                                variant="filled"
                                size="small"
                                value={values.name}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    borderRadius: "0.375rem",
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                                onChange={(event) =>
                                  setFieldValue("name", event.target.value)
                                }
                              />
                              <FormError
                                top="-10px"
                                isAbsolute
                                sx={{ position: "relative" }}>
                                <>{touched.name && errors.name}</>
                              </FormError>
                            </Grid>

                            {/* Preferred Language START */}
                            <Grid
                              item
                              xs={12}
                              className="flex items-center mt-2">
                              <div className="text-base font-bold text-white">
                                Preferred Language for Translations:
                              </div>
                            </Grid>

                            <Grid item xs={12}>
                              <Select
                                className="bg-white border-0 rounded w-[20%] text-gray-700 leading-tight "
                                id="countries"
                                value={values.preferred_language}
                                onChange={(event) => {
                                  setFieldValue(
                                    "preferred_language",
                                    event.target.value,
                                  );
                                }}>
                                {languageList.map((language) => {
                                  return (
                                    <MenuItem
                                      key={language.id}
                                      value={language.id}>
                                      {language.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </Grid>
                            {/*Preferred Language END */}

                            <Grid
                              item
                              xs={12}
                              className="flex items-center mt-2">
                              <div className="text-base font-bold text-white">
                                Notification Email
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ width: "38%" }}
                                className="bg-white rounded-md"
                                variant="filled"
                                size="small"
                                value={values.notif_email}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    borderRadius: "0.375rem",
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                                onChange={(event) => {
                                  setFieldValue(
                                    "notif_email",
                                    event.target.value,
                                  );
                                  if (event.target.value === "") {
                                    setFieldValue("checkedNotif", false);
                                  } else {
                                    setFieldValue("checkedNotif", true);
                                  }
                                }}
                              />
                            </Grid>
                            {values.notif_email && (
                              <Grid
                                item
                                xs={12}
                                className="flex items-center mt-2">
                                <FormGroup>
                                  <FormControlLabel
                                    className="text-white"
                                    control={
                                      <Checkbox
                                        sx={{
                                          color:
                                            "rgb(111 90 205 / var(--tw-bg-opacity))",
                                          "&.Mui-checked": {
                                            color:
                                              "rgb(111 90 205 / var(--tw-bg-opacity))",
                                          },
                                        }}
                                        checked={values.checkedNotif}
                                        disabled={
                                          values.notif_email.length === 0
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "checkedNotif",
                                            !values.checkedNotif,
                                          );
                                        }}
                                      />
                                    }
                                    label="Send Notifications via Email"
                                  />
                                </FormGroup>
                              </Grid>
                            )}

                            <Grid
                              item
                              xs={12}
                              className="flex flex-row justify-end">
                              <Button
                                onClick={() => setEditMode(false)}
                                variant="text"
                                className=" w-full ax:w-28 h-12 text-white text-base font-inter font-bold rounded-full self-end mb-3 ax:mb-0 bg-transparent normal-case">
                                Cancel
                              </Button>
                              <Button
                                disabled={isMutateUpdateOwnerProfileLoading}
                                type="submit"
                                className="bg-[#6F5ACD] w-full ax:w-32 h-12 text-white font-bold font-inter text-base rounded-full self-end normal-case"
                                variant="contained">
                                Update
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
                {!editMode && (
                  <Grid
                    container
                    columnGap={3}
                    rowGap={2.5}
                    className="flex grow-0">
                    <Grid item xs={12} className="flex items-center ">
                      <div className="font-bold text-black text-3xl">
                        General
                      </div>
                    </Grid>
                    <Grid item xs={12} className="flex items-center ml-6">
                      <div className="text-base font-bold text-black mr-2">
                        Display Name:
                      </div>
                      <div className="text-base text-black">{init.name}</div>
                    </Grid>
                    {profile?.authorized_qualifications && (
                      <Grid item xs={12} className="flex items-center ml-6">
                        <div className="text-base font-bold text-black mr-2">
                          Authorized Qualifications:
                        </div>
                        <div className="text-base text-black">
                          {profile.authorized_qualifications
                            .map((x) => x.name)
                            .join(", ")}
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} className="flex items-center ml-6">
                      <div className="text-base font-bold text-black mr-2">
                        Preferred Language for Translations:
                      </div>
                      <div className="text-base text-black">
                        {
                          languageList.find(
                            (x) => x.id === profile.preferred_language,
                          )?.name
                        }
                      </div>
                    </Grid>

                    <Grid item xs={12} className="flex items-center ml-6">
                      <div className="text-base font-bold text-black mr-2">
                        Notification Email:
                      </div>
                      <div className="text-base text-black">
                        {profile?.email_notif
                          ? `Enabled (${profile?.notification_email})`
                          : `Disabled`}
                      </div>
                    </Grid>
                    {/* {profile && (
                      <Grid
                        item
                        xs={12}
                        className="flex flex-row items-center ml-6">
                        <div className="flex">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={browserNotif}
                                  disabled={
                                    Notification.permission === "denied"
                                  }
                                  onChange={() => {
                                    handleBrowserNotif(!browserNotif);
                                  }}
                                />
                              }
                              label="Send browser notifications"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                    )} */}
                    <Grid
                      item
                      xs={12}
                      className="flex flex-row items-center ml-1">
                      <Button
                        className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white text-bold mb-0  font-inter text-md rounded-full self-end mr-2"
                        variant="contained"
                        onClick={() => {
                          setEditMode(true);
                        }}>
                        Edit Profile
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
              {!editMode && (
                <div className="flex flex-col p-5 bg-[#d6d1dab8] rounded-[10] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] px-7 pb-6 mt-3">
                  <div className="text-black text-3xl font-bold">Wallet</div>
                  <div className="flex flex-col ml-6">
                    {profile.address && (
                      <div className="flex flex-row mb-4 mt-5">
                        <div className="text-base font-bold text-black mr-2">
                          Wallet Address:
                        </div>
                        <div className="text-base text-black">
                          {profile.address}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row">
                    {profile.address ? (
                      <Button
                        className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white text-bold  font-inter text-md rounded-full ml-1 self-end"
                        variant="contained"
                        onClick={() => {
                          navigate("/updateAddress", {
                            replace: true,
                            state: { change: true },
                          });
                        }}>
                        Change Address
                      </Button>
                    ) : (
                      <Button
                        className="bg-[#6F5ACD] w-full max-w-[200px] h-10 mt-4 text-white text-bold  font-inter text-md rounded-full ml-1 self-end"
                        variant="contained"
                        onClick={() => {
                          navigate("/updateAddress", {
                            replace: true,
                            state: { change: false },
                          });
                        }}>
                        Add Address
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {!editMode && (
                <div className="flex flex-col p-5 bg-[#d6d1dab8] rounded-[10] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] px-7 pb-6 mt-3 mb-8">
                  <div className="text-black text-3xl font-bold">
                    Email/Password
                  </div>
                  {profile?.email ? (
                    <>
                      <div className="flex flex-col ml-6">
                        <div className="flex flex-row mb-4 mt-5">
                          <div className="text-base font-bold text-black mr-2">
                            Email:
                          </div>
                          <div className="text-base text-black">
                            {init.email}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <Button
                          className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white text-bold  font-inter text-md rounded-full ml-2 self-end "
                          variant="contained"
                          onClick={() => {
                            setEmailModal(true);
                          }}>
                          Change Email
                        </Button>
                        <Button
                          className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white text-bold  font-inter text-md rounded-full ml-2 self-end"
                          variant="contained"
                          onClick={() => {
                            setPasswordModal(true);
                          }}>
                          Change Password
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col">
                      <Button
                        className="bg-[#6F5ACD] w-full max-w-[320px] h-10 text-white text-bold ml-2  font-inter text-md rounded-full mt-4 mb-1"
                        variant="contained"
                        onClick={() => {
                          setWeb2Modal(true);
                        }}>
                        Create Email/Password Login
                      </Button>
                      <div className="text-xs ml-2 mt-1">
                        *Adding email/password login will allow you to login
                        using your email address and password in addition to
                        logging in with your wallet address
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <Modal
            open={passwordModal}
            title="Change Password"
            onClose={() => {
              setPasswordModal(false);
            }}>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={PasswordSchema}
              onSubmit={(values) => handleSubmitPassword(values)}>
              {({ values, setFieldValue, touched, errors }) => {
                return (
                  <Form>
                    <Grid
                      container
                      columnGap={2}
                      rowGap={2}
                      className="flex grow-0">
                      <Grid item xs={12}>
                        <div
                          style={{ fontWeight: "800" }}
                          className="text-base font-bold text-black">
                          Current Password
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.old_password}
                          type="password"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue("old_password", event.target.value)
                          }
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>{touched.old_password && errors.old_password}</>
                        </FormError>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ fontWeight: "800" }}>New Password</div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.password}
                          type="password"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue("password", event.target.value)
                          }
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>{touched.password && errors.password}</>
                        </FormError>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ fontWeight: "800" }}>
                          Confirm Password
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.confirm_password}
                          type="password"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue(
                              "confirm_password",
                              event.target.value,
                            )
                          }
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>
                            {touched.confirm_password &&
                              errors.confirm_password}
                          </>
                        </FormError>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          disabled={isMutateUpdatePasswordLoading}
                          style={{
                            textTransform: "none",
                            color: "#ffffff",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            fontWeight: "700",
                            alignSelf: "flex-end",
                            width: "100%",
                            height: "3rem",
                            borderRadius: "9999px",
                            background: "#6F5ACD",
                          }}
                          type="submit"
                          variant="contained">
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
          <Modal
            open={emailModal}
            title="Change Email"
            onClose={() => {
              setEmailModal(false);
            }}>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={EmailSchema}
              onSubmit={(values) => {
                handleEmailSubmit(values);
              }}>
              {({ values, setFieldValue, touched, errors }) => {
                return (
                  <Form>
                    <Grid container rowGap={2} className="flex grow 0">
                      <Grid item xs={12} className="flex items-center mt-3">
                        <div style={{ fontWeight: "800" }}>Email</div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          value={values.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>{touched.email && errors.email}</>
                        </FormError>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          disabled={isMutateChangeEmailLoading}
                          style={{
                            textTransform: "none",
                            color: "#ffffff",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            fontWeight: "700",
                            alignSelf: "flex-end",
                            width: "100%",
                            height: "3rem",
                            borderRadius: "9999px",
                            background: "#6F5ACD",
                          }}
                          type="submit"
                          variant="contained">
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
          <Modal
            open={web2Modal}
            title="Add Email/Password"
            onClose={() => {
              setWeb2Modal(false);
            }}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={Web2Schema}
              onSubmit={(values) => {
                handleWeb2Submit(values);
              }}>
              {({ values, setFieldValue, touched, errors }) => {
                return (
                  <Form>
                    <Grid container columnGap={2} rowGap={2} xs={12}>
                      <Grid item xs={12}>
                        <div style={{ fontWeight: "800" }}>Email</div>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          value={values.email}
                          onChange={(event) =>
                            setFieldValue("email", event.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ fontWeight: "800" }}>Password</div>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          value={values.password}
                          onChange={(event) =>
                            setFieldValue("password", event.target.value)
                          }
                          type="password"
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>{touched.password && errors.password}</>
                        </FormError>
                      </Grid>
                      <Grid item xs={12} className="flex items-center mt-3">
                        <div style={{ fontWeight: "800" }}>
                          Confirm Password
                        </div>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          value={values.confirm_password}
                          onChange={(event) =>
                            setFieldValue(
                              "confirm_password",
                              event.target.value,
                            )
                          }
                          type="password"
                        />
                        <FormError
                          top="-10px"
                          isAbsolute
                          sx={{ position: "relative" }}>
                          <>
                            {touched.confirm_password &&
                              errors.confirm_password}
                          </>
                        </FormError>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          disabled={isMutateAddWeb2Loading}
                          style={{
                            textTransform: "none",
                            color: "#ffffff",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            fontWeight: "700",
                            alignSelf: "flex-end",
                            width: "100%",
                            height: "3rem",
                            borderRadius: "9999px",
                            background: "#6F5ACD",
                          }}
                          type="submit"
                          variant="contained">
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        </FormWrapper>
      </PageWrapper>
    </StyledContainer>
  );
};

export default Component;
