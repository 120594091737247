import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useDisconnect } from "wagmi";
import { confirmUser } from "../../../services/users";
import { authState, initialAuthState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

const Container = styled.div`
  height: calc(100% - 64px);
`;

const StyledTitle = styled.p`
  width: 940px;
  height: 59px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
`;

const StyledText = styled.p`
  display: flex;
  width: 378px;
  left: 531px;
  top: 309px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: 0.15px;
`;

const StyledButton = styled(Button)`
  background-color: white;
  display: flex;
  width: 378px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #6f5acd;
  color: #6f5acd;
`;
const ConfirmButton = styled(Button)`
  background-color: white;
  display: flex;
  width: 378px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #6f5acd;
  color: #6f5acd;
`;
const Component = () => {
  const setSnackbar = useContext(SnackbarContext);
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const [auth, setAuth] = useRecoilState(authState);
  const [searchParams] = useSearchParams();
  const params = searchParams.get("token");

  const logout = () => {
    disconnect();
    setAuth(initialAuthState);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
  };
  useEffect(() => {
    logout();
    mutateConfirmAccount(params);
  }, []);

  const {
    mutate: mutateConfirmAccount,
    isLoading: isLoadingAccount,
    error,
  } = useMutation(
    ["confirmAccount"],
    async (token: any) => {
      // console.log('taskId :>> ', taskId);
      return confirmUser(token);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "User Confirmed",
          type: "success",
        });
      },
      onError: (e: any, variables: any) => {
        setFailed(true);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const [email, setEmail] = useState("");
  return (
    <Container className="bg-mainBg">
      {isLoadingAccount && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
          <CircularProgress color="inherit" />
        </div>
      )}
      {!failed && !isLoadingAccount && (
        <div className="flex flex-col items-center  mt-[50px]">
          <StyledTitle>Confirmed</StyledTitle>
          <StyledText>Your email id is successfully confirmed.</StyledText>

          <ConfirmButton
            onClick={() => {
              navigate("/login");
            }}>
            Go to Login
          </ConfirmButton>
        </div>
      )}
      {failed && (
        <div className="flex flex-col items-center mt-[50px]">
          <StyledTitle>Failed</StyledTitle>

          <StyledText>Token invalid or Expired</StyledText>
          <ConfirmButton
            onClick={() => {
              navigate("/register/confirm");
            }}>
            Re-send Email
          </ConfirmButton>
        </div>
      )}
    </Container>
  );
};

export default Component;
