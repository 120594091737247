import React, { useContext } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { getUserById } from "../../../services/users";

import LoginTemplate, { initialValues } from "../../templates/Login";

import { loginPA } from "../../../services/auth";
import { authState, globalState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

const Container = styled.div`
  height: calc(100% - 64px);
`;

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [global, setGlobal] = useRecoilState(globalState);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();

  function parseJwt(token) {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  }
  const {
    mutate: mutateLoginUser,
    isLoading,
    error,
  } = useMutation(
    "loginUser",
    async (values: typeof initialValues) => {
      const { code, data } = await loginPA(values);
      if (code !== 200) {
        setSnackbar({
          title: "Error",
          content: data.message ? data.message : data.reason,
          type: "error",
        });
        throw new Error(code.toString());
      } else return data;
    },
    {
      onSuccess: async (resp) => {
        const tokenInfo = parseJwt(resp?.token);
        const result = await getUserById(resp.token, resp.user_id);

        const Role =
          tokenInfo?.payload?.role === "nft" ? "owner" : tokenInfo.payload.role;
        setAuth((auth) => ({
          ...auth,
          user_id: resp?.user_id,
          role: Role,
          accessToken: resp?.token,
          user_name: resp?.user_name,
          walletAddress: result.user.address,
          payout_address: result.user.payout_address ?? null,
          email: result.user.email,
        }));
        localStorage.setItem("user_id", resp?.user_id);
        localStorage.setItem("accessToken", resp?.token);
        localStorage.setItem("role", Role);
        localStorage.setItem("user_name", resp?.user_name ?? "null");
        setSnackbar({
          title: "Success",
          content: "Logged in!",
          type: "success",
        });
        if (global.redirect_url) {
          setGlobal({
            ...global,
            redirect_url: "",
          });
          navigate(global.redirect_url);
        } else navigate("/");
      },
      onError: (e, variables) => {
        console.log("error", e);
        if (e.message === "409") navigate("/register/confirm");
        else navigate("/login");
      },
    },
  );

  return (
    <Container className="bg-mainBg">
      <LoginTemplate handleSubmit={mutateLoginUser} isLoading={isLoading} />
    </Container>
  );
};

export default Component;
