/**
 *
 * @param {{nomargin:boolean}} props
 * @returns
 */

const Component = (props) => {
  const { nomargin } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="3"
      stroke="currentColor"
      className={`w-4 h-4 ${!nomargin ? "mt-1 mr-2" : ""}`}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
      />
    </svg>
  );
};

export default Component;
