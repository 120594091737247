import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import TinderCard from "react-tinder-card";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { CheckButton, XButton } from "../../../assets/svg";
import Counter from "../../../components/organisms/Counter";
import {
  changeTaskStatus,
  getMissionsForPool,
  getTasksForPool,
} from "../../../services/tasks";
import {
  acceptMission,
  getTasksTimeout,
  setTasksTimeout,
} from "../../../services/users";
import { authState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import useUserFetch from "../../hooks/useUserFetch";
import Modal from "../../organisms/Modal";
import TaskCardTemplate from "../../templates/TaskCard";
import {
  CancelButton,
  StyledCardContainer,
  StyledContainer,
  StyledTaskTitle,
} from "./TaskCards.style";

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;
  margin-top: 10px;

  &:hover {
    background-color: #e8735a;
  }
`;

const Cancel2Button = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

const Component = ({ isMission }) => {
  const [id, setId] = useState<string | null>(null);
  const [confirm, setConfirm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [auth, setAuth] = useRecoilState(authState);
  const [waitForRightSwipe, setWaitForRightSwipe] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isClarifying, setIsClarifying] = useState(false);
  const [clarificationValue, setClarificationValue] = useState("");
  const [clickedConfirm, setClickedConfirm] = useState(false);

  const [timeoutEnded, setTimeoutEnded] = useState(false);
  const [key, setKey] = useState(0);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleFetchSuccess = (data) => {
    const { user } = data;
    if (
      !user.qualifications.find((qualif) => qualif.name === "General") &&
      !isMission
    ) {
      navigate("/dashboard");
      setSnackbar({
        title: "Unauthorized",
        content:
          "User is not yet onboarded, please complete the required missions first",
        type: "error",
      });
      return;
    }

    if (user.is_eligible === false && !isMission) {
      navigate("/dashboard");
      setSnackbar({
        title: "Unauthorized",
        content:
          "User / assistant is currently put on hold, please contact an admin for further discussion.",
        type: "error",
      });
      return;
    }
  };

  const {
    user: userData,
    isLoading: isUserDataLoading,
    isError: userDataError,
  } = useUserFetch(auth, "assistant", auth.user_id, [handleFetchSuccess]);

  const {
    data: userTimeout,
    isLoading: userTimeoutLoading,
    refetch: refetchUserTimeout,
    isRefetching: isRefetchingUserTimeout,
  } = useQuery(["fetchingUserTimeout", auth.accessToken], () => {
    if (auth.accessToken) {
      return getTasksTimeout(auth.accessToken);
    }
  });

  const {
    data: taskData,
    isLoading: isTasksLoading,
    refetch,
    isRefetching: isTaskRefetching,
  } = useQuery(
    ["fetchingTasksPublished", auth.accessToken],
    async () => {
      if (auth.accessToken) {
        if (isMission) {
          return await getMissionsForPool(auth.accessToken);
        } else {
          return await getTasksForPool(auth.accessToken);
        }
      }
    },
    {
      onSuccess: (resp) => {
        const taskLength = resp.tasks.length;

        setCurrentIndex(taskLength > 0 ? taskLength - 1 : -2);
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content: e.toString(),
          type: "error",
        });
        navigate("/");
      },
    },
  );

  const {
    mutate: mutateSetTimeout,
    isLoading: isSettingTimeout,
    error: errorSettingTimeout,
  } = useMutation(
    ["setTaskTimeout", auth.accessToken],
    () => {
      return setTasksTimeout(auth.accessToken);
    },
    {
      onSuccess: (resp, variables) => {
        refetchUserTimeout();
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        navigate("/");
      },
    },
  );

  const {
    mutate: mutateAcceptMission,
    isLoading: isAcceptingMission,
    error: errorAcceptingMission,
  } = useMutation(
    ["acceptMission", auth.accessToken],
    (taskPayload) => {
      return acceptMission(auth.accessToken, taskPayload);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "The mission was successfully assigned to you.",
          type: "success",
        });
        navigate(`/`, { replace: true });
      },
      onError: (e: any, variables: any) => {
        let content = e.message
          ? e.message
          : "We are having some technical issues, please try again later.";
        setSnackbar({
          title: "Error",
          content: content,
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateAcceptTask,
    isLoading: isAcceptingTask,
    error,
  } = useMutation(
    ["acceptTask", auth.accessToken],
    async (payload: any) => {
      // let tempUser = result.user;
      if (payload.status === "in_progress") {
        if (userData.activeTasks && userData.activeTasks.length > 1) {
          return Promise.reject({
            message:
              "You already have two active tasks. Please, finish one of those first.",
          });
        }
      } else if (payload.status === "clarification_needed") {
        if (
          userData.tasksBeingClarified &&
          userData.tasksBeingClarified.length >= 3
        ) {
          return Promise.reject({
            message:
              "You already have 3 tasks that needs clarification. Please, get one of those clarifed before adding more.",
          });
        }
      }

      return changeTaskStatus(auth.accessToken, payload);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "The task was successfully assigned to you.",
          type: "success",
        });
        navigate("/", { replace: true });
      },
      onError: (e: any, variables: any) => {
        console.log("error", e.message);
        let content = e.message
          ? e.message
          : "We are having some technical issues, please try again later.";
        setSnackbar({
          title: "Error",
          content: content,
          type: "error",
        });
      },
    },
  );

  let a = taskData?.tasks || [];
  const tasks = [...a].reverse();

  const childRefs = useMemo(
    () =>
      Array(tasks.length)
        .fill(0)
        .map((i) => React.createRef()),
    [tasks],
  );

  const outOfFrame = (name, idx) => {
    setWaitForRightSwipe(true);
    currentIndexRef.current >= idx && childRefs[idx]?.current?.restoreCard();
  };

  const { mutate: mutateRejectTask } = useMutation(
    ["RejectTask"],
    async (taskId: any) => {
      if (isMission) {
        return;
      }

      changeTaskStatus(auth.accessToken, {
        taskId: taskId,
        status: "declined",
      });
    },
    {
      onError: (e: any, variables: any) => {
        console.log("error", e);
        let content = e.message
          ? e.message
          : "Failed to assign the task. Please, try again.";
        setSnackbar({
          title: "Error",
          content: content,
          type: "error",
        });
      },
    },
  );

  const swipe = async (dir) => {
    try {
      if (canSwipe && currentIndex < tasks.length) {
        setWaitForRightSwipe(false);
        await childRefs?.[currentIndex]?.current?.swipe(dir); // Swipe the card!
      } else {
        throw new Error("hello");
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swiped = (direction: string, task_id: string, index: number) => {
    if (direction === "right" && !confirm) {
      setId(task_id);
      openConfirmModal();
      return;
    }
    if (!isMission) {
      if (direction === "left") {
        mutateRejectTask(task_id);
      }
    }
    updateCurrentIndex(index - 1);
  };

  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const canGoBack = currentIndex < tasks.length - 1;

  // const canSwipe = currentIndex >= 0 && !isSwiping;
  const canSwipe = currentIndex >= 0;
  const handleSwipe = (direction: "right" | "left", task_id?) => () => {
    if (direction === "right") {
      setId(task_id);
      openConfirmModal();
      return;
    }

    swipe(direction);
  };

  useEffect(() => {
    if (!isMission) {
      if (currentIndex === -1 && !confirm) {
        mutateSetTimeout();
      }
    }
  }, [currentIndex, confirm]);

  if (
    userTimeoutLoading ||
    isTasksLoading ||
    isAcceptingTask ||
    isSettingTimeout ||
    (timeoutEnded && isRefetchingUserTimeout)
  ) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div key={key}>
      <StyledContainer>
        {!isMission && userTimeout.timeout_ended && currentIndex === -2 ? (
          <>
            <div className="card flex self-center w-[423px] overflow-y-hidden">
              <StyledCardContainer>
                <StyledTaskTitle>No Available Tasks</StyledTaskTitle>
                <div></div>
                {!isMission && (
                  <>
                    <div className="text-sm md:text-lg text-white text-left font-medium mt-2 ">
                      Sorry, curently there are no available tasks. Please check
                      back later and ensure your email notifications are turned
                      on to be notified within 10 minutes of a new task
                      appearing.
                    </div>
                  </>
                )}

                <div className="h-full flex mb-11 items-end">
                  <CancelButton
                    variant="contained"
                    className="h-[38px]"
                    onClick={() => navigate("/")}
                    fullWidth>
                    Return to Dashboard
                  </CancelButton>
                </div>
              </StyledCardContainer>
            </div>
          </>
        ) : (
          <>
            {Boolean(
              !(timeoutEnded && isRefetchingUserTimeout) &&
                ((!isMission && !userTimeout.timeout_ended) ||
                  (taskData?.tasks?.length === 0 && isMission) ||
                  currentIndex === -1),
            ) && (
              <div className="card flex self-center w-[423px] overflow-y-hidden">
                <StyledCardContainer>
                  <StyledTaskTitle>
                    You have viewed all {isMission ? "missions" : "tasks"}
                  </StyledTaskTitle>
                  <div></div>
                  {!isMission && (
                    <>
                      <div className="text-sm md:text-lg text-white text-left font-medium mt-2 ">
                        Please wait for a period of time for the tasks to become
                        available again.
                      </div>
                      {userTimeout?.seconds_left && (
                        <Counter
                          time={userTimeout.seconds_left}
                          hasTask={taskData?.tasks?.length > 0}
                          taskDataRefetch={refetch}
                          hasTimeoutEnded={hasTimeoutEnded}
                        />
                      )}
                      {timeoutEnded && taskData?.tasks?.length > 0 && (
                        <div
                          title=""
                          onClick={() => {
                            refetchUserTimeout();
                            setKey((key) => key + 1);
                          }}
                          role="button"
                          className="w-full  text-white cursor-pointer hover:opacity-90 font-bold underline italic pt-2">
                          Click to see them!
                        </div>
                      )}
                    </>
                  )}

                  {!isMission ? (
                    <div className="h-full flex mb-11 items-end">
                      <CancelButton
                        variant="contained"
                        className="h-[38px]"
                        onClick={() => navigate("/")}
                        fullWidth>
                        Return to Dashboard
                      </CancelButton>
                    </div>
                  ) : (
                    <div className="h-full flex mb-11 items-end">
                      <CancelButton
                        variant="contained"
                        className="h-[38px]"
                        onClick={() => navigate("/")}
                        fullWidth>
                        Return to Dashboard
                      </CancelButton>
                    </div>
                  )}
                </StyledCardContainer>
              </div>
            )}

            {Boolean(
              (!isMission && userTimeout.timeout_ended) ||
                (isMission &&
                  taskData?.tasks?.length > 0 &&
                  currentIndex !== -1),
            ) && (
              <div
                className={`cardContainer relative flex self-start justify-center flex-row`}>
                {tasks?.length > 0 &&
                  tasks.map((task, index) => (
                    <TinderCard
                      ref={childRefs[index]}
                      className={`swipe${
                        taskData?.tasks[
                          taskData.tasks.length - 1 - currentIndex
                        ]?.task_id === task.task_id
                          ? " current"
                          : ""
                      }`}
                      key={task.task_id}
                      preventSwipe={["up", "down"]}
                      onSwipe={(dir) => swiped(dir, task.task_id, index)}
                      onSwipeRequirementUnfulfilled={() => setIsSwiping(true)}
                      onCardLeftScreen={() => {
                        outOfFrame(task.task_id, index);
                      }}>
                      <TaskCardTemplate
                        {...task}
                        setIsSwiping={setIsSwiping}
                        canSwipe={canSwipe}
                        handleSwipe={handleSwipe}
                      />
                    </TinderCard>
                  ))}
              </div>
            )}

            {Boolean(
              (!isMission &&
                userTimeout.timeout_ended &&
                (!waitForRightSwipe || taskData?.tasks?.length > 0)) ||
                (isMission &&
                  taskData?.tasks?.length > 0 &&
                  currentIndex !== -1),
            ) && (
              <div className={`buttons flex mb-12 ac:mb-16 self-center`}>
                <button
                  data-test-id="tasks-reject-button"
                  className="block bg-[#ffffff] h-[70px] w-[70px] rounded-[50%] cursor-pointer border-[transparent] mr-4"
                  onClick={handleSwipe(
                    "left",
                    taskData?.tasks[taskData.tasks.length - 1 - currentIndex]
                      ?.task_id,
                  )}>
                  <XButton />
                </button>

                <button
                  data-test-id="tasks-accept-button"
                  className="block bg-[#6F5ACD] h-[70px] w-[70px] rounded-[50%] cursor-pointer border-[transparent] ml-4"
                  onClick={handleSwipe(
                    "right",
                    taskData?.tasks[taskData.tasks.length - 1 - currentIndex]
                      ?.task_id,
                  )}>
                  <CheckButton />
                </button>
              </div>
            )}
          </>
        )}

        <Modal
          title="Confirm Task"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={confirm}
          onClose={closeModal}>
          <div style={{ maxWidth: 450 }} data-test-id="tasks-accept-modal">
            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <Typography fontWeight={700} fontFamily="Inter">
                Are you sure you want to Accept this{" "}
                {isMission ? "mission" : "task"}?
              </Typography>
            </div>
            <ConfirmButton
              data-test-id="tasks-accept-modal-yes-button"
              variant="contained"
              onClick={() => {
                handleConfirm("in_progress");
              }}
              fullWidth>
              Yes, accept this {isMission ? "mission" : "task"}.
            </ConfirmButton>
            {!isMission && (
              <ConfirmButton
                data-test-id="tasks-accept-modal-clarification-button"
                onClick={() => {
                  closeModal();
                  openClarificationModal();
                }}
                fullWidth>
                Yes, but I need to clarify something
              </ConfirmButton>
            )}

            <Cancel2Button variant="contained" onClick={closeModal} fullWidth>
              No, Cancel
            </Cancel2Button>
          </div>
        </Modal>

        <Modal
          title="Task Clarification"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={isClarifying}
          onClose={() => {
            setIsClarifying(false);
          }}>
          <div
            style={{ maxWidth: 450 }}
            data-test-id="tasks-clarification-modal">
            <div style={{ textAlign: "center" }}>
              <Typography
                fontWeight={700}
                fontFamily="Inter"
                style={{ paddingBottom: "12px" }}>
                What do you need to clarify in the task?
              </Typography>
              <TextField
                fullWidth
                placeholder="State the what needs to be clarified"
                multiline={true}
                rows={4}
                sx={{ height: "150px" }}
                variant="filled"
                onChange={(e) => {
                  setClarificationValue(e.target.value);
                }}
                inputProps={{
                  "data-test-id": "tasks-clarification-modal-input",
                }}
              />
            </div>
            <ConfirmButton
              data-test-id="tasks-clarification-modal-submit-button"
              onClick={() => handleConfirm("clarification_needed")}
              variant="contained"
              disabled={!clarificationValue}
              fullWidth>
              Submit
            </ConfirmButton>
          </div>
        </Modal>
      </StyledContainer>
    </div>
  );
  function closeModal() {
    currentIndexRef.current = currentIndex;
    setConfirm(false);
  }

  function openConfirmModal() {
    setConfirm(true);
  }

  function hasTimeoutEnded(ended) {
    setTimeoutEnded(ended);
  }

  function openClarificationModal() {
    setIsClarifying(true);
  }
  function handleConfirm(status: string) {
    if (clickedConfirm) return;
    setClickedConfirm(true);

    if (status === "clarification_needed") {
      return mutateAcceptTask({
        status: status,
        taskId: id,
        reason: clarificationValue,
      });
    }

    if (isMission) {
      return mutateAcceptMission({ task_id: id });
    }

    return mutateAcceptTask({
      status: status,
      taskId: id,
    });
  }
};

export default Component;
