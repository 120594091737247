import { languageList } from "../assets/languages";

const isHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const translationSources = {
  EDITOR: "editor",
  BASIC: "basic",
};

/**
 *
 * @param {any} id
 * @returns {string}
 */

const getLanguageNameById = (id) => {
  if (!id) {
    return languageList.find((x) => x.id === "en").name;
  }
  return languageList.find((x) => x.id === id).name;
};

const formatCommentDate = (dateToFormat) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateToFormat);

  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  return `${dayName} ${monthName} ${day} ${year} - ${hours}:${minutesStr} ${ampm}`;
};

export { formatCommentDate, getLanguageNameById, isHTML, translationSources };
