import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { getUserById } from "../../../services/users";
import { authState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

const PageWrapper = styled.div`
  min-width: 1100px;
  width: 75%;
  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const GiftPageWrapper = styled.div`
  width: 900px;
  height: 600px;

  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flexdirection: column;
  height: 100%;
  justify-content: center;
  min-width: 1100px;
  width: 100%;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const ProfileHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;
const FormWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

type FormValues = {
  id?: string;
  name: string;
  notif_email: string;
  checkedNotif: boolean;
};
type Profile = {
  address?: string;
  email_notif?: boolean;
  nonce?: string;
  notification_email?: string;
  remaining_energy?: number;
  role?: string;
  user_name?: string;
  hasPassword?: boolean;
};

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const setSnackbar = useContext(SnackbarContext);
  const [profile, setProfile] = useState<Profile>({});
  const init = {
    name: profile.user_name ? profile.user_name : "",
    notif_email: profile.notification_email ? profile.notification_email : "",
    checkedNotif: profile.email_notif ? profile.email_notif : false,
  };

  const navigate = useNavigate();
  useEffect(() => {
    async function runProfile() {
      const data = await getUserById(auth.accessToken, auth.user_id);
      setProfile(data.user);
    }
    if (auth.user_id && !profile?.address?.length) runProfile();
  }, [profile.address?.length]);

  return (
    <StyledContainer className="antialiased bg-mainBg flex-row">
      <PageWrapper className="">
        <ProfileHeader className="mb-10">
          <p className="font-Inter text-xs text-black">
            <span
              className=" text-black hover:cursor-pointer hover:underline"
              onClick={() => navigate("/")}>
              Owner Dashboard
            </span>
            {" > " + "Gift Energy "}
          </p>

          <p className="font-Inter text-4xl text-black font-bold my-2">
            Gift Energy
          </p>
        </ProfileHeader>
        <div className="flex flex-col w-full h-full items-center">
          <GiftPageWrapper className="flex flex-col items-center justify-items-center">
            <p className="font-Inter text-4xl my-[41px] text-black font-bold">
              Gift energy to a friend
            </p>
            <div className="flex flex-row">
              <div className="flex  mr-[28px] rounded-[10px] bg-[#F9F9F9] h-[415px] flex-col w-[413px]">
                <p className="font-Inter text-[15px] ml-[15px] mt-[18px] text-black font-bold">
                  Gift Details
                </p>
              </div>
              <div className="flex bg-[#E0848D] rounded-[10px] flex-col h-[415px] w-[309px]">
                DEF
              </div>
            </div>
          </GiftPageWrapper>
        </div>
      </PageWrapper>
    </StyledContainer>
  );
};

export default Component;
