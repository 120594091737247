const Component = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 50C90 72.0914 72.0914 90 50 90C27.9086 90 10 72.0914 10 50C10 27.9086 27.9086 10 50 10C72.0914 10 90 27.9086 90 50ZM50.0001 35C48.1557 35 46.538 35.9961 45.6659 37.5036C44.2832 39.8939 41.2246 40.7107 38.8343 39.328C36.444 37.9453 35.6271 34.8867 37.0099 32.4964C39.5959 28.0259 44.4417 25 50.0001 25C58.2843 25 65.0001 31.7157 65.0001 40C65.0001 46.5311 60.826 52.0873 55.0001 54.1465V55C55.0001 57.7614 52.7615 60 50.0001 60C47.2386 60 45.0001 57.7614 45.0001 55V50C45.0001 47.2386 47.2386 45 50.0001 45C52.7615 45 55.0001 42.7614 55.0001 40C55.0001 37.2386 52.7615 35 50.0001 35ZM50 75C52.7614 75 55 72.7614 55 70C55 67.2386 52.7614 65 50 65C47.2386 65 45 67.2386 45 70C45 72.7614 47.2386 75 50 75Z"
        fill="#111827"
      />
    </svg>
  );
};

export default Component;
