import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { CheckIcon, LoadingIcon, SubmitIcon } from "../../../assets/svg";
import { uploadFiles } from "../../../services/upload";
import { authState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

const StyledItemContainer = styled.div``;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 130,
  height: 130,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  @media (min-width: 768px) {
    margin-left: 1rem;
    width: 14rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

type Props = {
  onFinishUploading: (attachments: string[]) => void;
};
const Component = ({ onFinishUploading }: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilesToUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const [auth, setAuth] = useRecoilState(authState);
  const [uploadCount, setuploadCount] = useState(0);
  const [debounceKey] = useDebounce(uploadCount, 1000);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const setSnackbar = useContext(SnackbarContext);
  const [filesToUpload, setFilesToUpload] = useState([] as File[]);
  const imageTypes = ["image/gif", "image/jpeg", "image/png"];
  //var filesAdded: File[] = [];

  const thumbs = filesToUpload.map((file) => (
    <div style={thumb} key={file.name}>
      {!imageTypes.includes(file["type"]) ? (
        <div className="truncate">{file.name}</div>
      ) : (
        <div className="w-full h-full" style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            className="m-auto"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      )}
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      filesToUpload.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleUploadClick = async (component) => {
    setIsUploading(true);
    if (filesToUpload?.length >= 1) {
      const result = await uploadFiles(filesToUpload, auth.accessToken);

      setuploadCount(uploadCount + 1); // force component to re-render
      setFilesToUpload([] as File[]); // cear the state
      setIsUploading(false);

      // Update the task object after upload
      if (result.success) {
        setIsUploaded(true);
        onFinishUploading(result.uploaded);
        setTimeout(() => {
          setIsUploaded(false);
        }, 1000);
      } else {
        setIsUploading(false);
        setSnackbar({
          title: "Error",
          content: "Failed to upload file/s. Please try again.",
          type: "error",
        });
      }
    } else {
      setSnackbar({
        title: "Error",
        content: "No files to upload.",
        type: "error",
      });
    }
  };

  return (
    <StyledItemContainer>
      <section className="container">
        <div {...getRootProps()} className="w-full hover:opacity-90">
          <input autoFocus={true} {...getInputProps()} />
          <label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
            <span className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <span className="font-medium text-gray-600">
                Drop files to Attach, or
                <span className="text-blue-600 underline pl-1">browse</span>
              </span>
            </span>
          </label>
        </div>
        <aside className="object-contain" style={thumbsContainer}>
          {thumbs}
        </aside>
      </section>
      <TaskPageButton
        id="uploadButton"
        className={
          (filesToUpload.length === 0 && !isUploaded ? "hidden " : "") +
          "w-40 ml-0"
        }
        onClick={handleUploadClick}>
        <span>
          {isUploading ? (
            <LoadingIcon></LoadingIcon>
          ) : isUploaded ? (
            <CheckIcon></CheckIcon>
          ) : (
            <SubmitIcon></SubmitIcon>
          )}
        </span>
        <span>
          {isUploading ? "Uploading" : isUploaded ? "Uploaded" : "Upload"}
        </span>
      </TaskPageButton>
    </StyledItemContainer>
  );
};

export default Component;
