export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.84078 12.3528C4.3645 11.4915 6.12485 11 8 11C9.87515 11 11.6355 11.4915 13.1592 12.3528M10.25 6.5C10.25 7.74264 9.24264 8.75 8 8.75C6.75736 8.75 5.75 7.74264 5.75 6.5C5.75 5.25736 6.75736 4.25 8 4.25C9.24264 4.25 10.25 5.25736 10.25 6.5ZM14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
