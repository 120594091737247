import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import USDC_ICON from "../../../assets/images/usd-coin-usdc-logo.png";
import { EnergyBar } from "../../../assets/svg";
import { authState } from "../../../state/atoms";
const StyledInnerContainer = styled.span`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  // background-color: blue;
`;

const StyledInnerHeader = styled.span`
  display: flex;
  // margin-top: -0.5%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  // background-color: green;
  color: white;
  font-size: 12px;
  margin-right: 0px;

  .fname {
    font-size: 42px;
    font-weight: bold;
  }
`;

const StyledInnerHeaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  // background-color: green;
  color: white;
  font-size: 12px;
  margin-right: 0px;

  height: 100%;

  .fname {
    font-size: 42px;
    font-weight: bold;
  }
`;

const StyledWhiteButtonTaskStatus = styled.div`
  background-color: #f6f6f6;
  // z-index: 200;
  border-radius: 50px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  border: none;
  margin-top: -5%;
  color: black;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
`;

const KnowledgeBox = styled(Box)`
  padding: 10px;
  margin: 0 auto;
  background: #f9f9f9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-indent: 5px;

  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-top: 8px;
  cursor: pointer;
`;

const KnowledgeTitle = styled(Box)`
  padding-top: 10px;
  padding-bottom: 20px;
  margin: 0% auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-indent: 5px;
  display: flex;
  color: #fff;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
`;

const Component = ({
  label,
  onClick,
  className,
  needClarificationTasks = [],
  completedTasks = 0,
  forReviewTasks = 0,
  userEarnings,
}: any) => {
  const [auth, setAuth] = useRecoilState(authState);
  return (
    <>
      <StyledInnerContainer>
        <div className="w-full h-[300px] py-6 bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeaders>
            <div className="flex flex-col">
              <div className="date px-8 pb-2" data-test-id="dashboard-date">
                {GetDatePerUserTimezone()}
              </div>

              <div className="fname px-8" data-test-id="dashboard-name-text">
                {auth.user_name || ""}
              </div>
            </div>

            <div className="flex justify-center flex-row text-center w-full">
              <Link to="/profile" className="no-underline">
                <StyledWhiteButtonTaskStatus
                  data-test-id="dashboard-view-profile-btn"
                  className="hover:opacity-90 hover:scale-[1.1] ease-in-out duration-300">
                  VIEW PROFILE
                </StyledWhiteButtonTaskStatus>
              </Link>
            </div>
          </StyledInnerHeaders>
        </div>
        <div className="w-full h-[125] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-user-earnings-container">
            <span className="accomplished ml-[30]">TOTAL EARNINGS</span>
            <div className="totalAccomplished ml-[30] text-[36px] font-bold mt-[5%] flex flex-row items-center gap-2">
              <div className="" data-test-id="dashboard-user-earnings">
                {userEarnings.total_usd}
              </div>
              <div className="flex items-center justify-center bg-title rounded-full p-1.5">
                <img
                  className="w-5 hover:opacity-80 object-contain h-5"
                  title="$USDC"
                  src={USDC_ICON}
                />
              </div>
            </div>
          </StyledInnerHeader>
        </div>
        <div className="w-full h-[125] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-user-energy-container">
            <span className="accomplished ml-[30]">TOTAL ENERGIES</span>
            <div className="totalAccomplished ml-[30] text-[36px] font-bold mt-[5%] flex flex-row items-center gap-2">
              <div className="" data-test-id="dashboard-user-energy">
                {userEarnings.total_energy}
              </div>
              <div className=" bg-title rounded-full shadow-2xl">
                <div
                  className="hover:opacity-80 flex items-center justify-center  p-1.5"
                  title="Energies">
                  <EnergyBar filled color={"#FFBF00"} />
                </div>
              </div>
            </div>
          </StyledInnerHeader>
        </div>

        <div className="w-full min-h-[300px] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-knowledge-bits-container">
            <KnowledgeTitle>Knowledge Bits</KnowledgeTitle>
            {/* <span className="accomplished ml-[30]">APP GUIDES</span> */}
            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() =>
                window.open("https://help.humantaskforce.com/", "_blank")
              }
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Ask Ally - AI Assisted help
            </KnowledgeBox>
            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() =>
                window.open("https://bit.ly/pa-handbook", "_blank")
              }
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Getting Started
            </KnowledgeBox>
            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() => window.open("http://bit.ly/3GJmots", "_blank")}
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Best Practices
            </KnowledgeBox>

            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() =>
                window.open("https://academy.humandao.org/", "_blank")
              }
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Learn Crypto/Web3
            </KnowledgeBox>
            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() =>
                window.open(
                  "https://humandao.notion.site/Using-a-crypto-wallet-74737078120742a294068c66e538298f?pvs=4",
                  "_blank",
                )
              }
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Import tokens/networks
            </KnowledgeBox>
            <KnowledgeBox
              data-test-id="dashboard-knowledge-bits-item"
              onClick={() =>
                window.open("https://app.humandao.org/buyhdao", "_blank")
              }
              className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
              Where to get $HDAO
            </KnowledgeBox>
          </StyledInnerHeader>
        </div>

        <div className="w-full h-[125] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-tasks-status-container">
            <span className="accomplished ml-[30]">COMPLETED</span>
            <div className="totalAccomplished ml-[30] text-[36px] font-bold mt-[5%]">
              <div className="w-[100%]" data-test-id="dashboard-tasks-status">
                {completedTasks}
              </div>
              <span className="absolute -mt-[20%] ml-[62%] w-[100vw]"></span>
            </div>
          </StyledInnerHeader>
        </div>

        <div className="w-full h-[125] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-tasks-status-container">
            <span className="accomplished ml-[30]">CLARIFICATION</span>
            <div className="totalAccomplished ml-[30] text-[36px] font-bold mt-[5%]">
              <div className="w-[100%]" data-test-id="dashboard-tasks-status">
                {needClarificationTasks.length}
              </div>
              {/* <span className="absolute -mt-[20%] ml-[68%] w-[100vw]">
                <WhiteButtonSmall
                  label={"VIEW ALL"}
                  onClick={undefined}
                  className={""}
                />
              </span> */}
            </div>
          </StyledInnerHeader>
        </div>

        <div className="w-full h-[125] pt-[20] bg-[#806AD2] rounded-[10] pb-[30] mb-[20] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <StyledInnerHeader data-test-id="dashboard-tasks-status-container">
            <span className="accomplished ml-[30]">REVIEW</span>
            <div className="totalAccomplished ml-[30] text-[36px] font-bold mt-[5%]">
              <div className="w-[100%]" data-test-id="dashboard-tasks-status">
                {forReviewTasks}
              </div>
              {/* <span className="absolute -mt-[20%] ml-[68%] w-[100vw]">
                <WhiteButtonSmall
                  label={"VIEW ALL"}
                  onClick={undefined}
                  className={""}
                />
              </span> */}
            </div>
          </StyledInnerHeader>
        </div>
      </StyledInnerContainer>
    </>
  );
};

export default Component;
function Button(Button: any) {
  throw new Error("Function not implemented.");
}

function GetDatePerUserTimezone() {
  let date = new Date();
  date.toISOString().split("T")[0];

  return `${date
    .toLocaleString("en-us", {
      weekday: "long",
    })
    .toUpperCase()},
     ${date.toLocaleString("en-us", { month: "long" }).toUpperCase()} 
     ${date.toLocaleString("en-us", { day: "numeric" })}`;
}
