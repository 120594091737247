const Component = (props) => {
  return (
    <svg
      height={props.height ?? "16"}
      width={props.width ?? "16"}
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 1L8 8L1 1"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Component;
