import React from "react";

const Component = () => {
  return (
    <main className="h-[calc(100%-64px)] text-title bg-mainBg">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <section className="place-self-center justify-center justify-items-center items-center pt-[16%] lg:col-span-7">
          <h1 className="text-center text-4xl text-title font-extrabold leading-none tracking-tight text-slate-900 sm:text-7xl sm:leading-[3.5rem] lg:text-left">
            Small tasks? <br /> Not on your watch.
          </h1>
          <p className="text-center mt-3 md:mt-6 text-xl md:text-2xl leading-7 font-medium text-slate-700 text-black lg:text-left">
            Time is money. A Pocket Assistant saves you both.
          </p>
        </section>
        <div className="mx-auto lg:mt-0 mt-4 lg:col-span-5 lg:flex transform -rotate-6 transition hover:scale-40 duration-700 ease-in-out hover:rotate-6">
          <img
            loading="lazy"
            alt="Pocket Assistant"
            className="lg:mx-0 mx-auto max-w-[250px] md:max-w-sm"
            src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655789504/hdao-nft/19_yhdazc.png"
          />
        </div>
      </div>
    </main>
  );
};

export default Component;
