export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.75 4.25H3.5C2.11929 4.25 1 5.36929 1 6.75V20.5C1 21.8807 2.11929 23 3.5 23H17.25C18.6307 23 19.75 21.8807 19.75 20.5V14.25M17.9822 2.48223C18.9585 1.50592 20.5415 1.50592 21.5178 2.48223C22.4941 3.45854 22.4941 5.04146 21.5178 6.01777L10.7855 16.75H7.25L7.25 13.2145L17.9822 2.48223Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
