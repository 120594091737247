export const languageList = [
  { id: "af", name: "Afrikaans" },
  { id: "sq", name: "Albanian" },
  { id: "am", name: "Amharic" },
  { id: "ar", name: "Arabic" },
  { id: "hy", name: "Armenian" },
  { id: "as", name: "Assamese" },
  { id: "ay", name: "Aymara" },
  { id: "az", name: "Azerbaijani" },
  { id: "bm", name: "Bambara" },
  { id: "eu", name: "Basque" },
  { id: "be", name: "Belarusian" },
  { id: "bn", name: "Bengali" },
  { id: "bho", name: "Bhojpuri" },
  { id: "bs", name: "Bosnian" },
  { id: "bg", name: "Bulgarian" },
  { id: "ca", name: "Catalan" },
  { id: "ceb", name: "Cebuano" },
  { id: "zh-CN", name: "Chinese (Simplified)" },
  { id: "zh-TW", name: "Chinese (Traditional)" },
  { id: "co", name: "Corsican" },
  { id: "hr", name: "Croatian" },
  { id: "cs", name: "Czech" },
  { id: "da", name: "Danish" },
  { id: "dv", name: "Dhivehi" },
  { id: "doi", name: "Dogri" },
  { id: "nl", name: "Dutch" },
  { id: "en", name: "English" },
  { id: "eo", name: "Esperanto" },
  { id: "et", name: "Estonian" },
  { id: "ee", name: "Ewe" },
  { id: "fil", name: "Filipino (Tagalog)" },
  { id: "fi", name: "Finnish" },
  { id: "fr", name: "French" },
  { id: "fy", name: "Frisian" },
  { id: "gl", name: "Galician" },
  { id: "ka", name: "Georgian" },
  { id: "de", name: "German" },
  { id: "el", name: "Greek" },
  { id: "gn", name: "Guarani" },
  { id: "gu", name: "Gujarati" },
  { id: "ht", name: "Haitian Creole" },
  { id: "ha", name: "Hausa" },
  { id: "haw", name: "Hawaiian" },
  { id: "he", name: "Hebrew" },
  { id: "hi", name: "Hindi" },
  { id: "hmn", name: "Hmong" },
  { id: "hu", name: "Hungarian" },
  { id: "is", name: "Icelandic" },
  { id: "ig", name: "Igbo" },
  { id: "ilo", name: "Ilocano" },
  { id: "id", name: "Indonesian" },
  { id: "ga", name: "Irish" },
  { id: "it", name: "Italian" },
  { id: "ja", name: "Japanese" },
  { id: "jv", name: "Javanese" },
  { id: "kn", name: "Kannada" },
  { id: "kk", name: "Kazakh" },
  { id: "km", name: "Khmer" },
  { id: "rw", name: "Kinyarwanda" },
  { id: "gom", name: "Konkani" },
  { id: "ko", name: "Korean" },
  { id: "kri", name: "Krio" },
  { id: "ku", name: "Kurdish" },
  { id: "ckb", name: "Kurdish (Sorani)" },
  { id: "ky", name: "Kyrgyz" },
  { id: "lo", name: "Lao" },
  { id: "la", name: "Latin" },
  { id: "lv", name: "Latvian" },
  { id: "ln", name: "Lingala" },
  { id: "lt", name: "Lithuanian" },
  { id: "lg", name: "Luganda" },
  { id: "lb", name: "Luxembourgish" },
  { id: "mk", name: "Macedonian" },
  { id: "mai", name: "Maithili" },
  { id: "mg", name: "Malagasy" },
  { id: "ms", name: "Malay" },
  { id: "ml", name: "Malayalam" },
  { id: "mt", name: "Maltese" },
  { id: "mi", name: "Maori" },
  { id: "mr", name: "Marathi" },
  { id: "mni-Mtei", name: "Meiteilon (Manipuri)" },
  { id: "lus", name: "Mizo" },
  { id: "mn", name: "Mongolian" },
  { id: "my", name: "Myanmar (Burmese)" },
  { id: "ne", name: "Nepali" },
  { id: "no", name: "Norwegian" },
  { id: "ny", name: "Nyanja (Chichewa)" },
  { id: "or", name: "Odia (Oriya)" },
  { id: "om", name: "Oromo" },
  { id: "ps", name: "Pashto" },
  { id: "fa", name: "Persian" },
  { id: "pl", name: "Polish" },
  { id: "pt", name: "Portuguese (Portugal, Brazil)" },
  { id: "pa", name: "Punjabi" },
  { id: "qu", name: "Quechua" },
  { id: "ro", name: "Romanian" },
  { id: "ru", name: "Russian" },
  { id: "sm", name: "Samoan" },
  { id: "sa", name: "Sanskrit" },
  { id: "gd", name: "Scots Gaelic" },
  { id: "nso", name: "Sepedi" },
  { id: "sr", name: "Serbian" },
  { id: "st", name: "Sesotho" },
  { id: "sn", name: "Shona" },
  { id: "sd", name: "Sindhi" },
  { id: "si", name: "Sinhala (Sinhalese)" },
  { id: "sk", name: "Slovak" },
  { id: "sl", name: "Slovenian" },
  { id: "so", name: "Somali" },
  { id: "es", name: "Spanish" },
  { id: "su", name: "Sundanese" },
  { id: "sw", name: "Swahili" },
  { id: "sv", name: "Swedish" },
  { id: "tl", name: "Tagalog (Filipino)" },
  { id: "tg", name: "Tajik" },
  { id: "ta", name: "Tamil" },
  { id: "tt", name: "Tatar" },
  { id: "te", name: "Telugu" },
  { id: "th", name: "Thai" },
  { id: "ti", name: "Tigrinya" },
  { id: "ts", name: "Tsonga" },
  { id: "tr", name: "Turkish" },
  { id: "tk", name: "Turkmen" },
  { id: "ak", name: "Twi (Akan)" },
  { id: "uk", name: "Ukrainian" },
  { id: "ur", name: "Urdu" },
  { id: "ug", name: "Uyghur" },
  { id: "uz", name: "Uzbek" },
  { id: "vi", name: "Vietnamese" },
  { id: "cy", name: "Welsh" },
  { id: "xh", name: "Xhosa" },
  { id: "yi", name: "Yiddish" },
  { id: "yo", name: "Yoruba" },
  { id: "zu", name: "Zulu" },
];
