import * as Yup from "yup";

export const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export const PasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  password: Yup.string()
    .min(8, "Must have at least 8 characters.")
    .required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
});
export const Web2Schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required."),
  password: Yup.string()
    .min(8, "Must have at least 8 characters.")
    .required("Required."),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], `Both passwords must match.`)
    .required("Required."),
});
export const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required."),
});
