import { ChevronRight } from "@mui/icons-material";
import { MenuItem, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Menu, { MenuProps } from "@mui/material/Menu";
import { BigNumber } from "ethers";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Envelope, WhiteBellIcon } from "../../../assets/svg";
import { authState } from "../../../state/atoms";

type shortTime = {
  value: Number;
  suffix: string;
};
type notificationsObject = {
  timeStamp: string;
  archived: boolean;
  task_id: string;
  user_id: string;
  message: string;
  notification_id: string;
  title?: string;
  lapsed?: shortTime;
  status?: string;
  template: string;
};
type Props = MenuProps & {
  notifOptions: notificationsObject[];
  mutate: (any) => any;
  onClose: () => void;
  user: any;
};

const RoundButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #806ad2;
  border-radius: 50px;
  width: 37px;
  height: 37px;
`;
const NotificationCardEmail = styled.div`
  display: flex;
  padding: 1rem 0rem;
  margin: 0.5rem;
  flex-direction: row;
  border: 1px solid rgb(248, 248, 248);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #f8f8f8;

  &:hover {
    scale: 101%;
  }
`;

const Component = ({ notifOptions, ...props }: Props) => {
  const [auth, setAuth] = useRecoilState(authState);
  const [markId, setMarkId] = useState(null);
  const navigate = useNavigate();
  const onClick = (data: any) => {
    if (auth.accessToken) {
      props.mutate(data);
      if (data.notification_id) {
        setMarkId(data.notification_id);
      }
    }
  };

  if (notifOptions) {
    notifOptions.forEach((option) => {
      const time = BigNumber.from(option.timeStamp).div(1000);
      const timeNow = BigNumber.from(Date.now()).div(1000);

      let timeDiff = timeNow.sub(time);
      let current: shortTime = {
        value: timeDiff.toNumber(),
        suffix: "s",
      };
      if (timeDiff.gt(60)) {
        timeDiff = timeDiff.div(60);
        if (timeDiff.gt(60)) {
          timeDiff = timeDiff.div(60);
          if (timeDiff.gt(24)) {
            timeDiff = timeDiff.div(24);
            if (timeDiff.gt(7)) {
              if (timeDiff.gt(30)) {
                timeDiff = timeDiff.div(30);
                current.suffix = "mo";
              } else {
                timeDiff = timeDiff.div(7);
                current.suffix = "w";
              }
            } else {
              current.suffix = "d";
            }
          } else current.suffix = "h";
        } else current.suffix = "min";
        current.value = timeDiff.toNumber();
      }
      option.lapsed = current;
    });
  }
  function redirect(task_id: string) {
    props.onClose();
    if (auth.role === "owner") navigate("/tasks/view/" + task_id);
    else navigate("/task/" + task_id);
  }
  return (
    <Menu
      className="shadow rounded-md"
      {...props}
      sx={{
        overflow: "visible",
        mt: 3.2,
        ml: 3.4,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <Typography
        sx={{
          mr: 2,
          mb: 1,
          fontSize: "1.375rem",
          textAlign: "center",

          color: "#000000",
        }}>
        {notifOptions[0] && <a className="text-black">Notifications</a>}
      </Typography>
      {!props.user?.email_notif && (
        <NotificationCardEmail
          onClick={() => {
            props.onClose();
            navigate("/profile");
          }}
          style={{
            display: "flex",
            padding: "1rem 0rem",
            margin: "0.5rem",
            flexDirection: "row",
            border: "1px solid #F8F8F8",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.12)",
            borderRadius: "5px !important",
            cursor: "pointer",
          }}>
          <div
            style={{
              width: "16.666667%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <RoundButton>
              <WhiteBellIcon></WhiteBellIcon>
            </RoundButton>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              verticalAlign: "middle",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "66.666667%",
              margin: "auto",
            }}>
            <div
              style={{
                fontSize: "1.125rem",
                lineHeight: "1.25rem",
                fontWeight: "700",
              }}>
              Get Notified via Email
            </div>
            <div style={{ fontSize: "0.75rem", lineHeight: "1rem" }}>
              Start by changing your profile settings
            </div>
          </div>
          <div
            style={{
              width: "16.666667%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}>
            <ChevronRight
              sx={{ height: "1.5em", width: "1.5em" }}></ChevronRight>
          </div>
        </NotificationCardEmail>
      )}

      {!notifOptions[0] ? (
        <MenuItem
          style={{
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
          }}
          sx={{
            "&:hover": {
              cursor: "default",
            },
          }}
          disableRipple
          key={"a"}>
          <Typography
            display="flex"
            style={{
              justifyContent: "center",
              mx: "5px",
              float: "right",
              fontSize: "20px",
              fontWeight: "300",
              width: "328px",
              whiteSpace: "normal",
              color: "#000000",
            }}>
            No New Notifications
          </Typography>
        </MenuItem>
      ) : (
        <div
          className="flex w-full flex-row-reverse"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%",
            padding: "8px 16px 14px",
          }}>
          <div
            className="text-lg underline"
            onClick={() => {
              onClick({ user_id: auth.user_id });
            }}
            style={{
              textDecoration: "underline",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              cursor: "pointer",
            }}>
            Mark all as read
          </div>
        </div>
      )}

      {/* MENU ITEMS */}
      {notifOptions
        .slice(0)
        .reverse()
        .map((option, index) => {
          const statuses = [
            "in_progress",
            "for_clarification",
            "submitted",
            "completed",
          ];
          const tstamp = option.timeStamp;
          if (option.archived) return;
          return (
            <div key={index}>
              {option.lapsed && (
                <MenuItem
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                  disableRipple
                  key={option.timeStamp}>
                  <div
                    flex-direction="row"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      paddingBottom: "6px",
                    }}>
                    <div style={{ paddingRight: "5px" }}>
                      <Envelope height={19} width={19}></Envelope>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <Typography
                        display="flex"
                        style={{
                          mx: "5px",
                          float: "right",
                          fontSize: "14px",
                          fontWeight: "600",
                          width: "328px",
                          whiteSpace: "normal",
                          color: "#000000",
                        }}>
                        {option.template === "assistant_failure"
                          ? "Account Put On Hold"
                          : option.title}
                      </Typography>
                      <Typography
                        display="flex"
                        style={{
                          mx: "5px",
                          float: "right",
                          fontSize: "12px",
                          width: "328px",
                          whiteSpace: "normal",
                          color: "#000000",
                          paddingLeft: "1px",
                        }}>
                        {(option.title ||
                        option.template === "assistant_failure"
                          ? ""
                          : "[Task Deleted] ") + option.message}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      width: "100%",
                    }}>
                    <Typography
                      style={{
                        ml: "29px",
                        float: "left",
                        fontSize: "16px",
                        color: "#8E8E8E",
                        marginRight: "6px",
                      }}>
                      {option.lapsed.value.toString() +
                        option.lapsed.suffix +
                        " ago"}
                    </Typography>

                    {markId === option.notification_id ? (
                      <Typography
                        style={{
                          float: "left",
                          fontSize: "10px",
                          color: "#8E8E8E",
                        }}>
                        <CircularProgress color="inherit" size="8px" />
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography
                      key={option.notification_id + "_read"}
                      onClick={() =>
                        onClick({ notification_id: option.notification_id })
                      }
                      style={{
                        textDecorationLine: "underline",
                        float: "right",
                        fontSize: "10px",
                        paddingTop: "8px",
                      }}
                      sx={{
                        color: "#8E8E8E",
                        "&:hover": {
                          fontWeight: "bold",
                          color: "#806AD2",
                          cursor: "pointer",
                        },
                      }}>
                      Mark as Read
                    </Typography>

                    {auth.role !== "owner" && option.title && (
                      <Typography
                        key={option.notification_id}
                        onClick={() => {
                          if (option.template === "qualification_new_task") {
                            props.onClose();
                            navigate("/tasks");
                          } else if (
                            option.status &&
                            statuses.includes(option.status)
                          ) {
                            redirect(option.task_id);
                          }
                        }}
                        style={{
                          marginRight: "5px",
                          textDecorationLine: "underline",
                          float: "right",
                          fontSize: "10px",
                          color: "#8E8E8E",
                          paddingTop: "8px",
                        }}
                        sx={
                          (option.status && statuses.includes(option.status)) ||
                          option.template === "qualification_new_task"
                            ? {
                                "&:hover": {
                                  fontWeight: "bold",
                                  color: "#806AD2",
                                  cursor: "pointer",
                                },
                              }
                            : {}
                        }>
                        {option.template === "qualification_new_task"
                          ? "See Task Pool"
                          : option.status && statuses.includes(option.status)
                          ? "View Task"
                          : "Task Inaccessible"}
                      </Typography>
                    )}
                    {auth.role === "owner" && option.title && (
                      <Typography
                        key={option.notification_id}
                        onClick={
                          option.title
                            ? () => redirect(option.task_id)
                            : undefined
                        }
                        style={{
                          marginRight: "5px",
                          textDecorationLine: "underline",
                          float: "right",
                          fontSize: "10px",
                          color: "#8E8E8E",
                          paddingTop: "8px",
                        }}
                        sx={
                          option.title
                            ? {
                                "&:hover": {
                                  fontWeight: "bold",
                                  color: "#806AD2",
                                  cursor: "pointer",
                                },
                              }
                            : {}
                        }>
                        {"View Task"}
                      </Typography>
                    )}
                  </div>
                </MenuItem>
              )}
            </div>
          );
        })}
      {notifOptions
        .slice(0)
        .reverse()
        .map((option, index) => {
          const tstamp = option.timeStamp;
          if (!option.archived) return;
          return (
            <div key={index}>
              {option.lapsed && (
                <MenuItem disableRipple key={option.timeStamp}>
                  <div flex-direction="column">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        width: "328px",
                        whiteSpace: "normal",
                        color: "#000000",
                      }}>
                      {option.message}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#8E8E8E",
                      }}>
                      {option.lapsed.value.toString() +
                        option.lapsed.suffix +
                        " ago"}
                    </Typography>
                  </div>
                </MenuItem>
              )}
            </div>
          );
        })}
    </Menu>
  );
};

export default Component;
