export default (props) => {
  return (
    <svg
      style={props.style ?? {}}
      xmlns="http://www.w3.org/2000/svg"
      className="text-black h-6 w-6"
      fill={!props.filled ? "none" : props.color ? props.color : "#6F5ACD"}
      viewBox="0 0 24 24"
      stroke={!props.filled ? "#9197a3" : props.color ? props.color : "#6F5ACD"}
      strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 10V3L4 14h7v7l9-11h-7z"
      />
    </svg>
  );
};
