import React from "react";

const Component = () => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49671 0.5C4.52026 0.5 1.29671 3.85787 1.29671 8V12.4822L0.448187 13.3661C0.104989 13.7236 0.00232213 14.2613 0.188059 14.7284C0.373796 15.1954 0.81136 15.5 1.29671 15.5H15.6967C16.1821 15.5 16.6196 15.1954 16.8054 14.7284C16.9911 14.2613 16.8884 13.7236 16.5452 13.3661L15.6967 12.4822V8C15.6967 3.85787 12.4732 0.5 8.49671 0.5Z"
        fill="white"
      />
      <path
        d="M8.49668 20.5C6.50845 20.5 4.89668 18.8211 4.89668 16.75H12.0967C12.0967 18.8211 10.4849 20.5 8.49668 20.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
