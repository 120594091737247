const Component = () => {
  return (
    <svg
      width="60"
      height="80"
      viewBox="0 0 60 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 0C22.2386 0 20 2.23858 20 5C20 7.76142 22.2386 10 25 10H35C37.7614 10 40 7.76142 40 5C40 2.23858 37.7614 0 35 0H25Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15C0 9.47715 4.47715 5 10 5C10 13.2843 16.7157 20 25 20H35C43.2843 20 50 13.2843 50 5C55.5228 5 60 9.47715 60 15V70C60 75.5229 55.5228 80 50 80H10C4.47715 80 0 75.5229 0 70V15ZM15 35C12.2386 35 10 37.2386 10 40C10 42.7614 12.2386 45 15 45H15.05C17.8114 45 20.05 42.7614 20.05 40C20.05 37.2386 17.8114 35 15.05 35H15ZM30 35C27.2386 35 25 37.2386 25 40C25 42.7614 27.2386 45 30 45H45C47.7614 45 50 42.7614 50 40C50 37.2386 47.7614 35 45 35H30ZM15 55C12.2386 55 10 57.2386 10 60C10 62.7614 12.2386 65 15 65H15.05C17.8114 65 20.05 62.7614 20.05 60C20.05 57.2386 17.8114 55 15.05 55H15ZM30 55C27.2386 55 25 57.2386 25 60C25 62.7614 27.2386 65 30 65H45C47.7614 65 50 62.7614 50 60C50 57.2386 47.7614 55 45 55H30Z"
        fill="#111827"
      />
    </svg>
  );
};

export default Component;
