import React, { useCallback, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import CreateTaskTemplate from "../../templates/CreateTask";

import { CircularProgress } from "@mui/material";
import { Qualification } from "../../../interfaces/Qualification";
import { getAllQualificationsInTasks } from "../../../services/qualification";
import {
  createTask,
  getTranslation,
  getTranslationCheck,
  reassignTask,
} from "../../../services/tasks";
import { authState, defaultTaskState, taskState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";
import useUserFetch from "../../hooks/useUserFetch";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [activeTask, setActiveTask] = useRecoilState(taskState);
  const [translatedTexts, setTranslatedTexts] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [qualificationsDropdownData, setQualificationsDropdownData] = useState<
    Qualification[]
  >([]);

  const location = useLocation();

  var isReassign = location.state?.isReassign || false;

  if (!location.state || !location.state.isNew) setActiveTask(defaultTaskState);

  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleSuccess = (data) => {
    setQualificationsDropdownData((oldArr) => [
      ...oldArr,
      ...data.user.authorized_qualifications,
    ]);
  };

  const {
    user,
    refetch,
    isLoading: isUserDataLoading,
    isRefetching,
  } = useUserFetch(auth, "nft", auth.user_id, [handleSuccess]);

  const resetTranslation = useCallback(() => {
    setTranslatedTexts({});
  }, []);

  const {
    mutate: mutateCreateTask,
    isLoading: isLoading,
    error,
  } = useMutation(
    "createTask",
    (values: any) => {
      return createTask(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);
        setSnackbar({
          title: "Success",
          content: "Successfully created new task.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/tasks/view/" + resp.id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateReassign,
    isLoading: isReassignLoading,
    error: isReassignError,
  } = useMutation(
    "reassignTask",
    (values: any) => {
      values.assistant_id = location.state.assistant_id;
      return reassignTask(auth.accessToken, values);
    },
    {
      onSuccess: (resp: any) => {
        setIsSuccess(true);
        setSnackbar({
          title: "Success",
          content: "Task has been reassigned Successfully",
          type: "success",
        });
        // navigate("/");
        // refetch();
        navigate(`/tasks/view/${resp.id}`);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setIsSuccess(false);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateDraftTask } = useMutation(
    "draftTask",
    (values: any) => {
      return createTask(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);
        setSnackbar({
          title: "Success",
          content: "Successfully created a new draft.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/tasks/view/" + resp.id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { data: publicQualifications, isLoading: isQualificationsLoading } =
    useQuery(
      "fetchPublicQualifications",
      () => {
        return getAllQualificationsInTasks(auth.accessToken);
      },
      {
        onSuccess: (resp) => {
          setQualificationsDropdownData((oldArray) => [
            ...oldArray,
            ...resp.qualifications,
          ]);
        },
        onError: (resp) => {},
      },
    );

  const {
    mutate: mutateTranslateDescription,
    isLoading: isTranslating,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp, variables) => {
        if (resp) {
          const propertyName = Object.keys(variables)[0];
          setTranslatedTexts((prevState) => {
            return {
              ...prevState,
              [propertyName]: resp.message,
            };
          });
          setSnackbar({
            title: "Success",
            content: `Successfully translated the task ${propertyName}.`,
            type: "success",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: isDescriptionTranslated,
    isLoading: checkTranslatedLoading,
    error: errorTranslatedLoading,
  } = useMutation(
    "checkDescriptionTranslated",
    (values: any) => {
      return getTranslationCheck(auth.accessToken, values.task.description);
    },
    {
      onSuccess: (resp, data) => {
        if (resp.message.translated) {
          mutateCreateTask(data);
        } else {
          setSnackbar({
            title: "Error",
            content:
              "Task description is not translated to english, please click the translate to english button",
            type: "error",
          });
        }
      },
      onError: (e, data) => {
        mutateCreateTask(data);
        // setSnackbar({
        //   title: "Error",
        //   content: "Something went wrong",
        //   type: "error",
        // });
      },
    },
  );

  if (isUserDataLoading || isQualificationsLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <StyledContainer className="bg-mainBg flex justify-center items-center">
      <CreateTaskTemplate
        handleSubmit={mutateCreateTask}
        handleTranslate={mutateTranslateDescription}
        handleDraftSubmit={mutateDraftTask}
        mode="create"
        handleReassignSubmit={mutateReassign}
        isReassign={isReassign}
        isLoading={isReassign ? isReassignLoading : isLoading}
        isSuccess={isSuccess}
        qualificationsDropdownData={qualificationsDropdownData}
        isUserDataLoading={isUserDataLoading}
        userData={user}
        isQualificationsLoading={isQualificationsLoading}
        translatedTexts={translatedTexts}
        resetTranslation={resetTranslation}
        isTranslating={isTranslating}
        isDescriptionTranslated={isDescriptionTranslated}
        checkTranslatedLoading={checkTranslatedLoading}
      />
    </StyledContainer>
  );
};

export default Component;
