import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Qualification } from "../../../interfaces/Qualification";
import { getAllQualifications } from "../../../services/qualification";
import {
  adminPocketAssistantState,
  adminTaskOwnersState,
  authState,
} from "../../../state/atoms";

type Props = {
  setActive: (active: any) => void;
  assistants: Array<any>;
  assistantAfter: any;
  assistantBefore: any;
  isAssistantLoading: boolean;

  owners: Array<any>;
  isOwnersLoading: boolean;
  ownersAfter: any;
  ownersBefore: any;
};

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 80%;
  min-width: 1100px;
  width: 75%;
  flex-direction: column;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
    padding: 0 2%;
  }
`;

export default function AdminAccountsTemplate({
  setActive,
  assistants,
  assistantAfter,
  assistantBefore,
  isAssistantLoading,
  owners,
  ownersBefore,
  ownersAfter,
  isOwnersLoading,
}: Props) {
  const [auth] = useRecoilState(authState);

  const navigate = useNavigate();
  const [adminPocketAssistant, setAdminPocketAssistant] = useRecoilState(
    adminPocketAssistantState,
  );

  const [adminTaskOwners, setAdminTaskOwners] =
    useRecoilState(adminTaskOwnersState);

  const [ratingStart, setRatingStart] = useState(
    adminPocketAssistant?.filters?.rating[0] ?? "",
  );
  const [ratingEnd, setRatingEnd] = useState(
    adminPocketAssistant?.filters?.rating[1] ?? "",
  );
  const [rejectionStart, setRejectionStart] = useState(
    adminPocketAssistant?.filters?.rejections[0] ?? "",
  );
  const [rejectionEnd, setRejectionEnd] = useState(
    adminPocketAssistant?.filters?.rejections[1] ?? "",
  );
  const [searchText, SetSearchText] = useState(
    adminPocketAssistant?.filters?.text ?? "",
  );
  const [searchOwnerText, setSearchOwnerText] = useState(
    adminTaskOwners?.filters?.text ?? "",
  );

  const [listQualification, setListQualification] = useState([]);
  const [filterQualifications, setFilterQualifications] = useState(
    adminPocketAssistant?.filters?.qualification ?? "",
  );
  const [filterAuthorizedQualifications, setFilterAuthorizedQualifications] =
    useState(adminTaskOwners?.filters?.authorizedQualification ?? "");

  const { data: qualifications } = useQuery(
    ["fetchingQualification", auth.accessToken],
    () => {
      return getAllQualifications(auth.accessToken, null);
    },
    {
      onSuccess: (message) => {
        setListQualification(message.qualifications);
      },
      onError: (e) => {},
    },
  );

  const nextAssistantPage = () => {
    if (assistantAfter !== null) {
      setAdminPocketAssistant({
        filters: {
          text: adminPocketAssistant.filters.text,
          rating: adminPocketAssistant.filters.rating,
          rejections: adminPocketAssistant.filters.rejections,
          qualification: adminPocketAssistant.filters.qualification,
        },
        pageNum: adminPocketAssistant.pageNum + 1,
        after: assistantAfter,
        before: null,
      });
    }
  };

  const prevAssistantPage = () => {
    if (assistantBefore !== null) {
      setAdminPocketAssistant({
        filters: {
          text: adminPocketAssistant.filters.text,
          rating: adminPocketAssistant.filters.rating,
          rejections: adminPocketAssistant.filters.rejections,
          qualification: adminPocketAssistant.filters.qualification,
        },
        pageNum: adminPocketAssistant.pageNum - 1,
        after: null,
        before: assistantBefore,
      });
    }
  };

  const nextOwnersPage = () => {
    if (ownersAfter !== null) {
      setAdminTaskOwners({
        filters: {
          text: adminTaskOwners.filters.text,
          authorizedQualification:
            adminTaskOwners.filters.authorizedQualification,
        },
        pageNum: adminTaskOwners.pageNum + 1,
        after: ownersAfter,
        before: null,
      });
    }
  };

  const prevOwnersPage = () => {
    if (ownersBefore !== null) {
      setAdminTaskOwners({
        filters: {
          text: adminTaskOwners.filters.text,
          authorizedQualification:
            adminTaskOwners.filters.authorizedQualification,
        },
        pageNum: adminTaskOwners.pageNum - 1,
        after: null,
        before: ownersBefore,
      });
    }
  };

  const handleFetchAssistants = () => {
    const parsedRatingStart = ratingStart ? parseInt(ratingStart) : null;
    const parsedRatingEnd = ratingEnd ? parseInt(ratingEnd) : null;
    const rating = [parsedRatingStart, parsedRatingEnd].filter(
      Number.isInteger,
    );

    const parsedRejectionStart = rejectionStart
      ? parseInt(rejectionStart)
      : null;
    const parsedRejectionEnd = rejectionEnd ? parseInt(rejectionEnd) : null;
    const rejections = [parsedRejectionStart, parsedRejectionEnd].filter(
      Number.isInteger,
    );

    setAdminPocketAssistant({
      filters: {
        text: searchText,
        rating: rating,
        rejections: rejections,
        qualification: filterQualifications,
      },
      pageNum: 0,
      after: null,
      before: null,
    });
  };

  const handleFetchOwners = () => {
    setAdminTaskOwners({
      filters: {
        text: searchOwnerText,
        authorizedQualification: filterAuthorizedQualifications,
      },
      pageNum: 0,
      after: null,
      before: null,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;

    handleFetchAssistants();
  };

  const handleTaskOwnerKeyDown = (e) => {
    if (e.key !== "Enter") return;

    handleFetchOwners;
  };

  useEffect(() => {
    if (listQualification.length === 0) return;

    handleFetchAssistants();
  }, [filterQualifications]);

  useEffect(() => {
    if (listQualification.length === 0) return;

    handleFetchOwners();
  }, [filterAuthorizedQualifications]);

  return (
    <StyledBoardWrapper className="m-auto space-y-[35px] mt-[35px]">
      <div className="w-full" data-test-id="admin-accounts-pocket-assistants">
        <div className="flex justify-between">
          <div className="font-gilroy font-extrabold text-black text-4xl leading-[26px]">
            Pocket Assistants
          </div>
          <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              placeholder="Search..."
              value={searchText}
              onChange={(event) => {
                SetSearchText(event.target.value);
              }}
              onKeyDown={handleKeyDown}></TextField>
            <FormControl>
              <InputLabel>Filter Qualification</InputLabel>
              <Select
                label="Filter Qualification"
                defaultvalue=""
                placeholder="Filter Qualification"
                sx={{ width: "200px" }}
                onChange={(e) => {
                  setFilterQualifications(e.target.value);
                }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {listQualification
                  .filter((qualif: Qualification) => qualif.name !== "General")
                  .map((qualif: Qualification) => (
                    <MenuItem key={qualif.id} value={qualif.id}>
                      {qualif.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              <React.Fragment>
                <TextField
                  sx={{ width: "100px" }}
                  label="Rating from"
                  variant="outlined"
                  value={ratingStart}
                  onChange={(event) => {
                    setRatingStart(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}></TextField>
                <Box sx={{ mx: 0 }}> ~ </Box>
                <TextField
                  sx={{ width: "100px" }}
                  label="Rating to"
                  variant="outlined"
                  value={ratingEnd}
                  onChange={(event) => {
                    setRatingEnd(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}></TextField>
              </React.Fragment>
              F
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <React.Fragment>
                <TextField
                  sx={{ width: "100px" }}
                  label="Rejection from"
                  variant="outlined"
                  value={rejectionStart}
                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                  onChange={(event) => {
                    setRejectionStart(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}></TextField>
                <Box sx={{ mx: 0 }}> ~ </Box>
                <TextField
                  sx={{ width: "100px" }}
                  label="Rejection to"
                  variant="outlined"
                  value={rejectionEnd}
                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                  onChange={(event) => {
                    setRejectionEnd(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}></TextField>
              </React.Fragment>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="mt-[32px] bg-[#ffffff] w-full text-left border-adminAssistantBorder rounded-lg">
            <thead className="bg-[#f9fafb] h-[40px] font-inter not-italic text-[12px] leading-[16px] tracking-tighter uppercase color text-adminTableHead">
              <tr>
                <th className="pl-[16px]">CONTACT</th>
                <th className="pl-0 text-center">RATING</th>
                <th className="pl-0 text-center">TASK COMPLETED</th>
                <th className="pl-0 text-center">REJECTIONS</th>
                <th></th>
              </tr>
            </thead>
            {isAssistantLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <div className="h-[400px] w-full flex">
                      <CircularProgress
                        color="inherit"
                        size="6rem"
                        sx={{ margin: "auto" }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="text-adminTableBody">
                {assistants.length > 0 ? (
                  assistants.map((item, index) => (
                    <tr key={index}>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                        <div className="flex flex-col">
                          <div className="not-italic font-medium text-[14px] leading-[20px] text-adminTableBody font-inter">
                            {item.user_name}
                          </div>
                          <div className="not-italic font-normal text-[14px] leading-[20px] text-adminTableBody font-inter">
                            {item.email}
                          </div>
                        </div>
                      </td>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                        {item.rating}
                      </td>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                        {item.competed_tasks}
                      </td>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                        {item.rejections}
                      </td>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                        <div
                          className="text-[14px] cursor-pointer not-italic font-inter font-normal leading-[20px] tracking-[0.15px] text-[#4f46e5] text-center"
                          onClick={() => {
                            navigate(
                              `/accounts/assistant/${item.assistant_id}`,
                            );
                            setActive(1);
                          }}>
                          View Profile
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <div className="flex items-center justify-center m-auto text-3xl select-none h-[400px] w-full">
                        No Assistants
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="flex h-max items-center justify-end md:justify-end mt-5">
          <span
            onClick={() => (assistantBefore ? prevAssistantPage() : null)}
            className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
              assistantBefore === null
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}>
            Previous
          </span>
          <span className="mx-2 text-gray-500 font-medium">
            {adminPocketAssistant.pageNum + 1}
          </span>
          <span
            onClick={() => (assistantAfter ? nextAssistantPage() : null)}
            className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
              assistantAfter === null
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}>
            Next
          </span>
        </div>
      </div>

      <div className="w-full" data-test-id="admin-accounts-task-owners">
        <div className="flex justify-between">
          <div className="font-gilroy font-extrabold text-4xl text-black leading-[26px]">
            Task Owners
          </div>
          <div className="flex items-center gap-3">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              placeholder="Search..."
              value={searchOwnerText}
              onChange={(event) => {
                setSearchOwnerText(event.target.value);
              }}
              onKeyDown={handleTaskOwnerKeyDown}></TextField>
            <FormControl>
              <InputLabel>Authorized Qualifications</InputLabel>
              <Select
                label="Filter Qualification"
                defaultvalue=""
                placeholder="Filter Qualification"
                sx={{ width: "300px" }}
                onChange={(e) => {
                  setFilterAuthorizedQualifications(e.target.value);
                }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {listQualification
                  .filter(
                    (qualif: Qualification) =>
                      qualif.name !== "General" && !qualif.is_global,
                  )
                  .map((qualif: Qualification) => (
                    <MenuItem key={qualif.id} value={qualif.id}>
                      {qualif.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="mt-[32px] bg-[#ffffff] w-full text-left border border-solid border-adminAssistantBorder rounded-lg">
            <thead className="bg-[#f9fafb] h-[40px] font-inter not-italic text-[12px] leading-[16px] tracking-tighter uppercase color text-adminTableHead">
              <tr>
                <th className="pl-[16px]">CONTACT</th>
                <th></th>
              </tr>
            </thead>
            {isOwnersLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <div className="h-[400px] w-full flex">
                      <CircularProgress
                        color="inherit"
                        size="6rem"
                        sx={{ margin: "auto" }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="text-adminTableBody">
                {owners.length > 0 ? (
                  owners.map((item, index) => (
                    <tr key={index}>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <div className="not-italic text-[14px] font-semibold leading-[20px] text-adminTableBody font-inter">
                            {item.user_name ? item.user_name : "Not set"}
                          </div>
                          <div className="not-italic font-normal text-[14px] leading-[20px] text-adminTableBody font-inter">
                            {item.email
                              ? item.email
                              : item.address
                              ? item.address.substring(0, 5) +
                                "..." +
                                item.address.substring(item.address.length - 2)
                              : "Not set"}
                          </div>
                        </div>
                      </td>
                      <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder">
                        <div
                          className="text-[14px] cursor-pointer not-italic font-inter font-normal leading-[20px] tracking-[0.15px] text-[#4f46e5] text-center"
                          onClick={() => {
                            navigate(`/accounts/owner/${item.owner_id}`);
                            setActive(1);
                          }}>
                          View Profile
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <div className="flex items-center justify-center m-auto text-3xl select-none h-[400px] w-full">
                        No Task Owners
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="flex h-max items-center justify-end md:justify-end mt-5">
          <span
            onClick={() => (ownersBefore ? prevOwnersPage() : null)}
            className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
              ownersBefore === null
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}>
            Previous
          </span>
          <span className="mx-2 text-gray-500 font-medium">
            {adminTaskOwners.pageNum + 1}
          </span>
          <span
            onClick={() => (ownersAfter ? nextOwnersPage() : null)}
            className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
              ownersAfter === null
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}>
            Next
          </span>
        </div>
      </div>
    </StyledBoardWrapper>
  );
}
