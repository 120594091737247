import * as Yup from "yup";

export const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  payout: Yup.string().matches(
    /^0x[a-fA-F0-9]{40}$/,
    "Not a valid ethereum address",
  ),
});

export const PasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
});
