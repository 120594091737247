import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import AllyIcon from "../assets/images/AllyIcon.png";
import Header from "../components/organisms/Header";
import Dashboard from "../components/pages/Dashboard";
import DashboardTaskView from "../components/pages/DashboardTaskView";
import Leaderboard from "../components/pages/Leaderboard";
import ProfileView from "../components/pages/ProfileView";
import TaskCards from "../components/pages/TaskCards";

import { authState, initialAuthState } from "../state/atoms";

import { LeaderboardIcon, LogoutIcon, ProfileIcon } from "../assets/svg";

export default () => {
  const [auth, setAuth] = useRecoilState(authState);
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");

    setAuth(initialAuthState);
    navigate("/");
  };

  return (
    <>
      <div
        className="fixed cursor-pointer hover:scale-110 ease-in-out duration-300 bottom-1 right-1 p-2 z-50 bg-buttonBorder rounded-full flex items-center justify-center"
        onClick={() =>
          window.open("https://help.humantaskforce.com/", "_blank")
        }
        title="Talk to Ally">
        <img src={AllyIcon} alt="Ally icon" className="w-12" />
      </div>
      <Header
        role="pa"
        dropdownButtonName={auth.user_name ?? auth.email ?? ""}
        dropdownOptions={[
          {
            name: "Profile",
            onClick: () => navigate("/profile"),
            icon: <ProfileIcon />,
          },
          {
            name: "Leaderboard",
            onClick: () => navigate("/leaderboard"),
            icon: <LeaderboardIcon />,
          },
          { name: "Logout", onClick: logoutUser, icon: <LogoutIcon /> },
        ]}
      />
      <Routes>
        <Route index element={<Navigate to="/dashboard" />} />
        <Route exact="true" path="dashboard" element={<Dashboard />} />
        <Route
          exact="true"
          path="task/:taskId"
          element={<DashboardTaskView />}
        />
        <Route path="profile" element={<ProfileView />} />
        <Route path="tasks" element={<TaskCards isMission={false} />} />
        <Route path="missions" element={<TaskCards isMission={true} />} />
        <Route path="leaderboard" element={<Leaderboard />} />
      </Routes>
    </>
  );
};
