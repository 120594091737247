import React, { useCallback, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import CreateMissionTemplate from "../../templates/CreateMission";

import { CircularProgress } from "@mui/material";
import { getAllQualifications } from "../../../services/qualification";
import { createMission, getTranslation } from "../../../services/tasks";
import {
  authState,
  defaultTaskState,
  qualificationsListState,
  taskState,
} from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";
import useUserFetch from "../../hooks/useUserFetch";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [activeTask, setActiveTask] = useRecoilState(taskState);
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();
  setActiveTask(defaultTaskState);
  const [qualificationsState] = useRecoilState(qualificationsListState);
  const [translatedTexts, setTranslatedTexts] = useState({});

  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const resetTranslation = useCallback(() => {
    setTranslatedTexts({});
  }, []);

  const {
    user: userData,
    isLoading: isUserDataLoading,
    refetch: isUserDataRefetch,
  } = useUserFetch(auth, "admin", auth.user_id);

  const {
    mutate: mutateCreateMission,
    isLoading: isLoading,
    error,
  } = useMutation(
    "createMission",
    (values: any) => {
      return createMission(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);
        setSnackbar({
          title: "Success",
          content: "Successfully created new mission.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/missions/view/" + resp.id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateDraftMission } = useMutation(
    "draftMission",
    (values: any) => {
      return createMission(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);
        setSnackbar({
          title: "Success",
          content: "Successfully created a new draft.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/missions/view/" + resp.id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { data: listOfQualifications, isLoading: isQualificationsLoading } =
    useQuery(
      "fetchQualifications",
      () => {
        return getAllQualifications(auth.accessToken, qualificationsState);
      },
      {
        onSuccess: (resp) => {},
        onError: (resp) => {},
      },
    );

  const {
    mutate: mutateTranslateDescription,
    isLoading: isTranslating,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp, variables) => {
        if (resp) {
          const propertyName = Object.keys(variables)[0];
          setTranslatedTexts((prevState) => {
            return {
              ...prevState,
              [propertyName]: resp.message,
            };
          });
          setSnackbar({
            title: "Success",
            content: `Successfully translated the task ${propertyName}.`,
            type: "success",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  if (isUserDataLoading || isQualificationsLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    listOfQualifications && (
      <StyledContainer className="bg-mainBg flex justify-center items-center">
        <CreateMissionTemplate
          handleSubmit={mutateCreateMission}
          handleDraftSubmit={mutateDraftMission}
          mode="create"
          isLoading={isLoading}
          isSuccess={isSuccess}
          qualifications={listOfQualifications.qualifications}
          translatedTexts={translatedTexts}
          resetTranslation={resetTranslation}
          isTranslating={isTranslating}
          handleTranslate={mutateTranslateDescription}
          userData={userData}
        />
      </StyledContainer>
    )
  );
};

export default Component;
