import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { countryList } from "../../../assets/countries";
import { Qualification } from "../../../interfaces/Qualification.js";
import { getSubscription, subscribeNotifs } from "../../../services/users.js";
import { authState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import FormError from "../../organisms/FormError/FormError";
import Modal from "../../organisms/Modal";
import { PasswordSchema, ProfileSchema } from "./validation";
/*Dashboard Main */
import { languageList } from "../../../assets/languages";
import { ArrowDownIcon, DiscordIcon } from "../../../assets/svg";
import { resetPassword, updateProfile } from "../../../services/users.js";
import { ensureStartsWithAt } from "../../../utils/strings";
import useUserFetch from "../../hooks/useUserFetch";
const StyledContainer = styled.span`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1030px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileHeader = styled.div`
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const StyledTitleContainer = styled.span`
  padding-top: -6%;
  // margin-bottom: 50%;
  display: flex;
  // flex: 100vw;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  // background-color: green;
`;

const StyledInnerContainer = styled.span`
  display: flex;
  // align-content: center;
  flex-direction: column;
  // justifycontent: center;
  flex-wrap: nowrap;
  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

type UserData = {
  user_name: string;
  user_id: string;
  country?: string;
  payout_address?: string;
  notification_email?: string;
  email_notif?: boolean;
  qualifications: Qualification[];
  preferred_language: string;
};

const Component = () => {
  const [show, setShow] = useState(false);
  const [browserNotif, setBrowserNotif] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [auth, setAuth] = useRecoilState(authState);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const {
    user: user,
    isLoading: isLoadingUserData,
    refetch: userRefetch,
    isRefetching: isUserRefetching,
  } = useUserFetch(auth, "assistant", auth.user_id);
  const [isShowingSocials, setIsShowingSocials] = useState(false);

  const toggleOpen = () => setIsShowingSocials(!isShowingSocials);

  const {
    data: subscriptionData,
    isLoading,
    refetch,
  } = useQuery(
    ["fetchingSubscription", auth.accessToken],
    () => {
      if (auth.accessToken) {
        return getSubscription(auth.accessToken);
      }
    },
    {
      onSuccess: (resp) => {},
      onError: () => {},
    },
  );

  const subscriberData = subscriptionData || null;
  useEffect(() => {
    async function checkRegistered() {
      if (!("serviceWorker" in navigator))
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
      const registration = await navigator.serviceWorker.getRegistrations();
      if (registration.length > 0) {
        const subscription =
          await registration[0].pushManager.getSubscription();
        if (
          subscription &&
          subscription.endpoint === subscriberData?.data.subscription.endpoint
        ) {
          setBrowserNotif(true);
        }
        setIsRegistered(true);
      }
    }
    checkRegistered();
  }, [subscriberData]);

  const { mutate: mutateSubscribe } = useMutation(
    ["updateProfileSubscription", subscribeNotifs],
    (values: any) => {
      return subscribeNotifs(auth.accessToken, auth.user_id, values);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: " Subscribed",
          type: "success",
        });
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateUpdateProfile,
    isLoading: isMutateUpdateProfileLoading,
  } = useMutation(
    ["updateProfile", updateProfile],
    (values: any) => {
      return updateProfile(auth.accessToken, auth.user_id, values);
    },
    {
      onSuccess: (resp, variables) => {
        console.log(variables);
        setSnackbar({
          title: "Success",
          content: "Successfully updated the profile.",
          type: "success",
        });
        setIsEdit(false);
        userRefetch();
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        navigate("/profile");
      },
    },
  );

  const { mutate: mutateUpdatePassword } = useMutation(
    ["resetPassword", resetPassword],
    (values: any) => {
      return resetPassword(auth.accessToken, values);
    },
    {
      onSuccess: (resp) => {
        setChangePassword(false);
        setIsChangingPassword(false);
        setSnackbar({
          title: "Success",
          content: "New password successfully saved",
          type: "success",
        });
      },
      onError: (e) => {
        setIsChangingPassword(false);
        let abc = e.toString();
        abc = abc.slice(6);
        setSnackbar({
          title: "Error",
          content: abc,
          type: "error",
        });
      },
    },
  );
  const handleSubmit = (values) => {
    mutateUpdateProfile(values);

    // reset the user_name in the localStorage
    localStorage.setItem("user_name", values.name);
  };
  const handleSubmitPassword = (values) => {
    mutateUpdatePassword(values);
  };

  function urlBase64ToUint8Array(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  async function registerServiceWorker() {
    return navigator.serviceWorker
      .register(new URL("./worker.js", import.meta.url), { type: "module" })
      .catch(function (err) {});
  }
  async function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result,
      ) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== "granted") {
      }
      return permissionResult;
    });
  }

  const handleBrowserNotif = async (value) => {
    if (value === true) {
      if (!("serviceWorker" in navigator)) {
        if (!("PushManager" in window)) {
        }
      } else if (Notification.permission === "default") {
        const worker = await registerServiceWorker();
        const permission = await askPermission();
        const registration = await navigator.serviceWorker.getRegistrations();
        if (permission === "granted" && worker) {
          const subscription = await createSubscription(worker);
          mutateSubscribe(subscription);
        }
      }
    }
    setBrowserNotif(value);
  };
  const createSubscription = async (worker) => {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.VAPID),
    };
    return worker.pushManager
      .subscribe(subscribeOptions)
      .then(function (subscription) {
        JSON.stringify(subscription);
        return subscription;
      });
  };

  if (isLoadingUserData || isUserRefetching) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <section className="bg-[#F6F6F6]">
      <StyledContainer>
        <div className="max-w-[75%] pt-[20] rounded-[10] w-full mx-[13.7%]">
          <StyledTitleContainer>
            <ProfileHeader className="mb-2">
              <p className="font-Inter text-xs text-black">
                <span
                  className=" text-black hover:cursor-pointer"
                  onClick={() => navigate("/")}>
                  Dashboard
                </span>
                {" > " + "Profile "}
              </p>
              <p className="font-Inter text-4xl text-black font-bold my-2">
                Profile
              </p>
            </ProfileHeader>
          </StyledTitleContainer>

          {user && (
            <div
              className={`flex flex-col ${
                isEdit ? "bg-[#CE6F7A]" : "bg-[#d6d1dab8]"
              } p-5  rounded-[10] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] mx-5 px-7 pb-6`}>
              <StyledInnerContainer>
                {isEdit && user ? (
                  <>
                    <Formik
                      initialValues={{
                        name: user ? user.user_name : "",
                        country: user?.country ? user.country : "",
                        payout: user?.payout_address ? user.payout_address : "",
                        notif_email: user?.notification_email
                          ? user?.notification_email
                          : "",
                        checkedNotif: user?.email_notif
                          ? user?.email_notif
                          : false,
                        preferred_language: user?.preferred_language,
                        socials: user?.socials,
                      }}
                      validationSchema={ProfileSchema}
                      onSubmit={(values) => handleSubmit(values)}>
                      {({ values, setFieldValue, touched, errors }) => {
                        return (
                          <Form>
                            <Grid
                              container
                              columnGap={3}
                              rowGap={2}
                              className="flex grow-0">
                              <Grid item xs={12} className="flex items-center">
                                <div className="text-base font-bold text-white">
                                  Display Name
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  sx={{ width: "38%" }}
                                  className="bg-white rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={values.name}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      borderRadius: "0.375rem",
                                    },
                                  }}
                                  inputProps={{
                                    style: {
                                      padding: "10px 12px",
                                      textIndent: "5px",
                                    },
                                  }}
                                  onChange={(event) =>
                                    setFieldValue("name", event.target.value)
                                  }
                                />
                                <FormError
                                  top="-10px"
                                  isAbsolute
                                  sx={{ position: "relative" }}>
                                  <>{touched.name && errors.name}</>
                                </FormError>
                              </Grid>
                              <Grid item xs={12} className="flex items-center ">
                                <div className="text-base font-bold text-white">
                                  Country
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  className="bg-white border-0 rounded w-[20%] text-gray-700 leading-tight "
                                  sx={{
                                    height: "45px",
                                  }}
                                  id="countries"
                                  value={values.country}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "country",
                                      event.target.value,
                                    );
                                  }}>
                                  {countryList.map((country) => {
                                    return (
                                      <MenuItem
                                        key={country.code}
                                        value={country.code}>
                                        {country.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                              <Grid item xs={12} className="flex items-center ">
                                <div className="text-base font-bold text-white">
                                  Preferred Language for Translations:
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  className="bg-white border-0 rounded w-[20%] text-gray-700 leading-tight "
                                  sx={{
                                    height: "45px",
                                  }}
                                  id="countries"
                                  value={values.preferred_language}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "preferred_language",
                                      event.target.value,
                                    );
                                  }}>
                                  {languageList.map((language) => {
                                    return (
                                      <MenuItem
                                        key={language.id}
                                        value={language.id}>
                                        {language.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                              <Grid item xs={12} className="flex items-center ">
                                <div className="text-base font-bold text-white">
                                  Payout Address
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  sx={{ width: "38%" }}
                                  className="bg-white rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={values.payout}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      borderRadius: "0.375rem",
                                    },
                                  }}
                                  inputProps={{
                                    style: {
                                      padding: "10px 12px",
                                      textIndent: "5px",
                                    },
                                  }}
                                  onChange={(event) =>
                                    setFieldValue("payout", event.target.value)
                                  }
                                />
                                <div className="w-full text-[0.7rem] text-white">
                                  *Polygon Address Only
                                </div>
                                <FormError
                                  top="-10px"
                                  isAbsolute
                                  sx={{ position: "relative" }}>
                                  <>{touched.payout && errors.payout}</>
                                </FormError>
                              </Grid>

                              <Grid item xs={12} className="flex items-center ">
                                <div className="text-base font-bold text-white">
                                  Discord Username
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  sx={{ width: "38%" }}
                                  className="bg-white rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={values?.socials?.discord_username}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      borderRadius: "0.375rem",
                                    },
                                  }}
                                  inputProps={{
                                    style: {
                                      padding: "10px 12px",
                                      textIndent: "5px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    setFieldValue("socials", {
                                      discord_username: event.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} className="flex items-center ">
                                <div className="text-base font-bold text-white">
                                  Notification Email
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  sx={{ width: "38%" }}
                                  className="bg-white rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={values.notif_email}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      borderRadius: "0.375rem",
                                    },
                                  }}
                                  inputProps={{
                                    style: {
                                      padding: "10px 12px",
                                      textIndent: "5px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "notif_email",
                                      event.target.value,
                                    );
                                    if (event.target.value === "") {
                                      setFieldValue("checkedNotif", false);
                                    } else {
                                      setFieldValue("checkedNotif", true);
                                    }
                                  }}
                                />
                              </Grid>
                              {values.notif_email && (
                                <Grid
                                  item
                                  xs={12}
                                  className="flex items-center ">
                                  <FormGroup>
                                    <FormControlLabel
                                      className="text-white"
                                      control={
                                        <Checkbox
                                          sx={{
                                            color:
                                              "rgb(111 90 205 / var(--tw-bg-opacity))",
                                            "&.Mui-checked": {
                                              color:
                                                "rgb(111 90 205 / var(--tw-bg-opacity))",
                                            },
                                          }}
                                          checked={values.checkedNotif}
                                          disabled={
                                            values.notif_email.length === 0
                                          }
                                          onChange={() => {
                                            setFieldValue(
                                              "checkedNotif",
                                              !values.checkedNotif,
                                            );
                                          }}
                                        />
                                      }
                                      label="Send Notifications via Email"
                                    />
                                  </FormGroup>
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={12}
                                className="flex flex-row justify-end">
                                <Button
                                  onClick={() => setIsEdit(false)}
                                  variant="text"
                                  className="w-full ax:w-28 h-12 text-white text-base font-inter font-bold rounded-full self-end mb-3 ax:mb-0 bg-transparent normal-case">
                                  Cancel
                                </Button>
                                <Button
                                  disabled={isMutateUpdateProfileLoading}
                                  type="submit"
                                  className="bg-[#6F5ACD] w-full ax:w-32 h-12 text-white font-bold font-inter text-base rounded-full self-end normal-case"
                                  variant="contained">
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        );
                      }}
                    </Formik>
                  </>
                ) : Boolean(!isEdit && user) ? (
                  <div className="flex flex-col text-black text-base ">
                    <div className="pb-3 font-bold text-3xl">General</div>
                    <div className="ml-6 flex flex-col gap-5 text-black text-base">
                      <div className="">
                        <b>Display Name: </b> {user?.user_name}
                      </div>
                      <div>
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer"
                          onClick={toggleOpen}>
                          <b>Socials</b>
                          <div
                            className={`transform transition-transform duration-300 ease-in-out ${
                              isShowingSocials ? "rotate-180" : ""
                            }`}>
                            <div className="flex items-center justify-center hover:opacity-70">
                              <ArrowDownIcon height="12" width="12" />
                            </div>
                          </div>
                        </div>
                        {isShowingSocials && (
                          <div
                            className={`flex flex-row transition-opacity duration-300 ease-in-out ${
                              isShowingSocials ? "opacity-100" : "opacity-0"
                            } overflow-hidden`}>
                            {/* Add your social handles here */}
                            <div id="socials" className="flex flex-col ml-8">
                              <div
                                id="discord"
                                className="flex items-center flex-row gap-2">
                                <div className="logo flex h-5 w-5 items-center justify-center cursor-pointer hover:opacity-80">
                                  <DiscordIcon />
                                </div>
                                <div>
                                  {user?.socials?.discord_username
                                    ? ensureStartsWithAt(
                                        user?.socials?.discord_username,
                                      )
                                    : "Not set"}
                                </div>
                              </div>
                            </div>

                            {/* Add more social handles as needed */}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <b>Country: </b> {user?.country}
                      </div>
                      <div className="">
                        <b>Preferred Language for Translations: </b>{" "}
                        {
                          languageList.find(
                            (x) => x.id === user?.preferred_language,
                          )?.name
                        }
                      </div>

                      {user?.qualifications?.length > 0 && (
                        <div className="">
                          <b>Qualifications: </b>{" "}
                          {user.qualifications.map((x) => x.name).join(", ")}
                        </div>
                      )}

                      <div className="">
                        <b>Payout Address: </b>
                        {user?.payout_address}
                      </div>
                      <div className="">
                        <b>Notification Email:</b>{" "}
                        {user?.email_notif
                          ? `Enabled (${user?.notification_email})`
                          : `Disabled ${
                              user?.notification_email
                                ? `(${user?.notification_email})`
                                : ""
                            }`}
                      </div>
                      {/* <FormGroup className="pb-5">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "rgb(111 90 205 / var(--tw-bg-opacity))",
                                "&.Mui-checked": {
                                  color:
                                    "rgb(111 90 205 / var(--tw-bg-opacity))",
                                },
                              }}
                              checked={browserNotif}
                              disabled={Notification.permission === "denied"}
                              onChange={() => {
                                handleBrowserNotif(!browserNotif);
                              }}
                            />
                          }
                          label="Send browser notifications"
                        />
                      </FormGroup> */}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </StyledInnerContainer>
              {Boolean(!isEdit) && (
                <div className="flex flex-row pt-5">
                  <Button
                    type="button"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                    className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white font-inter text-md rounded-full mr-2"
                    variant="contained">
                    Edit Profile
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      setChangePassword(true);
                    }}
                    className="bg-[#6F5ACD] w-full max-w-[200px] h-10 text-white font-inter text-md rounded-full"
                    variant="contained">
                    Change Password
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          open={changePassword}
          title="Change Password"
          onClose={() => {
            setChangePassword(false);
          }}>
          <Formik
            initialValues={{
              old_password: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={PasswordSchema}
            onSubmit={(values) => {
              setIsChangingPassword(true);
              handleSubmitPassword(values);
            }}>
            {({ values, setFieldValue, touched, errors }) => {
              return (
                <Form>
                  <Grid container columnGap={2} rowGap={2}>
                    <Grid item xs={12}>
                      <div style={{ fontWeight: "800" }}>Current Password</div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%", height: "45px" }}
                        variant="filled"
                        size="small"
                        value={values.old_password}
                        type="password"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            borderRadius: "0.375rem",
                          },
                        }}
                        inputProps={{
                          style: {
                            padding: "10px 12px",
                            textIndent: "15px",
                          },
                        }}
                        onChange={(event) =>
                          setFieldValue("old_password", event.target.value)
                        }
                      />
                      <FormError
                        top="-10px"
                        isAbsolute
                        sx={{ position: "relative" }}>
                        <>{touched.old_password && errors.old_password}</>
                      </FormError>
                    </Grid>
                    <Grid item xs={12} className="flex items-center mt-3">
                      <div style={{ fontWeight: "800" }}>New Password</div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%", height: "45px" }}
                        variant="filled"
                        size="small"
                        value={values.password}
                        type="password"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            borderRadius: "0.375rem",
                          },
                        }}
                        inputProps={{
                          style: {
                            padding: "10px 12px",
                            textIndent: "15px",
                          },
                        }}
                        onChange={(event) =>
                          setFieldValue("password", event.target.value)
                        }
                      />
                      <FormError
                        top="-10px"
                        isAbsolute
                        sx={{ position: "relative" }}>
                        <>{touched.password && errors.password}</>
                      </FormError>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ fontWeight: "800" }}>Confirm Password</div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%", height: "45px" }}
                        variant="filled"
                        size="small"
                        value={values.confirm_password}
                        type="password"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            borderRadius: "0.375rem",
                          },
                        }}
                        inputProps={{
                          style: {
                            padding: "10px 12px",
                            textIndent: "15px",
                          },
                        }}
                        onChange={(event) =>
                          setFieldValue("confirm_password", event.target.value)
                        }
                      />
                      <FormError
                        top="-10px"
                        isAbsolute
                        sx={{ position: "relative" }}>
                        <>
                          {touched.confirm_password && errors.confirm_password}
                        </>
                      </FormError>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#ffffff",
                          fontSize: "1rem",
                          lineHeight: "1.5rem",
                          fontWeight: "700",
                          alignSelf: "flex-end",
                          width: "100%",
                          height: "3rem",
                          borderRadius: "9999px",
                          background: !isChangingPassword
                            ? "#6F5ACD"
                            : "rgb(179 179 179)",
                        }}
                        disabled={isChangingPassword}
                        type="submit"
                        variant="contained">
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </StyledContainer>
    </section>
  );
};

export default Component;
