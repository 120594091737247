import { ContentState, EditorState, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import { TextEditorWrapper } from "./TextEditor.styles";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export interface IEditorComponentProps extends EditorProps {
  type?: "compact";
  htmlString?: string;
  options?: (
    | "inline"
    | "blockType"
    | "fontSize"
    | "fontFamily"
    | "list"
    | "textAlign"
    | "colorPicker"
    | "link"
    | "embedded"
    | "emoji"
    | "image"
    | "remove"
    | "history"
  )[];
  onEditorChange?: (htmlString: string) => void;
  debounceTimeout?: number;
  placeholder?: string;
  className?: string;
  isTranslated;
}

const DEFAULT_OPTIONS = [
  "inline",
  "blockType",
  "fontSize",
  // "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  "link",
  "embedded",
  "emoji",
  "image",
  "remove",
  "history",
];

export default function TextEditor({
  type,
  htmlString = "",
  onEditorChange,
  debounceTimeout = 100,
  options = [],
  placeholder,
  isTranslated,
  ...rest
}: IEditorComponentProps) {
  const [editorState, setEditorState] = useState(() => {
    if (htmlString) {
      let convertedToHTML = decodeURIComponent(htmlString);
      const blocksFromHtml = htmlToDraft(convertedToHTML);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (!htmlString) {
      setEditorState(EditorState.createEmpty());
      setEditorState((prevState) => EditorState.moveFocusToEnd(prevState));
    }
    if (isTranslated) {
      let convertedToHTML = decodeURIComponent(htmlString);
      const blocksFromHtml = htmlToDraft(convertedToHTML);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );

      setEditorState(EditorState.createWithContent(contentState));
      // setEditorState((prevState)=>EditorState.createWithContent(contentState));
    }
  }, [htmlString]);

  return (
    <TextEditorWrapper type={type}>
      <Editor
        editorState={editorState}
        toolbarClassName="wysiwyg__toolbar"
        wrapperClassName="wysiwyg"
        editorClassName="wysiwyg__editor"
        onEditorStateChange={onEditorStateChange}
        handlePastedText={() => false}
        toolbar={{
          options: options?.length ? options : DEFAULT_OPTIONS,
        }}
        placeholder={placeholder}
        {...rest}
      />
    </TextEditorWrapper>
  );

  function onEditorStateChange(newState: EditorState) {
    const currentContent = convertToRaw(newState.getCurrentContent());
    let draftContent = draftToHtml(currentContent);

    if (draftContent.trim() === "<p></p>") draftContent = "";

    setEditorState(newState);
    onEditorChange?.(draftContent);
  }
}
