import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Box, Button } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import ArrowRight from "../../../assets/images/ArrowRight.svg";
import Brain from "../../../assets/images/Brain.png";
import JohnNFT from "../../../assets/images/John.png";
import PhoneIcon from "../../../assets/images/PhoneIcon.svg";
import DropDown from "../../../assets/images/dropdown.png";
import DropDownBlack from "../../../assets/images/dropdownblack.png";
import DropUp from "../../../assets/images/dropup.png";
import HandNFT from "../../../assets/images/handnft.png";
import PartyPopper from "../../../assets/images/onboard_icon.svg";
import Profile from "../../../assets/images/profile.png";
import { updateUserOnboarding } from "../../../services/users.js";
import { assetState, authState, paginationState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

import {
  CircledQuestionIcon,
  ClipBoardIcon,
  PlusIcon,
  StarDashboardIcon,
} from "../../../assets/svg";
import TaskItem from "../../organisms/TaskItem";

import { CircularProgress } from "@mui/material";
import { useAccount, useConnect, useDisconnect, useNetwork } from "wagmi";
import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";

import { useMutation } from "react-query";
import { getBalance, getTokenContract } from "../../../utils/contracts";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 40,
  borderRadius: 60,
  boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.1)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 60,
    backgroundColor: "#E0848D",
  },
}));

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 15px;
  background: #806ad2;

  @media (min-width: 768px) {
    width: 10rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const MiniTaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 15px;
  background: #806ad2;

  @media (min-width: 768px) {
    width: 7rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const StyledContainerForOnboarding = styled.div`
  display: flex;
  height: 100%;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 100%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const LeftBox = styled(Box)`
  flex: 0.68;
  @media screen and (max-width: 760px) {
    flex: 1;
    row-gap: 16px;
    margin-bottom: 16px;
  }
`;

const RightBox = styled(Box)`
  flex: 0.3;
  @media screen and (max-width: 760px) {
    flex: 1;
    padding-bottom: 20px;
  }
`;

const RightDiv = styled(Box)`
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
`;
const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin: 20px 0 30px;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

const ShadowedContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
`;

const EnergyContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;

  @media only screen and (max-width: 760px) {
    justify-content: flex-end !important;
    .eng-img {
      left: 10px !important;
      height: 120px;
      width: 80px;
    }
    .eng-div {
      width: 70% !important;

      .text-5xl {
        font-size: 20 px !important;
      }
    }
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #806ad2;
  border-radius: 50px;
`;

const FilterButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #806ad2;
  border-radius: 20px;
  width: 150px;
  height: 30px;
  padding: 0px 12px;
  color: white;
  user-select: none;
  /* background-color: #806ad2; */
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? `
        background-color: #806ad2;
        color: white;
        `
      : `  color: #000000;`}
`;

const WelcomeContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
  min-height: 200px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(226, 219, 250, 1) 0%,
    rgba(250, 223, 246, 1) 100%
  );
  @media only screen and (max-width: 760px) {
    justify-content: flex-end !important;
    .eng-div {
      width: 70% !important;

      .text-5xl {
        font-size: 24px !important;
      }
    }
  }
`;

const OptionsButton = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 88px; */
  height: 30px;
  /* min-width: 88px; */
  padding-left: 12px;
  padding-right: 12px;

  border: 1px solid #806ad2;
  border-radius: 20px;

  ${({ selected }) =>
    selected
      ? `
        background-color: #806ad2;
        color: white;
        `
      : `  color: #000000;`}

  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  text-align: center;

  :hover {
    background-color: #806ad270;
  }
`;

const TaskItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  > div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

let timerid;

type WelcomeData = {
  energy: string;
  sender: string;
};
type Props = {
  tasks: Array<any>;
  nft: any;
  handleDeleteTask: (taskId: string) => void;
  handleFavoriteTask: (task: any) => void;
  after: any;
  before: any;
  energy?: number;
  handleSwitchFetchedTasks: (fetchtype: string) => void;
  handleNextPage: (after: any) => void;
  handlePrevPage: (before: any) => void;
  isFavorites: boolean;
  fetchType: string;
  isLoading: boolean;
  handleShowGuide: () => void;
  user: any;
};

type UserData = {
  user_name: string;
  user_email: string;
  user_id: string;
  country?: string;
  payout_address?: string;
  comment?: string | null;
  welcome?: string | null;
  max_energy?: number;
  onboarding?: string;
};

const Component = ({
  tasks,
  nft,
  handleDeleteTask,
  handleFavoriteTask,
  after,
  before,
  energy = 0,
  handleSwitchFetchedTasks,
  handleNextPage,
  handlePrevPage,
  isFavorites,
  fetchType,
  isLoading,
  handleShowGuide,
  user,
}: Props) => {
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [assets, setAsset] = useRecoilState(assetState);
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [isOpenHDAOMenu, setIsOpenHDAOMenu] = useState(false);
  const [isOpenNativeMenu, setIsOpenNativeMenu] = useState(false);
  const [isOpenStableMenu, setIsOpenStableMenu] = useState(false);
  const [filterText, setFilterText] = useState("open");
  const [HDAO, setHDAO] = useState(0);
  const [Stable, setStable] = useState(3);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [showEmailGuideBanner, setShowEmailGuideBanner] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const statuses = ["completed", "draft", "published", "none"];
  const { address, connector, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();
  const { connect, connectors, error, pendingConnector } = useConnect();

  const connectWallet = () => {
    for (let i = 0; i < connectors.length; i++) {
      if (connectors[i].ready) {
        const connector = connectors[i];
        connect({ connector });
        break;
      }
    }
  };

  //const disconnectWallet = useDisconnect();
  //const connectWithMetamask = useMetamask();

  const Ether_Stables = [
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "0x6b175474e89094c44da98b954eedeac495271d0f",
  ];
  const Polygon_Stables = [
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  ];

  const HDAO_ADDR = {
    1: "0xdac657ffd44a3b9d8aba8749830bf14beb66ff2d",
    137: "0x72928d5436ff65e57f72d5566dcd3baedc649a88",
  };

  const stableTokens = ["USDC", "USDT", "DAI", "Total USD"];
  const hdaoTokens = ["HDAO", "Staked"];

  const [etherStable, setEtherStable] = useState(0);
  const [etherHDAO, setEtherHDAO] = useState(0);
  const [etherHDAOStacked, setEtherHDAOStacked] = useState(0);

  const [polygonStable, setPolygonStable] = useState(0);
  const [polygonHDAO, setPolygonHDAO] = useState(0);
  const [polygonHDAOStacked, setPolygonHDAOStacked] = useState(0);

  const [eUSDC, setEUSDC] = useState(0);
  const [eUSDT, setEUSDT] = useState(0);
  const [eDAI, setEDAI] = useState(0);
  const [pUSDC, setPUSDC] = useState(0);
  const [pUSDT, setPUSDT] = useState(0);
  const [pDAI, setPDAI] = useState(0);

  const [eBalance, setEBalance] = useState(0);
  const [pBalance, setPBalance] = useState(0);
  const [isEther, setIsEther] = useState(true);

  const [pending, setPending] = useState(false);
  const [pending1, setPending1] = useState(false);
  const [pending2, setPending2] = useState(false);

  const [welcome, setWelcome] = useState<WelcomeData>();
  const max_energy = user?.max_energy ? user.max_energy : 20;

  const getHDAO = async () => {
    // setPending2(true);
    let tokenContract = getTokenContract(HDAO_ADDR[1], 1);
    await tokenContract
      .balanceOf(address)
      .then((data) => {
        setEtherHDAO(data / Math.pow(10, 18));
        setAsset((assets) => ({
          ...assets,
          etherHDAO: data / Math.pow(10, 18),
        }));
      })
      .catch((error) => console.log(error));
    tokenContract = getTokenContract(HDAO_ADDR[137], 137);
    await tokenContract
      .balanceOf(address)
      .then((data) => {
        setPolygonHDAO(data / Math.pow(10, 18));
        setAsset((assets) => ({
          ...assets,
          polygonHDAO: data / Math.pow(10, 18),
        }));
      })
      .catch((error) => console.log(error));
    setPending2(false);
  };

  const getBal = async () => {
    // setPending1(true);
    try {
      const eB = await getBalance(address, 1);
      setEBalance(eB / Math.pow(10, 18));
      const pB = await getBalance(address, 137);
      setPBalance(pB / Math.pow(10, 18));
      setAsset((assets) => ({
        ...assets,
        eBalance: eB / Math.pow(10, 18),
        pBalance: pB / Math.pow(10, 18),
      }));
    } catch (e) {
      console.log("error", e);
      setPending1(false);
    }
    setPending1(false);
  };

  const getTotalUSDS = async () => {
    // setPending(true);
    let tokenContract = getTokenContract(Ether_Stables[0], 1);
    try {
      await tokenContract.balanceOf(address).then((data) => {
        setEUSDC(data / Math.pow(10, 6));
        setAsset((assets) => ({
          ...assets,
          eUSDC: data / Math.pow(10, 6),
        }));
      });
      tokenContract = getTokenContract(Polygon_Stables[0], 137);
      await tokenContract.balanceOf(address).then((data) => {
        setPUSDC(data / Math.pow(10, 6));
        setAsset((assets) => ({
          ...assets,
          pUSDC: data / Math.pow(10, 6),
        }));
      });

      tokenContract = getTokenContract(Ether_Stables[1], 1);
      await tokenContract.balanceOf(address).then((data) => {
        setEUSDT(data / Math.pow(10, 6));
        setAsset((assets) => ({
          ...assets,
          eUSDT: data / Math.pow(10, 18),
        }));
      });
      tokenContract = getTokenContract(Polygon_Stables[1], 137);
      await tokenContract.balanceOf(address).then((data) => {
        setPUSDT(data / Math.pow(10, 6));
        setAsset((assets) => ({
          ...assets,
          pUSDT: data / Math.pow(10, 18),
        }));
      });

      tokenContract = getTokenContract(Ether_Stables[2], 1);
      await tokenContract.balanceOf(address).then((data) => {
        setEDAI(data / Math.pow(10, 18));
        setAsset((assets) => ({
          ...assets,
          eDAI: data / Math.pow(10, 18),
        }));
      });
      tokenContract = getTokenContract(Polygon_Stables[2], 137);
      await tokenContract.balanceOf(address).then((data) => {
        setPDAI(data / Math.pow(10, 18));
        setAsset((assets) => ({
          ...assets,
          pDAI: data / Math.pow(10, 18),
          isLoaded: true,
        }));
      });
      setPending(false);
    } catch (e) {
      console.log(e);
      setPending(false);
    }
  };

  useEffect(() => {
    if (!address || !(chain?.id === 1 || chain?.id === 137)) return;
    if (assets.isLoaded) return;
    if (timerid) clearInterval(timerid);
    //timerid = setInterval(() => {
    getBal();
    getHDAO();
    getTotalUSDS();
    //}, 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (Stable !== 3) {
      if (Stable == 0) {
        setEtherStable(assets.eUSDC);
        setPolygonStable(assets.pUSDC);
      } else if (Stable == 1) {
        setEtherStable(assets.eUSDT);
        setPolygonStable(assets.pUSDT);
      } else if (Stable == 2) {
        setEtherStable(assets.eDAI);
        setPolygonStable(assets.pDAI);
      }
    }
  }, [Stable, HDAO]);

  const navigateTo = (route: string) => () => {
    console.log(route);
    navigate(route);
  };

  const filterList = {
    all: "All",
    published: "Published",
    in_progress: "Active",
    clarification_needed: "Clarification",
    submitted: "Review",
    completed: "Completed",
    draft: "Draft",
    none: "Favorite",
    open: "Open",
  };

  const onClickFilterItem = (filtertype: string) => {
    setIsOpenFilterMenu(false);
    if (
      filtertype !== "draft" &&
      filtertype !== "completed" &&
      filtertype !== "none"
    )
      setFilterText(filtertype);
    // if (pagination.fetchType === filtertype) return;
    // setPageNum(0);
    setPagination({
      fetchType: filtertype,
      pageNum: 0,
      after: null,
      before: null,
    });
    handleSwitchFetchedTasks(filtertype);
  };

  const nextPage = () => {
    if (after !== null) {
      setPagination({
        fetchType: fetchType,
        pageNum: pagination.pageNum + 1,
        after: after,
        before: null,
      });
      handleNextPage(after);
    }
  };

  const prevPage = () => {
    if (before !== null) {
      setPagination({
        fetchType: fetchType,
        pageNum: pagination.pageNum - 1,
        after: null,
        before: before,
      });
      handlePrevPage(before);
    }
  };

  const handleGotIt = () => {
    const body = {
      user_id: auth.user_id,
      action: "acknowledge",
      message: user?.onboarding,
    };
    mutateUpdateUserOnboarding(body);
    if (user?.onboarding == "email_notification") {
      setShowEmailGuideBanner(false);
    }
    if (user?.onboarding == "welcome") {
      setIsOnboarding(false);
    }
  };

  const { mutate: mutateUpdateUserOnboarding } = useMutation(
    ["updateOwnerProfile", updateUserOnboarding],
    (body: any) => {
      return updateUserOnboarding(auth.accessToken, body);
    },
    {
      onSuccess: (resp) => {
        setIsOnboarding(false);
      },
      onError: (e) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  // if (isOnboarding) {
  //   return <OwnerOnboardingPage user={user}></OwnerOnboardingPage>;
  // } else {
  return (
    user && (
      <StyledContainer className="bg-mainBg">
        <StyledBoardWrapper>
          <div className="flex flex-col w-full align-left items-start text-black odMax:hidden ">
            <div className="text-xs pb-2">Owner Dashboard</div>
            <div
              className="text-5xl font-[1000] pb-4"
              data-test-id="dashboard-header">
              Dashboard
            </div>
          </div>
          {isOnboarding ? (
            <div className="flex items-center justify-left odMax:mx-5 w-full">
              <WelcomeContainer className="relative h-full p-5">
                <img
                  src={PartyPopper}
                  className="absolute right-11 eng-img w-[200px] mdMax:hidden xxl:bottom-1 xxlMax:bottom-2 "
                />
                <div className="flex flex-col md:w-[64%] pl-[1%]">
                  <span className="text-black font-inter text-4xl mdMax:w-full font-bold mb-1 ">
                    Hey, {user?.user_name}
                  </span>
                  <span className="text-black font-inter font-medium text-base">
                    Welcome to the Human Task Force app. You've just been gifted
                    with {welcome?.energy} energy credits thanks to{" "}
                    {welcome?.sender}. Create a task worth 30 mins of PA time
                    for every energy credit you use.
                  </span>
                  <TaskPageButton
                    className="mt-[1rem] text-base"
                    onClick={() => {
                      handleGotIt();
                    }}>
                    Got it
                  </TaskPageButton>
                </div>
              </WelcomeContainer>
            </div>
          ) : (
            <></>
          )}
          {showEmailGuideBanner && !isOnboarding ? (
            <div className="flex items-center justify-left odMax:mx-5 w-full">
              <WelcomeContainer className="relative h-full p-5">
                <img
                  src={PhoneIcon}
                  className="absolute right-11 eng-img w-[200px] mdMax:hidden xxl:bottom-1 xxlMax:bottom-2 h-[190px]"
                />
                <div className="flex flex-col md:w-[64%] pl-[1%]">
                  <span className="text-black font-inter text-4xl mdMax:w-full font-bold mb-1 ">
                    Get notified
                  </span>
                  <span className="text-black font-inter font-medium text-base">
                    To avoid missing important information related to your task,
                    you should enable notifications in your profile page.{" "}
                    <b>
                      Please note that tasks submitted for your review, if left
                      unreviewed, are automatically accepted after 48 hours.
                    </b>
                  </span>

                  <div className="flex flex-row">
                    <TaskPageButton
                      className="mt-[1rem] text-base mr-3"
                      onClick={() => {
                        handleShowGuide();
                      }}>
                      Show me how
                    </TaskPageButton>

                    <MiniTaskPageButton
                      className="mt-[1rem] text-base"
                      onClick={() => {
                        handleGotIt();
                      }}>
                      Got it
                    </MiniTaskPageButton>
                  </div>
                </div>
              </WelcomeContainer>
            </div>
          ) : (
            <></>
          )}

          <StyledCardsWrapper>
            <LeftBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}>
              <div className="items-center justify-center mb-2">
                <EnergyContainer
                  data-test-id="dashboard-energy-container"
                  className="flex relative bg-buttonBorder p-8 pb-11 items-center justify-center">
                  <img
                    src={HandNFT}
                    className="absolute bottom-0 left-8 eng-img scale-100"
                  />
                  <div className="flex flex-col w-4/6 eng-div ">
                    <div className="flex flex-row text-white font-inter font-medium text-base mb-4 items-center">
                      <div className="text-sm">ENERGY </div>
                      <div
                        className="pl-3 font-extrabold text-3xl"
                        data-test-id="dashboard-energy">
                        {energy}/{max_energy}
                      </div>
                    </div>
                    {energy > max_energy ? (
                      <BorderLinearProgress variant="determinate" value={100} />
                    ) : (
                      <BorderLinearProgress
                        variant="determinate"
                        value={(energy / max_energy) * 100}
                      />
                    )}
                  </div>
                </EnergyContainer>
              </div>
              <div className="min-h-[600px]">
                <ShadowedContainer
                  className="flex flex-col justify-start bg-tableGrey h-full p-6 mb-3"
                  data-test-id="dashboard-tasks-container">
                  <div className="w-full flex flex-row justify-between h-max">
                    <div className="flex h-max items-center gap-3 justify-between md:justify-start">
                      <div className="font-inter text-2xl text-black font-bold my-2 ">
                        Your Tasks
                      </div>
                      <AddButton
                        data-test-id="dashboard-create-task-btn"
                        className="hover:cursor-pointer w-[55] h-[55] md:w-[45] md:h-[45] hover:scale-[1.1] ease-in-out duration-300 outline-none border-none"
                        onClick={() => navigate("/tasks/create")}>
                        <PlusIcon className="w-[20] h-[20] md:w-[18] md:h-[18]" />
                      </AddButton>
                    </div>
                    <div className="md:hidden flex self-center flex-row gap-3 md:justify-end">
                      <div className="relative">
                        <FilterButton
                          className="hover:opacity-90"
                          // selected={
                          //   pagination.fetchType !== "draft" &&
                          //   pagination.fetchType !== "none"
                          //     ? true
                          //     : false
                          // }
                          selected={true}
                          onClick={() => {
                            setIsOpenFilterMenu(!isOpenFilterMenu);
                          }}>
                          <span>
                            {/* {pagination.fetchType !== "draft" &&
                        pagination.fetchType !== "none"
                          ? filterList[pagination.fetchType]
                          : "No Filter"} */}
                            {filterList[pagination.fetchType]}
                          </span>
                          <svg
                            className="ml-5 w-4 h-4"
                            aria-hidden="true"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"></path>
                          </svg>
                        </FilterButton>
                        {isOpenFilterMenu && (
                          <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%]">
                            <div
                              onClick={() => {
                                onClickFilterItem("open");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                              Open
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("published");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                              Published
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("clarification_needed");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                              Clarification
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("in_progress");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                              Active
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("submitted");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                              Review
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("completed");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] rounded-b-[5px] select-none">
                              Completed
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("draft");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] rounded-b-[5px] select-none">
                              Draft
                            </div>
                            <div
                              onClick={() => {
                                onClickFilterItem("none");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] rounded-b-[5px] select-none">
                              Favorite
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:flex w-full flex self-center flex-row gap-3 mt-4 ">
                    <div className="relative">
                      <FilterButton
                        className="hover:opacity-90"
                        selected={
                          pagination.fetchType !== "draft" &&
                          pagination.fetchType !== "none" &&
                          pagination.fetchType !== "completed"
                            ? true
                            : false
                        }
                        onClick={() => {
                          onClickFilterItem(filterText);
                        }}>
                        <span>
                          {/* {pagination.fetchType !== "draft" &&
                          pagination.fetchType !== "none"
                            ? filterList[pagination.fetchType]
                            : "No Filter"} */}
                          {/* {filterList[filterText]} */}
                          {pagination.fetchType !== "draft" &&
                          pagination.fetchType !== "none" &&
                          pagination.fetchType !== "completed"
                            ? filterList[pagination.fetchType]
                            : filterList[filterText]}
                        </span>
                        <img
                          src={
                            !isOpenFilterMenu
                              ? pagination.fetchType !== "draft" &&
                                pagination.fetchType !== "none" &&
                                pagination.fetchType !== "completed"
                                ? DropDown
                                : DropDownBlack
                              : DropUp
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsOpenFilterMenu(!isOpenFilterMenu);
                          }}
                          data-test-id="dashboard-filter-dropdown"
                          className="w-4 h-4 self-center"
                        />
                      </FilterButton>
                      {isOpenFilterMenu && (
                        <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%] text-left">
                          {pagination.fetchType !== "open" && (
                            <div
                              data-test-id="dashboard-filter-open"
                              onClick={() => {
                                onClickFilterItem("open");
                              }}
                              className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] hover:rounded-t-[5px] select-none">
                              Open
                            </div>
                          )}
                          <div
                            data-test-id="dashboard-filter-published"
                            onClick={() => {
                              onClickFilterItem("published");
                            }}
                            className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                            Published
                          </div>
                          <div
                            data-test-id="dashboard-filter-clarification"
                            onClick={() => {
                              onClickFilterItem("clarification_needed");
                            }}
                            className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                            Clarification
                          </div>
                          <div
                            data-test-id="dashboard-filter-active"
                            onClick={() => {
                              onClickFilterItem("in_progress");
                            }}
                            className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                            Active
                          </div>
                          <div
                            data-test-id="dashboard-filter-review"
                            onClick={() => {
                              onClickFilterItem("submitted");
                            }}
                            className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                            Review
                          </div>
                        </div>
                      )}
                    </div>
                    <OptionsButton
                      data-test-id="dashboard-filter-completed"
                      className="hover:cursor-pointer select-none"
                      selected={
                        pagination.fetchType === "completed" ? true : false
                      }
                      onClick={() => {
                        onClickFilterItem("completed");
                      }}>
                      Completed
                    </OptionsButton>
                    <OptionsButton
                      data-test-id="dashboard-filter-draft"
                      className="hover:cursor-pointer select-none"
                      selected={pagination.fetchType === "draft" ? true : false}
                      onClick={() => {
                        onClickFilterItem("draft");
                      }}>
                      Drafts
                    </OptionsButton>
                    <OptionsButton
                      data-test-id="dashboard-filter-favorite"
                      className="hover:cursor-pointer select-none"
                      selected={pagination.fetchType === "none" ? true : false}
                      onClick={() => {
                        onClickFilterItem("none");
                      }}>
                      Favorites
                    </OptionsButton>
                  </div>
                  <TaskItemsContainer>
                    {isLoading ? (
                      <CircularProgress
                        color="inherit"
                        size="6rem"
                        sx={{ margin: "auto" }}
                      />
                    ) : (
                      <>
                        {tasks.length > 0 ? (
                          tasks.map((task) => {
                            const isTrashDisabled = !statuses.includes(
                              task.status,
                            );
                            return (
                              <TaskItem
                                key={task.task_id}
                                title={task.title}
                                energy={task.energy_assigned}
                                status={task.status}
                                latestComment={task.latest_comment_role}
                                taskType={task.task_type}
                                onStarClick={() => handleFavoriteTask(task)}
                                onPenClick={() =>
                                  navigate(`/tasks/view/${task.task_id}`)
                                }
                                onTrashClick={
                                  !isTrashDisabled
                                    ? () => handleDeleteTask(task.task_id)
                                    : undefined
                                }
                                isTrashDisabled={isTrashDisabled}
                              />
                            );
                          })
                        ) : pagination.fetchType == "all" ? (
                          <div className="flex text-black flex-col items-center  justify-center text-center m-auto px-[15%]">
                            <div className="scale-125">
                              <CircledQuestionIcon></CircledQuestionIcon>
                            </div>
                            <div className="text-4xl select-none font-bold ">
                              No tasks here?
                            </div>
                            <div className="text-lg select-none pt-1 font-medium">
                              Check your Completed, Drafts or Favorites tabs
                              above or spend your energy on a task by clicking
                              the plus icon.
                            </div>
                          </div>
                        ) : pagination.fetchType == "draft" ? (
                          <>
                            <div className="flex text-black flex-col items-center  justify-center text-center m-auto px-[15%]">
                              <div className="scale-125 pb-2">
                                <ClipBoardIcon></ClipBoardIcon>
                              </div>
                              <div className="text-3xl select-none font-bold ">
                                Looks like you haven't started a draft yet
                              </div>
                              <div className="text-lg select-none pt-1 font-medium">
                                Drafts are meant to save your task description
                                while you're not ready to make it available to
                                the task pool
                              </div>
                              <div className="text-lg select-none pt-2 font-medium">
                                Save your task as a draft to keep them there
                              </div>
                              <div className="italic text-xs select-none px-[10%] font-medium">
                                If you expected to see existing tasks here, it
                                could be that you earlier were connected with a
                                different wallet
                              </div>
                            </div>
                          </>
                        ) : pagination.fetchType == "none" ? (
                          <div className="flex text-black flex-col items-center  justify-center text-center m-auto px-[15%]">
                            <div className="scale-125 pb-2">
                              <StarDashboardIcon></StarDashboardIcon>
                            </div>
                            <div className="text-3xl select-none font-bold ">
                              Save your first template
                            </div>
                            <div className="text-lg select-none pt-1 font-medium">
                              When you set your task as a favorite, you're
                              creating a template that you can use to fill out
                              the task description right away
                            </div>
                            <div className="text-lg select-none pt-2 font-medium">
                              You can do that by clicking the star icon on your
                              dashboard
                            </div>
                            <div className="italic text-xs select-none px-[10%] font-medium">
                              If you expected to see existing tasks here, it
                              could be that you earlier were connected with a
                              different wallet
                            </div>
                          </div>
                        ) : (
                          <div className="flex text-black flex-col items-center  justify-center text-center m-auto px-[15%]">
                            <div className="scale-125">
                              <CircledQuestionIcon></CircledQuestionIcon>
                            </div>
                            <div className="text-4xl select-none font-bold ">
                              No tasks here?
                            </div>
                            <div className="text-lg select-none pt-1 font-medium">
                              Check your Completed, Drafts or Favorites tabs
                              above or spend your energy on a task by clicking
                              the plus icon.
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </TaskItemsContainer>
                  <div
                    className={`${
                      tasks.length == 0 ? "hidden " : " "
                    } flex h-max items-center justify-end md:justify-end mt-5`}>
                    <a
                      onClick={() => prevPage()}
                      className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                        before === null
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}>
                      Previous
                    </a>
                    <span className="mx-2 text-gray-500 font-medium">
                      {pagination.pageNum + 1}
                    </span>
                    <a
                      onClick={() => nextPage()}
                      className={`inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                        after === null
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}>
                      Next
                    </a>
                  </div>
                </ShadowedContainer>
              </div>
            </LeftBox>

            <RightBox>
              <ShadowedContainer className="bg-nftBg w-full ">
                <RightDiv data-test-id="dashboard-owner-profile">
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    alignItems="center">
                    <img
                      src={JohnNFT}
                      className="w-40 h-40 self-center rounded-3xl"
                    />
                    <ProfileName data-test-id="dashboard-name-text">
                      {user?.user_name?.length > 0
                        ? user?.user_name
                        : user?.email
                        ? user?.email
                        : user?.notification_email
                        ? user?.notification_email
                        : `${address?.substring(0, 5)}...${address?.substring(
                            address?.length - 2,
                          )}`}
                    </ProfileName>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="12px"
                    width="100%">
                    {isConnected ? (
                      chain?.id == 1 || chain?.id == 137 ? (
                        <>
                          <PinkBox>
                            <KnowledgeTitle>
                              <img src={Brain} />
                              Assets
                            </KnowledgeTitle>
                            <AssetBox>
                              <div className="relative">
                                <FilterButton
                                  className="hover:opacity-90 font-bold"
                                  selected={true}
                                  onClick={() => {
                                    setIsOpenHDAOMenu(!isOpenHDAOMenu);
                                  }}>
                                  <span>{hdaoTokens[HDAO]}</span>
                                  <svg
                                    className="ml-5 w-4 h-4"
                                    aria-hidden="true"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 9l-7 7-7-7"></path>
                                  </svg>
                                </FilterButton>
                                {isOpenHDAOMenu && (
                                  <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%]">
                                    <div
                                      onClick={() => {
                                        setIsOpenHDAOMenu(!isOpenHDAOMenu),
                                          setHDAO(0);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] hover:rounded-t-[5px] select-none">
                                      HDAO
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsOpenHDAOMenu(!isOpenHDAOMenu),
                                          setHDAO(1);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                                      Staked HDAO
                                    </div>
                                  </div>
                                )}
                              </div>
                              <AssetText>
                                {HDAO === 0 ? (
                                  pending2 ? (
                                    "Loading..."
                                  ) : (
                                    (
                                      assets.etherHDAO + assets.polygonHDAO
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })
                                  )
                                ) : (
                                  <Box fontSize="12px">Coming soon</Box>
                                )}
                              </AssetText>
                            </AssetBox>
                            <AssetBox>
                              <div className="relative">
                                <FilterButton
                                  className="hover:opacity-90 font-bold"
                                  selected={true}
                                  onClick={() => {
                                    setIsOpenStableMenu(!isOpenStableMenu);
                                  }}>
                                  <span>{stableTokens[Stable]}</span>
                                  <svg
                                    className="ml-5 w-4 h-4"
                                    aria-hidden="true"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 9l-7 7-7-7"></path>
                                  </svg>
                                </FilterButton>
                                {isOpenStableMenu && (
                                  <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%]">
                                    <div
                                      onClick={() => {
                                        setIsOpenStableMenu(!isOpenStableMenu),
                                          setStable(3);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] hover:rounded-t-[5px] select-none">
                                      Total USD
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsOpenStableMenu(!isOpenStableMenu),
                                          setStable(0);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] hover:rounded-t-[5px] select-none">
                                      USDC
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsOpenStableMenu(!isOpenStableMenu),
                                          setStable(1);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                                      USDT
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsOpenStableMenu(!isOpenStableMenu),
                                          setStable(2);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                                      DAI
                                    </div>
                                  </div>
                                )}
                              </div>
                              <AssetText>
                                {pending
                                  ? "Loading..."
                                  : Stable === 3
                                  ? (
                                      assets.eUSDC +
                                      assets.eUSDT +
                                      assets.eDAI +
                                      assets.pUSDC +
                                      assets.pUSDT +
                                      assets.pDAI
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })
                                  : (
                                      etherStable + polygonStable
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })}
                              </AssetText>
                            </AssetBox>
                            <AssetBox>
                              <div className="relative">
                                <FilterButton
                                  className="hover:opacity-90 font-bold"
                                  selected={true}
                                  onClick={() => {
                                    setIsOpenNativeMenu(!isOpenNativeMenu);
                                  }}>
                                  <span>{isEther ? "Ether" : "Matic"}</span>
                                  <svg
                                    className="ml-5 w-4 h-4"
                                    aria-hidden="true"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 9l-7 7-7-7"></path>
                                  </svg>
                                </FilterButton>
                                {isOpenNativeMenu && (
                                  <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%]">
                                    <div
                                      onClick={() => {
                                        setIsOpenNativeMenu(!isOpenNativeMenu),
                                          setIsEther(true);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] hover:rounded-t-[5px] select-none">
                                      Ether
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsOpenNativeMenu(!isOpenNativeMenu),
                                          setIsEther(false);
                                      }}
                                      className="cursor-pointer hover:bg-[#dddddd] pl-[10px] p-[5px] select-none">
                                      Matic
                                    </div>
                                  </div>
                                )}
                              </div>
                              <AssetText>
                                {pending1
                                  ? "Loading..."
                                  : isEther
                                  ? assets.eBalance.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })
                                  : assets.pBalance.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })}
                              </AssetText>
                            </AssetBox>
                            <KnowledgeTitle className="mt-[16px]">
                              <img src={Profile} />
                              Insights
                            </KnowledgeTitle>
                            <p className="text-white text-sm -mt-1 -mb-1">
                              (Coming soon)
                            </p>
                            <InsightBox>
                              <InsightTitle>20</InsightTitle>
                              <InsightDesc>Completed Tasks</InsightDesc>
                              <InsightText>
                                You have saved <b>10 hrs</b> and provided wages
                                to
                                <b>{" 8 people"}</b> 🤝 🤝
                              </InsightText>
                            </InsightBox>
                            <InsightBox display="flex" flexDirection="column">
                              <InsightStatus borderBottom="1px solid #d9d9d9">
                                <Box>ACTIVE</Box>
                                <Box>-</Box>
                              </InsightStatus>
                              <InsightStatus borderBottom="1px solid #d9d9d9">
                                <Box>clarification</Box>
                                <Box>-</Box>
                              </InsightStatus>
                              <InsightStatus>
                                <Box>Review</Box>
                                <Box>-</Box>
                              </InsightStatus>
                            </InsightBox>
                          </PinkBox>
                        </>
                      ) : (
                        <>
                          <PinkBox>
                            <ConnectBtn>{`${address.substring(
                              0,
                              5,
                            )}...${address.substring(
                              address.length - 2,
                            )}`}</ConnectBtn>
                            <NetworkText>
                              Please use Ethereum or Polygon to view assets
                            </NetworkText>
                          </PinkBox>
                          <PinkBox>
                            <KnowledgeTitle className="mt-[16px]">
                              <img src={Profile} />
                              Insights
                            </KnowledgeTitle>
                            <p className="text-white text-sm -mt-1 -mb-1">
                              (Coming soon)
                            </p>
                            <InsightBox>
                              <InsightTitle>20</InsightTitle>
                              <InsightDesc>Completed Tasks</InsightDesc>
                              <InsightText>
                                You have saved <b>10 hrs</b> and provided wages
                                to
                                <b>{" 8 people"}</b> 🤝 🤝
                              </InsightText>
                            </InsightBox>
                            <InsightBox display="flex" flexDirection="column">
                              <InsightStatus borderBottom="1px solid #d9d9d9">
                                <Box>ACTIVE</Box>
                                <Box>-</Box>
                              </InsightStatus>
                              <InsightStatus borderBottom="1px solid #d9d9d9">
                                <Box>clarification</Box>
                                <Box>-</Box>
                              </InsightStatus>
                              <InsightStatus>
                                <Box>Review</Box>
                                <Box>-</Box>
                              </InsightStatus>
                            </InsightBox>
                          </PinkBox>
                        </>
                      )
                    ) : (
                      <>
                        <PinkBox>
                          <ConnectBtn
                            data-test-id="dashboard-connect-wallet-btn"
                            onClick={() => connectWallet()}>
                            Connect Wallet
                          </ConnectBtn>
                          <NetworkText data-test-id="dashboard-connect-wallet-text">
                            Please use Ethereum or Polygon to view assets
                          </NetworkText>
                        </PinkBox>

                        <PinkBox>
                          <KnowledgeTitle className="mt-[16px]">
                            <img src={Profile} />
                            Insights
                          </KnowledgeTitle>
                          <p className="text-white text-sm -mt-1 -mb-1">
                            (Coming soon)
                          </p>
                          <InsightBox>
                            <InsightTitle>20</InsightTitle>
                            <InsightDesc>Completed Tasks</InsightDesc>
                            <InsightText>
                              You have saved <b>10 hrs</b> and provided wages to
                              <b>{" 8 people"}</b> 🤝 🤝
                            </InsightText>
                          </InsightBox>
                          <InsightBox display="flex" flexDirection="column">
                            <InsightStatus borderBottom="1px solid #d9d9d9">
                              <Box>ACTIVE</Box>
                              <Box>-</Box>
                            </InsightStatus>
                            <InsightStatus borderBottom="1px solid #d9d9d9">
                              <Box>clarification</Box>
                              <Box>-</Box>
                            </InsightStatus>
                            <InsightStatus>
                              <Box>Review</Box>
                              <Box>-</Box>
                            </InsightStatus>
                          </InsightBox>
                        </PinkBox>
                      </>
                    )}
                  </Box>
                </RightDiv>
              </ShadowedContainer>
              <ShadowedContainer className="bg-nftBg w-full mt-[12px]">
                <RightDiv data-test-id="dashboard-knowledge-bits-container">
                  <PinkBox className="order-5 od:order-5">
                    <KnowledgeTitle>
                      <img src={Brain} />
                      Knowledge bits
                    </KnowledgeTitle>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() =>
                        window.open(
                          "https://help.humantaskforce.com/",
                          "_blank",
                        )
                      }
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      Ask Ally - AI Assisted help
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() =>
                        window.open("https://bit.ly/panft-userguide", "_blank")
                      }
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      Getting Started
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() =>
                        window.open(
                          "https://www.notion.so/humandao/PANFT-User-Guide-c5249ba4724241cca6ed44c8044decc2#c4601437b7794ca8bd1e323d5a626b50",
                          "_blank",
                        )
                      }
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      Creating a task
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() => {
                        navigate("/user-tutorials");
                      }}
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      In-app tutorials
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() =>
                        window.open(
                          "https://app.humandao.org/buyhdao",
                          "_blank",
                        )
                      }
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      Where to buy $HDAO
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                    <KnowledgeBox
                      data-test-id="dashboard-knowledge-bits-item"
                      onClick={() =>
                        window.open("https://academy.humandao.org/", "_blank")
                      }
                      className="hover:opacity-90 hover:scale-[1.02] ease-in-out duration-300">
                      Learn Crypto/Web3
                      <img src={ArrowRight} />
                    </KnowledgeBox>
                  </PinkBox>
                </RightDiv>
              </ShadowedContainer>
            </RightBox>
          </StyledCardsWrapper>
        </StyledBoardWrapper>
      </StyledContainer>
    )
  );
};

const InsightStatus = styled(Box)`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  > div:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-indent: 5px;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.8);
  }
  > div:last-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-indent: 5px;

    color: #000000;
  }
`;

const InsightBox = styled(Box)`
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 12px;
  margin-top: 12px;
  width: 100%;
`;

const InsightTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-indent: 5px;

  color: #000000;
`;

const InsightDesc = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-indent: 5px;
  text-transform: uppercase;

  color: #000000;
`;

const InsightText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding-left: 5px;
  padding-right: 20px;

  color: rgba(0, 0, 0, 0.8);
  margin-top: 12px;
`;

const KnowledgeDesc = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-indent: 5px;
  text-transform: uppercase;

  color: #ffffff;
  margin-top: 24px;
  text-align: left;
  width: 100%;
`;

const PinkBox = styled(Box)`
  background: #e0848d;
  border-radius: 10px;
  width: 100%;
  padding: 12px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileName = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  text-indent: 5px;
  color: #fff;
`;

const KnowledgeTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-indent: 5px;
  display: flex;
  color: #fff;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
`;

const KnowledgeBox = styled(Box)`
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-indent: 5px;

  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  cursor: pointer;
`;

const AssetBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  margin-top: 12px;
`;

const AssetText = styled(Box)`
  padding: 5px 10px;
  background: #f9f9f9;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-indent: 5px;

  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const ConnectBtn = styled(Box)`
  width: 177px;
  height: 36px;
  background: #806ad2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-indent: 5px;
  color: white;
  cursor: pointer;
  margin-top: 42px;
`;

const NetworkText = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-indent: 5px;
  margin-top: 8px;
  width: 190px;
  height: 30px;
  color: white;
`;

export default Component;
