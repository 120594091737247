const REGEX_Youtube: RegExp =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

const REGEX_Vimeo: RegExp =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

function CONDITION_DTube(url: string) {
  if (url.indexOf("https://d.tube/#!/v/") != -1) {
    return true;
  }
}

function CONDITION_BitChute(url: string) {
  if (url.indexOf("https://www.bitchute.com/") != -1) {
    return true;
  }
}

export { CONDITION_BitChute, CONDITION_DTube, REGEX_Vimeo, REGEX_Youtube };
