import React, { useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import OwnerProfileView from "../components/pages/OwnerProfileView";

import Header from "../components/organisms/Header";
import CreateTask from "../components/pages/CreateTask";
import EditTask from "../components/pages/EditTask";
import OwnerDashboard from "../components/pages/OwnerDashboard";
import ViewTask from "../components/pages/ViewTask";

import { useLocation } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { KnowledgeIcon, LogoutIcon, ProfileIcon } from "../assets/svg";
import GiftEnergy from "../components/pages/GiftEnergy";
import OwnerAddress from "../components/pages/OwnerAddress";
import OwnerOnboardingPage from "../components/templates/OwnerOnboardingPage";
import {
  authState,
  globalState,
  initialAuthState,
  paginationState,
} from "../state/atoms";
// Note: Currently unused but might be helpful
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

export default () => {
  const { pathname: locationPath, state } = useLocation();
  const navigate = useNavigate();
  // const address = useAddress();
  const { address, connector, isConnected } = useAccount();
  // const disconnectWallet = useDisconnect();
  const [auth, setAuth] = useRecoilState(authState);
  const [global, setGlobal] = useRecoilState(globalState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const { disconnect } = useDisconnect();

  const locationRef = useRef(locationPath);
  useEffect(() => {
    locationRef.current = locationPath;
    console.log("locationRef.current :>> ", locationRef.current);
  }, [locationPath]);
  const logOutOwner = () => {
    // disconnectWallet();
    disconnect();
    setPagination({
      fetchType: "open",
      pageNum: 0,
      after: null,
      before: null,
    });
    setAuth(initialAuthState);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    navigate("/");
  };

  function handleAccountsChanged() {
    let addMailCheck = locationRef.current.slice(0, 14);
    if (addMailCheck !== "/updateAddress") {
      logOutOwner();
    }
  }
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }
    return () => {
      if (window?.ethereum?.removeListener) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged,
        );
      }
    };
  }, [locationPath]);

  return (
    <>
      <Header
        role="owner"
        dropdownButtonName={
          address
            ? `${address.substring(0, 5)}...${address.substring(
                address.length - 2,
              )}`
            : auth.user_name ?? auth.email ?? ""
        }
        dropdownOptions={[
          {
            name: "Profile",
            onClick: () => navigate("profile"),
            icon: <ProfileIcon />,
          },
          {
            name: "In-app tutorials",
            onClick: () => navigate("user-tutorials"),
            icon: <KnowledgeIcon />,
          },
          ,
          {
            name: address ? "Disconnect" : "Logout",
            onClick: logOutOwner,
            icon: <LogoutIcon />,
          },
        ]}
      />
      <Routes>
        <Route path="/*" element={<OwnerDashboard />} />
        <Route path="user-tutorials" element={<OwnerOnboardingPage />} />
        <Route path="tasks/create" element={<CreateTask />} />
        <Route path="tasks/view/:id" element={<ViewTask isAdmin={false} />} />
        <Route path="tasks/edit/:id" element={<EditTask />} />
        <Route path="profile" element={<OwnerProfileView />} />
        <Route path="gift" element={<GiftEnergy />} />
        <Route
          path="updateAddress"
          element={<OwnerAddress change={state?.change} />}
        />
      </Routes>
    </>
  );
};
