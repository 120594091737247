import * as Yup from "yup";

export const TaskSchema = Yup.object().shape({
  title: Yup.string().required("Title required"),
  energy_assigned: Yup.number().min(
    0.05,
    "Please select energy to assign (1 energy = 30 mins)",
  ),
  task_type: Yup.string(),
  max_assignment: Yup.number().when("task_type", {
    is: "multiplicity",
    then: Yup.number()
      .min(2, "Max assignment should not be less than 2 for multiplicity")
      .required(),
  }),
});
