import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Task } from "../../../interfaces/Task";
import {
  addRatingData,
  addTaskComment,
  changeEnergy,
  changeMaxAssignments,
  createTask,
  getChildTaskByParentId,
  getTaskById,
  getTranslation,
  getTranslationCheck,
  updateTaskByFileUpload,
} from "../../../services/tasks";
import ViewTaskTemplate from "../../templates/ViewTask";

import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { setTaskStatus } from "../../../services/tasks";
import { authState, taskState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";
import useUserFetch from "../../hooks/useUserFetch";
import Modal from "../../organisms/Modal";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;
  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;
  &:hover {
    background: #e8e8e8;
  }
`;

type Props = {
  isAdmin: boolean;
  active: any;
};

const Component = ({ isAdmin, active }: Props) => {
  const [auth, setAuth] = useRecoilState(authState);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [taskLoading, setTaskLoading] = useState(false);
  //Change here
  const [hasInsufficientEnergy, setHasInsufficientEnergy] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isCompleteTask, setIsCompleteTask] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isDrafted, setIsDrafted] = useState(false);
  const [isChangingState, setIsChangingState] = useState(false);
  const [showChangeEnergyModal, setShowChangeEnergyModal] = useState(false);
  const [newEnergyValue, setNewEnergyValue] = useState(0);
  const [hasTouchedEnergy, setHasTouchedEnergy] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showChangeMaxAssignmentModal, setShowChangeMaxAssignmentModal] =
    useState(false);
  const [maxAssignmentValue, setMaxAssignmentValue] = useState(0);

  const [isEnergyChanging, setIsEnergyChanging] = useState(false);
  const [isEnergyChanged, setIsEnergyChanged] = useState(false);

  const [energyCost, setEnergyCost] = useState(0);
  const [canChangeMaxAssignment, setCanChangeMaxAssignment] = useState(false);
  const [maxAssignmentErrorObject, setMaxAssignmentErrorObject] = useState([]);
  const [maxAssignmentTouched, setMaxAssignmentTouched] = useState(false);

  const [showReassignModal, setShowReassignModal] = useState(false);
  const [
    loadingCommentsTranslationIndexes,
    setLoadingCommentsTranslationIndexes,
  ] = useState([]);

  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);
  const [translatedComment, setTranslatedComment] = useState("");
  let { id } = useParams();

  const { user: userData, isLoading: isUserDataLoading } = useUserFetch(
    auth,
    "nft",
    auth.user_id,
  );

  const {
    data: tasksData,
    isLoading: isTasksLoading,
    refetch,
  } = useQuery(
    ["fetchingTask", auth.accessToken, id],
    () => {
      if (auth.accessToken) {
        return getTaskById(auth.accessToken, id, true);
      }
    },
    {
      onSuccess: (resp) => {
        if (!Array.isArray(resp.task.category)) {
          let categoryList: string[] = [];
          categoryList.push(resp.task.category);
          resp.task.category = categoryList;
        }
        resp.task.videos = !resp.task.videos ? [] : resp.task.videos;
        resp.task.urls = !resp.task.urls ? [] : resp.task.urls;

        setActiveTask(resp.task);

        setTaskLoading(false);
      },
      onError: (e) => {},
    },
  );

  const { data: childTasksData } = useQuery(
    ["fetchingChildTasks", auth.accessToken, id],
    () => {
      if (auth.accessToken) {
        return getChildTaskByParentId(auth.accessToken, id);
      }
    },
    {
      onSuccess: (resp) => {},
      onError: (e) => {},
    },
  );

  useEffect(() => {
    setTaskLoading(true);
  }, []);
  const {
    mutate: mutateChangeTaskState,
    isLoading,
    error,
  } = useMutation(
    "changeTaskState",
    (data: any) => {
      if (["published", "draft", "published"].includes(data.state)) {
        return setTaskStatus(auth.accessToken, { id, status: data.state });
      } else {
        return Promise.reject("Unexpected state change request");
      }
    },
    {
      onSuccess: (resp, data) => {
        setIsChangingState(false);
        if (resp.success) {
          if (data.state === "published") {
            setIsPublished(true);
          }
          if (data.state === "draft") {
            setIsDrafted(true);
          }

          setSnackbar({
            title: "Success",
            content:
              data.state === "draft"
                ? "Your task is now set to draft."
                : "Your task is now published!",
            type: "success",
          });
          if (data.state === "draft") {
            refetch();
          } else {
            setTimeout(() => {
              setIsPublished(false);
              navigate("/");
            }, 1500);
          }
        } else {
          setSnackbar({
            title: "Alert!",
            content: resp.message,
            type: "warning",
          });
        }
      },
      onError: (e, variables) => {
        setIsChangingState(false);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: completeTask,
    isLoading: isTaskCompleting,
    error: completeTaskError,
  } = useMutation(
    "completeTask",
    () => {
      return setTaskStatus(auth.accessToken, { id, status: "completed" });
    },
    {
      onSuccess: (resp) => {
        if (resp.success) {
          setSnackbar({
            title: "Success",
            content: "Task is completed!",
            type: "success",
          });
          refetch();
          setIsCompleteTask(true);
        } else {
          setSnackbar({
            title: "Alert!",
            content: "Task could not be completed.",
            type: "warning",
          });
          setIsCompleteTask(false);
        }
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleDescriptionTranslation,
    isLoading: descriptionTranslationLoading,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      const isPreferred = true;
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
        isPreferred,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setActiveTask((prev) => ({ ...prev, description: resp.message }));
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleOutputTranslation,
    isLoading: isOutputTranslating,
    error: outputTranslationError,
  } = useMutation(
    "translateOutput",
    (values: any) => {
      const isPreferred = true;
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.BASIC,
        isPreferred,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setActiveTask((prev) => ({
          ...prev,
          delivery: { description: resp.message },
        }));
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleTranslateExistingComment,
    isLoading: translateExistingCommentLoading,
  } = useMutation(
    "translateCommentInput",
    (values: any) => {
      const isPreferred = true;
      setLoadingCommentsTranslationIndexes((prevState) => [
        ...prevState,
        values.index,
      ]);
      return getTranslation(
        auth.accessToken,
        values.text,
        values.source,
        isPreferred,
      );
    },
    {
      onSuccess: (data, variables) => {
        setActiveTask((prevState) => {
          const updatedComments = [...prevState.comments];

          // Find the index of the comment to be updated
          const commentIndex = updatedComments.findIndex(
            (comment) => comment.comment_id === variables.index,
          );

          if (commentIndex !== -1) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              text: data.message,
            };
          }

          // Return the updated state
          return { ...prevState, comments: updatedComments };
        });
        setLoadingCommentsTranslationIndexes((prevState) =>
          prevState.filter((x) => x !== variables.index),
        );
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const {
    mutate: handleTranslateInputComment,
    isLoading: isInputCommentTranslationLoading,
  } = useMutation(
    "translateComment",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.comment,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp) => {
        setTranslatedComment(resp.message);
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const { mutate: assistantRejected, isLoading: isAssistantRejecting } =
    useMutation(
      "rejectAssistant",
      () => {
        return setTaskStatus(auth.accessToken, {
          id,
          status: "assistant_rejected",
        });
      },
      {
        onSuccess: (resp) => {
          if (resp.success) {
            setSnackbar({
              title: "Success",
              content: "Assistant is Rejected",
              type: "success",
            });
            navigate("/");
          } else {
            setSnackbar({
              title: "Alert!",
              content: "Assistant couldn't be rejected",
              type: "warning",
            });
          }
        },
        onError: (e, variables) => {
          setSnackbar({
            title: "Success",
            content: "Assistant is Rejected",
            type: "success",
          });
          navigate("/");
        },
      },
    );

  const {
    mutate: rejectTaskSubmission,
    isLoading: isTaskBeingRejected,
    error: taskRejectionError,
  } = useMutation(
    "rejectTaskSubmission",
    (reasonString: string) => {
      if (!reasonString && reasonString == "") {
        return setTaskStatus(auth.accessToken, {
          id,
          status: "submission_rejected",
        });
      }
      return setTaskStatus(auth.accessToken, {
        id,
        reason: reasonString,
        status: "submission_rejected",
      });
    },
    {
      onSuccess: (resp) => {
        if (resp.success) {
          setSnackbar({
            title: "Success",
            content: "The submission was sucessfully rejected!",
            type: "success",
          });
          setRejected(true);

          setTimeout(() => {
            navigate("/");
          }, 1000);

          // refetch(); Do not refecth because the state will be in-progress which will change what is being shown on the page
        } else {
          setSnackbar({
            title: "Alert!",
            content: "The submission could not be rejected.",
            type: "warning",
          });
        }
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateSubmitComment,
    isLoading: isSubmittingComment,
    error: commentError,
  } = useMutation(
    ["submitComment", auth.accessToken, id],
    (comment: any) => {
      return addTaskComment(auth.accessToken, comment, id);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "Comment posted.",
          type: "success",
        });
        refetch();
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateChangeEnergy, isLoading: isChangingEnergy } =
    useMutation(
      ["changeEnergy", auth.accessToken, id],
      () => {
        return changeEnergy(auth.accessToken, newEnergyValue, id);
      },
      {
        onSuccess: (resp, variables) => {
          setIsEnergyChanging(false);
          setIsEnergyChanged(true);
          setSnackbar({
            title: "Success",
            content: resp.message,
            type: "success",
          });

          setTimeout(() => {
            setIsEnergyChanged(false);
            refetch();
          }, 2000);
        },
        onError: (e, variables) => {
          setSnackbar({
            title: "Error",
            content: e.message,
            type: "error",
          });
        },
      },
    );

  const {
    mutate: mutateChangeMaxAssignment,
    isLoading: isChangingMaxAssignment,
  } = useMutation(
    ["changeMaxAssignments", auth.accessToken, id],
    () => {
      return changeMaxAssignments(auth.accessToken, {
        task_id: id,
        assignment_value: maxAssignmentValue,
      });
    },
    {
      onSuccess: (resp, variables) => {
        setIsEnergyChanging(false);
        setIsEnergyChanged(true);
        setSnackbar({
          title: "Success",
          content: "Successfully changed the max assignment.",
          type: "success",
        });

        setTimeout(() => {
          setIsEnergyChanged(false);
          refetch();
        }, 2000);
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content: e.message,
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateAddRating,
    isLoading: isAddRating,
    error: addRatingError,
  } = useMutation(
    ["addRating", auth.accessToken, id],
    (ratingdata: any) => {
      return addRatingData(auth.accessToken, ratingdata, id);
    },
    {
      onSuccess: (resp, variables) => {
        // resp.task.rating = !resp.task.rating ? [] : resp.task.rating;
        // setActiveTask(resp.task);
        setSnackbar({
          title: "Success",
          content: "Rating posted.",
          type: "success",
        });
        navigate("/");
        // refetch();
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateUpdateTask,
    isLoading: isUpdatingTask,
    error: updateTaskError,
  } = useMutation(
    ["updateTask", auth.accessToken, id],
    (taskParam: any) => {
      const taskPayload = { attachments: taskParam.attachments };
      return updateTaskByFileUpload(
        auth.accessToken,
        taskPayload,
        taskParam.task_id,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "Successfully uploaded files to task.",
          type: "success",
        });
        refetch();
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateCreateTask,
    isLoading: isCreateLoading,
    error: errorCreate,
  } = useMutation(
    "createTask",
    (values: any) => {
      values.task.is_template = false;
      return createTask(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully created new task.",
          type: "success",
        });
        navigate("/tasks/view/" + resp.id);
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: isDescriptionTranslated,
    isLoading: checkTranslatedLoading,
    error: errorTranslatedLoading,
  } = useMutation(
    "checkDescriptionTranslated",
    (values: any) => {
      setIsChangingState(true);
      return getTranslationCheck(auth.accessToken, values.description);
    },
    {
      onSuccess: (resp, data) => {
        if (resp.message.translated) {
          mutateChangeTaskState({ state: "published" });
        } else {
          setSnackbar({
            title: "Error",
            content:
              "Task description is not translated to english, please edit the task and click the translate to english button",
            type: "error",
          });
        }
      },
      onError: (e, data) => {
        mutateChangeTaskState({ state: "published" });
        // setSnackbar({
        //   title: "Error",
        //   content: "Something went wrong",
        //   type: "error",
        // });
      },
    },
  );

  const {
    mutate: isCommentTranslated,
    isLoading: checkCommentTranslated,
    error: errorCommentTranslated,
  } = useMutation(
    "checkCommentTranslated",
    (comment: any) => {
      console.log(
        "🚀 ~ file: ViewTaskPage.tsx:723 ~ Component ~ comment:",
        comment,
      );
      return getTranslationCheck(auth.accessToken, comment.text);
    },
    {
      onSuccess: (resp, data) => {
        if (resp.message.translated) {
          mutateSubmitComment(data);
        } else {
          setSnackbar({
            title: "Error",
            content:
              "Comment is not translated to English. Please click the translate to english button and re-post it.",
            type: "error",
          });
        }
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content: "Something went wrong",
          type: "error",
        });
      },
    },
  );

  const resetTranslation = useCallback(() => {
    setTranslatedComment("");
  }, []);

  if (isUserDataLoading || isTasksLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <StyledContainer className="bg-mainBg flex justify-center items-center">
      {/* Change Energy Modal */}
      <Modal
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        title="Select the new amount of energy"
        open={showChangeEnergyModal}
        onClose={closeChangeEnergyModal}>
        <div style={{ maxWidth: 450 }}>
          <div style={{ marginBottom: 0, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter"></Typography>

            <TextField
              multiline={false}
              rows={1}
              style={{
                margin: "1rem 0",
              }}
              type="number"
              variant="filled"
              size="small"
              defaultValue={activeTask.energy_assigned}
              inputProps={{
                style: {
                  padding: "10px 12px",
                  textAlign: "center",
                  margin: "0",
                  borderRadius: "0.375rem",
                  width: "100%",
                  background: "#fdfdfd",
                },
                pattern: "^d*(.d+)?$",
                placeholder: "Energies",
                disableUnderline: true,
                step: "0.05",
              }}
              onChange={(e) => {
                setHasTouchedEnergy(true);
                if (e.target.value === null || e.target.value === 0) {
                  setNewEnergyValue(0);
                  return;
                }

                const value: any = parseFloat(e.target.value).toFixed(2);

                const rounded = Math.ceil(value / 0.05) * 0.05;

                // Convert rounded value to a number and back to string for comparison
                const truncated = Number(rounded.toFixed(2)).toString();

                // If the original value matches the truncated value, set it with no decimal places
                e.target.value =
                  rounded.toFixed(2) === truncated
                    ? parseInt(truncated)
                    : rounded.toFixed(2);

                e.target.value = rounded.toFixed(2);

                if (parseFloat(e.target.value) > 1000) {
                  e.target.value = "1000.00";
                }

                if (e.target.value) {
                  // Check if the value has .00 decimal
                  let fieldValue = e.target.value.endsWith(".00")
                    ? parseInt(e.target.value, 10)
                    : e.target.value;

                  setNewEnergyValue(Number(fieldValue));
                }
              }}
            />
          </div>

          {hasInsufficientEnergy ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "red",
              }}>
              You have insufficient energy credits for the desired change
            </div>
          ) : null}

          <ConfirmButton
            disabled={hasInsufficientEnergy || !hasTouchedEnergy}
            variant="contained"
            className="flex flex-row justify-center items-center m-auto text-center"
            onClick={() => {
              handleConfirmChangeEnergy();
            }}
            fullWidth>
            Yes, change the energy
          </ConfirmButton>
          <CancelButton
            type="button"
            variant="contained"
            onClick={closeChangeEnergyModal}
            fullWidth>
            Cancel
          </CancelButton>
        </div>
      </Modal>

      {/* Request improvements modal */}
      <Modal
        type={"extended"}
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        title={
          activeTask.status === "clarification_needed"
            ? "Task Reassign"
            : "Request Improvements"
        }
        open={rejectModal}
        onClose={closeModal}>
        <div style={{ maxWidth: 900 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              {activeTask.status === "clarification_needed"
                ? "Please confirm that you wish to re-assign the task to another assistant. In doing so the task will be returned to the task pool and can be picked up by another assistant."
                : `Are you sure you wish to request for improvements? In doing so the task status will go back to active and the assistant assigned will be able to make changes to the task. `}
            </Typography>
          </div>

          {activeTask.status != "clarification_needed" && (
            <>
              <Typography
                fontWeight={700}
                fontFamily="Inter"
                fontSize="16px"
                paddingBottom="5px"></Typography>
              <TextField
                fullWidth
                placeholder="What improvements would you like to request?"
                multiline={true}
                rows={4}
                sx={{ height: "150px" }}
                variant="filled"
                onChange={(e) => {
                  setRejectReason(e.target.value);
                }}
              />
            </>
          )}

          <ConfirmButton
            variant="contained"
            onClick={() => {
              if (activeTask.status === "clarification_needed") {
                assistantRejected();
              }
              handleConfirmReject(rejectReason);
            }}
            fullWidth
            disabled={
              rejectReason == "" && activeTask.status != "clarification_needed"
            }>
            {activeTask.status === "clarification_needed"
              ? "Yes, re-assign the task"
              : "Yes, request for improvements"}
          </ConfirmButton>
          <CancelButton variant="contained" onClick={closeModal} fullWidth>
            No, Cancel
          </CancelButton>
        </div>
      </Modal>

      {/* Reassign assistant modal */}
      <Modal
        type={"extended"}
        title="Reassign Task"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showReassignModal}
        onClose={closeReassignModal}>
        <div style={{ maxWidth: 900 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Please confirm that you wish to re-assign the task to another
              assistant. In doing so the task will be returned to the task pool
              and can be picked up by another assistant.
            </Typography>
          </div>

          <ConfirmButton
            variant="contained"
            onClick={() => {
              handleConfirmReassign();
            }}
            fullWidth>
            Yes, re-assign the task
          </ConfirmButton>
          <CancelButton
            variant="contained"
            onClick={closeReassignModal}
            fullWidth>
            No, Cancel
          </CancelButton>
        </div>
      </Modal>

      {/* Change Max Assignments Modal */}
      <Modal
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showChangeMaxAssignmentModal}
        title="Change Max Assignments"
        onClose={closeChangeMaxAssignmentModal}>
        <div style={{ maxWidth: 450 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Input the new amount of Maximum Assignments
            </Typography>
            <Typography
              fontFamily="Inter"
              fontSize="10px"
              paddingX="10px"
              style={{ marginBottom: "15px" }}>
              *Take note that the new amount should not be exceeding the current
              number of energies you have in your account.
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: "15px",
              }}>
              <div id="label" style={{ fontWeight: "700" }}>
                Max Assignment:
              </div>
              <TextField
                variant="filled"
                size="small"
                type="number"
                defaultValue={activeTask.max_assignment}
                style={{ width: "60%" }}
                required={true}
                InputProps={{ disableUnderline: true }}
                onChange={(event) => {
                  setEnergyCost(0);
                  setMaxAssignmentErrorObject([]);
                  const inputValue = Number(event.target.value);
                  setMaxAssignmentTouched(true);

                  if (event.target.value == "") {
                    return setCanChangeMaxAssignment(false);
                  }
                  //Value is less than current

                  if (inputValue <= 0 && maxAssignmentTouched) {
                    setMaxAssignmentErrorObject((oldArr) => [
                      ...oldArr,
                      {
                        message: "New value cannot be 0 or less",
                      },
                    ]);
                    return setCanChangeMaxAssignment(false);
                  }

                  if (
                    inputValue <= activeTask.max_assignment &&
                    maxAssignmentTouched
                  ) {
                    setMaxAssignmentErrorObject((oldArr) => [
                      ...oldArr,
                      {
                        message:
                          "New value cannot be equal or less than the previous value",
                      },
                    ]);
                    return setCanChangeMaxAssignment(false);
                  }
                  //if negative

                  //Energy exceed
                  if (
                    (inputValue - activeTask.max_assignment) *
                      activeTask.energy_assigned >
                    auth?.energy
                  ) {
                    setEnergyCost(
                      (inputValue - activeTask.max_assignment) *
                        activeTask.energy_assigned,
                    );
                    setMaxAssignmentErrorObject((oldArr) => [
                      ...oldArr,
                      {
                        message:
                          "New amount should not be exceeding currently owned energies",
                      },
                    ]);
                    return setCanChangeMaxAssignment(false);
                  }
                  setEnergyCost(
                    (inputValue - activeTask.max_assignment) *
                      activeTask.energy_assigned,
                  );
                  setMaxAssignmentValue(inputValue);
                  return setCanChangeMaxAssignment(true);
                }}
                inputMode="numeric"
                inputProps={{
                  style: { padding: "10px 12px" },
                }}
              />
            </div>
            {energyCost != 0 && (
              <div style={{ fontSize: "12px", marginBottom: "px" }}>
                Energy Cost: {energyCost}, Current Energy: {auth?.energy}
              </div>
            )}
            <div
              style={{ fontSize: "9px", color: "red", paddingBottom: "4px" }}>
              {maxAssignmentErrorObject.map((val, index) => (
                <span key={index}>{val.message}</span>
              ))}
            </div>

            <ConfirmButton
              variant="contained"
              onClick={handleConfirmChangeMaxAssignment}
              disabled={!canChangeMaxAssignment}
              fullWidth>
              Confirm
            </ConfirmButton>
            <CancelButton
              variant="contained"
              onClick={closeChangeMaxAssignmentModal}
              fullWidth>
              Cancel
            </CancelButton>
          </div>
        </div>
      </Modal>
      <ViewTaskTemplate
        handleSubmit={mutateChangeTaskState}
        submitComment={mutateSubmitComment}
        isSubmittingComment={isSubmittingComment}
        updateTask={mutateUpdateTask}
        createTask={mutateCreateTask}
        completeTask={completeTask}
        addRating={mutateAddRating}
        isTaskCompleting={isTaskCompleting}
        rejectTaskSubmission={handleReject}
        isTaskBeingRejected={isTaskBeingRejected}
        holder_id={auth?.user_id}
        submissionRejected={rejected}
        isCompleteTask={isCompleteTask}
        isLoading={taskLoading}
        isAdmin={isAdmin}
        active={active}
        isPublished={isPublished}
        isChangingState={isChangingState}
        isDrafted={isDrafted}
        changeEnergy={handleChangeEnergy}
        childTasksData={childTasksData}
        changeMaxAssignmentsValue={handleChangeMaxAssignmentModal}
        changeEnergyStates={{
          energyChanging: isEnergyChanging,
          energyChanged: isEnergyChanged,
        }}
        rejectAssistant={assistantRejected}
        reassignTask={handleReassign}
        descriptionTranslationLoading={descriptionTranslationLoading}
        handleDescriptionTranslation={handleDescriptionTranslation}
        handleOutputTranslation={handleOutputTranslation}
        isOutputTranslating={isOutputTranslating}
        userData={userData}
        handleTranslateExistingComment={handleTranslateExistingComment}
        translateExistingCommentLoading={translateExistingCommentLoading}
        resetTranslation={resetTranslation}
        translatedComment={translatedComment}
        handleTranslateInputComment={handleTranslateInputComment}
        isInputCommentTranslationLoading={isInputCommentTranslationLoading}
        loadingCommentsTranslationIndexes={loadingCommentsTranslationIndexes}
        isDescriptionTranslated={isDescriptionTranslated}
        isCommentTranslated={isCommentTranslated}
      />
    </StyledContainer>
  );

  //Make assistant re-do the task
  function closeModal() {
    setRejectModal(false);
  }
  function handleReject() {
    setRejectModal(true);
  }

  function handleConfirmReject(reasonString) {
    setRejectModal(false);
    rejectTaskSubmission(reasonString);
  }

  //Re-assign another assistant
  function closeReassignModal() {
    setShowReassignModal(false);
  }
  function handleReassign() {
    setShowReassignModal(true);
  }

  function handleConfirmReassign() {
    setShowReassignModal(false);
    assistantRejected();
  }

  //Change Energy
  function handleChangeEnergy() {
    setShowChangeEnergyModal(true);
  }

  function closeChangeEnergyModal() {
    setShowChangeEnergyModal(false);
    setHasTouchedEnergy(false);
  }

  function handleConfirmChangeEnergy() {
    setShowChangeEnergyModal(false);
    setIsEnergyChanging(true);
    setHasTouchedEnergy(false);
    mutateChangeEnergy();
    //Add function here to update energy
  }

  //Change Max Assignment
  function closeChangeMaxAssignmentModal() {
    setShowChangeMaxAssignmentModal(false);
  }

  function handleChangeMaxAssignmentModal() {
    setShowChangeMaxAssignmentModal(true);
  }

  function handleConfirmChangeMaxAssignment() {
    setShowChangeMaxAssignmentModal(false);
    mutateChangeMaxAssignment();
    //Some logic here
  }
};

export default Component;
