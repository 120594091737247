export default () => {
  return (
    <svg
      width="76"
      height="72"
      viewBox="0 0 76 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.2447 3.63527C34.7415 -0.971281 41.2585 -0.971278 42.7553 3.63528L48.1031 20.0943C48.7725 22.1544 50.6923 23.5492 52.8584 23.5492H70.1644C75.008 23.5492 77.0219 29.7472 73.1033 32.5943L59.1025 42.7665C57.3501 44.0397 56.6168 46.2965 57.2861 48.3566L62.634 64.8156C64.1307 69.4222 58.8583 73.2528 54.9398 70.4058L40.9389 60.2336C39.1865 58.9604 36.8135 58.9604 35.0611 60.2336L21.0602 70.4058C17.1417 73.2528 11.8693 69.4222 13.366 64.8156L18.7139 48.3566C19.3832 46.2965 18.6499 44.0397 16.8975 42.7665L2.89666 32.5943C-1.02191 29.7472 0.991972 23.5492 5.83559 23.5492H23.1416C25.3077 23.5492 27.2275 22.1544 27.8969 20.0943L33.2447 3.63527Z"
        fill="#111827"
      />
    </svg>
  );
};
