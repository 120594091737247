import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  CheckIcon,
  EnergyBar,
  InfoCircelIcon,
  LoadingIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "../../../assets/svg";
import { Qualification } from "../../../interfaces/Qualification";
import { Task } from "../../../interfaces/Task";
import { TaskTypes, taskState } from "../../../state/atoms";

import {
  CONDITION_BitChute,
  CONDITION_DTube,
  REGEX_Vimeo,
  REGEX_Youtube,
} from "../../../utils/regex";
import FormError from "../../organisms/FormError";
import TextEditor from "../../organisms/TextEditor";
import { TaskSchema } from "./validation";

const INPUT_DESCRIPTION_PLACEHOLDER =
  "Please be specific when submitting a mission, as it will reduce time for completion. i.e. quantities, formats, or deliverable method (URLs, spreadsheet, doc, etc.)";

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 1100px;
  width: 100%;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  @media (min-width: 768px) {
    margin-left: 1rem;
    width: 12rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const TaskWrapper = styled.div`
  min-width: 1100px;
  width: 75%;
  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const CreateTaskHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const FormStyle = styled.div`
  justify-content: center;
  align-items: center;
  width: 960px;

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

const Header = styled.div`
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  > div {
    padding-top: 10px;
  }
`;

const StyledSwitch = styled(Switch)`
  font-family: Inter;
  .MuiSwitch-switchBase.Mui-checked {
    color: #806ad2;
    &.Mui-checked + .MuiSwitch-track {
      background-color: #806ad2;
    }
  }
`;

const TagsButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 88px; */
  height: 30px;
  /* min-width: 88px; */
  padding-left: 12px;
  padding-right: 12px;

  border: 1px solid #806ad2;
  border-radius: 20px;

  ${({ selected }) =>
    selected
      ? `
        background-color: #806ad2;
        color: white;
        `
      : `  color: #000000;`}

  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  text-align: center;

  :hover {
    background-color: #806ad270;
  }
`;

type Props = {
  handleSubmit: (values: any) => void;
  isLoading: boolean;
  mode: string;
  isSuccess: boolean;
  handleDraftSubmit: (values: any) => void;
  qualifications: Qualification[];
  translatedTexts: any;
  resetTranslation: () => void;
  isTranslating: boolean;
  handleTranslate: (values: any) => void;
  userData: any;
};

const Component = ({
  handleSubmit,
  isLoading,
  mode,
  isSuccess,
  handleDraftSubmit,
  qualifications,
  translatedTexts,
  resetTranslation,
  isTranslating,
  handleTranslate,
  userData,
}: Props) => {
  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);
  const [isTranslated, setIsTranslated] = useState(false);
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const navigate = useNavigate();
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const [hasVideoError, setHasVideoError] = useState<boolean>(false);
  const [hasUrlError, setHasUrlError] = useState<boolean>(false);
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const [isRewarding, setIsRewarding] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [openScheduleTooltip, setOpenScheduleTooltip] =
    useState<boolean>(false);
  const [openTagsTooltip, setOpenTagsTooltip] = useState<boolean>(false);
  const [openEnergyTooltip, setOpenEnergyTooltip] = useState<boolean>(false);
  const [openRewardTooltip, setOpenRewardTooltip] = useState<boolean>(false);

  useEffect(() => {
    //Nevermind the error message here. not sure why it keeps getting errors.
    if (activeTask.status == "published") {
      setIsPublish(true);
    }
    if (!Array.isArray(activeTask.category)) {
      setTags((oldArr) => [...oldArr, activeTask.category]);
    } else {
      setTags(activeTask.category);
    }
  }, []);

  /*
  //Datepicker
  const [value, setValue] = React.useState<Date | null>(new Date());
  
  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };

  const [task, setTask] = React.useState("");

  // Handle selected task event
  const handleTask = (event: SelectChangeEvent) => {
    setTask(event.target.value);
  };

  // State to store uploaded file
  const [file, setFile] = React.useState("");
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files && event.target.files[0]);
  };
  */

  const getModeLabel = () => {
    return mode === "create" ? "Create Mission" : "Edit Mission";
  };

  const getCreateLabel = () => {
    return isPublish
      ? "Once published, the mission can no longer be edited. You can always change the mission to draft mode later to make changes as long as it has not been selected by a PA."
      : "Create a draft to edit it later. Publish when ready.";
  };

  const getModeSaveLabel = () => {
    return mode === "create" ? "Publish" : "Save Mission";
  };

  const getModeSaveLoadingLabel = () => {
    return mode === "create" ? "Publishing" : "Saving Mission";
  };

  const getModeSaveSuccessLabel = () => {
    return mode === "create" ? "Published" : "Mission Saved";
  };

  // Upload file preview.
  const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} />;
  };

  const InnerComponent = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (Object.keys(translatedTexts).length > 0) {
        Object.keys(translatedTexts).forEach((key) => {
          setFieldValue(key, translatedTexts[key]);
        });
        resetTranslation();
      }
    }, [translatedTexts, resetTranslation]);

    return null;
  };

  return (
    <StyledContainer className="antialiased bg-mainBg">
      <TaskWrapper>
        {
          <CreateTaskHeader className="mb-10">
            <p className="font-Inter text-xs text-black">
              <span
                className=" text-black hover:cursor-pointer hover:underline"
                onClick={() => navigate("/")}>
                Owner Dashboard
              </span>
              {" > " + getModeLabel() + " "}
            </p>
            <p className="font-Inter text-4xl text-black font-bold my-2">
              {getModeLabel()}
            </p>
          </CreateTaskHeader>
        }
        <FormContainer className="flex flex-col justify-center mt-10">
          <FormStyle className="mx-auto bg-white shadow-lg">
            <Header className="px-10 flex justify-between bg-[#ffdea0]">
              <h2 className="">Mission Information</h2>

              {/* <Divider light className="w-full" /> */}
            </Header>
            <Formik
              initialValues={{
                date: activeTask.date ? new Date(activeTask.date) : new Date(),
                category: [activeTask.category],
                title: activeTask.title,
                energy_assigned: activeTask.energy_assigned,
                description: activeTask.description,
                topic: activeTask.topic,
                status: activeTask.status,
                attachments: activeTask.attachments,
                videos: [...activeTask.videos, ""], // ["https://www.youtube.com/embed/c80LZn-cLTs",
                urls: [...activeTask.urls, ""],
                rewards_qualifications:
                  mode !== "create"
                    ? (activeTask?.rewards_qualifications[0]?.id
                        ? [activeTask?.rewards_qualifications[0]?.id]
                        : []) || []
                    : [],
              }}
              validationSchema={TaskSchema}
              onSubmit={async (values) => {
                values.category = tags;
                values.is_mission = true;
                values.is_template = false;
                let url = values.videos[values.videos.length - 1];
                if (url.length > 0 && url.indexOf("https://youtu.be/") == -1) {
                  setHasVideoError(true);
                } else {
                  !clickedOnce && setClickedOnce(true);
                  console.log(values);
                  if (!isPublish) {
                    values.status = "draft";
                    handleDraftSubmit({ task: values, isPublish });
                  }
                  if (isPublish) {
                    values.status = "published";
                    handleSubmit({ task: values, isPublish });
                  }
                }
              }}>
              {({ values, setFieldValue, touched, errors }) => {
                return (
                  <Form className="mt-5">
                    <InnerComponent />
                    <Box style={{ marginLeft: "40px" }}>
                      <Grid container rowGap={3}>
                        <Grid container columnGap={2}>
                          <div className="flex flex-col">
                            <div className="text-base font-bold text-black">
                              Title
                            </div>
                            <div className="">
                              <TextField
                                className="bg-[#fdfdfd] w-[300px] lg:w-[850px] rounded-md"
                                variant="filled"
                                size="small"
                                value={values.title}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    borderRadius: "0.375rem",
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                                onChange={(event) =>
                                  setFieldValue("title", event.target.value)
                                }
                                placeholder="New mission"
                              />
                              <FormError
                                top="-10px"
                                isAbsolute
                                sx={{ position: "relative" }}>
                                <>{touched.title && errors.title}</>
                              </FormError>
                            </div>
                          </div>
                        </Grid>
                        <Grid container rowGap={3}>
                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <div className="text-base font-bold text-left text-black">
                                Schedule
                              </div>
                              <Tooltip
                                onClick={() => {
                                  setOpenScheduleTooltip(!openScheduleTooltip);
                                }}
                                open={openScheduleTooltip}
                                title="Select a date on which you want the task to be shown to assistants (note, that only 'published' tasks are shown).">
                                <div className="flex items-center cursor-pointer">
                                  <InfoCircelIcon />
                                </div>
                              </Tooltip>
                              <div className="flex items-center pl-3">
                                <input
                                  id="link-checkbox"
                                  type="checkbox"
                                  value={isScheduling}
                                  checked={Boolean(
                                    (mode != "create" &&
                                      values.date > new Date(Date.now())) ||
                                      isScheduling,
                                  )}
                                  onChange={() => {
                                    setIsScheduling((val) => !val);

                                    if (isScheduling) {
                                      setFieldValue(
                                        "date",
                                        new Date(Date.now()),
                                      );
                                    }

                                    if (
                                      mode != "create" &&
                                      values.date > new Date(Date.now())
                                    ) {
                                      setIsScheduling(false);
                                    }

                                    if (mode != "create" && !isScheduling) {
                                      setFieldValue(
                                        "date",
                                        new Date(Date.now()),
                                      );
                                    }
                                  }}
                                  className="scale-110 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </div>
                            {}
                            {Boolean(
                              (mode != "create" &&
                                values.date > new Date(Date.now())) ||
                                isScheduling,
                            ) ? (
                              <div className="lg:w-full w-[300px]">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    disablePast
                                    value={values.date}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        "date",
                                        new Date(newValue || Date.now()),
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          style: {
                                            maxWidth: "400px",
                                            padding: "10px 12px",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Grid>

                        {qualifications.length > 0 && (
                          <Grid container rowGap={3}>
                            <div className="flex flex-col">
                              <div className="flex items-center">
                                <div className="text-base font-bold text-left text-black">
                                  Reward
                                </div>
                                <Tooltip
                                  onClick={() => {
                                    setOpenRewardTooltip(!openRewardTooltip);
                                  }}
                                  open={openRewardTooltip}
                                  title="Select a qualification that will reward the assistant once accomplished">
                                  <div className="flex items-center cursor-pointer">
                                    <InfoCircelIcon />
                                  </div>
                                </Tooltip>
                                <div className="flex items-center pl-3">
                                  <input
                                    id="link-checkbox"
                                    type="checkbox"
                                    checked={Boolean(
                                      (mode !== "create" &&
                                        values?.rewards_qualifications?.length >
                                          0) ||
                                        isRewarding,
                                    )}
                                    onChange={(event) => {
                                      setIsRewarding(event.target.checked);
                                      if (
                                        Boolean(event.target.checked) === false
                                      ) {
                                        setFieldValue(
                                          "rewards_qualifications",
                                          [],
                                        );
                                      }
                                      if (
                                        mode !== "create" &&
                                        Boolean(event.target.checked)
                                      ) {
                                        setFieldValue(
                                          "rewards_qualifications",
                                          activeTask?.rewards_qualifications
                                            ? activeTask.rewards_qualifications
                                            : [],
                                        );
                                      }
                                    }}
                                    className="scale-110 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </div>
                              {Boolean(
                                isRewarding ||
                                  (mode !== "create" &&
                                    values?.rewards_qualifications?.length > 0),
                              ) ? (
                                <div className="lg:w-full w-[400px]">
                                  <Select
                                    placeholder="Select a qualification"
                                    id="demo-select-small"
                                    className="w-full"
                                    value={values.rewards_qualifications[0]}
                                    onChange={(event) => {
                                      setFieldValue("rewards_qualifications", [
                                        event.target.value,
                                      ]);
                                    }}>
                                    {qualifications.map((qualification) => (
                                      <MenuItem value={qualification.id}>
                                        {qualification.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                        )}

                        <Grid container rowGap={3}>
                          <div className="flex flex-col mdMax:w-[90%]">
                            <div className="flex items-center">
                              <div className="text-base font-bold text-left text-black">
                                Tags
                              </div>
                              <Tooltip
                                onClick={() => {
                                  setOpenTagsTooltip(!openTagsTooltip);
                                }}
                                open={openTagsTooltip}
                                title="Please select any and all tags that apply to your mission">
                                <div className="flex items-center cursor-pointer">
                                  <InfoCircelIcon />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="flex flex-row pt-1 mdMax:flex-col">
                              {TaskTypes.map((type, index) => (
                                <TagsButton
                                  selected={tags.includes(type.value)}
                                  key={index}
                                  onClick={() => {
                                    //If clicked when active
                                    if (tags.includes(type.value)) {
                                      setTags((tags) =>
                                        tags.filter(
                                          (tag) => tag !== type.value,
                                        ),
                                      );
                                    }
                                    if (!tags.includes(type.value)) {
                                      setTags((tags) => [...tags, type.value]);
                                    }
                                  }}
                                  className="flex mx-1 hover:cursor-pointer">
                                  {type.name}
                                </TagsButton>
                              ))}
                            </div>
                          </div>
                        </Grid>
                        <Grid container columnGap={2}>
                          <div className="flex flex-col">
                            <Grid item xs={2} className="flex items-center">
                              <div className="text-base font-bold text-left text-black">
                                Energy
                              </div>
                              <Tooltip
                                onClick={() => {
                                  setOpenEnergyTooltip(!openEnergyTooltip);
                                }}
                                open={openEnergyTooltip}
                                title="Please select energy to assign (1 energy = 30 mins)">
                                <div className="flex items-center cursor-pointer">
                                  <InfoCircelIcon />
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={7}>
                              <div className="flex flex-row items-center justify-center gap-1">
                                <TextField
                                  sx={{ width: 360 }}
                                  multiline={false}
                                  rows={1}
                                  type="number"
                                  className="bg-[#fdfdfd] w-4/5 m-0 rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={values.energy_assigned}
                                  inputProps={{
                                    style: {
                                      padding: "10px 12px",
                                      textAlign: "center",
                                    },
                                    pattern: "^d*(.d+)?$",
                                    placeholder: "Energies",
                                    disableUnderline: true,
                                    step: "0.05",
                                  }}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === null ||
                                      e.target.value === 0
                                    ) {
                                      setFieldValue("energy_assigned", 0);
                                      return;
                                    }

                                    const value: any = parseFloat(
                                      e.target.value,
                                    ).toFixed(2);

                                    const rounded =
                                      Math.ceil(value / 0.05) * 0.05;

                                    // Convert rounded value to a number and back to string for comparison
                                    const truncated = Number(
                                      rounded.toFixed(2),
                                    ).toString();

                                    // If the original value matches the truncated value, set it with no decimal places
                                    e.target.value =
                                      rounded.toFixed(2) === truncated
                                        ? parseInt(truncated)
                                        : rounded.toFixed(2);

                                    e.target.value = rounded.toFixed(2);

                                    if (parseFloat(e.target.value) > 1000) {
                                      e.target.value = "1000.00";
                                    }

                                    if (e.target.value) {
                                      // Check if the value has .00 decimal
                                      let fieldValue = e.target.value.endsWith(
                                        ".00",
                                      )
                                        ? parseInt(e.target.value, 10)
                                        : e.target.value;

                                      setFieldValue(
                                        "energy_assigned",
                                        fieldValue,
                                      );
                                    }
                                  }}
                                />
                                <EnergyBar filled />
                              </div>
                              <FormError
                                top="-10px"
                                isAbsolute
                                sx={{
                                  position: "relative",
                                  width: "200px",
                                }}>
                                <>
                                  {touched.energy_assigned &&
                                    errors.energy_assigned}
                                </>
                              </FormError>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid container pt={"15px"} columnGap={3}>
                          <Grid item xs={2}>
                            <div className="block mb-2 font-bold text-black">
                              Description
                            </div>
                          </Grid>

                          <Grid item xs={11}>
                            <div className="flex flex-col gap-1">
                              <div className=" w-full">
                                <TextEditor
                                  htmlString={values?.description}
                                  onEditorChange={(string) => {
                                    setFieldValue("description", string);
                                    setIsTranslated(false);
                                  }}
                                  isTranslated={isTranslated}
                                  placeholder={INPUT_DESCRIPTION_PLACEHOLDER}
                                />
                              </div>

                              <div className="flex flex-row gap-2">
                                <div
                                  className={`hover:opacity-50 text-xs ${
                                    isTranslating
                                      ? "opacity-50"
                                      : "cursor-pointer underline"
                                  }`}
                                  onClick={async () => {
                                    if (isTranslating || !values.description) {
                                      return;
                                    }
                                    await handleTranslate({
                                      description: values.description,
                                    });
                                    setIsTranslated(true);
                                  }}>
                                  {isTranslating
                                    ? "Translating..."
                                    : `Translate to English`}
                                </div>
                                {isTranslating && (
                                  <div className="flex items-center justify-center">
                                    <LoadingIcon
                                      size={3}
                                      color={"primaryButton"}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container className="grid lg:grid-cols-2">
                          <div>
                            <label className="block mb-3 font-bold text-black">
                              Video URLs
                            </label>
                            {values.videos.slice(0, -1).map((url, index) => (
                              <div
                                className="mb-3 flex items-center"
                                key={index}>
                                {REGEX_Youtube.test(url) ? (
                                  <iframe
                                    className="rounded-md mr-5"
                                    width="344"
                                    height="188"
                                    src={
                                      "https://www.youtube.com/embed/" +
                                      url.substring(17)
                                    }
                                    title="YouTube video player"
                                    frameBorder={"0"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                                ) : REGEX_Vimeo.test(url) ? (
                                  <iframe
                                    className="rounded-md mr-5"
                                    width="344"
                                    height="188"
                                    src={
                                      "https://player.vimeo.com/video/" +
                                      url.substring(18)
                                    }
                                    title="YouTube video player"
                                    frameBorder={"0"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                                ) : CONDITION_DTube(url) ? (
                                  <iframe
                                    className="rounded-md mr-5"
                                    width="344"
                                    height="188"
                                    src={
                                      "https://emb.d.tube/#!/" +
                                      url.substring(21)
                                    }
                                    title="YouTube video player"
                                    frameBorder={"0"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                                ) : CONDITION_BitChute(url) ? (
                                  <iframe
                                    className="rounded-md mr-5"
                                    width="344"
                                    height="188"
                                    src={
                                      "https://www.bitchute.com/embed/" +
                                      url.substring(31)
                                    }
                                    title="YouTube video player"
                                    frameBorder={"0"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    scrolling="no"
                                    allowFullScreen></iframe>
                                ) : (
                                  <iframe
                                    className="rounded-md mr-5"
                                    width="344"
                                    height="188"
                                    src={
                                      "https://vimeo.com/api/oembed.json?url=" +
                                      encodeURI(url)
                                    }
                                    title="YouTube video player"
                                    frameBorder={"0"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                                )}

                                <div
                                  className="cursor-pointer"
                                  onClick={(event) => {
                                    setFieldValue(
                                      "videos",
                                      (values.videos = [
                                        ...values.videos.slice(0, index),
                                        ...values.videos.slice(index + 1),
                                      ]),
                                    );
                                  }}>
                                  <MinusCircleIcon />
                                </div>
                              </div>
                            ))}
                            <div className="mb-3 flex items-center">
                              <TextField
                                sx={{ width: 360 }}
                                multiline={false}
                                rows={1}
                                className="bg-[#fdfdfd] w-4/5 mr-5 m-0 rounded-md"
                                variant="filled"
                                size="small"
                                value={values.videos[values.videos.length - 1]}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event) => {
                                  if (event.target.value == "") {
                                    setHasVideoError(false);
                                  }
                                  setFieldValue(
                                    `videos[${values.videos.length - 1}]`,
                                    event.target.value,
                                  );
                                }}
                                inputProps={{
                                  style: { padding: "10px 12px" },
                                }}
                                onBlur={() => {
                                  let url =
                                    values.videos[values.videos.length - 1];

                                  if (REGEX_Youtube.test(url)) {
                                    if (url.indexOf("https://youtu.be/") > -1) {
                                      setHasVideoError(false);
                                      setFieldValue(
                                        "videos",
                                        values.videos.concat([""]),
                                      );
                                      return;
                                    }
                                    if (
                                      url.indexOf(
                                        "https://www.youtube.com/watch?v=",
                                      ) > -1
                                    ) {
                                      if (url.length != 43) {
                                        setHasVideoError(true);
                                        return;
                                      }
                                      let videoCode = url.slice(
                                        url.length - 11,
                                      );
                                      let videoTempUrl = `https://youtu.be/${videoCode}`;
                                      //Proceeds if there's an element in the array or the last value of it is the current url value
                                      if (
                                        values.videos.length > 0 ||
                                        values.videos[
                                          values.videos.length - 1
                                        ].indexOf(videoCode) != -1
                                      ) {
                                        values.videos.pop();
                                        values.videos.push(videoTempUrl);
                                      }
                                      setHasVideoError(false);
                                      setFieldValue(
                                        "videos",
                                        values.videos.concat([""]),
                                      );
                                      return;
                                    }
                                  }

                                  if (REGEX_Vimeo.test(url)) {
                                    let videoCode = url.substring(18);

                                    let videoTempUrl = `https://vimeo.com/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  if (CONDITION_DTube(url)) {
                                    let videoCode = url.substring(19);
                                    let videoTempUrl = `https://d.tube/#!/v/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  if (CONDITION_BitChute(url)) {
                                    let videoCode = url.substring(31);
                                    console.log(videoCode, "VIdeo Code");
                                    let videoTempUrl = `https://www.bitchute.com/video/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  //Stops triggering input errors
                                  if (url == "") {
                                    return;
                                  } else {
                                    setHasVideoError(true);
                                    return;
                                  }
                                }}
                              />
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  let url =
                                    values.videos[values.videos.length - 1];

                                  if (REGEX_Youtube.test(url)) {
                                    if (url.indexOf("https://youtu.be/") > -1) {
                                      setHasVideoError(false);
                                      setFieldValue(
                                        "videos",
                                        values.videos.concat([""]),
                                      );
                                      return;
                                    }
                                    if (
                                      url.indexOf(
                                        "https://www.youtube.com/watch?v=",
                                      ) > -1
                                    ) {
                                      if (url.length != 43) {
                                        setHasVideoError(true);
                                        return;
                                      }
                                      let videoCode = url.slice(
                                        url.length - 11,
                                      );
                                      let videoTempUrl = `https://youtu.be/${videoCode}`;
                                      //Proceeds if there's an element in the array or the last value of it is the current url value
                                      if (
                                        values.videos.length > 0 ||
                                        values.videos[
                                          values.videos.length - 1
                                        ].indexOf(videoCode) != -1
                                      ) {
                                        values.videos.pop();
                                        values.videos.push(videoTempUrl);
                                      }
                                      setHasVideoError(false);
                                      setFieldValue(
                                        "videos",
                                        values.videos.concat([""]),
                                      );
                                      return;
                                    }
                                  }

                                  if (REGEX_Vimeo.test(url)) {
                                    let videoCode = url.substring(18);

                                    let videoTempUrl = `https://vimeo.com/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  if (CONDITION_DTube(url)) {
                                    let videoCode = url.substring(19);

                                    let videoTempUrl = `https://d.tube/#!/v/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  if (CONDITION_BitChute(url)) {
                                    let videoCode = url.substring(32);

                                    let videoTempUrl = `https://www.bitchute.com/video/${videoCode}`;
                                    //Proceeds if there's an element in the array or the last value of it is the current url value
                                    if (
                                      values.videos.length > 0 ||
                                      values.videos[
                                        values.videos.length - 1
                                      ].indexOf(videoCode) != -1
                                    ) {
                                      values.videos.pop();
                                      values.videos.push(videoTempUrl);
                                    }
                                    setHasVideoError(false);
                                    setFieldValue(
                                      "videos",
                                      values.videos.concat([""]),
                                    );
                                    return;
                                  }

                                  //Stops triggering input errors
                                  if (url == "") {
                                    return;
                                  } else {
                                    setHasVideoError(true);
                                    return;
                                  }
                                }}>
                                <PlusCircleIcon />
                              </div>
                            </div>
                            <span
                              className={`text-xs text-error ${
                                hasVideoError ? "" : "hidden"
                              }`}>
                              Not a valid video link
                            </span>
                          </div>
                          <div>
                            <label className="block mb-3 font-bold text-black">
                              Website URLs
                            </label>
                            {values.urls.map((url, index) => (
                              <div
                                className="mb-3 flex items-center"
                                key={index}>
                                <TextField
                                  sx={{ width: 360 }}
                                  multiline={false}
                                  rows={1}
                                  className="bg-[#fdfdfd] w-4/5 mr-5 m-0 rounded-md"
                                  variant="filled"
                                  size="small"
                                  value={url}
                                  onBlur={() => {
                                    const url = values.urls[index];
                                    if (url == "") {
                                      return;
                                    }
                                    const regex: RegExp =
                                      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
                                    if (
                                      regex.test(url) &&
                                      index === values.urls.length - 1
                                    ) {
                                      setFieldValue(
                                        "urls",
                                        values.urls.concat([""]),
                                      );
                                    } else {
                                      if (index === values.urls.length - 1)
                                        setHasUrlError(true);
                                      return;
                                    }
                                  }}
                                  InputProps={{ disableUnderline: true }}
                                  onChange={(event) => {
                                    if (event.target.value == "") {
                                      setHasUrlError(false);
                                    }
                                    setFieldValue(
                                      `urls[${index}]`,
                                      event.target.value,
                                    );
                                  }}
                                  inputProps={{
                                    style: { padding: "10px 12px" },
                                  }}
                                />
                                {index === values.urls.length - 1 ? (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const url = values.urls[index];
                                      if (url == "") {
                                        return;
                                      }
                                      const regex: RegExp =
                                        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
                                      if (
                                        regex.test(url) &&
                                        index === values.urls.length - 1
                                      ) {
                                        setFieldValue(
                                          "urls",
                                          values.urls.concat([""]),
                                        );
                                        return;
                                      } else {
                                        if (index === values.urls.length - 1)
                                          setHasUrlError(true);
                                        return;
                                      }
                                    }}>
                                    <PlusCircleIcon />
                                  </div>
                                ) : (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setFieldValue(
                                        "urls",
                                        (values.urls = [
                                          ...values.urls.slice(0, index),
                                          ...values.urls.slice(index + 1),
                                        ]),
                                      );
                                    }}>
                                    <MinusCircleIcon />
                                  </div>
                                )}
                              </div>
                            ))}
                            <span
                              className={`text-xs text-error ${
                                hasUrlError ? "" : "hidden"
                              }`}>
                              Not a valid http link
                            </span>
                          </div>
                        </Grid>
                        {mode === "create" && (
                          <Grid container>
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              alignItems="center">
                              <div className="font-inter text-base">Draft</div>
                              <StyledSwitch
                                checked={isPublish}
                                onChange={(_, checked) => setIsPublish(checked)}
                              />
                              <div className="font-inter text-base">
                                Publish
                              </div>
                            </Grid>
                            <Grid item xs={12} pr="10px">
                              <div className="text-sm font-inter italic">
                                *{getCreateLabel()}
                              </div>
                            </Grid>
                            <Grid item xs={12} pr="10px">
                              <div className="text-sm font-inter italic">
                                **To attach files, create a draft, then add
                                files.
                              </div>
                            </Grid>
                          </Grid>
                        )}
                        {mode === "edit" && (
                          <Grid container>
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              alignItems="center">
                              <div className="font-inter text-base">Draft</div>
                              <StyledSwitch
                                title={
                                  activeTask.status === "published"
                                    ? "Cannot set status as draft since this is already published"
                                    : ""
                                }
                                disabled={activeTask.status === "published"}
                                checked={isPublish}
                                onChange={(_, checked) => setIsPublish(checked)}
                              />
                              <div className="font-inter text-base">
                                Publish
                              </div>
                            </Grid>
                            {!Boolean(activeTask.status === "published") && (
                              <>
                                <Grid item xs={12} pr="10px">
                                  <div className="text-sm font-inter italic">
                                    *{getCreateLabel()}
                                  </div>
                                </Grid>
                                <Grid item xs={12} pr="10px">
                                  <div className="text-sm font-inter italic">
                                    **To attach files, create a draft, then add
                                    files.
                                  </div>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        )}
                        <div>
                          <div className="flex flex-col">
                            {errors &&
                              Object.keys(errors).map((object, index) => {
                                return (
                                  <div key={index}>
                                    <FormError
                                      sx={{
                                        position: "relative",
                                        width: "400px",
                                      }}>
                                      <>{touched[object] && errors[object]}</>
                                    </FormError>
                                  </div>
                                );
                              })}
                          </div>
                          <Stack
                            className="mb-20 mt-5"
                            spacing={2}
                            direction="row">
                            <TaskPageButton
                              style={{ textTransform: "none" }}
                              type="submit"
                              variant="contained"
                              disabled={isLoading}>
                              <span>
                                {isLoading ? (
                                  <LoadingIcon></LoadingIcon>
                                ) : isSuccess ? (
                                  <CheckIcon></CheckIcon>
                                ) : (
                                  <></>
                                )}
                              </span>
                              {Boolean(isPublish && mode === "create") ? (
                                <span>
                                  {isLoading
                                    ? getModeSaveLoadingLabel()
                                    : isSuccess
                                    ? getModeSaveSuccessLabel()
                                    : getModeSaveLabel()}
                                </span>
                              ) : Boolean(mode !== "create") ? (
                                <span>
                                  {isLoading
                                    ? "Saving"
                                    : isSuccess
                                    ? "Saved"
                                    : "Save"}
                                </span>
                              ) : (
                                <span>
                                  {isLoading
                                    ? "Creating"
                                    : isSuccess
                                    ? "Created"
                                    : "Create"}
                                </span>
                              )}
                            </TaskPageButton>
                            <Button
                              style={{ textTransform: "none" }}
                              className={
                                (isLoading || isSuccess ? "hidden " : "") +
                                "text-black font-bold mt-4"
                              }
                              disabled={isLoading}
                              variant="text"
                              onClick={() => navigate(-1)}>
                              Cancel
                            </Button>
                          </Stack>
                        </div>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </FormStyle>
        </FormContainer>
      </TaskWrapper>
    </StyledContainer>
  );
};

export default Component;
