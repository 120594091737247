export default () => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00333343 4.88355L7.99995 8.88186L15.9967 4.8835C15.9363 3.83315 15.0655 3 14 3H2C0.934518 3 0.0636347 3.83318 0.00333343 4.88355Z"
        fill="#DF8B3F"
      />
      <path
        d="M16 7.1179L7.99995 11.1179L0 7.11796V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V7.1179Z"
        fill="#DF8B3F"
      />
      <circle cx="17.5" cy="1.5" r="1.5" fill="#DF8B3F" />
    </svg>
  );
};
