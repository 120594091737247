const Component = () => {
  return (
    <svg
      width="60"
      height="50"
      viewBox="25 5 65 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M51.1626 11.4256L65.0845 11.5846L62.2897 18.896L51.1626 11.4256Z" fill="#F78419" stroke="#F78419"/>
<path d="M62.2897 18.896L54.5317 18.8312L51.1626 11.4256L62.2897 18.896Z" fill="#F78419" stroke="#F78419"/>
<path d="M81.7616 4.42796L62.29 18.8959L65.0848 11.5846L81.7616 4.42796Z" fill="#E17719" stroke="#E17719"/>
<path d="M73.6309 25.1929L82.9291 20.9035L82.462 22.9516L73.6309 25.1929Z" fill="#773900" stroke="#773900"/>
<path d="M82.1674 25.4142L73.6309 25.1929L82.462 22.9516L82.1674 25.4142Z" fill="#773900" stroke="#773900"/>
<path d="M80.9438 28.9678L69.9434 25.8554L73.6309 25.1929L80.9438 28.9678Z" fill="#773900" stroke="#773900"/>
<path d="M82.1674 25.4142L80.9438 28.9678L73.6309 25.1929L82.1674 25.4142Z" fill="#773900" stroke="#773900"/>
<path d="M54.5317 18.8312L34.9014 3.74734L51.1626 11.4256L54.5317 18.8312Z" fill="#E47719" stroke="#E47719"/>
<path d="M82.9292 20.9035L73.631 25.1929L73.4268 14.0156L82.9292 20.9035Z" fill="#773900" stroke="#773900"/>
<path d="M73.4266 14.0156L73.6309 25.1929L69.9434 25.8554L73.4266 14.0156Z" fill="#773900" stroke="#773900"/>
<path d="M66.1792 46.9484L66.8191 47.0772L69.8754 48.6131L66.1792 46.9484Z" fill="#E17719" stroke="#E17719"/>
<path d="M46.9736 48.7073L49.6377 47.1367L49.9504 47.0037L46.9736 48.7073Z" fill="#E17719" stroke="#E17719"/>
<path d="M42.5449 25.0146L33.6698 22.6245L33.1802 20.5268L42.5449 25.0146Z" fill="#773900" stroke="#773900"/>
<path d="M33.9887 25.1413L33.6699 22.6245L42.5451 25.0146L33.9887 25.1413Z" fill="#773900" stroke="#773900"/>
<path d="M35.2529 28.7777L42.545 25.0146L46.5095 25.7277L35.2529 28.7777Z" fill="#773900" stroke="#773900"/>
<path d="M33.9888 25.1413L42.5452 25.0146L35.2531 28.7777L33.9888 25.1413Z" fill="#773900" stroke="#773900"/>
<path d="M43.1636 13.6997L42.5449 25.0146L33.1802 20.5268L43.1636 13.6997Z" fill="#773900" stroke="#773900"/>
<path d="M46.5095 25.7277L42.5449 25.0146L43.1636 13.6997L46.5095 25.7277Z" fill="#773900" stroke="#773900"/>
<path d="M84.9117 40.7799L81.8723 53.1009L69.875 48.6131L84.9117 40.7799Z" fill="#E47719" stroke="#E47719"/>
<path d="M84.0785 21.6533L82.4619 22.9516L82.929 20.9035L84.0785 21.6533Z" fill="#773900" stroke="#773900"/>
<path d="M82.1675 25.4142L82.462 22.9516L83.7664 23.8605L82.1675 25.4142Z" fill="#773900" stroke="#773900"/>
<path d="M73.4268 14.0156L84.4342 12.359L82.9292 20.9035L73.4268 14.0156Z" fill="#773900" stroke="#773900"/>
<path d="M80.9438 28.9678L82.1675 25.4142L83.2349 26.1056L80.9438 28.9678Z" fill="#773900" stroke="#773900"/>
<path d="M69.9437 25.8554L62.29 18.8959L73.427 14.0156L69.9437 25.8554Z" fill="#773900" stroke="#773900"/>
<path d="M73.6421 29.7542L80.9439 28.9678L84.9118 40.7799L73.6421 29.7542Z" fill="#F78419" stroke="#F78419"/>
<path d="M69.875 48.6131L75.4837 39.3804L84.9117 40.7799L69.875 48.6131Z" fill="#E47719" stroke="#E47719"/>
<path d="M73.6421 29.7542L84.9118 40.7799L75.4838 39.3804L73.6421 29.7542Z" fill="#F78419" stroke="#F78419"/>
<path d="M43.1636 13.6997L54.5317 18.8312L46.5095 25.7277L43.1636 13.6997Z" fill="#773900" stroke="#773900"/>
<path d="M46.9736 48.7073L33.9888 53.3896L30.9302 40.8245L46.9736 48.7073Z" fill="#E47719" stroke="#E47719"/>
<path d="M80.9438 28.9678L73.642 29.7542L69.9434 25.8554L80.9438 28.9678Z" fill="#F78419" stroke="#F78419"/>
<path d="M32.0244 21.2755L33.1803 20.5268L33.6699 22.6245L32.0244 21.2755Z" fill="#773900" stroke="#773900"/>
<path d="M33.9884 25.1413L32.3594 23.5354L33.6696 22.6245L33.9884 25.1413Z" fill="#773900" stroke="#773900"/>
<path d="M33.1803 20.5268L31.8325 11.7705L43.1637 13.6997L33.1803 20.5268Z" fill="#773900" stroke="#773900"/>
<path d="M35.2528 28.7777L32.9165 25.8352L33.9884 25.1413L35.2528 28.7777Z" fill="#773900" stroke="#773900"/>
<path d="M69.9436 25.8554L73.6422 29.7542L61.96 29.7878L69.9436 25.8554Z" fill="#E47719" stroke="#E47719"/>
<path d="M61.96 29.7878L62.2899 18.896L69.9436 25.8554L61.96 29.7878Z" fill="#E47719" stroke="#E47719"/>
<path d="M43.3773 29.6387L30.9302 40.8245L35.253 28.7777L43.3773 29.6387Z" fill="#F78419" stroke="#F78419"/>
<path d="M35.2529 28.7777L46.5095 25.7277L43.3772 29.6387L35.2529 28.7777Z" fill="#F78419" stroke="#F78419"/>
<path d="M81.7614 4.42796L84.4342 12.359L73.4268 14.0156L81.7614 4.42796Z" fill="#773900" stroke="#773900"/>
<path d="M73.427 14.0156L62.29 18.8959L81.7616 4.42796L73.427 14.0156Z" fill="#773900" stroke="#773900"/>
<path d="M30.9302 40.8245L41.5265 39.3884L46.9736 48.7073L30.9302 40.8245Z" fill="#E47719" stroke="#E47719"/>
<path d="M41.5265 39.3884L30.9302 40.8245L43.3773 29.6387L41.5265 39.3884Z" fill="#F78419" stroke="#F78419"/>
<path d="M46.5093 25.7277L54.5315 18.8312L55.4931 29.7633L46.5093 25.7277Z" fill="#E47719" stroke="#E47719"/>
<path d="M55.4931 29.7633L43.377 29.6387L46.5093 25.7277L55.4931 29.7633Z" fill="#E47719" stroke="#E47719"/>
<path d="M34.9014 3.74734L54.5317 18.8312L43.1636 13.6997L34.9014 3.74734Z" fill="#773900" stroke="#773900"/>
<path d="M43.1637 13.6997L31.8325 11.7705L34.9015 3.74734L43.1637 13.6997Z" fill="#773900" stroke="#773900"/>
<path d="M54.5317 18.8312L62.2898 18.8959L61.9599 29.7878L54.5317 18.8312Z" fill="#F78419" stroke="#F78419"/>
<path d="M61.9599 29.7878L55.4933 29.7633L54.5317 18.8312L61.9599 29.7878Z" fill="#F78419" stroke="#F78419"/>
<path d="M69.8754 48.6131L68.9819 39.0722L75.4841 39.3804L69.8754 48.6131Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M46.9735 48.7073L41.5264 39.3884L48.4092 39.0743L46.9735 48.7073Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M61.96 29.7878L73.6422 29.7542L69.0431 34.3076L61.96 29.7878Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M73.6424 29.7542L68.9819 39.0722L69.0432 34.3076L73.6424 29.7542Z" fill="#E47424" stroke="#E47424"/>
<path d="M75.4841 39.3804L68.9819 39.0722L73.6424 29.7542L75.4841 39.3804Z" fill="#F78419" stroke="#F78419"/>
<path d="M48.2581 34.2681L43.377 29.6387L55.4931 29.7633L48.2581 34.2681Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M43.377 29.6387L48.2581 34.2681L48.409 39.0743L43.377 29.6387Z" fill="#E47424" stroke="#E47424"/>
<path d="M43.3772 29.6387L48.4092 39.0743L41.5264 39.3884L43.3772 29.6387Z" fill="#F78419" stroke="#F78419"/>
<path d="M63.8574 49.1688L69.8752 48.6131L67.7031 49.983L63.8574 49.1688Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M49.5482 50.0681L46.9736 48.7073L54.6773 49.2085L49.5482 50.0681Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M69.0431 34.3076L64.8109 32.3118L61.96 29.7878L69.0431 34.3076Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M64.0577 46.5253L69.8752 48.6131L63.8574 49.1687L64.0577 46.5253Z" fill="#D6C2B2" stroke="#D6C2B2"/>
<path d="M69.875 48.6131L64.0575 46.5253L63.4766 41.5594L69.875 48.6131Z" fill="#D6C2B2" stroke="#D6C2B2"/>
<path d="M63.4766 41.5594L68.9815 39.0722L69.875 48.6131L63.4766 41.5594Z" fill="#E47719" stroke="#E47719"/>
<path d="M63.1499 35.7576L64.811 32.3118L69.0431 34.3076L63.1499 35.7576Z" fill="#243343" stroke="#243343"/>
<path d="M68.9818 39.0722L63.1499 35.7576L69.0431 34.3076L68.9818 39.0722Z" fill="#F78419" stroke="#F78419"/>
<path d="M55.4933 29.7633L52.5789 32.2784L48.2583 34.2681L55.4933 29.7633Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M64.8109 32.3118L63.1499 35.7576L61.96 29.7878L64.8109 32.3118Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M54.7945 46.5552L54.6773 49.2086L46.9736 48.7073L54.7945 46.5552Z" fill="#D6C2B2" stroke="#D6C2B2"/>
<path d="M46.9736 48.7073L55.4177 41.5687L54.7945 46.5552L46.9736 48.7073Z" fill="#D6C2B2" stroke="#D6C2B2"/>
<path d="M55.4177 41.5687L46.9736 48.7073L48.4093 39.0743L55.4177 41.5687Z" fill="#E47719" stroke="#E47719"/>
<path d="M54.4147 35.7463L48.2583 34.2681L52.5789 32.2784L54.4147 35.7463Z" fill="#243343" stroke="#243343"/>
<path d="M48.4092 39.0743L48.2583 34.2681L54.4147 35.7463L48.4092 39.0743Z" fill="#F78419" stroke="#F78419"/>
<path d="M52.5791 32.2784L55.4935 29.7633L54.4149 35.7463L52.5791 32.2784Z" fill="#CD6200" stroke="#CD6200"/>
<path d="M63.1498 35.7576L61.8296 33.2233L61.9599 29.7878L63.1498 35.7576Z" fill="#E17719" stroke="#E17719"/>
<path d="M55.8744 33.2094L55.4932 29.7633L61.9597 29.7878L55.8744 33.2094Z" fill="#F78419" stroke="#F78419"/>
<path d="M61.9598 29.7878L61.8295 33.2233L55.8745 33.2094L61.9598 29.7878Z" fill="#F78419" stroke="#F78419"/>
<path d="M54.4146 35.7463L55.4931 29.7633L55.8744 33.2094L54.4146 35.7463Z" fill="#E47424" stroke="#E47424"/>
<path d="M67.7029 49.983L63.4985 51.1846L63.8573 49.1688L67.7029 49.983Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M54.677 49.2086L55.1149 51.228L49.5479 50.0681L54.677 49.2086Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M63.1499 35.7576L68.9818 39.0722L63.4769 41.5594L63.1499 35.7576Z" fill="#F78419" stroke="#F78419"/>
<path d="M55.4176 41.5687L48.4092 39.0743L54.4147 35.7463L55.4176 41.5687Z" fill="#F78419" stroke="#F78419"/>
<path d="M61.8296 33.2233L63.1498 35.7575L62.4382 40.8358L61.8296 33.2233Z" fill="#E47424" stroke="#E47424"/>
<path d="M63.4765 41.5594L62.438 40.8358L63.1496 35.7576L63.4765 41.5594Z" fill="#F78419" stroke="#F78419"/>
<path d="M55.8744 33.2094L56.4602 40.8408L54.4146 35.7463L55.8744 33.2094Z" fill="#E47424" stroke="#E47424"/>
<path d="M54.4146 35.7463L56.4602 40.8408L55.4174 41.5687L54.4146 35.7463Z" fill="#F78419" stroke="#F78419"/>
<path d="M55.8745 33.2094L61.8295 33.2233L62.4381 40.8358L55.8745 33.2094Z" fill="#F78419" stroke="#F78419"/>
<path d="M62.4381 40.8358L56.4603 40.8408L55.8745 33.2094L62.4381 40.8358Z" fill="#F78419" stroke="#F78419"/>
<path d="M64.0576 46.5253L63.8573 49.1687L63.4985 51.1845L64.0576 46.5253Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M55.1152 51.228L54.6772 49.2086L54.7945 46.5552L55.1152 51.228Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M63.5959 45.7592L63.4988 51.1846L55.1152 51.228L63.5959 45.7592Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M63.4985 51.1846L63.5957 45.7592L64.0576 46.5253L63.4985 51.1846Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M54.7944 46.5552L55.4106 45.7829L55.1151 51.228L54.7944 46.5552Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M55.1152 51.228L55.4107 45.7829L63.5959 45.7592L55.1152 51.228Z" fill="#C0AC9D" stroke="#C0AC9D"/>
<path d="M63.4766 41.5594L64.0575 46.5253L63.5956 45.7592L63.4766 41.5594Z" fill="black" stroke="black"/>
<path d="M55.4106 45.7829L54.7944 46.5552L55.4176 41.5687L55.4106 45.7829Z" fill="black" stroke="black"/>
<path d="M62.6483 41.298L62.438 40.8358L63.4765 41.5594L62.6483 41.298Z" fill="black" stroke="black"/>
<path d="M63.5957 45.7592L63.314 42.049L63.4767 41.5594L63.5957 45.7592Z" fill="black" stroke="black"/>
<path d="M63.4767 41.5594L63.314 42.0489L62.6484 41.298L63.4767 41.5594Z" fill="black" stroke="black"/>
<path d="M56.5565 41.3043L56.4604 40.8408L62.4383 40.8358L56.5565 41.3043Z" fill="black" stroke="black"/>
<path d="M62.4384 40.8358L62.6487 41.298L56.5566 41.3043L62.4384 40.8358Z" fill="black" stroke="black"/>
<path d="M55.4175 41.5687L56.4603 40.8408L56.5563 41.3043L55.4175 41.5687Z" fill="black" stroke="black"/>
<path d="M55.4177 41.5687L55.8894 42.059L55.4106 45.7829L55.4177 41.5687Z" fill="black" stroke="black"/>
<path d="M56.5563 41.3043L55.8891 42.059L55.4175 41.5687L56.5563 41.3043Z" fill="black" stroke="black"/>
<path d="M55.8892 42.059L63.3139 42.049L63.5957 45.7591L55.8892 42.059Z" fill="black" stroke="black"/>
<path d="M63.5959 45.7592L55.4106 45.7829L55.8894 42.059L63.5959 45.7592Z" fill="black" stroke="black"/>
<path d="M62.6484 41.298L63.3139 42.0489L55.8892 42.059L62.6484 41.298Z" fill="black" stroke="black"/>
<path d="M55.8892 42.059L56.5563 41.3043L62.6484 41.298L55.8892 42.059Z" fill="black" stroke="black"/>
    </svg>
  );
};

export default Component;
