import React from "react";

const Component = (props) => {
  const { color, size, top, right } = props;

  return (
    <svg
      className={`absolute top-[${top ? top : 0}] right-[${
        right ? right : 0
      }] h-${size ? size : 4} w-${size ? size : 4} text-${
        color ? color : "white"
      }`}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round">
      {/* Adjust SVG size and colors as needed */}
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

export default Component;
