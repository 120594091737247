export default (props) => (
  <svg
    width={props.svgWidth ? props.svgWidth : "20"}
    height={props.svgHeight ? props.svgHeight : "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1872 5L6.00268 11.5858C5.15062 12.3668 5.15062 13.6332 6.00268 14.4142C6.85473 15.1953 8.23618 15.1953 9.08823 14.4142L16.0856 7.82843C17.7897 6.26633 17.7897 3.73367 16.0856 2.17157C14.3815 0.609476 11.6185 0.609476 9.91444 2.17157L2.91712 8.75736C0.36096 11.1005 0.36096 14.8995 2.91712 17.2426C5.47328 19.5858 9.61763 19.5858 12.1738 17.2426L19 11"
      stroke={props.svgColor ? props.svgColor : "white"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
