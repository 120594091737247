import React, { useEffect } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import jwt_decode from "jwt-decode";
import { useAccount, useDisconnect } from "wagmi";
import { GlobalRoutes } from "./GlobalRoutes";
import ProtectedAdminRoutes from "./ProtectedAdminRoutes";
import ProtectedOwnerRoutes from "./ProtectedOwnerRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import UnprotectedRoutes from "./UnprotectedRoutes";

import {
  authState,
  globalState,
  initialAuthState,
  paginationState,
} from "../state/atoms";

const RenderRoute = () => {
  const { pathname: locationPath } = useLocation();
  const { address } = useAccount();
  const [auth, setAuth] = useRecoilState(authState);
  const [global, setGlobal] = useRecoilState(globalState);
  const isPathGlobal =
    locationPath === "/page-not-found" || locationPath === "/server-error";
  const { disconnect } = useDisconnect();
  const [pagination, setPagination] = useRecoilState(paginationState);
  const navigate = useNavigate();

  if (isPathGlobal) return <GlobalRoutes />;
  let check = locationPath.slice(0, 6);
  let addMailCheck = locationPath.slice(0, 8);
  let confirmationCheck = locationPath.slice(0, 16);

  const logOut = () => {
    // disconnectWallet();
    disconnect();
    setPagination({
      fetchType: "open",
      pageNum: 0,
      after: null,
      before: null,
    });
    setAuth(initialAuthState);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    navigate("/");
  };

  useEffect(() => {
    if (auth.accessToken || auth.walletAddress) {
      let decoded = jwt_decode(auth.accessToken);
      if (Date.now() >= decoded.exp * 1000) {
        logOut();
      }

      return;
    }

    if (check === "/tasks" || check === "/task/") {
      setGlobal({
        ...globalState,
        redirect_url: locationPath,
      });

      navigate("/login");
    }
  }, []);

  return (
    <>
      {confirmationCheck === "/confirm-account" ||
      addMailCheck === "/addmail" ? (
        <UnprotectedRoutes />
      ) : auth.accessToken || auth.walletAddress ? (
        auth.role === "owner" ? (
          <ProtectedOwnerRoutes />
        ) : auth.role === "admin" ? (
          <ProtectedAdminRoutes />
        ) : auth.role === "pa" ? (
          <ProtectedRoutes />
        ) : (
          <></>
        )
      ) : (
        <UnprotectedRoutes />
      )}
    </>
  );
};

const RootNavigator = () => {
  return (
    <React.Suspense fallback={<div>loading routes...</div>}>
      <BrowserRouter>
        <RenderRoute />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootNavigator;
