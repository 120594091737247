import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { confirmEmail } from "../../../services/auth";
import { SnackbarContext } from "../../../state/hooks";
const Container = styled.div`
  height: calc(100% - 64px);
`;

const StyledTitle = styled.p`
  width: 940px;
  height: 59px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
`;

const StyledText = styled.p`
  display: flex;
  width: 378px;
  left: 531px;
  top: 309px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: 0.15px;
`;

const StyledButton = styled(Button)`
  background-color: white;
  display: flex;
  width: 378px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #6f5acd;
  color: #6f5acd;
`;

const Component = () => {
  const setSnackbar = useContext(SnackbarContext);
  const {
    mutate: mutateRequestConfirm,
    isLoading,
    error,
  } = useMutation(
    "ConfirmEmail",
    (email: string) => {
      return confirmEmail(email);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: resp.message,
          type: "success",
        });
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );
  const [email, setEmail] = useState("");
  return (
    <Container className="bg-mainBg">
      <div className="flex flex-col items-center">
        <StyledTitle>Missing your confirmation email?</StyledTitle>
        <StyledText>
          Sorry if the first link didn’t successfully land in your inbox.
          <br />
          <br />
          Please try again by entering your email address below.
          <br />
          <br />
          <br />
        </StyledText>
        <TextField
          name="email"
          label="Email Address"
          variant="filled"
          value={email}
          style={{
            paddingBottom: 40,
          }}
          className="w-[378px]"
          inputProps={{ maxLength: 150 }}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}></TextField>
        <StyledButton
          className="bg-mainBg"
          onClick={() => {
            mutateRequestConfirm(email);
          }}>
          Re-Send Confirmation Email
        </StyledButton>
      </div>
    </Container>
  );
};

export default Component;
