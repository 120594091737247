import { useState } from "react";
import { useQuery } from "react-query";
import { Admin, Assistant, TaskOwner } from "../../../interfaces/User";
import { getUserById } from "../../../services/users";

type UserRole = "nft" | "assistant" | "admin";
type UserTypeMap = {
  nft: TaskOwner;
  assistant: Assistant;
  admin: Admin;
};

/**
 * Custom React hook to fetch and type user data.
 *
 * @param {object} auth - The authentication object, typically containing the user's access token and user ID.
 * @param {UserRole} [role] - Optional. The expected role of the user. If provided, the user data is strictly typed to this role.
 * @param {string|null} [userId] - Fetches data for the specific user ID instead of the one in the auth object.
 * @param {Function[]} [onSuccessCallback] - Optional. A list of call back function needed to be executed upon successful data fetch
 * @param {Function[]} [onErrorCallback] - Optional. A list of call back function needed to be executed upon unsuccessful data fetch
 * @returns {object} An object containing user data, a refetch function, and a loading state.
 */
function useUserFetch<T extends UserRole>(
  auth,
  role?: T,
  userId?: string | null,
  onSuccessCallback?: ((...args: any[]) => void)[],
  onErrorCallback?: ((...args: any[]) => void)[],
  onSuccessAuthCallback?: (...args: any[]) => void,
  onErrorAuthCallback?: (...args: any[]) => void,
) {
  const [user, setUser] = useState<UserTypeMap[T]>();
  const { data, refetch, isLoading, isRefetching, isError } = useQuery(
    ["userDataFetch", auth.accessToken, userId],
    () => {
      if (auth.accessToken && userId) {
        return getUserById(auth.accessToken, userId);
      } else {
        return undefined;
      }
    },
    {
      onSuccess: (data) => {
        if (role) {
          setUser(data.user as UserTypeMap[T]);
        } else {
          setUser(data.user as any);
        }

        if (onSuccessAuthCallback && data.user) {
          onSuccessAuthCallback(data.user as UserTypeMap[T]);
        }
        if (onSuccessCallback && onSuccessCallback.length > 0) {
          onSuccessCallback.forEach((callback) => {
            callback(data);
          });
        }
      },
      onError: (data) => {
        if (onErrorCallback) {
          onErrorCallback.forEach((callback) => {
            callback(data);
          });
        }
        if (onErrorAuthCallback) {
          onErrorAuthCallback();
        }
      },
    },
  );

  return { user, refetch, isLoading, isRefetching, isError };
}

export default useUserFetch;
