export default {
  published: "#40AD5F",
  submission_rejected: "#C13F4C",
  completed: "#000000",
  in_progress: "#FFCA0E",
  clarification_needed: "#E17719",
  declined: "#C13F4C",
  submitted: "#E17719",
  clarification_ended: "#E17719",
  draft: "#708090",
  none: "#806AD2",
};
