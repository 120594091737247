import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { registerPA } from "../../../services/auth";
import { SnackbarContext } from "../../../state/hooks";
import RegistrationTemplate from "../../templates/Registration";
const Container = styled.div`
  height: calc(100% - 64px);
`;
const StyledTitle = styled.p`
  width: 940px;
  height: 59px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
`;

const StyledText = styled.p`
  display: flex;
  width: 378px;
  left: 531px;
  top: 309px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: 0.15px;
`;

const StyledButton = styled(Button)`
  background-color: white;
  display: flex;
  width: 378px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #6f5acd;
  color: #6f5acd;
`;

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
};
const Component = () => {
  const [registered, setRegistered] = useState(false);
  const setSnackbar = useContext(SnackbarContext);
  const [data, setData] = useState<FormValues>();
  const navigate = useNavigate();
  const {
    mutate: mutateRegisterUser,
    isLoading,
    error,
  } = useMutation(
    "registerUser",
    (values: FormValues) => {
      setData(values);
      return registerPA({ ...values, type: "assistant" });
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully registered user!",
          type: "success",
        });
        setRegistered(true);
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );
  return (
    <Container className="bg-mainBg">
      {!registered && (
        <RegistrationTemplate
          mutateRegisterUser={mutateRegisterUser}
          isLoading={isLoading}
        />
      )}
      {registered && (
        <div className="flex flex-col items-center">
          <StyledTitle>Just One More Step</StyledTitle>
          <StyledText>
            Almost there, {data?.name}!
            <br />
            <br />
            We’ve sent a link to your email address so you can confirm your
            account.
            <br />
            <br />
            Visit your inbox soon and click the link to log in to your account.
            <br />
            <br />
            <br />
          </StyledText>
          <StyledText className="justify-center">
            Didn't Receive An Email?
          </StyledText>
          <StyledButton
            className="bg-mainBg"
            onClick={() => {
              navigate("/register/confirm");
            }}>
            Re-Send Confirmation Email
          </StyledButton>
        </div>
      )}
    </Container>
  );
};

export default Component;
