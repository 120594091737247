import { atom } from "recoil";
import { Delivery } from "../interfaces/Delivery";
import { Task } from "../interfaces/Task";

export const taskListState = atom({
  key: "TaskList",
  default: [],
});

const MainTaskTypes = [
  { name: "Personal Tasks", value: "personal_tasks" },
  { name: "Administration", value: "administration" },
  {
    name: "Lead Generation",
    value: "lead_generation",
  },
  { name: "Web3 Research", value: "web3_research" },
  { name: "Search", value: "search" },
];

export const TaskTypes = [...MainTaskTypes];

export const MissionTaskTypes = [
  { name: "Missions", value: "missions" },
  ...MainTaskTypes,
];

export const TaskTopics = [
  { name: "Find", value: "find" },
  { name: "Collect", value: "collect" },
  {
    name: "Make",
    value: "make",
  },
  { name: "Add", value: "add  " },
  { name: "Search", value: "Search" },
];

export const initialAuthState = {
  role: null,
  accessToken: null,
  walletAddress: null,
  user_id: null,
  user_name: "",
  email: "",
  energy: 0,
  payout_address: "",
};

export const assetState = atom({
  key: "Assets",
  default: {
    eUSDC: 0,
    eUSDT: 0,
    eDAI: 0,
    pUSDC: 0,
    pUSDT: 0,
    pDAI: 0,
    eBalance: 0,
    pBalance: 0,
    etherHDAO: 0,
    polygonHDAO: 0,
    isLoaded: false,
  },
});

export const initialGlobalState = {
  redirect_url: "",
};
export const defaultDeliveryState = {
  attachments: [],
  videos: [],
  urls: [],
  description: "",
};

export const authState = atom({
  key: "Auth",
  default: {
    ...initialAuthState,
    accessToken: localStorage.getItem("accessToken"),
    user_id: localStorage.getItem("user_id"),
    role: localStorage.getItem("role"),
    user_name:
      localStorage.getItem("user_name") == "null"
        ? JSON.parse(localStorage.getItem("user_name") ?? "null")
        : localStorage.getItem("user_name"),
    walletAddress:
      localStorage.getItem("role") === "pa"
        ? ""
        : localStorage.getItem("walletAddress"),
  } as AuthAtomState,
});

export const globalState = atom({
  key: "Global",
  default: {
    ...initialGlobalState,
  } as GlobalAtomState,
});
export const paginationState = atom({
  key: "Pagination",
  default: {
    fetchType: "open",
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const feedbackState = atom({
  key: "Feedback",
  default: {
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const earningState = atom({
  key: "Earning",
  default: {
    dates: ["2020-01-01", "2099-12-31"],
    paid_out: true,
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const earningPeriodState = atom({
  key: "EarningPeriod",
  default: {
    from: "2020-01",
    to: "2099-12",
  },
});

export const adminTaskListState = atom({
  key: "adminTaskList",
  default: {
    status: "",
    rating: null,
    swipes: [[], []],
    dates: [],
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const adminMissionListState = atom({
  key: "adminMissionList",
  default: {
    status: "",
    rating: "",
    swipes: [[], []],
    dates: [],
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const userTaskListState = atom({
  key: "userTaskList",
  default: {
    filters: {
      status: "",
      rating: null,
      dates: [],
    },
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const qualificationsListState = atom({
  key: "qualificationsList",
  default: {
    filters: {
      is_global: "all",
    },
    size: 10,
    after: null,
    before: null,
    pageNum: 1,
  },
});

export const ownerTaskListState = atom({
  key: "ownerTaskList",
  default: {
    filters: {
      status: "",
      dates: [],
    },
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const adminPocketAssistantState = atom({
  key: "adminPocketAssistant",
  default: {
    filters: {
      text: "",
      rating: [],
      rejections: [],
      qualification: "",
    },
    pageNum: 0,
    after: null,
    before: null,
  },
});

export const adminTaskOwnersState = atom({
  key: "adminOwners",
  default: {
    filters: {
      text: "",
      authorizedQualification: "",
    },
    after: null,
    before: null,
    pageNum: 0,
  },
});

export const adminPocketAssistantSelectionState = atom({
  key: "adminPocketAssistantSelectionState",
  default: {
    email: "",
    id: "",
  },
});

type AuthAtomState = {
  eligibility: string | null;
  role: "pa" | "owner" | null;
  accessToken: string | null;
  walletAddress?: string | null;
  user_id: string | null;
  user_name?: string | null;
  email?: string | null;
  notif_email?: string | null;
  energy?: number;
  max_energy?: number;
  email_notif?: boolean | null;
  payout_address?: string | null;
};

type GlobalAtomState = {
  redirect_url?: string | null;
};
export const taskState = atom({
  key: "activeTask",
  default: {
    task_id: "",
    title: "",
    date: new Date(),
    category: [],
    description: "",
    topic: "",
    status: "none",
    energy_assigned: 0,
    holder_name: "",
    attachments: [],
    videos: [],
    urls: [],
    comments: [],
    delivery: defaultDeliveryState,
    rating: 0,
    task_type: "",
  } as Task,
});

export const deliveryState = atom({
  key: "activeDelivery",
  default: {
    description: "",
    attachments: [],
    videos: [],
    urls: [],
  } as Delivery,
});

export const defaultTaskState = {
  task_id: "",
  title: "",
  date: new Date(),
  category: [],
  description: "",
  topic: "",
  status: "none",
  energy_assigned: 0,
  holder_name: "",
  attachments: [],
  videos: [],
  urls: [],
  comments: [],
  delivery: {
    description: "",
    videos: [],
    urls: [],
    comments: [],
    attachments: [],
  },
  rating: 0,
};
