import { sanitizeUrl } from "@braintree/sanitize-url";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BoostTwo from "../../../assets/images/BoostTwo.svg";
import { ArrowDownIcon, Energy } from "../../../assets/svg";
import AttachIcon from "../../../assets/svg/AttachIcon";
import UrlIcon from "../../../assets/svg/UrlIcon";
import {
  CONDITION_BitChute,
  CONDITION_DTube,
  REGEX_Vimeo,
  REGEX_Youtube,
} from "../../../utils/regex";
import HTMLTextRender from "../../organisms/HTMLTextRender";

const energyPerDollar = 2;

type Props = {
  title: string;
  description: any;
  energy_assigned: number;
  holder_name: string;
  task_id: string;
  canSwipe: boolean;
  attachments: any;
  urls: string[];
  videos: string[];
  earnings_boost: number;
  setIsSwiping: (val: boolean) => void;
  handleSwipe: (direction: "left" | "right") => () => void;
  task_type: string;
};

const StyledPurpleButton = styled(Button)`
  border-radius: 50px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  border: none;
  text-transform: none;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px 30px;
`;

const StyledFrontContainer = styled.span`
  padding-top: 8%;
  margin-bottom: 5%;
  display: flex;
  flex: 100vw;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  // background-color: red;
`;

const StyledBackContainer = styled.span`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  // background-color: red;
`;

const StyledAboutTitle = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: max-content;
  font-size: 18px;
  font-weight: 600;
  margin-left: 8%;
  text-align: left;
  // background-color: green;
`;

const StyledTaskTitle = styled.div`
  width: 100%;
  height: max-content;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  // background-color: green;
`;

const StyledTaskDurationTitle = styled.span`
  color: white;
  font-size: 14px;
  width: 82%;
  font-weight: 400;
  text-align: left;
  display: flex;
  margin-top: 5%;
  margin-left: 8%;
  // background-color: orange;
`;

const StyledTaskDuration = styled.span`
  color: white;
  font-size: 14px;
  width: 82%;
  font-weight: 400;
  text-align: left;
  display: flex;
  margin-top: 2%;
  margin-left: 8%;
  // background-color: orange;
`;

const StyledTaskAttachmentsTitle = styled.span`
  color: white;
  font-size: 14px;
  width: 82%;
  font-weight: 400;
  text-align: left;
  display: flex;
  margin-top: 5%;
  margin-left: 8%;
  // background-color: orange;
`;

const StyledTaskAttachments = styled.span`
  color: white;
  font-size: 14px;
  width: 82%;
  font-weight: 400;
  text-align: left;
  display: flex;
  margin-top: 2%;
  margin-left: 8%;
  // background-color: orange;
`;

type VideoPlayerProps = {
  url: string;
  title: string;
  sourceUrl: string;
};

const getVideoService = (url: string) => {
  if (REGEX_Youtube.test(url))
    return { serviceName: "YouTube", trimmedUrl: url.substring(17) };
  if (REGEX_Vimeo.test(url))
    return { serviceName: "Vimeo", trimmedUrl: url.substring(18) };
  if (CONDITION_DTube(url))
    return { serviceName: "DTube", trimmedUrl: url.substring(21) };
  if (CONDITION_BitChute(url))
    return { serviceName: "BitChute", trimmedUrl: url.substring(31) };
  return { serviceName: "Other", trimmedUrl: url };
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, title, sourceUrl }) => (
  <iframe
    className="rounded-md mr-5"
    width="200"
    height="140"
    src={sourceUrl + url}
    title={title}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

const Component = ({
  title,
  description,
  energy_assigned,
  holder_name,
  task_id,
  urls,
  videos,
  canSwipe,
  attachments,
  setIsSwiping,
  handleSwipe,
  earnings_boost,
  task_type,
}: Props) => {
  const ref = useRef(null);
  const [isRotate, setIsRotate] = useState(false);
  const [hideScrollHelpers, setHideScrollHelpers] = useState(true);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const descriptionText = convertToPlain(description);
  const arr = descriptionText.split("\n");

  const scrollUp = (element, value) => {
    element.scrollBy({ top: value, behavior: "smooth" });
  };
  const scrollDown = (element, value) => {
    element.scrollBy({ top: value, behavior: "smooth" });
  };
  // This function returns true if the element overflows vertically
  function checkOverflow(element) {
    return element.scrollHeight > element.clientHeight;
  }

  const handleScroll = () => {
    const specificElement = ref.current.querySelector(".rdw-editor-main");
    // Check if we're at the top of the element
    if (specificElement.scrollTop > 0) {
      setShowScrollUp(true);
    } else {
      setShowScrollUp(false);
    }
    // Check if we're at the bottom of the element
    if (
      specificElement.scrollTop <
      specificElement.scrollHeight - specificElement.clientHeight
    ) {
      setShowScrollDown(true);
    } else {
      setShowScrollDown(false);
    }
  };

  useEffect(() => {
    const specificElement = ref.current.querySelector(".rdw-editor-main");
    const mobileBreakpoint = 900;
    if (
      specificElement &&
      checkOverflow(specificElement) &&
      window.innerWidth < mobileBreakpoint
    ) {
      setHideScrollHelpers(false);
      if (specificElement && checkOverflow(specificElement)) {
        handleScroll();
        specificElement.addEventListener("scroll", handleScroll);

        return () => {
          specificElement.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [isRotate]);
  useEffect(() => {
    const handleClick = (event) => {
      setIsSwiping(false);
    };

    const element: any = ref.current;

    element?.addEventListener("click", handleClick);

    return () => {
      element?.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <motion.div
      ref={ref}
      className="card relative flex self-center justify-center overflow-y-hidden flex-col"
      animate={{
        rotateY: isRotate ? -180 : 0,
        height: isRotate ? "min-content" : "580px",
        backgroundColor: isRotate ? "white" : "#fb7d8a",
        width: isRotate ? "600px" : "423px",
      }}
      transition={{
        rotateY: { duration: 0.5 }, // Add this line (adjust the value as needed)
        height: { duration: 0.5 }, // adjust as per requirement
        backgroundColor: { duration: 0.5 }, // adjust as per requirement
        width: { duration: 0.25 }, // adjust as per requirement
      }}>
      {/* // <h3 style={{ color: "black" }}>{characterName}</h3> */}
      <StyledFrontContainer
        style={{
          display: isRotate ? "none" : "flex",
        }}>
        <div className="flex flex-col">
          <div className="flex flex-row w-full my-5 justify-center">
            <div className="flex flex-row w-8/12 mt-1">
              <StyledTaskTitle data-test-id="card-task-title" className="ml-6">
                {title}
              </StyledTaskTitle>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-3xl text-white font-bold mb-0 pb-2">{`$${
                earnings_boost !== null
                  ? energy_assigned * energyPerDollar * earnings_boost
                  : energy_assigned * energyPerDollar
              }`}</div>

              <div className="pb-2">
                <div className="mx-4">
                  {Boolean(
                    energy_assigned > 0 && descriptionText.length > 0,
                  ) && (
                    <div className="flex rounded-[50px] w-[130px] h-[30px] justify-center mx-2 relative bg-white">
                      <div className="flex flex-row self-center gap-1 items-center">
                        {energy_assigned && <div>{energy_assigned}</div>}

                        <Energy />
                      </div>
                      <div>
                        {earnings_boost === 2 ? (
                          <img
                            src={BoostTwo}
                            width="22"
                            height="22"
                            className="absolute -right-2 -top-2"></img>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Causing the issue */}
          <div className="flex flex-col mx-6 my-4 max-h-[360px] max-w-full overflow-y-hidden ">
            {Boolean(energy_assigned > 0 && descriptionText.length > 0) && (
              <div className="flex flex-col  items-start text-white">
                <b>{/* <u>Description </u> */}</b>
                {/* <p className="flex flex-column text-black justify-center bg-primaryButton w-full mt-[7%]"> */}
                <b>Description</b>
              </div>
            )}
            <div className="flex flex-col items-start text-start break-all max-w-[400px] text-white">
              {descriptionText.length > 200
                ? descriptionText.slice(0, 200) + "..."
                : descriptionText}
            </div>
            {videos && (
              <div className="flex flex-col my-4 items-start text-start">
                {videos.map((url, index) => {
                  const video = getVideoService(url);
                  const sourceUrls: any = {
                    YouTube: "https://www.youtube.com/embed/",
                    Vimeo: "https://player.vimeo.com/video/",
                    DTube: "https://emb.d.tube/#!/",
                    BitChute: "https://www.bitchute.com/embed/",
                    Other:
                      "https://vimeo.com/api/oembed.json?url=" + encodeURI(url),
                  };

                  return (
                    <div className="mb-3 flex items-center" key={index}>
                      <VideoPlayer
                        url={video.trimmedUrl}
                        title={`${video.serviceName} video player`}
                        sourceUrl={sourceUrls[video.serviceName]}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* Not causing the css issue */}
        <div className="flex h-full flex-col-reverse">
          <div className="flex flex-row">
            <div className=" flex flex-row ml-5">
              {
                <div
                  style={{
                    display:
                      attachments && attachments.length ? "flex" : "none",
                  }}
                  className=" flex flex-row mx-2 items-center text-white">
                  {
                    <div className="flex mr-2">
                      <AttachIcon
                        svgWidth="16px"
                        svgHeight="16px"
                        sx={{
                          color: "white",
                          verticalAlign: "middle",
                        }}
                      />
                    </div>
                  }
                  {attachments.length}
                </div>
              }
              {
                <div
                  className=" flex flex-row mx-2 items-center text-white ml-[8%]"
                  style={{
                    display: urls && urls.length ? "flex" : "none",
                  }}>
                  <div className="flex mr-2">
                    <UrlIcon
                      svgWidth="16px"
                      svgHeight="16px"
                      sx={{
                        color: "white",
                        verticalAlign: "middle",
                      }}
                    />
                  </div>
                  {urls.length}
                </div>
              }
              {
                <div
                  style={{
                    display: videos && videos.length ? "flex" : "none",
                  }}
                  className=" flex flex-row mx-2 items-center text-white">
                  <VideoLibraryIcon
                    className="flex mr-2"
                    sx={{
                      height: "16px",
                      width: "16px",
                      color: "white",
                      verticalAlign: "middle",
                    }}
                  />
                  {videos.length}
                </div>
              }
            </div>
            <div className="flex flex-row-reverse mr-5 w-full">
              <StyledPurpleButton
                data-test-id="card-task-read-more-button"
                variant="contained"
                className="bg-[#806AD2]"
                onTouchEnd={() => setIsRotate(!isRotate)}
                onClick={() => setIsRotate(!isRotate)}>
                Read More
              </StyledPurpleButton>
            </div>
          </div>
        </div>
      </StyledFrontContainer>

      <StyledBackContainer
        style={{
          display: isRotate ? "flex" : "none",
          transform: "rotateY(180deg)",
        }}
        data-test-id="card-read-more-container">
        <div className="flex flex-row w-full justify-center align-center">
          <StyledAboutTitle className="text-center py-2 bg-[#fb7d8a] text-white ml-0">
            About The {task_type === "mission" ? "Mission" : "Task"}
          </StyledAboutTitle>
        </div>
        <div className="flex flex-col mx-6 mt-5 items-start">
          <b>{/* <u>Description </u> */}</b>
          {/* <p className="flex flex-column justify-center bg-primaryButton w-full mt-[7%]"> */}
          <b>Description</b>
        </div>
        <div className="flex flex-col mx-6 items-start text-start text-black textContainer relative ">
          <HTMLTextRender
            key={task_id}
            htmlString={descriptionText?.replaceAll("<br/>", "\n")}
          />

          {!hideScrollHelpers && (
            <>
              {showScrollUp && (
                <div
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-[#0000004d] rounded-full p-2"
                  onTouchEnd={() => {
                    const specificElement =
                      ref.current.querySelector(".rdw-editor-main");
                    scrollDown(specificElement, -100);
                  }}
                  onClick={() => {
                    const specificElement =
                      ref.current.querySelector(".rdw-editor-main");
                    scrollDown(specificElement, -100);
                  }}>
                  <div className="flex items-center justify-center scale-110 h-3 w-3 rotate-180 opacity-70">
                    <ArrowDownIcon />
                  </div>
                </div>
              )}
              {showScrollDown && (
                <div
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-[#0000004d] rounded-full p-2"
                  onTouchEnd={() => {
                    const specificElement =
                      ref.current.querySelector(".rdw-editor-main");
                    scrollUp(specificElement, 100);
                  }}
                  onClick={() => {
                    const specificElement =
                      ref.current.querySelector(".rdw-editor-main");
                    scrollUp(specificElement, 100);
                  }}>
                  <div className="flex items-center justify-center scale-110 h-3 w-3 opacity-70">
                    <ArrowDownIcon />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {urls && (
          <div className="flex flex-col my-3 mx-6 items-start">
            <div className="my-2 items-start">
              <b>Links</b>
            </div>
            <div className="flex flex-col items-start">
              {urls.map((url, index) => (
                <a
                  key={index}
                  className="text-start pressable"
                  href={sanitizeUrl(url)}
                  target="_blank">
                  {url}
                </a>
              ))}
            </div>
          </div>
        )}
        {videos && (
          <div className="flex flex-col my-3 mx-6 items-start">
            <div className="mt-3 items-start">
              <b>Videos</b>
            </div>
            <div className="flex flex-row my-4 items-start text-start">
              {videos?.map((url, index) => (
                <div className="mb-3 flex items-center" key={index}>
                  <iframe
                    className="rounded-md mr-5"
                    width="200"
                    height="140"
                    src={"https://www.youtube.com/embed/" + url.substring(17)}
                    title="YouTube video player"
                    frameBorder={"0"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="my-3 mx-6 justify-items-start self-start">
          <b>Attachments</b>
        </div>

        <div className=" flex flex-col mx-6 items-start text-black">
          {attachments?.map((file, index) => {
            let fileName = file.substr(file.lastIndexOf("/") + 1);
            return (
              <div
                key={index}
                className="flex rounded-[8px] flex-row my-2 items-center w-full"
                style={{
                  backgroundColor: "lightgray",
                }}>
                <div className="flex mx-2">
                  <AttachIcon
                    svgColor={"black"}
                    svgWidth={"14"}
                    svgHeight={"14"}
                  />
                </div>
                <span className="flex ml-3 my-2">{fileName}</span>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col-reverse grow mb-3">
          <Button
            variant="contained"
            className=" mx-3 bg-[#806AD2] self-center rounded-[50px] w-[132px] h-[40px] text-white"
            onClick={() => setIsRotate(!isRotate)}
            onTouchEnd={() => setIsRotate(!isRotate)}>
            Go Back
          </Button>
        </div>
      </StyledBackContainer>
    </motion.div>
  );

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
};

export default Component;
