import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { PlusIcon } from "../../../assets/svg";
import {
  adminMissionListState,
  adminPocketAssistantSelectionState,
  authState,
  paginationState,
} from "../../../state/atoms";

import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Dayjs } from "dayjs";
import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";
import statusColors from "../../../assets/statusColors";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 40,
  borderRadius: 60,
  boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.1)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 60,
    backgroundColor: "#E0848D",
  },
}));

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 80%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
    padding: 0 2%;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #806ad2;
  border-radius: 50px;
  width: 45px;
  height: 45px;
`;

const ChartBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  gap: 10px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  height: 332px;
  min-width: 540px;
`;

const ChartHeader = styled(Box)`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  /* primary/dark 1 */

  color: #242731;
  display: flex;
  width: 100%;
`;

const YearBox = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* gray/dark 3 */

  color: #72767c;
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 24px;
`;

const LeftBox = styled(Box)`
  flex: 0.68;
  @media screen and (max-width: 760px) {
    flex: 1;
    row-gap: 16px;
    margin-bottom: 16px;
  }
`;

const RightBox = styled(Box)`
  flex: 0.3;
  @media screen and (max-width: 760px) {
    flex: 1;
    padding-bottom: 20px;
  }
`;
const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: space-between;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

const ShadowedContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
`;

const EnergyContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;

  @media only screen and (max-width: 760px) {
    justify-content: flex-end !important;
    .eng-img {
      left: 10px !important;
      height: 120px;
      width: 80px;
    }
    .eng-div {
      width: 70% !important;

      .text-5xl {
        font-size: 24px !important;
      }
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
  width: 265px;
  color: white;
  background: #806ad2;
  padding: 28px 24px;

  > div:last-child {
    font-size: 36px;
    font-weight: 700;
  }
`;

const FilterButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  width: 150px;
  height: 46px;
  padding: 0px 12px;
  color: #000;
  user-select: none;
  /* background-color: #806ad2; */
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? `
          background-color: #fff;
          color: #000;
          `
      : `  color: #000000;`}
`;
const OptionsButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 30px;
  min-width: 88px;

  border: 1px solid #806ad2;
  border-radius: 20px;

  ${({ selected }) =>
    selected
      ? `
          background-color: #806ad2;
          color: white;
          `
      : `  color: #000000;`}

  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
`;

const TaskItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  > div {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const TableHeader = styled.div`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 26px;
  /* or 65% */

  letter-spacing: 0.46px;

  color: #000000;
`;

const TableSearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 11px 17px 11px 21px;
  gap: 10px;

  background: #fff;
  border-radius: 8px;
  width: 262px;
`;

const SearchInput = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* gray/dark 4 */

  color: #000;
  border: unset;
  background: transparent;
  :focus {
    outline: none;
  }
`;

const StyledTable = styled.table`
  margin-top: 32px;
  background: #ffffff;
  width: 100%;
  text-align: left;
  /* gray/200 */

  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledThead = styled.thead`
  background: #f9fafb;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* gray/500 */

  color: #6b7280;
`;

const Styledth = styled.th`
  padding-left: 16px;
`;

const StyledTbody = styled.tbody`
  color: #111827;
`;

const Styledtr = styled.tr``;

const Styledtd = styled.td`
  padding: 16px;
  border-top: 1px solid #e5e7eb;
`;

const StyledName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/900 */

  color: #111827;
`;

const StyledEmail = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/500 */

  color: #6b7280;
`;

const StyledStatus = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  height: 21px;
  width: 83px;

  border-radius: 20px;
  background: ${(props) => props.color || "#6987F0"};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #ffffff;
  margin: auto;
`;

const StyledLink = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* indigo/600 */

  color: #4f46e5;
  cursor: pointer;
  text-align: center;
`;

const TesterModalOverlay = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const TesterModal = styled(Box)`
  background: #ffffff;
  border-radius: 16px;
  width: 70%;
  padding: 24px;
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const TesterModalTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  text-align: center;

  /* $dark-tone-100 */

  color: #1a1a25;
`;

const TesterModalBody = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 34px */

  padding: 32px;

  color: #1a1a25;
`;

const TesterModalFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const TesterModalBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 12px 14px 16px;
  width: 120px;
  height: 24px;
  background: #806ad2;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14.5px;
  line-height: 140%;
  /* or 20px */

  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #a08af2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const NewTesterBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 1px;
  gap: 10px;

  width: 160px;
  height: 32px;
  user-select: none;

  background: #806ad2;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #907ae2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const FormStyle = styled.div`
  justify-content: center;
  align-items: center;
  /* width: 960px; */

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

type Props = {
  setActive: (active: any) => void;
  missions: Array<any>;
  missions_count: any;
  after: any;
  before: any;
  handleNextPage: (after: any) => void;
  handlePrevPage: (before: any) => void;
  isLoading: boolean;
  assistants: Array<any>;
  isAssistantLoading: boolean;
  assignMission: (values: any) => void;
  isAssignMissionLoading: boolean;
  assistantMissions: any;
  isAssistantMissionsLoading: boolean;
};

const AssignBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 1px;
  gap: 10px;

  width: 80px;
  height: 25px;

  background: #806ad2;
  &:hover {
    background: #604ab2;
  }
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  cursor: pointer;
`;
const AssignedBadge = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 1px;
  gap: 10px;

  width: 80px;
  height: 25px;

  background: #f78419;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
`;

const Component = ({
  setActive,
  missions,
  missions_count,
  after,
  before,
  handleNextPage,
  handlePrevPage,
  isLoading,
  assistants,
  isAssistantLoading,
  assignMission,
  isAssignMissionLoading,
  assistantMissions,
  isAssistantMissionsLoading,
}: Props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [adminMissionList, setAdminMissionList] = useRecoilState(
    adminMissionListState,
  );
  const [adminPocketAssistantSelection, setAdminPocketAssistantSelection] =
    useRecoilState(adminPocketAssistantSelectionState);
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [isRatingFilterMenu, setIsRatingFilterMenu] = useState(false);
  const [isSwipeFilterMenu, setIsSwipeFilterMenu] = useState(false);
  const [value, setValue] = useState<DateRange<Dayjs>>([
    adminMissionList?.dates[0] || null,
    adminMissionList?.dates[1] || null,
  ]);
  const [swipes, setSwipes] = useState(adminMissionList?.swipes);
  const [rating, setRating] = useState(adminMissionList?.rating);
  const [status, setStatus] = useState(adminMissionList?.status);

  const [testerModalOpened, setTesterModalOpened] = useState(false);

  // Assistant search box
  const [asbOpen, setAsbOpen] = useState(false);
  const [asbValue, setAsbValue] = useState(null);

  useEffect(() => {
    setAsbValue(
      assistants.find(
        (assist) => assist.assistant_id == adminPocketAssistantSelection.id,
      ) ?? null,
    );
  }, []);

  const swipesList = [
    [null, null],
    [0, 14],
    [15, 29],
    [30, 44],
    [45, null],
  ];

  const Status = styled.div<{ color: string }>`
    background: ${(props) => props.color || "black"};
    border-radius: 20px;
    border: 1px solid transparent;
    display: flex;
    align-items: left;
    justify-content: center;
    width: 6rem;
    height: 22px;
    color: white;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    margin-right: 10px;

    @media only screen and (max-width: 780px) {
      margin-right: 10px;
    }
    padding: 0px 5px;
  `;

  const handleSwipes = (event) => {
    setSwipes(event.target.value);
    setAdminMissionList({
      status: adminMissionList.status,
      rating: adminMissionList.rating,
      swipes: swipesList[event.target.value],
      dates: adminMissionList.dates,
      pageNum: 0,
      after: null,
      before: null,
    });
  };

  const handleRating = (event) => {
    setRating(event.target.value);
    if (event.target.value !== "") {
      setStatus("completed");
      setAdminMissionList({
        status: "completed",
        rating: event.target.value,
        swipes: adminMissionList.swipes,
        dates: adminMissionList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    } else {
      setStatus("");
      setAdminMissionList({
        status: "",
        rating: event.target.value,
        swipes: adminMissionList.swipes,
        dates: adminMissionList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    }
  };

  const nextPage = () => {
    if (after !== null) {
      setAdminMissionList({
        status: adminMissionList.status,
        rating: adminMissionList.rating,
        swipes: adminMissionList.swipes,
        dates: adminMissionList.dates,
        pageNum: adminMissionList.pageNum + 1,
        after: after,
        before: null,
      });
      handleNextPage(after);
    }
  };

  const prevPage = () => {
    if (before !== null) {
      setAdminMissionList({
        status: adminMissionList.status,
        rating: adminMissionList.rating,
        swipes: adminMissionList.swipes,
        dates: adminMissionList.dates,
        pageNum: adminMissionList.pageNum - 1,
        after: null,
        before: before,
      });
      handlePrevPage(before);
    }
  };

  const handleCreateClick = function () {
    navigate("/missions/create", { state: { isNew: true } });
  };

  return (
    <StyledContainer className="bg-mainBg">
      <StyledBoardWrapper>
        <div
          style={{ marginTop: "0", width: "100%" }}
          data-test-id="admin-missions-main-container">
          <div className="flex flex-col">
            <div className="flex flex-row justify-start items-center">
              <TableHeader className="pr-2">Missions</TableHeader>
              <AddButton
                className="hover:cursor-pointer w-[55] h-[55] md:w-[45] md:h-[45] hover:scale-[1.1] ease-in-out duration-300"
                onClick={() => handleCreateClick()}>
                <PlusIcon className="w-[20] h-[20] md:w-[18] md:h-[18]" />
              </AddButton>
            </div>
            <div
              style={{ display: "flex", gap: 6, alignItems: "center" }}></div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <StyledTable>
              <StyledThead>
                <Styledtr>
                  <Styledth>Title</Styledth>
                  <Styledth className="text-center pl-0">Status</Styledth>
                  <Styledth></Styledth>
                </Styledtr>
              </StyledThead>

              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          display: "flex",
                        }}>
                        <CircularProgress
                          color="inherit"
                          size="6rem"
                          sx={{ margin: "auto" }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <StyledTbody>
                  {missions.length > 0 ? (
                    missions.map((item, key) => (
                      <Styledtr key={key}>
                        {/* <Styledtd>
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              value=""
                              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </Styledtd> */}
                        <Styledtd>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <StyledName>{item.title}</StyledName>
                            {/* <StyledEmail>{item.short_address}</StyledEmail> */}
                          </div>
                        </Styledtd>
                        <Styledtd>
                          <div className="flex items-center justify-center">
                            <Status color={statusColors?.[item.status]}>
                              {allStatuses?.[item.status] || item.status}
                            </Status>
                            {/* <StyledEmail>{item.email}</StyledEmail> */}
                          </div>
                        </Styledtd>

                        <Styledtd>
                          {item.mission_id && (
                            <StyledLink
                              onClick={() => {
                                navigate(`/missions/view/${item.mission_id}`);
                              }}>
                              View Mission
                            </StyledLink>
                          )}
                        </Styledtd>

                        {/* <Styledtd style={{ textAlign: "center" }}>
                            <StyledStatus
                              color={colors[filters.indexOf(item.status)]}>
                              {filter_text[filters.indexOf(item.status)]
                                ? filter_text[filters.indexOf(item.status)]
                                : "Draft"}
                            </StyledStatus>
                          </Styledtd>
                          <Styledtd
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <Rating
                              name="rating"
                              size="medium"
                              value={item.rating}
                              readOnly
                            />
                            <Rating
                              className="text-black"
                              name="energy"
                              size="small"
                              icon={
                                <EnergyBar style={{ width: "1.125rem" }} filled />
                              }
                              emptyIcon={
                                <EnergyBar style={{ width: "1.125rem" }} />
                              }
                              value={item.energy}
                              readOnly
                            />
                          </Styledtd>
                          <Styledtd style={{ textAlign: "center" }}>
                            {item.status_changed ? (
                              <>
                                {new Date(
                                  item.status_changed,
                                ).toLocaleDateString()}
                                ,
                                {new Date(
                                  item.status_changed,
                                ).toLocaleTimeString()}
                              </>
                            ) : null}
                          </Styledtd>
                          <Styledtd>
                            <StyledLink
                              onClick={() => {
                                navigate(`/tasks/view/${item.task_id}`);
                              }}>
                              View Task
                            </StyledLink>
                          </Styledtd> */}
                      </Styledtr>
                    ))
                  ) : (
                    <td colSpan={10}>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          display: "flex",
                        }}
                        className="flex items-center justify-center m-auto text-3xl select-none">
                        No Mission
                      </div>
                    </td>
                  )}
                </StyledTbody>
              )}
            </StyledTable>
          </div>
          <div className="flex h-max items-center justify-end md:justify-end mt-5">
            <span className="mx-2 text-gray-500 font-medium">
              Total Count:{" "}
              {isLoading ? (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  sx={{ margin: "auto" }}
                />
              ) : missions_count ? (
                missions_count
              ) : (
                0
              )}
            </span>
            <span
              onClick={() => (before ? prevPage() : null)}
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                before === null ? "opacity-50 cursor-not-allowed" : ""
              }`}>
              Previous
            </span>
            <span className="mx-2 text-gray-500 font-medium">
              {adminMissionList.pageNum + 1}
            </span>
            <span
              onClick={() => (after ? nextPage() : null)}
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                after === null ? "opacity-50 cursor-not-allowed" : ""
              }`}>
              Next
            </span>
          </div>
        </div>
        <div
          style={{ marginTop: "75px", width: "100%" }}
          data-test-id="admin-missions-assistants-filter">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TableHeader>Assistants</TableHeader>
            <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <Autocomplete
                sx={{ width: 300, cursor: "pointer" }}
                open={asbOpen}
                onOpen={() => {
                  setAsbOpen(true);
                }}
                onClose={() => {
                  setAsbOpen(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.email === value.email
                }
                value={asbValue}
                onChange={(event, newValue) => {
                  setAsbValue(newValue);
                  setAdminPocketAssistantSelection({
                    email: adminPocketAssistantSelection.email,
                    id: newValue?.assistant_id ?? "",
                  });
                }}
                getOptionLabel={(option) => option.email}
                options={assistants}
                loading={isAssistantLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assistant Email"
                    // onChange={(e) => {
                    // setAdminPocketAssistantSelection({ email: e.target.value, id: adminPocketAssistantSelection.id });
                    // }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isAssistantLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{
              overflowX: "auto",
              marginBottom:
                adminPocketAssistantSelection.id != "" ? "0px" : "400px",
            }}>
            <StyledTable>
              <StyledThead>
                <Styledtr>
                  {/* <Styledth></Styledth> */}
                  <Styledth>CONTACT</Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    RATING
                  </Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    TASK COMPLETED
                  </Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    REJECTIONS
                  </Styledth>

                  <Styledth></Styledth>
                </Styledtr>
              </StyledThead>
              {isAssistantLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          display: "flex",
                        }}>
                        <CircularProgress
                          color="inherit"
                          size="6rem"
                          sx={{ margin: "auto" }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <StyledTbody>
                  {asbValue ? (
                    <Styledtr>
                      <Styledtd>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <StyledName>{asbValue.user_name}</StyledName>
                          <StyledEmail>{asbValue.email}</StyledEmail>
                        </div>
                      </Styledtd>
                      <Styledtd style={{ textAlign: "center" }}>
                        {asbValue.rating}
                      </Styledtd>
                      <Styledtd style={{ textAlign: "center" }}>
                        {asbValue.competed_tasks}
                      </Styledtd>
                      <Styledtd style={{ textAlign: "center" }}>
                        {asbValue.rejections}
                      </Styledtd>

                      <Styledtd>
                        <StyledLink
                          onClick={() => {
                            navigate(
                              `/accounts/assistant/${asbValue.assistant_id}`,
                            );
                            setActive(1);
                          }}>
                          View Profile
                        </StyledLink>
                      </Styledtd>
                    </Styledtr>
                  ) : (
                    <></>
                  )}
                </StyledTbody>
              )}
            </StyledTable>
          </div>
        </div>
        {adminPocketAssistantSelection.id != "" && (
          <div style={{ marginTop: "75px", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <TableHeader>Missions Assigned To Assistant</TableHeader>
              <div
                style={{ display: "flex", gap: 6, alignItems: "center" }}></div>
            </div>
            <div style={{ overflowX: "auto" }}>
              <StyledTable>
                <StyledThead>
                  <Styledtr>
                    <Styledth>Title</Styledth>
                    <Styledth style={{ paddingLeft: "36px" }}>Status</Styledth>
                    <Styledth></Styledth>
                  </Styledtr>
                </StyledThead>

                {isAssistantMissionsLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            height: "400px",
                            width: "100%",
                            display: "flex",
                          }}>
                          <CircularProgress
                            color="inherit"
                            size="6rem"
                            sx={{ margin: "auto" }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <StyledTbody>
                    {assistantMissions.length > 0 ? (
                      assistantMissions.map((item, key) => (
                        <Styledtr key={key}>
                          <Styledtd>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}>
                              <StyledName>{item.title}</StyledName>
                            </div>
                          </Styledtd>
                          <Styledtd>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}>
                              <Status color={statusColors?.[item.status]}>
                                {allStatuses?.[item.status] || item.status}
                              </Status>
                              {/* <StyledEmail>{item.email}</StyledEmail> */}
                            </div>
                          </Styledtd>
                          <Styledtd>
                            <StyledLink
                              onClick={() => {
                                navigate(`/missions/view/${item.mission_id}`);
                              }}>
                              View Mission
                            </StyledLink>
                          </Styledtd>
                        </Styledtr>
                      ))
                    ) : (
                      <td colSpan={10}>
                        <div
                          style={{
                            height: "400px",
                            width: "100%",
                            display: "flex",
                          }}
                          className="flex items-center justify-center m-auto text-3xl select-none">
                          No Mission
                        </div>
                      </td>
                    )}
                  </StyledTbody>
                )}
              </StyledTable>
            </div>
            <div className="flex h-max items-center justify-end md:justify-end mt-5">
              <span className="mx-2 text-gray-500 font-medium">
                Total Count:{" "}
                {isAssistantMissionsLoading ? (
                  <CircularProgress
                    color="inherit"
                    size="1rem"
                    sx={{ margin: "auto" }}
                  />
                ) : (
                  assistantMissions.length
                )}
              </span>
              <span
                onClick={() => (before ? prevPage() : null)}
                className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                  before === null ? "opacity-50 cursor-not-allowed" : ""
                }`}>
                Previous
              </span>
              <span className="mx-2 text-gray-500 font-medium">
                {adminMissionList.pageNum + 1}
              </span>
              <span
                onClick={() => (after ? nextPage() : null)}
                className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                  after === null ? "opacity-50 cursor-not-allowed" : ""
                }`}>
                Next
              </span>
            </div>
          </div>
        )}
      </StyledBoardWrapper>
    </StyledContainer>
  );
};

export default Component;
