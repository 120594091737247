import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";
import {
  CoinbaseLogo,
  MetaMaskLogo,
  WalletConnectLogo,
} from "../../../assets/svg";
import {
  authenticateUserWithSignedMessage,
  getNonceForUser,
} from "../../../services/users";
import { authState, globalState, initialAuthState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

import jwt_decode from "jwt-decode";
import ContractLogo from "../../../assets/images/contract.svg";

import { Button } from "@mui/material";
const Container = styled.div`
  height: calc(100% - 64px);
  padding-bottom: 8%;
`;

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  width: 14rem;

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.p`
  font-size: 40px;
  letter-spacing: 0.4px;
  margin-bottom: 60px;
`;

const StyledWalletOptions = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 350px;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;
const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;
  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;
  &:hover {
    background: #e8e8e8;
  }
`;

const Component = () => {
  // const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);

  // const [address, setAddress] = useState("");
  const [auth, setAuth] = useRecoilState(authState);
  const [global, setGlobal] = useRecoilState(globalState);
  // const disconnectWallet = useDisconnect();
  // const connectWithMetamask = useMetamask();
  // const connectWithWalletConnect = useWalletConnect();
  // const connectWithCoinbaseWallet = useCoinbaseWallet();
  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { data, isSuccess, signMessage } = useSignMessage();
  const connectorList = {
    metaMask: {
      name: "MetaMask",
      desc: "Connect to your Metamask Wallet",
      icon: <MetaMaskLogo />,
    },
    walletConnect: {
      name: "WalletConnect",
      desc: "Scan WalletConnect QR to connect",
      icon: <WalletConnectLogo />,
    },
    coinbaseWallet: {
      name: "Coinbase",
      desc: "Connect to your Coinbase Wallet",
      icon: <CoinbaseLogo />,
    },
  };
  const connectMetaMaskWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[0];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithMetamask, 1000);
  };

  const connectWalletConnectWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[1];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithWalletConnect, 1000);
  };

  const connectCoinbasetWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[2];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithWalletConnect, 1000);
  };

  const connectWallet = (connector) => {
    disconnect();
    connect({ connector });
  };

  const LoginUser = async () => {
    try {
      let result = await authenticateUserWithSignedMessage(address, data);
      let decoded = jwt_decode(result.token);

      if (decoded?.payload?.role === "admin") {
        setAuth({
          role: "admin",
          accessToken: result.token,
          walletAddress: address,
          user_id: result.user_id,
          user_name: result.user_name,
        });
        localStorage.setItem("user_id", result?.user_id);
        localStorage.setItem("accessToken", result?.token);
        localStorage.setItem("walletAddress", address);
        localStorage.setItem("role", "admin");
      } else {
        setAuth({
          role: "owner",
          accessToken: result.token,
          walletAddress: address,
          user_id: result.user_id,
          user_name: result.user_name,
        });
        localStorage.setItem("user_id", result?.user_id);
        localStorage.setItem("accessToken", result?.token);
        localStorage.setItem("walletAddress", address);
        localStorage.setItem("role", "owner");
      }
      setSnackbar({
        title: "Success",
        content: "Logged in!",
        type: "success",
      });
      if (global.redirect_url) {
        setGlobal({
          ...global,
          redirect_url: "",
        });
        navigate(global.redirect_url);
      } else navigate("/");
    } catch (e) {
      disconnect();
      setAuth(initialAuthState);

      setSnackbar({
        title: "Error",
        content:
          e?.code && e.code === 4001
            ? "Message signature denied"
            : "Something went wrong.",
        type: "error",
      });
      navigate("/");
      console.log("error: ", e);
    }
  };

  const {
    mutate: mutateLoginOwner,
    isLoading,
    error,
  } = useMutation(
    ["loginOwner", address],
    async () => {
      if (isConnected && address) {
        let result = await getNonceForUser(address);
        signMessage({
          message: `Please, sign this message to login to the PA app (${result.nonce}).`,
        });
      }
      // }
      // console.log("singedMessage", singedMessage);
      // const signedMessage = await signer?.signMessage(
      //   `Please, sign this message to login to the PA app (${result.nonce}).`,
      // );
      // console.log(signedMessage);
      // result = await authenticateUserWithSignedMessage(address, signedMessage);

      // setAuth({
      //   role: "owner",
      //   accessToken: result.token,
      //   walletAddress: address,
      //   user_id: result.user_id,
      //   user_name: result.user_name,
      // });
      // localStorage.setItem("user_id", result?.user_id);
      // localStorage.setItem("accessToken", result?.token);
      // localStorage.setItem("walletAddress", address);
      // localStorage.setItem("role", "owner");
      // return result;
    },
    {
      onSuccess: (resp) => {
        // setSnackbar({
        //   title: "Success",
        //   content: "Logged in!",
        //   type: "success",
        // });
        // navigate("/");
      },
      onError: (e, variables) => {
        // disconnectWallet();
        disconnect();
        setAuth(initialAuthState);

        setSnackbar({
          title: "Error",
          content:
            e?.code && e.code === 4001
              ? "Message signature denied"
              : "Something went wrong.",
          type: "error",
        });
        navigate("/");
      },
    },
  );

  // useEffect(() => {
  //   if (
  //     address &&
  //     // signer &&
  //     isConnected &&
  //     !isLoading &&
  //     !auth.walletAddress &&
  //     localStorage?.getItem("walletAddress") !== address
  //   ) {
  //     mutateLoginOwner();
  //   }
  // }, [address, isConnected, auth]);

  useEffect(() => {
    // console.log("SignMessage", isSuccess);
    if (!isSuccess) return;
    console.log("Singed message success");
    LoginUser();
  }, [isSuccess]);

  return (
    <Container className="bg-mainBg">
      {!isConnected ? (
        <>
          <StyledContainer>
            <StyledTitle className="text-center font-gilroy font-bold">
              Connect your wallet
            </StyledTitle>
            <StyledWalletOptions className="bg-white">
              {connectors.map(
                (connector) =>
                  connector.ready && (
                    <button
                      data-test-id={`login-${connector.name.toLowerCase()}-button`}
                      title={`Login with ${connector.name}`}
                      disabled={!connector.ready}
                      key={connector.id}
                      className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer disabled:opacity-80 disabled:cursor-default"
                      onClick={() => connectWallet(connector)}>
                      {connectorList[connector.id].icon}
                      <div className="font-inter font-bold text-2xl mt-4">
                        {/* {connectorList[index].name} */}
                        {connector.name}
                      </div>
                      <div className="font-inter text-sm">
                        {connectorList[connector.id].desc}
                      </div>
                    </button>
                  ),
              )}
              {/* <div
                className="flex md:hidden flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer"
                onClick={openModal}>
                <div className="font-inter font-bold text-2xl mt-4">
                  Use Address
                </div>
              </div> */}
              {/* <div
                className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer"
                onClick={connectWalletConnectWallet}>
                <WalletConnectLogo />
                <div className="font-inter font-bold text-2xl mt-4">
                  WalletConnect
                </div>
                <div className="font-inter text-sm">
                  Scan WalletConnect QR to connect
                </div>
              </div>
              <div
                className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer"
                onClick={connectCoinbasetWallet}>
                <CoinbaseLogo />
                <div className="font-inter font-bold text-2xl mt-4">
                  Coinbase
                </div>
                <div className="font-inter text-sm">
                  Connect to your Coinbase wallet
                </div>
              </div>
               */}
            </StyledWalletOptions>
          </StyledContainer>
        </>
      ) : (
        <>
          <StyledContainer className="">
            <StyledTitle className="text-center font-gilroy font-bold mb-0 leading-[0.7]">
              Wallet Connected!
            </StyledTitle>
            <StyledTitle className="text-center font-borderGray font-gilroy font-bold text-xl mt-0 mb-[20px]">
              Just one more step..
            </StyledTitle>

            <StyledWalletOptions className="bg-white">
              <div
                className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer"
                onClick={mutateLoginOwner}>
                <div>
                  <img src={ContractLogo}></img>
                </div>
                <div className="w-[90%] text-center flex flex-col">
                  <div className="font-inter font-bold text-2xl mt-4">
                    Sign in to Ethereum
                  </div>
                  <div className="font-inter text-sm mb-1 whitespace-normal">
                    Before you access the app, we'd like to verify if you're the
                    owner of this wallet.
                  </div>
                  <div className="font-inter text-sm mb-5 whitespace-normal">
                    Kindly sign the message in your wallet to continue
                  </div>
                  <div>
                    <TaskPageButton
                      data-test-id="login-confirm-sign"
                      className="ml-0">
                      Sign in
                    </TaskPageButton>
                  </div>
                </div>
              </div>
            </StyledWalletOptions>
          </StyledContainer>
        </>
      )}
      {/* <Modal
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={modal}
        onClose={closeModal}>
        <div style={{ maxWidth: 450 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Enter your address
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              value={address}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          <ConfirmButton variant="contained" onClick={handleConfirm} fullWidth>
            Login
          </ConfirmButton>
          <CancelButton variant="contained" onClick={closeModal} fullWidth>
            Cancel
          </CancelButton>
        </div>
      </Modal> */}
    </Container>
  );
  // function openModal() {
  //   setModal(true);
  // }
  // function closeModal() {
  //   setModal(false);
  // }
  // function handleConfirm() {
  //   mutateLoginOwner(address);
  // }
  // function handleChange(value) {
  //   setAddress(value);
  // }
};

export default Component;
