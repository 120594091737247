import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { PlusIcon } from "../../../assets/svg";
import { Qualification, qualificationsListState } from "../../../state/atoms";

interface Props {
  qualificationsList: Qualification[];
  qualificationsLoading: boolean;
  openCreateQualificationsModal: () => void;
  openEditQualificationModal: (id: string) => void;
  qualificationsBefore: any;
  qualificationsAfter: any;
  openDeleteQualificationModal: (id: string) => void;
}

export default function AdminQualificationsTemplate({
  qualificationsList,
  qualificationsLoading,
  openCreateQualificationsModal,
  openEditQualificationModal,
  qualificationsAfter,
  qualificationsBefore,
  openDeleteQualificationModal,
}: Props) {
  const [qualificationState, setQualificationState] = useRecoilState(
    qualificationsListState,
  );

  const handleNextPage = () => {
    if (qualificationsAfter !== null) {
      setQualificationState({
        filters: qualificationState.filters,
        size: qualificationState.size,
        after: qualificationsAfter,
        before: null,
        pageNum: qualificationState.pageNum + 1,
      });
    }
  };
  const handlePreviousPage = () => {
    if (qualificationsBefore !== null) {
      setQualificationState({
        filters: qualificationState.filters,
        size: qualificationState.size,
        after: null,
        before: qualificationsBefore,
        pageNum: qualificationState.pageNum - 1,
      });
    }
  };

  const handleChangeFilter = (event) => {
    setQualificationState({
      filters: {
        is_global: event.target.value,
      },
      size: qualificationState.size,
      after: null,
      before: null,
      pageNum: 1,
    });
  };

  return (
    <div className="flex h-full justify-center odMax:w-full w-[75%] m-auto">
      <div
        className="min-h-[800px]  h-[80%] flex justify-start items-start flex-col mx-0 mt-9 mb-7 w-full odMax:py-0 odMax:px-[2%]"
        data-test-id="admin-qualifications-main-container">
        <div className="mt-0 w-full">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center justify-center">
                <div className="pr-2 font-gilroy not-italic font-extrabold text-[42px] tracking-[0.46px] leading-[26px] text-black">
                  Qualifications
                </div>
                <div
                  onClick={openCreateQualificationsModal}
                  className="flex items-center justify-center bg-[#806ad2] rounded-[50px] hover:cursor-pointer w-[55] h-[55] md:w-[45] md:h-[45] hover:scale-[1.1] ease-in-out duration-300">
                  <PlusIcon className="w-[20] h-[20] md:w-[18] md:h-[18]" />
                </div>
              </div>
              <div
                className="flex gap-6 items-center"
                style={{ display: "flex", gap: 6, alignItems: "center" }}>
                <div className="relative">
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-select-small">Status</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={qualificationState.filters.is_global}
                      label="Status"
                      onChange={handleChangeFilter}>
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="public">Public</MenuItem>
                      <MenuItem value="private">Private</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", gap: 6, alignItems: "center" }}></div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <table className="mt-[32px] bg-[#ffffff] w-full text-left border-adminAssistantBorder rounded-lg">
              <thead className="bg-[#f9fafb] h-[40px] font-inter not-italic text-[12px] leading-[16px] tracking-tighter uppercase color text-adminTableHead">
                <tr>
                  <th className="pl-[16px]">Name</th>
                  <th className="pl-0 text-center">PUBLIC/PRIVATE</th>
                  <th className="pl-0 text-center">Date Added</th>
                  <th className="pl-0 text-center">Last Modified</th>
                  <th></th>
                </tr>
              </thead>
              {qualificationsLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <div className="h-[400px] w-full flex">
                        <CircularProgress
                          color="inherit"
                          size="6rem"
                          sx={{ margin: "auto" }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : qualificationsList ? (
                <tbody className="text-adminTableBody">
                  {qualificationsList.length > 0 ? (
                    qualificationsList
                      .sort((a, b) => b.date_added - a.date_added)
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                            {item.name}
                          </td>
                          <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                            {item.is_global ? "Public" : "Private"}
                          </td>
                          <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                            {new Date(item.date_added).toLocaleDateString()}
                          </td>
                          <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder text-center">
                            {item.last_modified
                              ? new Date(
                                  item.last_modified,
                                ).toLocaleDateString()
                              : ""}
                          </td>

                          {/* TODO: Make this hoverable icons */}

                          <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder pr-1 flex flex-row space-x-4 items-center justify-center">
                            {item.name !== "General" && (
                              <>
                                <div
                                  className="text-[14px] cursor-pointer not-italic font-inter font-normal leading-[20px] tracking-[0.15px] text-[#4f46e5] text-center"
                                  onClick={() => {
                                    openEditQualificationModal(item.id);
                                  }}>
                                  Edit
                                </div>
                                <div
                                  className="text-[14px] cursor-pointer not-italic font-inter font-normal leading-[20px] tracking-[0.15px] text-[#4f46e5] text-center"
                                  onClick={() => {
                                    openDeleteQualificationModal(item.id);
                                  }}>
                                  Delete
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <div className="flex items-center justify-center m-auto text-3xl select-none h-[400px] w-full">
                          No Qualifications
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <></>
              )}
            </table>
          </div>
          <div className="flex h-max items-center justify-end md:justify-end mt-5">
            <span
              onClick={() =>
                qualificationsBefore ? handlePreviousPage() : null
              }
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                qualificationsBefore === null
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}>
              Previous
            </span>
            <span className="mx-2 text-gray-500 font-medium">
              {qualificationState.pageNum}
            </span>
            <span
              onClick={() => (qualificationsAfter ? handleNextPage() : null)}
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                qualificationsAfter === null
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}>
              Next
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
