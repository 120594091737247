export default () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 13H16.5V11.5C16.5 10.2574 15.4926 9.25 14.25 9.25C13.5333 9.25 12.8949 9.5851 12.4828 10.1072M12.75 13H5.25M12.75 13V11.5C12.75 11.0078 12.6552 10.5378 12.4828 10.1072M5.25 13H1.5V11.5C1.5 10.2574 2.50736 9.25 3.75 9.25C4.46671 9.25 5.10514 9.5851 5.51718 10.1072M5.25 13V11.5C5.25 11.0078 5.34482 10.5378 5.51718 10.1072M5.51718 10.1072C6.07013 8.72577 7.42111 7.75 9 7.75C10.5789 7.75 11.9299 8.72577 12.4828 10.1072M11.25 3.25C11.25 4.49264 10.2426 5.5 9 5.5C7.75736 5.5 6.75 4.49264 6.75 3.25C6.75 2.00736 7.75736 1 9 1C10.2426 1 11.25 2.00736 11.25 3.25ZM15.75 5.5C15.75 6.32843 15.0784 7 14.25 7C13.4216 7 12.75 6.32843 12.75 5.5C12.75 4.67157 13.4216 4 14.25 4C15.0784 4 15.75 4.67157 15.75 5.5ZM5.25 5.5C5.25 6.32843 4.57843 7 3.75 7C2.92157 7 2.25 6.32843 2.25 5.5C2.25 4.67157 2.92157 4 3.75 4C4.57843 4 5.25 4.67157 5.25 5.5Z"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
