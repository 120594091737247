import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  formatCommentDate,
  getLanguageNameById,
  isHTML,
  translationSources,
} from "../../../utils/index";
import Modal from "../../organisms/Modal";

import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses, { paTaskViewStatuses } from "../../../assets/paStatuses";
import {
  CheckIcon,
  CloseIcon,
  Energy,
  LoadingIcon,
  MinusCircleIcon,
  PaperClipIcon,
  PlusCircleIcon,
  SubmitIcon,
} from "../../../assets/svg";
import {
  addTaskComment,
  getTaskById,
  getTranslation,
  getTranslationCheck,
  setTaskStatus,
  updateTask,
} from "../../../services/tasks";
import {
  authState,
  defaultDeliveryState,
  taskState,
} from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import FileUpload from "../../organisms/FileUpload";
import HTMLTextRender from "../../organisms/HTMLTextRender";
import { CommentSchema, FormSchema } from "./validation";

import statusColors from "../../../assets/statusColors";
import {
  CONDITION_BitChute,
  CONDITION_DTube,
  REGEX_Vimeo,
  REGEX_Youtube,
} from "../../../utils/regex";
import useUserFetch from "../../hooks/useUserFetch";
import TextEditor from "../../organisms/TextEditor";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const ConfirmButton = styled(Button)`
  background-color: #6f5acd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;
  &:hover {
    background-color: #e8735a;
  }
`;

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  @media (min-width: 768px) {
    margin-left: 1rem;
    width: 12rem;
  }

  &:hover {
    background-color: #806ad2;
    cursor: pointer;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`;

const CancelButton = styled(Button)`
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;
  &:hover {
    background: #e8e8e8;
  }
`;

const StyledContainer = styled.div`
  margin-top: 3%;
  margin-bottom: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-width: 1100px;
  width: 100%;
  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
  .shadowed {
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

const TaskHeader = styled(Box)`
  justify-content: center;
  align-items: center;
  /* height: 50px; */
  padding-top: 16px;
  padding-bottom: 16px;
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 1080px;
  @media only screen and (max-width: 1080px) {
    width: 100% !important;
  }
`;

const FormStyle = styled.div`
  justify-content: center;
  align-items: center;
  width: 1080px;
  @media only screen and (max-width: 1080px) {
    width: 100% !important;
  }
`;

const StyledPurpleButton = styled(Button)`
  border-radius: 50px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  border: none;
  text-transform: none;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px 30px;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  border-radius: 50px;
`;

const URLContainer = styled.div`
  .MuiFilledInput-input {
    padding-top: 10px !important;
  }
`;

const CommentsContainer = styled.div`
  width: 1080px;
  @media only screen and (max-width: 1080px) {
    width: 100% !important;
  }
`;

const HeaderStatus = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  > div:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #c5c5c5;
  }
  > div:last-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }
`;

const Status = styled.div<{ color: string }>`
  background: ${(props) => props.color || "black"};
  border-radius: 20px;
  border: 1px solid transparent;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 6rem;
  height: 22px;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  margin-right: 10px;

  @media only screen and (max-width: 780px) {
    margin-right: 10px;
  }
  padding: 0px 5px;
`;

const Component = () => {
  const [show, setShow] = useState(false);
  const [auth, setAuth] = useRecoilState(authState);
  const [rejectModal, setRejectModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [deliverable, setDeliverable] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);

  //Submitting State - Waiting for response from API
  const [isSaving, setIsSaving] = useState(false);
  const [isTaskSubmitting, setIsTaskSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  //End State
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isCommentAdded, setIsCommentAdded] = useState(false);

  //State that checks what button is triggered
  const [isSaveTriggered, setIsSaveTriggered] = useState(false);
  const [isRejectTriggered, setIsRejectTriggered] = useState(false);
  const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);
  const [translatedComment, setTranslatedComment] = useState("");
  const [translatedDescription, setTranslatedDescription] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const [isCompletionTranslated, setIsCompletionTranslated] = useState(false);
  const [loadingTranslationIndexes, setLoadingTranslationIndexes] = useState<
    any[]
  >([]);

  const [hasVideoError, setHasVideoError] = useState(false);
  const [hasUrlError, setHasUrlError] = useState(false);

  // const { setFieldValue, values } = useFormikContext()
  const navigate = useNavigate();
  const { taskId } = useParams();
  const setSnackbar = useContext(SnackbarContext);

  let statusValues: Array<any> = [];
  const [activeTask, setActiveTask] = useRecoilState(taskState);

  const handleShow = () => {
    show ? setShow(false) : setShow(true);
  };

  /*
  useEffect(() => {
  }, [submitAfterUpload])
  */

  let id = activeTask.task_id;

  useEffect(() => {
    setTaskLoading(true);
  }, []);

  const {
    user: userData,
    isLoading: isUserDataLoading,
    refetch: isUserDataRefetch,
  } = useUserFetch(auth, "assistant", auth.user_id);


  const {
    mutate: rejectTaskSubmission,
    isLoading: isTaskBeingRejected,
    error: taskRejectionError,
  } = useMutation(
    "rejectTaskSubmission",
    () => {
      return setTaskStatus(auth.accessToken, {
        id,
        status: "task_cancelled",
      });
    },
    {
      onSuccess: (resp) => {
        if (resp.success) {
          setIsRejecting(false);
          setIsRejected(true);
          setSnackbar({
            title: "Success",
            content: "Success. You are no longer assigned to this task",
            type: "success",
          });

          setTimeout(() => {
            navigate("/");
          }, 2000);

          // refetch(); Do not refecth because the state will be in-progress which will change what is being shown on the page
        } else {
          setSnackbar({
            title: "Alert!",
            content: "Could not remove you from the task",
            type: "warning",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    data: taskData,
    isLoading: isloadingTask,
    refetch: taskRefetch,
  } = useQuery(
    ["fetchingTask", auth.accessToken, taskId],
    () => {
      if (auth.accessToken && taskId) {
        return getTaskById(auth.accessToken, taskId, true);
      }
    },
    {
      onSuccess: (resp) => {
        if (
          !resp.task.delivery?.attachments.length &&
          !resp.task.delivery?.description &&
          !resp.task.delivery?.urls.length &&
          !resp.task.delivery?.videos.length
        ) {
          // Adding empty delivery object if delivery is empty
          resp.task.delivery = Object.assign({}, defaultDeliveryState);
        }
        // Adding empty rows for the empty input fields to be shown
        resp.task.delivery.urls = resp.task.delivery.urls.concat("");
        resp.task.delivery.videos = resp.task.delivery.videos.concat("");

        setActiveTask(resp.task);
        setTaskLoading(false);
      },
      onError: () => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        navigate("/");
      },
    },
  );

  const {
    mutate: mutateSubmitComment,
    isLoading: isSubmittingComment,
    error,
  } = useMutation(
    ["submitComment", auth.accessToken, taskId],
    (comment: any) => {
      return addTaskComment(auth.accessToken, comment, taskId);
    },
    {
      onSuccess: (resp, variables) => {
        setIsCommentAdded(true);
        setSnackbar({
          title: "Success",
          content: "Comment posted.",
          type: "success",
        });
        taskRefetch();
        setTimeout(() => {
          setIsCommentAdded(false);
        }, 2000);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateChangeTaskState,
    isLoading: changeTaskLoading,
    error: changeTaskError,
  } = useMutation(
    "changePATaskState",
    async (status: string) => {
      if (["clarification_ended"].includes(status)) {
        return setTaskStatus(auth.accessToken, {
          id: taskId,
          status,
        });
      }
    },
    {
      onSuccess: (resp: any) => {
        if (resp.success) {
          setSnackbar({
            title: "Success",
            content:
              "The task is now assigned to you and you can start working on it.",
            type: "success",
          });
        } else {
          setSnackbar({
            title: "Alert!",
            content:
              "We are having some technical issues, please try again later.",
            type: "error",
          });
        }
        navigate("/dashboard");
      },
      onError: (e: any, variables: any) => {
        console.log("error", e);
        let content = e.message
          ? e.message
          : "We are having some technical issues, please try again later.";
        setSnackbar({
          title: "Error",
          content: content,
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleTranslateInputComment,
    isLoading: isInputCommentTranslationLoading,
  } = useMutation(
    "translateComment",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.comment,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp) => {
        setTranslatedComment(resp.message);
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const {
    mutate: handleDescriptionTranslation,
    isLoading: descriptionTranslationLoading,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      const isPreferred = true;
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
        isPreferred,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setActiveTask((prev) => ({ ...prev, description: resp.message }));
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: deliverTask,
    isLoading: isUpdatingTask,
    error: updateTaskError,
  } = useMutation(
    ["updateTask", auth.accessToken, taskId],
    async ({ task, doSubmit, toDashboard }: any) => {
      let result = await updateTask(auth.accessToken, task, taskId);
      if (!doSubmit) {
        return result;
      }
      return setTaskStatus(auth.accessToken, {
        id: taskId,
        status: "submitted",
      });
    },
    {
      onSuccess: (resp, variables) => {
        if (variables.doSubmit) {
          ChangeButtonState();
        }

        setSnackbar({
          title: "Success",
          content: variables.doSubmit
            ? "Task successfully submitted for review."
            : "Task successfully updated!",
          type: "success",
        });

        // Need to update the state with the values that were submitted
        // Shallow copying the object from state so as to not be mutating it
        let updatedTask = Object.assign({}, activeTask);
        updatedTask.delivery = variables.task.delivery; // This updates the values
        setActiveTask(updatedTask);

        setTimeout(() => {
          if (variables.toDashboard) {
            navigate("/dashboard");
          }
        }, 2000);
        // Need to re-add empty rows since they have been clened up during submission
        // Dont need this anymore because in the update method removal of blank last item is done in a copied object and does not affect the state object that was copied above
        // updatedTask.delivery.urls = updatedTask.delivery.urls.concat("");
        // updatedTask.delivery.videos = updatedTask.delivery.videos.concat("");
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleTranslateExistingComment,
    isLoading: translateExistingCommentLoading,
  } = useMutation(
    "translateCommentInput",
    (values: any) => {
      const isPreferred = true;
      setLoadingTranslationIndexes((prevState) => [...prevState, values.index]);
      return getTranslation(
        auth.accessToken,
        values.text,
        values.source,
        isPreferred,
      );
    },
    {
      onSuccess: (data, variables) => {
        setActiveTask((prevState) => {
          const updatedComments = [...prevState.comments];

          // Find the index of the comment to be updated
          const commentIndex = updatedComments.findIndex(
            (comment) => comment.comment_id === variables.index,
          );

          if (commentIndex !== -1) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              text: data.message,
            };
          }

          console.log("HELLO", updatedComments);

          // Return the updated state
          return { ...prevState, comments: updatedComments };
        });
        setLoadingTranslationIndexes((prevState) =>
          prevState.filter((x) => x !== variables.index),
        );
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const {
    mutate: handleTranslateInputDescription,
    isLoading: isInputDescriptionTranslationLoading,
  } = useMutation(
    "translateDescription",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp) => {
        setTranslatedDescription(resp.message);
        setSnackbar({
          title: "Success",
          content: `Successfully translated the text`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const {
    mutate: isCommentTranslated,
    isLoading: checkCommentTranslated,
    error: errorCommentTranslated,
  } = useMutation(
    "checkCommentTranslated",
    (comment: any) => {
      return getTranslationCheck(auth.accessToken, comment.text);
    },
    {
      onSuccess: (resp, data) => {
        if (resp.message.translated) {
          mutateSubmitComment(data);
        } else {
          setSnackbar({
            title: "Error",
            content:
              "Comment is not translated to English. Please click the translate to english button and re-post it.",
            type: "error",
          });
        }
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content: "Something went wrong",
          type: "error",
        });
      },
    },
  );

  const resetCommentTranslation = useCallback(() => {
    setTranslatedComment("");
  }, []);

  const resetDescriptionTranslation = useCallback(() => {
    setTranslatedDescription("");
  }, []);

  for (const [value, name] of Object.entries(paTaskViewStatuses)) {
    statusValues.push({ value, name });
  }

  const InnerCommentComponent = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (translatedComment !== "") {
        setFieldValue("comment", translatedComment);
      }
      resetCommentTranslation();
    }, [translatedComment, resetCommentTranslation]);

    return null;
  };

  const InnerDescriptionComponent = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (translatedDescription !== "") {
        setFieldValue("delivery.description", translatedDescription);
      }

      resetDescriptionTranslation();
    }, [translatedDescription, resetDescriptionTranslation]);

    return null;
  };

  if (taskLoading || isloadingTask || isUserDataLoading || !userData) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <StyledContainer>
      <FormContainer className="flex flex-col justify-center">
        <FormStyle className="mx-auto bg-white shadow-lg">
          <TaskHeader
            className="px-10 w-full"
            borderBottom="1px solid rgba(163, 163, 163, 0.21)">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <div
                className="text-[26px] font-bold justify-start w-fit"
                data-test-id="task-title">
                {activeTask.title}
              </div>
              <div
                onClick={() => {
                  navigate("/dashboard");
                }}
                className="cursor-pointer mx-2">
                <CloseIcon></CloseIcon>
              </div>
            </Box>
            <Box display="flex" paddingY="16px" marginTop="32px">
              <HeaderStatus>
                <Box>Published</Box>
                <Box>{new Date(activeTask.date).toDateString()}</Box>
              </HeaderStatus>
              <HeaderStatus>
                <Box>Status</Box>
                <Box>
                  <Status
                    color={statusColors?.[activeTask.status]}
                    data-test-id="task-status">
                    {allStatuses?.[activeTask.status] || activeTask.status}
                  </Status>
                </Box>
              </HeaderStatus>
              <HeaderStatus>
                <Box>Since</Box>
                <Box>
                  {new Date(
                    activeTask.status_changed || Date.now(),
                  ).toLocaleString()}
                </Box>
              </HeaderStatus>
              <HeaderStatus>
                <Box>Energy</Box>
                <Box>
                  <span className="text-base flex flex-row items-center gap-1">
                    <div className="font-bold" data-test-id="task-energy">
                      {activeTask?.energy_assigned}
                    </div>

                    <Energy />
                  </span>
                </Box>
              </HeaderStatus>
            </Box>
          </TaskHeader>
          {/* <Divider light className="w-full" /> */}
          <Box className="my-4 mx-10" paddingTop="26px">
            <Grid container rowGap={3}>
              <Grid container columnGap={4}>
                <div className="flex flex-row gap-2">
                  <div className="text-base font-bold">Description</div>
                  {activeTask.description && (
                    <>
                      <div
                        onClick={async () => {
                          if (descriptionTranslationLoading) {
                            return;
                          }
                          await handleDescriptionTranslation({
                            description: activeTask?.description,
                          });
                        }}
                        className={`${
                          descriptionTranslationLoading
                            ? "opacity-50"
                            : "underline cursor-pointer"
                        } flex items-center justify-center text-xs hover:opacity-50`}>
                        {descriptionTranslationLoading
                          ? "Translating..."
                          : `Translate to ${getLanguageNameById(
                              userData.preferred_language,
                            )}`}
                      </div>
                      {descriptionTranslationLoading && (
                        <div>
                          <LoadingIcon size={3} color={"primaryButton"} />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <Grid item xs={11}>
                  <div className=" -mt-[5]">
                    <HTMLTextRender
                      htmlString={activeTask.description?.replaceAll(
                        "<br/>",
                        "\n",
                      )}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container rowGap={2} columnGap={2}>
                <Grid item xs={12}>
                  <div className="text-base font-bold ">Attachments:</div>
                </Grid>
                {activeTask.attachments?.map((file, index) => {
                  let fileName = file.substr(file.lastIndexOf("/") + 1);
                  return (
                    <Grid item key={index}>
                      <div className="bg-[#fdfdfd] mb-3 p-[9] w-[fit-content] pl-[20] pr-[20] cursor-pointer rounded-[5px] h-fit">
                        <a href={file} target="_blank">
                          {fileName}
                          <span className="relative float-left mr-[5px]">
                            <PaperClipIcon />
                          </span>
                        </a>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                rowSpacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  width: "calc(100% - 50px) !important",
                  alignSelf: " center",
                }}>
                <Grid item xs={12} md={6}>
                  <div className="text-black font-bold font-inter mb-2">
                    Video URLs:
                  </div>
                  {activeTask.videos?.map((url, index) => (
                    <div className="mb-3 flex items-center" key={index}>
                      {REGEX_Youtube.test(url) ? (
                        <iframe
                          className="rounded-md mr-5"
                          width="344"
                          height="188"
                          src={
                            "https://www.youtube.com/embed/" + url.substring(17)
                          }
                          title="YouTube video player"
                          frameBorder={"0"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      ) : REGEX_Vimeo.test(url) ? (
                        <iframe
                          className="rounded-md mr-5"
                          width="344"
                          height="188"
                          src={
                            "https://player.vimeo.com/video/" +
                            url.substring(18)
                          }
                          title="YouTube video player"
                          frameBorder={"0"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      ) : CONDITION_DTube(url) ? (
                        <iframe
                          className="rounded-md mr-5"
                          width="344"
                          height="188"
                          src={"https://emb.d.tube/#!/" + url.substring(21)}
                          title="YouTube video player"
                          frameBorder={"0"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      ) : CONDITION_BitChute(url) ? (
                        <iframe
                          className="rounded-md mr-5"
                          width="344"
                          height="188"
                          src={
                            "https://www.bitchute.com/embed/" +
                            url.substring(31)
                          }
                          title="YouTube video player"
                          frameBorder={"0"}
                          scrolling="no"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      ) : (
                        <iframe
                          className="rounded-md mr-5"
                          width="344"
                          height="188"
                          src={
                            "https://vimeo.com/api/oembed.json?url=" +
                            encodeURI(url)
                          }
                          title="YouTube video player"
                          frameBorder={"0"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      )}
                    </div>
                  ))}
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="text-black font-bold font-inter mb-2">
                    Website URLs:
                  </div>
                  {activeTask.urls?.map((url, index) => (
                    <Grid item key={index}>
                      <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pt-[5] h-[35] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                        <a
                          href={url.includes("http") ? url : `https://${url}`}
                          target="_blank"
                          className="whitespace-nowrap">
                          {url}
                        </a>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {["in_progress", "submitted"].includes(activeTask.status) &&
                !isloadingTask && (
                  <Formik
                    validate={
                      activeTask.delivery?.attachments?.length
                        ? undefined
                        : FormSchema
                    }
                    initialValues={{
                      delivery: activeTask.delivery, // || defaultDeliveryState,
                      /*
                      delivery: {
                        attachments: activeTask.delivery.attachments
                          ? activeTask.delivery.attachments
                          : defaultDeliveryState.attachments,
                        description: activeTask.delivery.description,
                        urls: activeTask.delivery?.urls
                          ? [...activeTask.delivery.urls, ""]
                          : defaultDeliveryState.urls,
                        videos: activeTask.delivery?.videos
                          ? [...activeTask.delivery.videos, ""]
                          : defaultDeliveryState.videos,
                      },
                      */
                    }}
                    onSubmit={(values, formikHelpers) => {
                      // Used when submit is triggered in useEffect in child component - will update task after file upload
                      setDeliverable(true);
                      setSubmitModal(true);
                    }}>
                    {({
                      values,
                      errors,
                      setFieldValue,
                      resetForm,
                      handleSubmit,
                      isValidating,
                      isSubmitting,
                      setSubmitting,
                      isValid,
                    }) => {
                      useEffect(() => {
                        if (
                          activeTask.delivery?.attachments?.length < 1 &&
                          !isValid
                        ) {
                          setDeliverable(false);
                        } else {
                          setDeliverable(true);
                        }
                      }, [isValid, activeTask.delivery.attachments.length]);
                      useEffect(() => {
                        if (isSubmitting) {
                          if (errors?.delivery && !isValidating) {
                            setSubmitModal(true);
                          }
                        }
                      }, [isSubmitting, isValidating, isValid]);
                      useEffect(() => {
                        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
                        // Attachments have been added
                        if (
                          values.delivery.attachments.length !==
                          activeTask.delivery.attachments.length
                        ) {
                          deliverTask({
                            task: values,
                            doSubmit: false,
                            toDashboard: false,
                          });
                        }
                        // if (values.attachments.length === 6) {
                        //}
                      }, [
                        values.delivery.attachments,
                        values.delivery.attachments.length,
                      ]);
                      function handleConfirmSubmit() {
                        setSubmitModal(false);
                        setIsSubmitTriggered(true);
                        setIsTaskSubmitting(true);
                        deliverTask({
                          task: values,
                          doSubmit: true,
                          toDashboard: true,
                        });
                      }
                      return (
                        <Grid
                          container
                          rowGap={2}
                          data-test-id="task-output-container">
                          <Grid item xs={12}>
                            <Modal
                              title="Task Submit"
                              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                              open={submitModal}
                              onClose={() => {
                                setSubmitting(false);
                                closeSubmitModal();
                              }}>
                              <div
                                style={{ maxWidth: 450 }}
                                data-test-id="task-confirm-submit-modal">
                                <div
                                  style={{
                                    marginBottom: 20,
                                    textAlign: "center",
                                  }}>
                                  {
                                    <Typography
                                      fontWeight={700}
                                      fontFamily="Inter">
                                      {deliverable
                                        ? "Did you complete the delivery and want to submit the task for approval?"
                                        : "You don't have an output yet. Please write in or upload your work."}
                                    </Typography>
                                  }
                                </div>
                                {deliverable && (
                                  <ConfirmButton
                                    data-test-id="task-confirm-submit-yes"
                                    variant="contained"
                                    onClick={handleConfirmSubmit}
                                    fullWidth>
                                    Yes
                                  </ConfirmButton>
                                )}
                                <CancelButton
                                  data-test-id="task-confirm-submit-cancel"
                                  variant="contained"
                                  onClick={() => {
                                    setSubmitting(false);
                                    closeSubmitModal();
                                  }}
                                  fullWidth>
                                  {!deliverable ? "Understood" : "No, Cancel"}
                                </CancelButton>
                              </div>
                            </Modal>
                            <div className="mt-[30] mb-[20]">
                              <span
                                className="text-[20px] font-bold "
                                data-test-id="task-output-header">
                                YOUR OUTPUT
                              </span>
                            </div>
                            <Form onSubmit={handleSubmit}>
                              <Grid container columnGap={2} rowGap={2}>
                                <Grid
                                  container
                                  rowGap={2}
                                  columnGap={2}
                                  data-test-id="task-output-attachments">
                                  <Grid item xs={12}>
                                    <div className="text-base font-bold ">
                                      Attachments:
                                    </div>
                                  </Grid>
                                  {activeTask.delivery?.attachments?.map(
                                    (file, index) => {
                                      let fileName = file.substr(
                                        file.lastIndexOf("/") + 1,
                                      );
                                      return (
                                        <Grid item key={index}>
                                          <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pl-[30] pt-[5] h-[35] w-[155] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                                            <a
                                              href={file}
                                              target="_blank"
                                              className="whitespace-nowrap">
                                              {fileName}
                                            </a>
                                            <div className="absolute left-[10] top-[8]">
                                              <PaperClipIcon className="h-[14] w-[14]" />
                                            </div>
                                          </div>
                                        </Grid>
                                      );
                                    },
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <FileUpload
                                    onFinishUploading={(attachments) => {
                                      setFieldValue("delivery.attachments", [
                                        ...values.delivery?.attachments,
                                        ...attachments,
                                      ]);
                                    }}
                                  />
                                </Grid>

                                <Grid container className="grid lg:grid-cols-2">
                                  <div data-test-id="task-output-video-urls">
                                    <label className="block mb-3 font-bold text-black ">
                                      Video URLs
                                    </label>
                                    {values.delivery.videos
                                      .slice(0, -1)
                                      .map((url, index) => (
                                        <div
                                          className="mb-3 flex items-center"
                                          key={index}>
                                          {REGEX_Youtube.test(url) ? (
                                            <iframe
                                              className="rounded-md mr-5"
                                              width="344"
                                              height="188"
                                              src={
                                                "https://www.youtube.com/embed/" +
                                                url.substring(17)
                                              }
                                              title="YouTube video player"
                                              frameBorder={"0"}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>
                                          ) : REGEX_Vimeo.test(url) ? (
                                            <iframe
                                              className="rounded-md mr-5"
                                              width="344"
                                              height="188"
                                              src={
                                                "https://player.vimeo.com/video/" +
                                                url.substring(18)
                                              }
                                              title="YouTube video player"
                                              frameBorder={"0"}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>
                                          ) : CONDITION_DTube(url) ? (
                                            <iframe
                                              className="rounded-md mr-5"
                                              width="344"
                                              height="188"
                                              src={
                                                "https://emb.d.tube/#!/" +
                                                url.substring(21)
                                              }
                                              title="YouTube video player"
                                              frameBorder={"0"}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>
                                          ) : CONDITION_BitChute(url) ? (
                                            <iframe
                                              className="rounded-md mr-5"
                                              width="344"
                                              height="188"
                                              src={
                                                "https://www.bitchute.com/embed/" +
                                                url.substring(31)
                                              }
                                              title="YouTube video player"
                                              frameBorder={"0"}
                                              scrolling="no"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>
                                          ) : (
                                            <iframe
                                              className="rounded-md mr-5"
                                              width="344"
                                              height="188"
                                              src={
                                                "https://vimeo.com/api/oembed.json?url=" +
                                                encodeURI(url)
                                              }
                                              title="YouTube video player"
                                              frameBorder={"0"}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>
                                          )}
                                          <div
                                            className="cursor-pointer"
                                            onClick={(event) => {
                                              setFieldValue(
                                                "delivery.videos",
                                                (values.delivery.videos = [
                                                  ...values.delivery.videos.slice(
                                                    0,
                                                    index,
                                                  ),
                                                  ...values.delivery.videos.slice(
                                                    index + 1,
                                                  ),
                                                ]),
                                              );
                                            }}>
                                            <MinusCircleIcon />
                                          </div>
                                        </div>
                                      ))}
                                    {values.delivery.videos.length >= 1 && (
                                      <>
                                        <div className="mb-3 flex items-center">
                                          <TextField
                                            sx={{ width: 360 }}
                                            multiline={false}
                                            rows={1}
                                            onBlur={() => {
                                              let url =
                                                values.delivery.videos[
                                                  values.delivery.videos
                                                    .length - 1
                                                ];

                                              if (REGEX_Youtube.test(url)) {
                                                if (
                                                  url.indexOf(
                                                    "https://youtu.be/",
                                                  ) > -1
                                                ) {
                                                  setHasVideoError(false);
                                                  setFieldValue(
                                                    "delivery.videos",
                                                    values.delivery.videos.concat(
                                                      [""],
                                                    ),
                                                  );
                                                  return;
                                                }
                                                if (
                                                  url.indexOf(
                                                    "https://www.youtube.com/watch?v=",
                                                  ) > -1
                                                ) {
                                                  if (url.length != 43) {
                                                    setHasVideoError(true);
                                                    return;
                                                  }
                                                  let videoCode = url.slice(
                                                    url.length - 11,
                                                  );
                                                  let videoTempUrl = `https://youtu.be/${videoCode}`;
                                                  //Proceeds if there's an element in the array or the last value of it is the current url value
                                                  if (
                                                    values.delivery.videos
                                                      .length > 0 ||
                                                    values.delivery.videos[
                                                      values.delivery.videos
                                                        .length - 1
                                                    ].indexOf(videoCode) != -1
                                                  ) {
                                                    values.delivery.videos.pop();
                                                    values.delivery.videos.push(
                                                      videoTempUrl,
                                                    );
                                                  }
                                                  setHasVideoError(false);
                                                  setFieldValue(
                                                    "delivery.videos",
                                                    values.delivery.videos.concat(
                                                      [""],
                                                    ),
                                                  );
                                                  return;
                                                }
                                              }

                                              if (REGEX_Vimeo.test(url)) {
                                                let videoCode =
                                                  url.substring(18);

                                                let videoTempUrl = `https://vimeo.com/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              if (CONDITION_DTube(url)) {
                                                let videoCode =
                                                  url.substring(19);

                                                let videoTempUrl = `https://d.tube/#!/v/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              if (CONDITION_BitChute(url)) {
                                                let videoCode =
                                                  url.substring(31);
                                                console.log(
                                                  videoCode,
                                                  "VIdeo Code",
                                                );
                                                let videoTempUrl = `https://www.bitchute.com/video/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              //Stops triggering input errors
                                              if (url == "") {
                                                return;
                                              } else {
                                                setHasVideoError(true);
                                                return;
                                              }
                                            }}
                                            className="bg-[#fdfdfd] w-4/5 mr-5 m-0 rounded-md"
                                            variant="filled"
                                            size="small"
                                            value={
                                              values.delivery.videos[
                                                values.delivery.videos.length -
                                                  1
                                              ]
                                            }
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            onChange={(event) => {
                                              if (event.target.value == "") {
                                                setHasVideoError(false);
                                              }
                                              setFieldValue(
                                                `delivery.videos[${
                                                  values.delivery.videos
                                                    .length - 1
                                                }]`,
                                                event.target.value,
                                              );
                                            }}
                                            inputProps={{
                                              style: { padding: "10px 12px" },
                                              "data-test-id": `task-output-video-url-input`,
                                            }}
                                          />
                                          <div
                                            className="cursor-pointer"
                                            data-test-id="task-output-video-url-add"
                                            onClick={() => {
                                              let url =
                                                values.delivery.videos[
                                                  values.delivery.videos
                                                    .length - 1
                                                ];
                                              if (url == "") {
                                                setHasVideoError(true);
                                                return;
                                              }
                                              if (REGEX_Youtube.test(url)) {
                                                if (
                                                  url.indexOf(
                                                    "https://youtu.be/",
                                                  ) > -1
                                                ) {
                                                  setHasVideoError(false);
                                                  setFieldValue(
                                                    "delivery.videos",
                                                    values.delivery.videos.concat(
                                                      [""],
                                                    ),
                                                  );
                                                  return;
                                                }
                                                if (
                                                  url.indexOf(
                                                    "https://www.youtube.com/watch?v=",
                                                  ) > -1
                                                ) {
                                                  if (url.length != 43) {
                                                    setHasVideoError(true);
                                                    return;
                                                  }
                                                  let videoCode = url.slice(
                                                    url.length - 11,
                                                  );
                                                  let videoTempUrl = `https://youtu.be/${videoCode}`;
                                                  //Proceeds if there's an element in the array or the last value of it is the current url value
                                                  if (
                                                    values.delivery.videos
                                                      .length > 0 ||
                                                    values.delivery.videos[
                                                      values.delivery.videos
                                                        .length - 1
                                                    ].indexOf(videoCode) != -1
                                                  ) {
                                                    values.delivery.videos.pop();
                                                    values.delivery.videos.push(
                                                      videoTempUrl,
                                                    );
                                                  }
                                                  setHasVideoError(false);
                                                  setFieldValue(
                                                    "delivery.videos",
                                                    values.delivery.videos.concat(
                                                      [""],
                                                    ),
                                                  );
                                                  return;
                                                }
                                              }

                                              if (REGEX_Vimeo.test(url)) {
                                                let videoCode =
                                                  url.substring(18);

                                                let videoTempUrl = `https://vimeo.com/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              if (CONDITION_DTube(url)) {
                                                let videoCode =
                                                  url.substring(19);

                                                let videoTempUrl = `https://d.tube/#!/v/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              if (CONDITION_BitChute(url)) {
                                                let videoCode =
                                                  url.substring(31);
                                                console.log(
                                                  videoCode,
                                                  "VIdeo Code",
                                                );
                                                let videoTempUrl = `https://www.bitchute.com/video/${videoCode}`;
                                                //Proceeds if there's an element in the array or the last value of it is the current url value
                                                if (
                                                  values.delivery.videos
                                                    .length > 0 ||
                                                  values.delivery.videos[
                                                    values.delivery.videos
                                                      .length - 1
                                                  ].indexOf(videoCode) != -1
                                                ) {
                                                  values.delivery.videos.pop();
                                                  values.delivery.videos.push(
                                                    videoTempUrl,
                                                  );
                                                }
                                                setHasVideoError(false);
                                                setFieldValue(
                                                  "delivery.videos",
                                                  values.delivery.videos.concat(
                                                    [""],
                                                  ),
                                                );
                                                return;
                                              }

                                              //Stops triggering input errors
                                              if (url == "") {
                                                return;
                                              } else {
                                                setHasVideoError(true);
                                                return;
                                              }
                                            }}>
                                            <PlusCircleIcon />
                                          </div>
                                        </div>
                                        <span
                                          className={`text-xs text-error ${
                                            hasVideoError ? "" : "hidden"
                                          }`}>
                                          Video Url is unsupported
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <div data-test-id="task-output-website-urls">
                                    <label className="block mb-3 font-bold text-black ">
                                      Website URLs
                                    </label>
                                    {values.delivery.urls.map((url, index) =>
                                      index ===
                                      values.delivery.urls.length - 1 ? (
                                        <div
                                          className="mb-3 flex items-center"
                                          key={index}>
                                          <TextField
                                            onBlur={() => {
                                              const url =
                                                values.delivery.urls[index];
                                              if (url == "") {
                                                return;
                                              }
                                              const regex: RegExp =
                                                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
                                              if (
                                                regex.test(url) &&
                                                index ===
                                                  values.delivery.urls.length -
                                                    1
                                              ) {
                                                setFieldValue(
                                                  "delivery.urls",
                                                  values.delivery.urls.concat([
                                                    "",
                                                  ]),
                                                );
                                              } else {
                                                if (
                                                  index ===
                                                  values.delivery.urls.length -
                                                    1
                                                )
                                                  setHasUrlError(true);
                                              }
                                            }}
                                            sx={{ width: 360 }}
                                            multiline={false}
                                            rows={1}
                                            className="bg-[#fdfdfd] w-4/5 mr-5 m-0 rounded-md"
                                            variant="filled"
                                            size="small"
                                            value={url}
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            onChange={(event) => {
                                              if (event.target.value == "") {
                                                setHasUrlError(false);
                                              }
                                              setFieldValue(
                                                `delivery.urls[${index}]`,
                                                event.target.value,
                                              );
                                            }}
                                            inputProps={{
                                              style: { padding: "10px 12px" },
                                              "data-test-id":
                                                "task-output-website-url-input",
                                            }}
                                          />
                                          <div
                                            data-test-id="task-output-website-url-add"
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const url =
                                                values.delivery.urls[index];
                                              if (url == "") {
                                                return;
                                              }
                                              const regex: RegExp =
                                                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
                                              if (regex.test(url)) {
                                                setFieldValue(
                                                  "delivery.urls",
                                                  values.delivery.urls.concat([
                                                    "",
                                                  ]),
                                                );
                                              } else {
                                                setHasUrlError(true);
                                              }
                                            }}>
                                            <PlusCircleIcon />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="mb-3 flex items-center"
                                          key={index}>
                                          <Typography
                                            sx={{
                                              wordWrap: "break-word",
                                              width: "360px",
                                              maxWidth: "360px",
                                              padding: "10px 12px",
                                            }}
                                            className="bg-[#fdfdfd] w-4/5 mr-5 m-0 rounded-md">
                                            {url}
                                          </Typography>
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const urls = [
                                                ...values.delivery.urls.slice(
                                                  0,
                                                  index,
                                                ),
                                                ...values.delivery.urls.slice(
                                                  index + 1,
                                                ),
                                              ];
                                              setFieldValue(
                                                "delivery.urls",
                                                urls,
                                              );
                                            }}>
                                            <MinusCircleIcon />
                                          </div>
                                        </div>
                                      ),
                                    )}
                                    <span
                                      className={`text-xs text-error ${
                                        hasUrlError ? "" : "hidden"
                                      }`}>
                                      Not a valid http link
                                    </span>
                                  </div>
                                </Grid>
                                <Grid
                                  container
                                  columnGap={2}
                                  rowGap={2}
                                  data-test-id="task-output-completion-details">
                                  <InnerDescriptionComponent />
                                  <Grid item xs={12}>
                                    <div className="font-bold text-black  font-base">
                                      Task Completion Details
                                    </div>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <div className="flex flex-col gap-1">
                                      <div className="w-full">
                                        <TextEditor
                                          onEditorChange={(string) => {
                                            setFieldValue(
                                              `delivery.description`,
                                              string,
                                            );
                                            setIsCompletionTranslated(false);
                                          }}
                                          htmlString={
                                            values.delivery.description
                                          }
                                          placeholder={"Add description here"}
                                          isTranslated={isCompletionTranslated}
                                        />
                                        {/* <TextField
                                          fullWidth
                                          placeholder="Add description here"
                                          multiline={true}
                                          rows={4}
                                          variant="filled"
                                          value={values.delivery?.description}
                                          onChange={(event) => {
                                            setFieldValue(
                                              `delivery.description`,
                                              event.target.value,
                                            );
                                          }}
                                        /> */}
                                      </div>
                                      <div className="flex flex-row gap-1">
                                        <div
                                          onClick={async () => {
                                            if (
                                              isInputDescriptionTranslationLoading ||
                                              !values.delivery.description
                                            ) {
                                              return;
                                            }

                                            await handleTranslateInputDescription(
                                              {
                                                description:
                                                  values.delivery.description,
                                              },
                                            );
                                            setIsCompletionTranslated(true);
                                          }}
                                          className={` hover:opacity-50 text-xs ${
                                            isInputDescriptionTranslationLoading
                                              ? "opacity-50"
                                              : "underline cursor-pointer"
                                          }`}>
                                          {isInputDescriptionTranslationLoading
                                            ? "Translating..."
                                            : `Translate to English`}
                                        </div>
                                        {isInputDescriptionTranslationLoading && (
                                          <div className="flex items-center justify-center">
                                            <LoadingIcon
                                              size={3}
                                              color={"primaryButton"}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className="flex flex-col md:flex-row md:items-center mb-[30] gap-[5]">
                                    {["in_progress", "submitted"].includes(
                                      activeTask.status,
                                    ) && (
                                      <>
                                        <TaskPageButton
                                          data-test-id="task-save-button"
                                          variant="contained"
                                          onClick={() => {
                                            setIsSaveTriggered(true);
                                            setIsSaving(true);
                                            deliverTask({
                                              task: values,
                                              doSubmit: false,
                                              toDashboard: true,
                                            });
                                          }}
                                          disabled={
                                            changeTaskLoading ||
                                            isUpdatingTask ||
                                            isTaskBeingRejected ||
                                            isSaved
                                          }
                                          className={`${
                                            (isRejecting ||
                                              isRejected ||
                                              isTaskSubmitting ||
                                              isSubmitted) &&
                                            !isSaveTriggered
                                              ? "hidden "
                                              : ""
                                          } w-28`}>
                                          <span
                                            className={
                                              isSaving ? "" : "hidden"
                                            }>
                                            <LoadingIcon></LoadingIcon>
                                          </span>
                                          <span
                                            className={isSaved ? "" : "hidden"}>
                                            <CheckIcon></CheckIcon>
                                          </span>

                                          <span>
                                            {isSaving
                                              ? "Saving"
                                              : isSaved
                                              ? "Saved"
                                              : "Save"}
                                          </span>
                                        </TaskPageButton>

                                        <TaskPageButton
                                          data-test-id="task-submit-button"
                                          variant="contained"
                                          type="submit"
                                          disabled={
                                            changeTaskLoading ||
                                            isUpdatingTask ||
                                            activeTask.status === "submitted" ||
                                            isTaskSubmitting ||
                                            isSubmitted
                                          }
                                          className={`
                                            ${
                                              activeTask.status === "submitted"
                                                ? "hidden "
                                                : ""
                                            }
                                            ${
                                              (isSaving ||
                                                isRejecting ||
                                                isSaved ||
                                                isRejected) &&
                                              !isSubmitTriggered
                                                ? "hidden "
                                                : ""
                                            } 
                                            w-52`}>
                                          <span
                                            className={
                                              isTaskSubmitting || isSubmitted
                                                ? "hidden"
                                                : ""
                                            }>
                                            <SubmitIcon></SubmitIcon>
                                          </span>
                                          <span
                                            className={
                                              isSubmitted || !isTaskSubmitting
                                                ? "hidden"
                                                : ""
                                            }>
                                            <LoadingIcon></LoadingIcon>
                                          </span>
                                          <span
                                            className={
                                              isTaskSubmitting || !isSubmitted
                                                ? "hidden"
                                                : ""
                                            }>
                                            <CheckIcon></CheckIcon>
                                          </span>
                                          <span>
                                            {isTaskSubmitting
                                              ? "Submitting"
                                              : isSubmitted
                                              ? "Submitted"
                                              : "Submit for Approval"}
                                          </span>
                                        </TaskPageButton>
                                      </>
                                    )}

                                    {!Boolean(
                                      Array.isArray(activeTask.category)
                                        ? activeTask.category.find(
                                            (task) => task == "missions",
                                          )
                                        : activeTask.category == "missions",
                                    ) && (
                                      <TaskPageButton
                                        data-test-id="task-reject-button"
                                        variant="contained"
                                        onClick={handleReject}
                                        disabled={
                                          changeTaskLoading ||
                                          isUpdatingTask ||
                                          activeTask.status === "submitted" ||
                                          isTaskBeingRejected ||
                                          isRejecting
                                        }
                                        className={`
                                      ${
                                        activeTask.status === "submitted" ||
                                        ((isSaved ||
                                          isSaving ||
                                          isTaskSubmitting ||
                                          isSubmitted) &&
                                          !isRejectTriggered)
                                          ? "hidden "
                                          : ""
                                      }
                                        `}>
                                        <span
                                          className={
                                            isRejecting ? "" : "hidden"
                                          }>
                                          <LoadingIcon></LoadingIcon>
                                        </span>
                                        <span
                                          className={
                                            isRejected ? "" : "hidden"
                                          }>
                                          <CheckIcon></CheckIcon>
                                        </span>
                                        <span>
                                          {isRejecting
                                            ? "Rejecting Task"
                                            : isRejected
                                            ? "Rejected"
                                            : "Reject Task"}
                                        </span>
                                      </TaskPageButton>
                                    )}

                                    <Button
                                      className={`${
                                        changeTaskLoading ||
                                        isUpdatingTask ||
                                        isSubmitting ||
                                        isSubmitted
                                          ? "hidden "
                                          : ""
                                      }text-black font-bold ml-4 mt-3 text-center md:w-20 h-12 rounded-full`}
                                      variant="text"
                                      disabled={
                                        changeTaskLoading ||
                                        isUpdatingTask ||
                                        isSubmitting ||
                                        isSubmitted ||
                                        isRejecting ||
                                        isRejected
                                      }
                                      onClick={() => navigate(-1)}>
                                      Cancel
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Form>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Formik>
                )}
              {activeTask.status === "clarification_needed" && (
                <StyledPurpleButton
                  data-test-id="task-clarification-done-button"
                  variant="contained"
                  className="bg-[#EF6775]"
                  disabled={changeTaskLoading || isUpdatingTask}
                  onClick={() => mutateChangeTaskState("clarification_ended")}>
                  Clarification Done
                </StyledPurpleButton>
              )}
            </Grid>
          </Box>
        </FormStyle>
        <CommentsContainer
          data-test-id="task-comments-container"
          className="flex flex-col w-[1080] min-h-[120] bg-commentBg rounded h-50 mb-8 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] justify-center items-center"
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
          <div className="flex w-full pl-[40px] pr-[40px]  flex-col">
            <span className="font-inter font-bold text-base mb-3 mt-6">
              Comments:
            </span>
            <Formik
              initialValues={{ comment: "" }}
              validationSchema={CommentSchema}
              onSubmit={(values, { setFieldValue, resetForm }) => {
                // mutateSubmitComment({
                //   text: values.comment,
                //   role: "pa",
                //   user_id: auth.user_id,
                // });

                isCommentTranslated({
                  text: values.comment,
                  role: "pa",
                  user_id: auth.user_id,
                });
                setFieldValue("comment", "");
                resetForm();
              }}>
              {({ values, errors, setFieldValue, resetForm, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <InnerCommentComponent />
                    <div className="flex flex-row gap-2">
                      <div className="w-full flex flex-col">
                        <TextEditor
                          onEditorChange={(string) => {
                            setFieldValue("comment", string);
                            setIsTranslated(false);
                          }}
                          htmlString={values?.comment}
                          placeholder={"Input comment"}
                          isTranslated={isTranslated}
                          editorStyle={{
                            backgroundColor: "white",
                          }}
                        />
                        <div className="flex flex-row gap-1">
                          <div
                            onClick={async () => {
                              if (
                                isInputCommentTranslationLoading ||
                                !values.comment
                              ) {
                                return;
                              }

                              await handleTranslateInputComment({
                                comment: values.comment,
                              });
                              setIsTranslated(true);
                            }}
                            className={`hover:opacity-50 text-xs ${
                              isInputCommentTranslationLoading
                                ? "opacity-50"
                                : "underline cursor-pointer"
                            }`}>
                            {isInputCommentTranslationLoading
                              ? "Translating..."
                              : `Translate to English`}
                          </div>
                          {isInputCommentTranslationLoading && (
                            <div className="flex items-center justify-center">
                              <LoadingIcon size={3} color={"primaryButton"} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <TaskPageButton
                        data-test-id="task-comments-submit-button"
                        variant="contained"
                        type="submit"
                        title={
                          translateExistingCommentLoading ||
                          isInputCommentTranslationLoading ||
                          isInputDescriptionTranslationLoading
                            ? "Translation loading"
                            : ""
                        }
                        disabled={
                          isSubmittingComment ||
                          isCommentAdded ||
                          translateExistingCommentLoading ||
                          isInputCommentTranslationLoading ||
                          isInputDescriptionTranslationLoading ||
                          !values.comment
                        }
                        className="w-28 gap-1">
                        {/* Loading icon visibility */}
                        {isSubmittingComment ||
                          (checkCommentTranslated && (
                            <span className="flex items-center justify-center">
                              <LoadingIcon />
                            </span>
                          ))}

                        {/* Check icon visibility */}
                        {isCommentAdded && (
                          <span className="flex items-center justify-center ">
                            <CheckIcon nomargin={true} />
                          </span>
                        )}

                        {/* Button text */}
                        <span>
                          {isSubmittingComment || checkCommentTranslated
                            ? "Posting"
                            : isCommentAdded
                            ? "Posted"
                            : "Post"}
                        </span>
                      </TaskPageButton>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <div
            className="flex w-[88%] flex-col mt-10"
            data-test-id="task-comments-section">
            {activeTask.comments &&
              activeTask.comments.length > 0 &&
              activeTask.comments
                .map((cmt, index) => {
                  return (
                    <div
                      key={index}
                      className={"w-full" + `${index === 0 ? " mb-6" : ""}`}>
                      {index !== activeTask.comments.length - 1 && (
                        <hr className="h-px my-2 mb-4 bg-gray-200 border-1 dark:bg-gray-700" />
                      )}
                      <div>
                        <div className="w-full flex-col">
                          <span className="font-bold font-inter text-xs mr-3">
                            {cmt.role === "nft"
                              ? "Task Owner"
                              : "Personal Assistant"}
                          </span>
                          <span className="font-inter text-xs">
                            {formatCommentDate(cmt.created_date)}
                          </span>
                        </div>
                        <div>
                          <div className="flex flex-col">
                            <div
                              className={`-mt-[5] ${
                                isHTML(cmt.text) ? "" : "hidden"
                              }`}>
                              <HTMLTextRender
                                htmlString={cmt.text?.replaceAll("<br/>", "\n")}
                              />
                            </div>
                            <span className={!isHTML(cmt.text) ? "" : "hidden"}>
                              {cmt.text}
                            </span>
                            <div className="flex flex-row gap-2">
                              <div
                                onClick={async () => {
                                  if (
                                    loadingTranslationIndexes.includes(
                                      cmt.comment_id,
                                    )
                                  ) {
                                    return;
                                  }

                                  await handleTranslateExistingComment({
                                    text: cmt.text,
                                    source: isHTML(cmt.text)
                                      ? translationSources.EDITOR
                                      : translationSources.BASIC,
                                    index: cmt.comment_id,
                                  });
                                }}
                                className={`${
                                  loadingTranslationIndexes.includes(
                                    cmt.comment_id,
                                  )
                                    ? "opacity-50"
                                    : "underline cursor-pointer"
                                } text-color-gray text-xs hover:opacity-50`}>
                                {loadingTranslationIndexes.includes(
                                  cmt.comment_id,
                                )
                                  ? "Translating..."
                                  : `Translate to ${getLanguageNameById(
                                      userData.preferred_language,
                                    )}`}
                              </div>
                              {loadingTranslationIndexes.includes(
                                cmt.comment_id,
                              ) && (
                                <div className="flex items-center justify-center">
                                  <LoadingIcon
                                    size={3}
                                    color={"primaryButton"}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                .reverse()}
          </div>
        </CommentsContainer>
      </FormContainer>

      <Modal
        title="Reject Task"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={rejectModal}
        onClose={closeRejectModal}>
        <div style={{ maxWidth: 450 }} data-test-id="task-reject-modal">
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Do you wish to remove yourself from this task without having
              finished it? Your rating will be unaffected but you will receive
              -2 points for doing so.
            </Typography>
          </div>
          <ConfirmButton
            data-test-id="task-reject-modal-confirm"
            variant="contained"
            onClick={handleConfirmReject}
            fullWidth>
            Yes, reject the task
          </ConfirmButton>
          <CancelButton
            data-test-id="task-reject-modal-cancel"
            variant="contained"
            onClick={closeRejectModal}
            fullWidth>
            No, Cancel
          </CancelButton>
        </div>
      </Modal>
    </StyledContainer>
  );
  function handleReject() {
    setRejectModal(true);
  }
  function closeRejectModal() {
    setRejectModal(false);
  }
  function handleConfirmReject() {
    closeRejectModal();
    setIsRejectTriggered(true);
    setIsRejecting(true);
    rejectTaskSubmission();
  }
  function closeSubmitModal() {
    setSubmitModal(false);
  }

  function ChangeButtonState() {
    //Disable Loading State
    setIsSaving(false);
    setIsTaskSubmitting(false);

    //Enable End State
    setIsSaved(true);
    setIsSubmitted(true);
  }
};

export default Component;
