import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import CreateMissionTemplate from "../../templates/CreateMission";

import { Task } from "../../../interfaces/Task";
import { getAllQualifications } from "../../../services/qualification";
import {
  getTaskById,
  getTranslation,
  updateTask,
} from "../../../services/tasks";
import { authState, paginationState, taskState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IS_GLOBAL = true; //fetching all public qualifications

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();

  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);
  const resetTranslation = React.useCallback(() => {
    setTranslatedTexts({});
  }, []);
  const [translatedTexts, setTranslatedTexts] = React.useState({});

  let { id } = useParams();

  const { data: tasksData, isLoading: isValidatingUser } = useQuery(
    ["fetchingTask", auth.accessToken],
    () => {
      if (auth.accessToken) {
        return getTaskById(auth.accessToken, id);
      }
    },
    {
      onSuccess: (resp) => {
        if (!Array.isArray(resp.task.category)) {
          let categoryList: string[] = [];
          categoryList.push(resp.task.category);
          resp.task.category = categoryList;
        }
        resp.task.videos = !resp.task.videos ? [] : resp.task.videos;
        resp.task.urls = !resp.task.urls ? [] : resp.task.urls;

        setActiveTask(resp.task);
      },
      onError: (e) => {
        console.log("error", e);
      },
    },
  );

  const { data: qualificationsList } = useQuery(
    ["fetchingQualifications", auth.accessToken],
    () => {
      if (auth.accessToken) {
        return getAllQualifications(auth.accessToken, IS_GLOBAL);
      }
    },
    {
      onSuccess: (resp) => {},
      onError: (e) => {},
    },
  );

  const {
    mutate: mutateCreateTask,
    isLoading: isLoading,
    error,
  } = useMutation(
    "updateTask",
    (values: any) => {
      return updateTask(auth.accessToken, values.task, id);
    },
    {
      onSuccess: (resp) => {
        console.log("resp", resp);
        setIsSuccess(true);

        setPagination({
          fetchType: pagination.fetchType,
          pageNum: 0,
          after: null,
          before: null,
        });

        setSnackbar({
          title: "Success",
          content: "Successfully updated the mission.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/missions/view/" + id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateDraftTask } = useMutation(
    "updateTask",
    (values: any) => {
      return updateTask(auth.accessToken, values.task, id);
    },
    {
      onSuccess: (resp) => {
        console.log("resp", resp);
        setIsSuccess(true);

        setPagination({
          fetchType: pagination.fetchType,
          pageNum: 0,
          after: null,
          before: null,
        });

        setSnackbar({
          title: "Success",
          content: "Successfully updated the draft.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/missions/view/" + id);
        }, 1500);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateTranslateDescription,
    isLoading: isTranslating,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp, variables) => {
        if (resp) {
          const propertyName = Object.keys(variables)[0];
          setTranslatedTexts((prevState) => {
            return {
              ...prevState,
              [propertyName]: resp.message,
            };
          });
          setSnackbar({
            title: "Success",
            content: `Successfully translated the task ${propertyName}.`,
            type: "success",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  return (
    qualificationsList && (
      <StyledContainer className="bg-mainBg flex justify-center items-center">
        <CreateMissionTemplate
          handleSubmit={mutateCreateTask}
          mode="edit"
          isLoading={isLoading}
          isSuccess={isSuccess}
          handleDraftSubmit={mutateDraftTask}
          qualifications={qualificationsList.qualifications}
          translatedTexts={translatedTexts}
          resetTranslation={resetTranslation}
          isTranslating={isTranslating}
          handleTranslate={mutateTranslateDescription}
        />
      </StyledContainer>
    )
  );
};

export default Component;
