import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";
import {
  CoinbaseLogo,
  MetaMaskLogo,
  WalletConnectLogo,
} from "../../../assets/svg";
import {
  addressChangeWithSignedMessage,
  getNonceAddressUpdate,
} from "../../../services/users";
import {
  authState,
  initialAuthState,
  paginationState,
} from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";

import ContractLogo from "../../../assets/images/contract.svg";

import { Button } from "@mui/material";
import Modal from "../../organisms/Modal";
const Container = styled.div`
  height: calc(100% - 64px);
  padding-bottom: 8%;
`;

const ConfirmButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  width: 10rem;

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
  }
`;

const CancelButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #000000;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #e8e8e8;

  width: 10rem;

  &:hover {
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.p`
  font-size: 40px;
  letter-spacing: 0.4px;
  margin-bottom: 60px;
`;

const StyledWalletOptions = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 350px;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

type Props = {
  change: boolean;
};
const Component = (props: Props) => {
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);
  const [returnModal, setReturnModal] = useState(false);
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  console.log("auth :>> ", auth);
  const { data, isSuccess, signMessage } = useSignMessage({
    onSuccess(data) {
      mutateSendSigned(data);
    },
  });

  const connectorList = [
    {
      name: "MetaMask",
      desc: "Connect to your Metamask Wallet",
      icon: <MetaMaskLogo />,
    },
    {
      name: "WalletConnect",
      desc: "Scan WalletConnect QR to connect",
      icon: <WalletConnectLogo />,
    },
    {
      name: "Coinbase",
      desc: "Connect to your Coinbase Wallet",
      icon: <CoinbaseLogo />,
    },
  ];

  const connectMetaMaskWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[0];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithMetamask, 1000);
  };

  const connectWalletConnectWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[1];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithWalletConnect, 1000);
  };

  const connectCoinbasetWallet = () => {
    // disconnectWallet();
    disconnect();
    const connector = connectors[2];
    // connect({ connector });
    // setTimeout(connect({ connector }), 1000);
    connect({ connector });
    // setTimeout(connectWithWalletConnect, 1000);
  };

  const connectWallet = (connector) => {
    disconnect();
    connect({ connector });
  };

  const {
    mutate: mutateSendSigned,
    isLoading: isLoadingSigned,
    error: errorSigned,
  } = useMutation(
    ["sendSigned", addressChangeWithSignedMessage],
    async (message: any) => {
      if (isConnected && address) {
        return addressChangeWithSignedMessage(
          auth.accessToken,
          address,
          message,
        );
      }
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Address Updated",
          type: "success",
        });
        setAuth({
          ...auth,
          walletAddress: address,
        });
        navigate("/profile");
      },
      onError: (e, variables) => {
        // disconnectWallet();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateLoginOwner,
    isLoading,
    error,
  } = useMutation(
    ["loginOwner", auth.accessToken],
    async () => {
      if (isConnected && address) {
        let result = await getNonceAddressUpdate(auth.accessToken);
        signMessage({
          message: `Please, sign this message to login to the PA app (${result.nonce}).`,
        });
      }
    },
    {
      onSuccess: (resp) => {},
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        navigate("/");
      },
    },
  );

  const logOutOwner = () => {
    disconnect();
    setPagination({
      fetchType: "open",
      pageNum: 0,
      after: null,
      before: null,
    });
    setAuth(initialAuthState);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    navigate("/");
  };

  const cancelChange = async () => {
    if (auth.walletAddress && auth.walletAddress !== address)
      setReturnModal(true);
    else navigate("/");
  };
  return (
    <Container className="bg-mainBg">
      {!isConnected ? (
        <>
          <StyledContainer>
            <StyledTitle className="text-center font-gilroy font-bold">
              Connect your wallet
            </StyledTitle>
            <StyledWalletOptions className="bg-white">
              {connectors.map(
                (connector, index) =>
                  connector.ready && (
                    <div
                      className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer"
                      onClick={() => connectWallet(connector)}>
                      {connectorList[index]?.icon}
                      <div className="font-inter font-bold text-2xl mt-4">
                        {/* {connectorList[index].name} */}
                        {connector.name}
                      </div>
                      <div className="font-inter text-sm">
                        {connectorList[index]?.desc}
                      </div>
                    </div>
                  ),
              )}
            </StyledWalletOptions>
          </StyledContainer>
        </>
      ) : (
        <>
          <StyledContainer className="">
            <StyledTitle className="text-center font-gilroy font-bold mb-0 leading-[0.7]">
              Wallet Connected!
            </StyledTitle>
            <StyledTitle className="text-center font-borderGray font-gilroy font-bold text-xl mt-0 mb-[20px]">
              Just one more step..
            </StyledTitle>

            <StyledWalletOptions className="bg-white">
              <div className="flex flex-1 flex-col py-1 items-center justify-center border-0 border-b-2 border-b-borderGray border-solid hover:cursor-pointer">
                <div>
                  <img src={ContractLogo}></img>
                </div>
                <div className="w-[90%] text-center flex flex-col">
                  <div className="font-inter text-sm mb-1 whitespace-pre-line text-left">
                    {props.change === true
                      ? "To change the wallet address associated with your account, please change to another address using the wallet that is connected to this site.\n\n After you see the desired address as the active wallet below, please click Sign Message to verify your ownership of the new address."
                      : "Please ensure that the wallet address shown as the active wallet below is the desired address you wish to associate with your account.\n\n Once correct, please click Sign Message to verify your ownership of the address."}
                  </div>
                  <div
                    className="font-inter text-sm mb-5 whitespace-normal truncate text-left"
                    title={address}>
                    <br />
                    Your active wallet address is{" "}
                    <b>{`${address?.substring(0, 6)}....${address?.substring(
                      address.length - 4,
                      address.length,
                    )}`}</b>
                  </div>
                  <div className="flex flex-row justify-center align-middle">
                    <CancelButton onClick={cancelChange} className="ml-0 mr-2">
                      Back
                    </CancelButton>

                    <ConfirmButton
                      onClick={mutateLoginOwner}
                      className="ml-0 m">
                      Sign Message
                    </ConfirmButton>
                  </div>
                </div>
              </div>
            </StyledWalletOptions>
          </StyledContainer>
        </>
      )}
      {/* <Modal
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={modal}
        onClose={closeModal}>
        <div style={{ maxWidth: 450 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Enter your address
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              value={address}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          <ConfirmButton variant="contained" onClick={handleConfirm} fullWidth>
            Login
          </ConfirmButton>
          <CancelButton variant="contained" onClick={closeModal} fullWidth>
            Cancel
          </CancelButton>
        </div>
      </Modal> */}
      <Modal
        open={returnModal}
        onClose={() => {
          setReturnModal(false);
        }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}>
            <div
              style={{
                fontSize: "1.125rem",
                lineHeight: "1.75rem",
                fontWeight: "700",
              }}>
              The connected wallet is not the same as the profile wallet, if you
              proceed you will be logged out.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                verticalAlign: "middle",
                justifyContent: "center",
              }}>
              <ConfirmButton
                style={{ marginLeft: "0px", marginRight: "6px" }}
                onClick={logOutOwner}>
                Proceed
              </ConfirmButton>
              <CancelButton
                style={{ marginLeft: "6px" }}
                onClick={() => {
                  setReturnModal(false);
                }}>
                Go Back
              </CancelButton>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
  // function openModal() {
  //   setModal(true);
  // }
  // function closeModal() {
  //   setModal(false);
  // }
  // function handleConfirm() {
  //   mutateLoginOwner(address);
  // }
  // function handleChange(value) {
  //   setAddress(value);
  // }
};

export default Component;
