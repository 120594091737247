import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledMainContainer = styled.div`
  text-align: center;
  display: flex;
`;

export const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  height: 800px;
  overflow: auto;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 900px) {
    padding-top: 10vw;
  }

  width: 100%;

  #root > div {
    display: flex;
    flex-direction: row;
  }

  .app > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // align-items: center;
  }

  .row {
    flex-direction: row !important;
  }

  .row > * {
    margin: 5px;
  }

  h1 {
    font-family: "Damion", cursive;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  }

  .swipe {
    position: absolute;
    overflow-y: hidden;
  }

  .cardContainer {
    width: 100%;
    /* max-width: 260px; */
    height: 100%;
    display: flex;
    // background-color: orange;
    flex-direction: row;
    .swipe {
      min-width: 410px;
    }
  }

  .card {
    // position: relative;
    background-color: #fb7d8a;
    // background-color: blue;
    overflow-x: hidden;
    width: 100%;

    height: 580px;
    // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    // flex-direction: row;
    // justify-content: flex-start;
    // display: flex;
  }

  .cardContent {
    width: 100%;
    height: 100%;
  }

  .swipe:last-of-type {
  }

  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 10% 20px 0;

    width: 100%;

    .cardContainer {
      .swipe {
        min-width: 90%;

        width: 90%;
        display: flex;
        justify-content: center;
      }
    }

    @media only screen and (max-width: 420px) {
      padding: 20% 20px 0;
    }
  }
`;

export const StyledCardContainer = styled.div`
  padding: 8% 30px 0;
  margin-bottom: 10%;
  display: flex;
  flex: 100vw;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;

  @media only screen and (max-width: 600px) {
    padding: 8% 20px 0;
  }
`;

export const StyledTaskTitle = styled.span`
  color: white;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  width: 80%;

  @media only screen and (max-width: 600px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 25px;
  }
`;

export const CancelButton = styled(Button)`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;
