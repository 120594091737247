import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import John from "../../../assets/images/John.png";
import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";
import statusColors from "../../../assets/statusColors";
import { Energy } from "../../../assets/svg";
import { Qualification } from "../../../interfaces/Qualification";
import { ownerTaskListState } from "../../../state/atoms";
const allStatuses = { ...ownerStatuses, ...paStatuses };

const Status = styled.div<{ color: string }>`
  background: ${(props) => props.color || "black"};
  border-radius: 20px;
  border: 1px solid transparent;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 6rem;
  height: 22px;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  margin-right: 10px;

  @media only screen and (max-width: 780px) {
    margin-right: 10px;
  }
  padding: 0px 5px;
`;

type Props = {
  isOwnerLoading: any;
  ownerData: any;
  qualificationsList: Qualification[];
  addQualifications: any;
  ownerTasks: any;
  isOwnerTasksLoading: boolean;
  tasksBefore: any;
  tasksAfter: any;
  userRefetching: boolean;
  openRemoveQualificationModal: (id: any) => void;
};

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export default function AdminOwnerAccountTemplate({
  ownerData,
  isOwnerLoading,
  qualificationsList,
  addQualifications,
  ownerTasks,
  tasksBefore,
  tasksAfter,
  isOwnerTasksLoading,
  userRefetching,
  openRemoveQualificationModal,
}: Props) {
  const [ownerTaskList, setOwnerTaskList] = useRecoilState(ownerTaskListState);
  const [value, setValue] = useState<DateRange<Dayjs>>([
    ownerTaskList?.filters?.dates[0] || null,
    ownerTaskList?.filters?.dates[1] || null,
  ]);

  const navigate = useNavigate();

  const handleTaskListNextPage = () => {
    if (tasksAfter != null) {
      setOwnerTaskList({
        filters: ownerTaskList.filters,
        before: null,
        after: tasksAfter,
        pageNum: ownerTaskList.pageNum + 1,
      });
    }
  };
  const handleTaskListPreviousPage = () => {
    if (tasksBefore != null) {
      setOwnerTaskList({
        filters: ownerTaskList.filters,
        before: tasksBefore,
        after: null,
        pageNum: ownerTaskList.pageNum - 1,
      });
    }
  };
  const handleTaskListChangeStatus = (event) => {
    setOwnerTaskList({
      filters: {
        status: event.target.value,
        dates: ownerTaskList.filters.dates,
      },
      before: null,
      after: null,
      pageNum: 1,
    });
  };

  return (
    <>
      {isOwnerLoading || userRefetching ? (
        <div className="flex items-center justify-center">
          <CircularProgress color="inherit" size="6rem" />
        </div>
      ) : (
        ownerData && (
          <div className="bg-mainBg flex h-full justify-center odMax:w-full flex-col items-center space-y-4">
            <div className="flex min-w-[1100px] w-[75%] justify-start items-start flex-col mt-[38px] mx-0 mb-[30px] odMax:min-w-[100%] odMax:w-full odMax:py-0 odMax:px-[2%]">
              <div className="font-gilroy not-italic font-extrabold text-4xl leading-[26px]  tracking-[0.46px text-black">
                Task Owner
              </div>
              <div className="flex justify-between bg-commentBg rounded-xl pt-4 pr-8 pb-5 pl-8 mt-7 w-full ">
                <Box display="flex" alignItems="center">
                  <img
                    src={John}
                    className="w-40 h-40 self-center rounded-3xl"
                  />
                  <Box display="flex" flexDirection="column" marginLeft="20px">
                    <div className="font-inter not-italic font-bold text-[48px] leading-[26px] tracking-[0.46px] text-black">
                      {ownerData?.user_name
                        ? ownerData?.user_name
                        : ownerData?.address
                        ? ownerData?.address.substring(0, 5) +
                          "..." +
                          ownerData?.address.substring(
                            ownerData?.address.length - 2,
                          )
                        : "Not Set"}
                    </div>
                    {ownerData?.email && (
                      <div className="mt-4 font-inter not-italic font-medium leading-[26px] tracking-[0.46px] text-black">
                        {ownerData?.email}
                      </div>
                    )}
                    <div className="font-inter mt-5 text-base text-black font-bold">
                      Authorized Qualifications
                    </div>
                    <div className="grid grid-cols-4 gap-x-3 gap-y-2 text-center mt-1">
                      {ownerData.authorized_qualifications.map((value, key) => {
                        return (
                          <div
                            className="group relative px-2 py-1 border rounded-full text-sm h-full min-w-[100px] bg-title text-white hover:opacity-80 flex items-center justify-center"
                            key={key}>
                            {value.name}
                            <div
                              onClick={() => {
                                openRemoveQualificationModal(value.id);
                              }}
                              className="absolute flex top-0 p-[1.5px] right-0 rounded-full bg-[#00000054] border border-white opacity-0 hover:scale-150 ease-in-out duration-300 group-hover:opacity-100 cursor-pointer">
                              <svg
                                className="h-3 w-3 text-gray-700"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        onClick={addQualifications}
                        className="px-4 py-1 border rounded-full text-sm h-full bg-title text-white hover:opacity-90 hover:scale-[1.1] ease-in-out duration-300 cursor-pointer">
                        +
                      </div>
                    </div>
                  </Box>
                </Box>
              </div>
            </div>
            <div className="min-h-[800px] h-[80%] min-w-[1100px] w-[75%]">
              <div className="flex justify-between items-center">
                <div className="font-gilroy not-italic font-extrabold text-[40px] leading-[26px] tracking-[0.46px] text-black">
                  Tasks
                </div>
                <div
                  className="flex gap-6 items-center"
                  style={{ display: "flex", gap: 6, alignItems: "center" }}>
                  <div className="relative">
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-select-small">Status</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={ownerTaskList.filters.status}
                        label="Status"
                        onChange={handleTaskListChangeStatus}>
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="published">Published</MenuItem>
                        <MenuItem value="in_progress">Active</MenuItem>
                        <MenuItem value="clarification_needed">
                          Clarification
                        </MenuItem>
                        <MenuItem value="submitted">Review</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="draft">Draft</MenuItem>
                        <MenuItem value="none">Template</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: "Start Date", end: "End Date" }}>
                    <DateRangePicker
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        setOwnerTaskList({
                          filters: {
                            status: ownerTaskList.filters.status,
                            dates: [
                              newValue[0]?.format(DATE_FORMAT)
                                ? newValue[0]?.format(DATE_FORMAT)
                                : null,
                              newValue[1]?.format(DATE_FORMAT)
                                ? newValue[1]?.format(DATE_FORMAT)
                                : null,
                            ],
                          },
                          pageNum: 0,
                          after: null,
                          before: null,
                        });
                      }}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} />
                          <Box sx={{ mx: 0 }}> - </Box>
                          <TextField {...endProps} />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="mt-[32px] bg-[#ffffff] w-full text-left border border-solid border-adminAssistantBorder rounded-lg">
                  <thead className="bg-[#f9fafb] h-[40px] font-inter not-italic text-[12px] leading-[16px] tracking-tighter uppercase color text-adminTableHead">
                    <tr>
                      <th className="pl-[16px]">Task Name</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Last Modified</th>
                      <th className="text-center">Energies</th>
                      <th></th>
                    </tr>
                  </thead>
                  {isOwnerTasksLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <div className="h-[400px] w-full flex">
                            <CircularProgress
                              color="inherit"
                              size="6rem"
                              sx={{ margin: "auto" }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="text-adminTableBody">
                      {ownerTasks.results.length > 0 ? (
                        ownerTasks.results
                          .sort((a, b) => b.latest_change - a.latest_change)
                          .map((item, index) => (
                            <tr key={index}>
                              <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                                <div className="flex flex-col">
                                  <div className="not-italic text-[14px] font-semibold leading-[20px] text-adminTableBody font-inter">
                                    {item.title}
                                  </div>
                                </div>
                              </td>
                              <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                                <div className="flex flex-col">
                                  <div className="not-italic text-[14px] font-semibold leading-[20px] text-adminTableBody font-inter">
                                    <div className="flex flex-col items-center">
                                      <Status
                                        color={statusColors?.[item.status]}>
                                        {allStatuses?.[item.status] ||
                                          item.status}
                                      </Status>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder ">
                                <div className="flex flex-col items-center">
                                  <div className="not-italic text-[14px] font-semibold leading-[20px] text-adminTableBody font-inter">
                                    {`${new Date(
                                      item.latest_change,
                                    ).toLocaleDateString()}, ${new Date(
                                      item.latest_change,
                                    ).toLocaleTimeString()}`}
                                  </div>
                                </div>
                              </td>
                              <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder">
                                <div className="flex flex-row items-center justify-center gap-1">
                                  <div className="font-bold">
                                    {item.energy_assigned}
                                  </div>
                                  <Energy />
                                </div>
                              </td>
                              <td className="p-4 border-0 border-t-[1px] border-solid border-t-adminAssistantBorder">
                                <div
                                  className="text-[14px] cursor-pointer not-italic font-inter font-normal leading-[20px] tracking-[0.15px] text-[#4f46e5] text-center"
                                  onClick={() => {
                                    navigate(`/tasks/view/${item.task_id}`);
                                  }}>
                                  View
                                </div>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan={10}>
                            <div className="flex items-center justify-center m-auto text-3xl select-none h-[400px] w-full">
                              No tasks found
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="flex h-max items-center justify-end md:justify-end mt-5">
                <span
                  onClick={() =>
                    tasksBefore ? handleTaskListPreviousPage() : null
                  }
                  className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                    tasksBefore === null ? "opacity-50 cursor-not-allowed" : ""
                  }`}>
                  Previous
                </span>
                <span className="mx-2 text-gray-500 font-medium">
                  {ownerTaskList.pageNum + 1}
                </span>
                <span
                  onClick={() => (tasksAfter ? handleTaskListNextPage() : null)}
                  className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                    tasksAfter === null ? "opacity-50 cursor-not-allowed" : ""
                  }`}>
                  Next
                </span>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
