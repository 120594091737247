import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { deleteTask, getTaskByHolderDashboard } from "../../../services/tasks";
import { authState, paginationState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import Modal from "../../organisms/Modal";
import OwnerDashboardTemplate from "../../templates/OwnerDashboard";

import { createTask } from "../../../services/tasks";
import useUserFetch from "../../hooks/useUserFetch";
import { TemplateSchema } from "./validation";

const StyledContainer = styled.div`
  height: calc(100% - 64px);
`;

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

const StyledFormWrapper = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

type UserData = {
  user_name: string;
  user_email: string;
  user_id: string;
  country?: string;
  payout_address?: string;
  comment?: string | null;
  welcome?: string | null;
  max_energy?: number;
  onboarding?: string;
};

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [fetchFavorites, setFetchFavorites] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFavoriteTemplate, setShowFavoriteTemplate] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState("");
  const [fetchType, setFetchType] = useState(pagination.fetchType);
  const [after, setAfter] = useState(null);
  const [before, setBefore] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [taskToTemplate, setTaskToTemplate] = useState({});
  const [user, setUser] = useState<UserData>();

  //Email Modal
  const [isEmailArcadeLoading, setIsEmailArcadeLoading] = useState(false);

  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);

  // useEffect(() => {
  //   // refetch();
  //   console.log("refechted");
  // }, [fetchType, after, before]);

  useEffect(() => {
    setTaskLoading(true);
  }, []);
  const handleSuccess = (data) => {
    setUser(data.user);
  };

  const handleErrorAuthUpdate = () => {
    setAuth((auth) => ({ ...auth, energy: 0 }));
  };
  const handleSuccessAuthUpdate = (user) => {
    setAuth((auth) => ({
      ...auth,
      energy: user.remaining_energy,
      email: user.email,
    }));
  };

  const {
    user: userData,
    isLoading: userDataLoading,
    refetch: userRefetch,
  } = useUserFetch(
    auth,
    "nft",
    auth.user_id,
    [handleSuccess],
    [],
    handleSuccessAuthUpdate,
    handleErrorAuthUpdate,
  );

  const {
    data: tasksData,
    isLoading: isLoading,
    refetch,
  } = useQuery(
    ["fetchingOwnerTasks", auth.accessToken, fetchType, after, before],
    () => {
      if (auth.accessToken) {
        return getTaskByHolderDashboard(
          auth.accessToken,
          auth.user_id,
          pagination.fetchType,
          pagination.after,
          pagination.before,
        );
      }
    },
    {
      onSuccess: () => {
        setTaskLoading(false);
      },
      onError: (e) => {
        console.log("error", e);
      },
    },
  );

  const {
    mutate: mutateDeleteTask,
    isLoading: deletingTask,
    error,
  } = useMutation(
    "deleteTask",
    (taskId: string) => {
      return deleteTask(auth.accessToken, taskId);
    },
    {
      onSuccess: () => {
        setSnackbar({
          title: "Success",
          content: "Successfully deleted task.",
          type: "success",
        });
        refetch();
        userRefetch();
      },
      onError: (e, variables) => {
        console.log(e);
        setSnackbar({
          title: "Error",
          content: e.message,
          type: "error",
        });
      },
      onSettled: () => {
        setTaskToDelete("");
        setShowConfirmation(false);
      },
    },
  );

  const {
    mutate: mutateTemplateTask,
    isLoading: isTemplatingTask,
    error: templateError,
  } = useMutation(
    "templateTask",
    (values: any) => {
      return createTask(auth.accessToken, values, false);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully set as favorite.",
          type: "success",
        });
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
      onSettled: () => {
        setTaskToTemplate({});
        setShowFavoriteTemplate(false);
      },
    },
  );

  const tasks = tasksData?.tasks || [];
  const after_ = tasksData?.after || null;
  const before_ = tasksData?.before || null;
  if (userDataLoading || taskLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    user && (
      <StyledContainer className="text-title bg-mainBg">
        <OwnerDashboardTemplate
          tasks={tasks}
          nft={null}
          handleDeleteTask={handleDeleteTask}
          handleFavoriteTask={handleFavoriteTask}
          after={after_}
          before={before_}
          energy={auth?.energy}
          handleSwitchFetchedTasks={handleSwitchFetchedTasks}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          isFavorites={fetchFavorites}
          fetchType={fetchType}
          isLoading={isLoading || taskLoading}
          handleShowGuide={showGuideModal}
          user={user}
        />
        <Modal
          title="Delete Task"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showConfirmation}
          onClose={closeModal}>
          <div
            style={{ maxWidth: 450 }}
            data-test-id="dashboard-delete-task-modal">
            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <Typography fontWeight={700} fontFamily="Inter">
                Are you sure you want to delete this task?
              </Typography>
            </div>
            <ConfirmButton
              data-test-id="dashboard-delete-task-modal-confirm"
              variant="contained"
              onClick={handleConfirmDelete}
              fullWidth>
              Yes, delete the task
            </ConfirmButton>
            <CancelButton variant="contained" onClick={closeModal} fullWidth>
              No, Cancel
            </CancelButton>
          </div>
        </Modal>
        <Modal
          title="Favorite Task"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showFavoriteTemplate}
          onClose={cancelFavorite}>
          <Formik
            initialValues={{ title: "" }}
            validationSchema={TemplateSchema}
            onSubmit={(values) => {
              mutateTemplateTask({ ...values, ...taskToTemplate });
            }}>
            {({ values, errors, handleChange, handleSubmit }) => {
              return (
                <div
                  style={{ width: 450 }}
                  data-test-id="dashboard-favorite-task-modal">
                  <div style={{ textAlign: "left" }}>
                    <StyledFormWrapper onSubmit={handleSubmit}>
                      <TextField
                        id="title"
                        name="title"
                        label="Title"
                        variant="filled"
                        style={{
                          width: "98%",
                          paddingBottom: errors?.title ? 18 : 30,
                        }}
                        onChange={handleChange}
                        error={!!errors.title}
                        helperText={errors?.title}
                        inputProps={{
                          "data-test-id": "dashboard-favorite-task-modal-input",
                        }}
                      />
                      <ConfirmButton
                        data-test-id="dashboard-favorite-task-modal-confirm"
                        variant="contained"
                        type="submit"
                        onClick={() => {}}
                        fullWidth
                        disabled={isTemplatingTask}>
                        Set as Favorite
                      </ConfirmButton>
                      <CancelButton
                        variant="contained"
                        onClick={cancelFavorite}
                        fullWidth>
                        No, Cancel
                      </CancelButton>
                    </StyledFormWrapper>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          title="Guide"
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
          open={showGuide}
          onClose={closeModal}
          type={"700px"}>
          <div style={{ maxWidth: 700 }}>
            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <Typography fontWeight={700} fontFamily="Inter">
                How to enable email notifications?
              </Typography>
            </div>
            <div
              style={{
                position: "relative",
                paddingBottom: "calc(51.25661375661375% + 41px)",
                height: "0px",
              }}>
              <iframe
                src="https://demo.arcade.software/upkR0j31KCy0sbeqjrrI?embed"
                frameBorder="0"
                loading="eager"
                onLoad={() => {
                  setIsEmailArcadeLoading(false);
                }}
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                }}></iframe>
              {isEmailArcadeLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "369.8px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <div style={{ margin: "auto" }}>
                    <CircularProgress color="inherit" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <ConfirmButton
              variant="contained"
              onClick={closeGuideModal}
              sx={{ marginTop: "10px" }}
              fullWidth>
              Understood
            </ConfirmButton>
          </div>
        </Modal>
      </StyledContainer>
    )
  );

  function cancelFavorite() {
    setShowFavoriteTemplate(false);
  }

  function closeModal() {
    setShowConfirmation(false);
  }

  function closeGuideModal() {
    setShowGuide(false);
  }

  function handleSwitchFetchedTasks(fetchtype: string) {
    // if (!isLoading) {
    // setFetchFavorites(isFav);
    setFetchType(fetchtype);
    setAfter(null);
    setBefore(null);
    // refetch();
    // }
  }

  function handleNextPage(after: any) {
    if (!isLoading) {
      setAfter(after);
      setBefore(null);
    }
  }

  function handlePrevPage(before: any) {
    if (!isLoading) {
      setAfter(null);
      setBefore(before);
    }
  }

  function handleFavoriteTask(task: any) {
    const taskToTemplate: any = {
      ...task,
      is_template: true,
      date: new Date().toISOString(),
    };
    delete taskToTemplate.delivery;
    delete taskToTemplate.comments;
    delete taskToTemplate.assistant_id;
    delete taskToTemplate.title;

    setTaskToTemplate(taskToTemplate);
    setShowFavoriteTemplate(true);
  }

  function handleDeleteTask(taskId: string) {
    setTaskToDelete(taskId);
    setShowConfirmation(true);
  }

  function showGuideModal() {
    setShowGuide(true);
    setIsEmailArcadeLoading(true);
  }

  function handleConfirmDelete() {
    mutateDeleteTask(taskToDelete);
  }
};

export default Component;
