const getAllQualifications = async (token, isGlobal) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        size: -1,
        after: null,
        before: null,
        filters: {
          is_global: isGlobal,
        },
      }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAllPaginatedQualifications = async (token, qualificationsState) => {
  const payload = JSON.parse(JSON.stringify(qualificationsState));
  delete payload.pageNumber;
  if (payload.filters.is_global === "all") {
    payload.filters.is_global = null;
  }
  if (payload.filters.is_global === "public") {
    payload.filters.is_global = true;
  }
  if (payload.filters.is_global === "private") {
    payload.filters.is_global = false;
  }

  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getQualificationById = async (token, id) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const addQualificationToUser = async (token, values) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications/user`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const removeQualificationToUser = async (token, values) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications/user`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const updateQualificationById = async (token, values) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications/${values.id}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: values.name,
        max_in_progress: values.max_in_progress,
        max_clarification: values.max_clarification,
      }),
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const createQualification = async (token, values) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAllQualificationsInTasks = async (token) => {
  const response = await fetch(process.env.API_URL + `/api/qualifications`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      size: -1,
      after: null,
      before: null,
      filters: {
        is_global: true,
      },
    }),
  });
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const deleteQualificationByid = async (token, id) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/qualifications/${id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    },
  );
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

export {
  addQualificationToUser,
  createQualification,
  deleteQualificationByid,
  getAllPaginatedQualifications,
  getAllQualifications,
  getAllQualificationsInTasks,
  getQualificationById,
  removeQualificationToUser,
  updateQualificationById,
};
