import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Task } from "../../../interfaces/Task";
import {
  addRatingData,
  addTaskComment,
  changeEnergy,
  createTask,
  getTaskById,
  getTranslation,
  updateTaskByFileUpload,
} from "../../../services/tasks";
import ViewMissionTemplate from "../../templates/ViewMission";

import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { EnergyBar } from "../../../assets/svg";
import {
  getChildMissionsByParentId,
  setTaskStatus,
} from "../../../services/tasks";
import { authState, taskState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";
import useUserFetch from "../../hooks/useUserFetch";
import Modal from "../../organisms/Modal";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;
  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;
  &:hover {
    background: #e8e8e8;
  }
`;

type Props = {
  isAdmin: boolean;
  active: any;
};

const Component = ({ isAdmin, active }): Props => {
  const [auth, setAuth] = useRecoilState(authState);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [taskLoading, setTaskLoading] = useState(false);
  //Change here
  const [hasInsufficientEnergy, setHasInsufficientEnergy] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isCompleteTask, setIsCompleteTask] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isDrafted, setIsDrafted] = useState(false);
  const [isChangingState, setIsChangingState] = useState(false);
  const [showChangeEnergyModal, setShowChangeEnergyModal] = useState(false);
  const [newEnergyValue, setNewEnergyValue] = useState(0);
  const [hasTouchedEnergy, setHasTouchedEnergy] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const [isEnergyChanging, setIsEnergyChanging] = useState(false);
  const [isEnergyChanged, setIsEnergyChanged] = useState(false);

  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);
  const [translatedComment, setTranslatedComment] = useState("");
  const [translatedCommentIndexes, setTranslatedCommentIndexes] = useState([]);
  const [descriptionTranslated, setDescriptionTranslated] = useState(false);
  const [
    loadingCommentsTranslationIndexes,
    setLoadingCommentsTranslationIndexes,
  ] = useState([]);

  let { id } = useParams();

  const resetTranslationState = () => {
    setDescriptionTranslated(false);
  };

  const { user: userData, isLoading: isUserDataLoading } = useUserFetch(
    auth,
    "admin",
    auth.user_id,
  );

  const {
    data: tasksData,
    isLoading: loadingTask,
    refetch,
  } = useQuery(
    ["fetchingTask", auth.accessToken, id],
    () => {
      if (auth.accessToken) {
        return getTaskById(auth.accessToken, id, true);
      }
    },
    {
      onSuccess: (resp) => {
        resp.task.videos = !resp.task.videos ? [] : resp.task.videos;
        resp.task.urls = !resp.task.urls ? [] : resp.task.urls;

        setActiveTask(resp.task);
        setTaskLoading(false);
      },
      onError: (e) => {
        console.log("error", e);
      },
    },
  );

  const { data: childTaskData, isLoading: loadingChildTaskData } = useQuery(
    ["fetchingChildTasksData", auth.accessToken, id],
    () => {
      if (auth.accessToken) {
        return getChildMissionsByParentId(auth.accessToken, id);
      }
    },
    {
      onSuccess: (resp) => {},
      onError: (e) => {
        console.log("error", e);
      },
    },
  );

  useEffect(() => {
    setTaskLoading(true);
  }, []);

  const {
    mutate: mutateChangeTaskState,
    isLoading,
    error,
  } = useMutation(
    "changeMissionState",
    (data: any) => {
      setIsChangingState(true);
      if (["published", "draft", "published"].includes(data.state)) {
        return setTaskStatus(auth.accessToken, { id, status: data.state });
      } else {
        return Promise.reject("Unexpected state change request");
      }
    },
    {
      onSuccess: (resp, data) => {
        setIsChangingState(false);
        if (resp.success) {
          if (data.state === "published") {
            setIsPublished(true);
          }
          if (data.state === "draft") {
            setIsDrafted(true);
          }

          setSnackbar({
            title: "Success",
            content:
              data.state === "draft"
                ? "Your mission is now set to draft."
                : "Your mission is now published!",
            type: "success",
          });
          if (data.state === "draft") {
            refetch();
          } else {
            setTimeout(() => {
              setIsPublished(false);
              navigate("/missions");
            }, 1500);
          }
        } else {
          setSnackbar({
            title: "Alert!",
            content: resp.message,
            type: "warning",
          });
        }
      },
      onError: (e, variables) => {
        setIsChangingState(false);
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: completeTask,
    isLoading: isTaskCompleting,
    error: completeTaskError,
  } = useMutation(
    "completeMission",
    () => {
      return setTaskStatus(auth.accessToken, { id, status: "completed" });
    },
    {
      onSuccess: (resp) => {
        if (resp.success) {
          setSnackbar({
            title: "Success",
            content: "Mission is completed!",
            type: "success",
          });
          setIsCompleteTask(true);
        } else {
          setSnackbar({
            title: "Alert!",
            content: "Task could not be completed.",
            type: "warning",
          });
          setIsCompleteTask(false);
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: rejectTaskSubmission,
    isLoading: isTaskBeingRejected,
    error: taskRejectionError,
  } = useMutation(
    "rejectMissionSubmission",
    (reasonString: string) => {
      if (!reasonString && reasonString == "") {
        return setTaskStatus(auth.accessToken, {
          id,
          status: "submission_rejected",
        });
      }
      return setTaskStatus(auth.accessToken, {
        id,
        reason: reasonString,
        status: "submission_rejected",
      });
    },
    {
      onSuccess: (resp) => {
        if (resp.success) {
          setSnackbar({
            title: "Success",
            content: "The submission was sucessfully rejected!",
            type: "success",
          });
          setRejected(true);

          setTimeout(() => {
            navigate(`/missions`);
          }, 1000);

          // refetch(); Do not refecth because the state will be in-progress which will change what is being shown on the page
        } else {
          setSnackbar({
            title: "Alert!",
            content: "The submission could not be rejected.",
            type: "warning",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateSubmitComment,
    isLoading: isSubmittingComment,
    error: commentError,
  } = useMutation(
    ["submitMissionComment", auth.accessToken, id],
    (comment: any) => {
      return addTaskComment(auth.accessToken, comment, id);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "Comment posted.",
          type: "success",
        });
        refetch();
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateChangeEnergy, isLoading: isChangingEnergy } =
    useMutation(
      ["changeMissionEnergy", auth.accessToken, id],
      () => {
        return changeEnergy(auth.accessToken, newEnergyValue, id);
      },
      {
        onSuccess: (resp, variables) => {
          setIsEnergyChanging(false);
          setIsEnergyChanged(true);
          setSnackbar({
            title: "Success",
            content: "Energy successfuly changed",
            type: "success",
          });

          setTimeout(() => {
            setIsEnergyChanged(false);
            refetch();
          }, 2000);
        },
        onError: (e, variables) => {
          setSnackbar({
            title: "Error",
            content: e.message,
            type: "error",
          });
        },
      },
    );

  const {
    mutate: mutateAddRating,
    isLoading: isAddRating,
    error: addRatingError,
  } = useMutation(
    ["addMissionRating", auth.accessToken, id],
    (ratingdata: any) => {
      return addRatingData(auth.accessToken, ratingdata, id);
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "Rating posted.",
          type: "success",
        });
        navigate("/");
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateUpdateTask,
    isLoading: isUpdatingTask,
    error: updateTaskError,
  } = useMutation(
    ["updateMission", auth.accessToken, id],
    (taskParam: any) => {
      const taskPayload = { attachments: taskParam.attachments };
      return updateTaskByFileUpload(
        auth.accessToken,
        taskPayload,
        taskParam.task_id,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setSnackbar({
          title: "Success",
          content: "Successfully accepted the submission",
          type: "success",
        });
        refetch();
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: mutateCreateTask,
    isLoading: isCreateLoading,
    error: errorCreate,
  } = useMutation(
    "createMission",
    (values: any) => {
      values.task.is_template = false;
      return createTask(auth.accessToken, values.task, values.isPublish);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully created new mission.",
          type: "success",
        });
        navigate("/missions/view/" + resp.id);
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleDescriptionTranslation,
    isLoading: descriptionTranslationLoading,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      const isPreferred = true;
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
        isPreferred,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setActiveTask((prev) => ({ ...prev, description: resp.message }));
        setDescriptionTranslated(true);
        setSnackbar({
          title: "Success",
          content: "Successfully translated task description",
          type: "success",
        });
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleOutputTranslation,
    isLoading: isOutputTranslating,
    error: outputTranslationError,
    isSuccess: isOutputTranslated,
  } = useMutation(
    "translateOutput",
    (values: any) => {
      const isPreferred = true;
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.BASIC,
        isPreferred,
      );
    },
    {
      onSuccess: (resp, variables) => {
        setActiveTask((prev) => ({
          ...prev,
          delivery: { description: resp.message },
        }));
        setSnackbar({
          title: "Success",
          content: "Successfully translated output",
          type: "success",
        });
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: handleTranslateExistingComment,
    isLoading: translateExistingCommentLoading,
  } = useMutation(
    "translateCommentInput",
    (values: any) => {
      const isPreferred = true;
      setLoadingCommentsTranslationIndexes((prevState) => [
        ...prevState,
        values.id,
      ]);
      return getTranslation(
        auth.accessToken,
        values.text,
        values.source,
        isPreferred,
      );
    },
    {
      onSuccess: (data, variables) => {
        setActiveTask((prevState) => {
          const updatedComments = [...prevState.comments];

          // Find the index of the comment to be updated
          const commentIndex = updatedComments.findIndex(
            (comment) => comment.comment_id === variables.id,
          );

          if (commentIndex !== -1) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              text: data.message,
            };
          }

          // Return the updated state
          return { ...prevState, comments: updatedComments };
        });
        setLoadingCommentsTranslationIndexes((prevState) =>
          prevState.filter((x) => x !== variables.id),
        );
        setTranslatedCommentIndexes((prevState) => {
          return [...prevState, variables.id];
        });
        setSnackbar({
          title: "Success",
          content: `Successfully translated the comment`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const {
    mutate: handleTranslateInputComment,
    isLoading: isInputCommentTranslationLoading,
  } = useMutation(
    "translateComment",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.comment,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp) => {
        setTranslatedComment(resp.message);
        setSnackbar({
          title: "Success",
          content: `Successfully translated the comment input`,
          type: "success",
        });
      },
      onError: () => {},
    },
  );

  const resetTranslation = useCallback(() => {
    setTranslatedComment("");
  }, []);

  if (loadingTask || loadingChildTaskData || taskLoading || isUserDataLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <StyledContainer className="bg-mainBg flex justify-center items-center">
      <Modal
        title="Change Energy"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showChangeEnergyModal}
        onClose={closeChangeEnergyModal}>
        <div style={{ maxWidth: 460 }}>
          <div style={{ marginBottom: 0, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Input the new amount of energy for the task
              <div>
                <TextField
                  sx={{ width: 360 }}
                  multiline={false}
                  rows={1}
                  type="number"
                  className="bg-[#fdfdfd] w-4/5 m-0 rounded-md"
                  variant="filled"
                  size="small"
                  value={activeTask.energy_assigned}
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                      textAlign: "center",
                      margin: "0",
                      borderRadius: "0.375rem",
                      width: "80%",
                      background: "#fdfdfd",
                    },
                    pattern: "^d*(.d+)?$",
                    placeholder: "Energies",
                    disableUnderline: true,
                    step: "0.05",
                  }}
                  onChange={(e) => {
                    if (e.target.value === null || e.target.value === 0) {
                      setNewEnergyValue(0);
                      return;
                    }

                    const value: any = parseFloat(e.target.value).toFixed(2);

                    const rounded = Math.ceil(value / 0.05) * 0.05;

                    // Convert rounded value to a number and back to string for comparison
                    const truncated = Number(rounded.toFixed(2)).toString();

                    // If the original value matches the truncated value, set it with no decimal places
                    e.target.value =
                      rounded.toFixed(2) === truncated
                        ? parseInt(truncated)
                        : rounded.toFixed(2);

                    e.target.value = rounded.toFixed(2);

                    if (parseFloat(e.target.value) > 1000) {
                      e.target.value = "1000.00";
                    }

                    if (e.target.value) {
                      // Check if the value has .00 decimal
                      let fieldValue = e.target.value.endsWith(".00")
                        ? parseInt(e.target.value, 10)
                        : e.target.value;

                      setNewEnergyValue(Number(fieldValue));
                    }
                  }}
                />
                <EnergyBar filled />
              </div>
            </Typography>
          </div>

          {/* APPLY LOGIC HERE */}
          {hasInsufficientEnergy ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "red",
              }}>
              You have insufficient energy credits for the desired change
            </div>
          ) : null}

          <ConfirmButton
            disabled={hasInsufficientEnergy || !hasTouchedEnergy}
            variant="contained"
            className="flex flex-row justify-center items-center m-auto text-center"
            onClick={() => {
              handleConfirmChangeEnergy();
            }}
            fullWidth>
            Yes, change the energy
          </ConfirmButton>
          <CancelButton
            type="button"
            variant="contained"
            onClick={closeChangeEnergyModal}
            fullWidth>
            Cancel
          </CancelButton>
        </div>
      </Modal>

      <Modal
        title="Reject Submission"
        type={"extended"}
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={rejectModal}
        onClose={closeModal}>
        <div style={{ maxWidth: 900 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Are you sure you wish to reject the submission of this task?
            </Typography>
          </div>

          <Typography
            fontWeight={500}
            fontFamily="Inter"
            fontSize="16px"
            paddingBottom="13px"
            fontStyle="italic"></Typography>
          <Typography
            fontWeight={700}
            fontFamily="Inter"
            fontSize="16px"
            paddingBottom="5px">
            Please state the reason below:
          </Typography>
          <TextField
            fullWidth
            placeholder="State the reason for the rejection"
            multiline={true}
            rows={4}
            sx={{ height: "150px" }}
            variant="filled"
            onChange={(e) => {
              setRejectReason(e.target.value);
            }}
          />

          <ConfirmButton
            variant="contained"
            onClick={() => {
              handleConfirmReject(rejectReason);
            }}
            fullWidth
            disabled={
              rejectReason == "" && activeTask.status != "clarification_needed"
            }>
            Yes, reject the submission
          </ConfirmButton>
          <CancelButton variant="contained" onClick={closeModal} fullWidth>
            No, Cancel
          </CancelButton>
        </div>
      </Modal>

      <ViewMissionTemplate
        handleSubmit={mutateChangeTaskState}
        submitComment={mutateSubmitComment}
        isSubmittingComment={isSubmittingComment}
        updateTask={mutateUpdateTask}
        createTask={mutateCreateTask}
        completeTask={completeTask}
        addRating={mutateAddRating}
        isTaskCompleting={isTaskCompleting}
        rejectTaskSubmission={handleReject}
        isTaskBeingRejected={isTaskBeingRejected}
        holder_id={auth?.user_id}
        submissionRejected={rejected}
        isCompleteTask={isCompleteTask}
        isLoading={taskLoading}
        isAdmin={isAdmin}
        active={active}
        isPublished={isPublished}
        isChangingState={isChangingState}
        isDrafted={isDrafted}
        changeEnergy={handleChangeEnergy}
        changeEnergyStates={{
          energyChanging: isEnergyChanging,
          energyChanged: isEnergyChanged,
        }}
        childTaskData={childTaskData}
        descriptionTranslationLoading={descriptionTranslationLoading}
        handleDescriptionTranslation={handleDescriptionTranslation}
        handleOutputTranslation={handleOutputTranslation}
        isOutputTranslating={isOutputTranslating}
        userData={userData}
        handleTranslateExistingComment={handleTranslateExistingComment}
        translateExistingCommentLoading={translateExistingCommentLoading}
        resetTranslation={resetTranslation}
        resetTranslationState={resetTranslationState}
        translatedComment={translatedComment}
        handleTranslateInputComment={handleTranslateInputComment}
        isInputCommentTranslationLoading={isInputCommentTranslationLoading}
        isOutputTranslated={isOutputTranslated}
        descriptionTranslated={descriptionTranslated}
        translatedCommentIndexes={translatedCommentIndexes}
        loadingCommentsTranslationIndexes={loadingCommentsTranslationIndexes}
      />
    </StyledContainer>
  );
  function closeModal() {
    setRejectModal(false);
  }
  function handleReject() {
    setRejectModal(true);
  }
  function handleChangeEnergy() {
    setShowChangeEnergyModal(true);
  }

  function closeChangeEnergyModal() {
    setShowChangeEnergyModal(false);
  }

  function handleConfirmChangeEnergy() {
    setShowChangeEnergyModal(false);
    setIsEnergyChanging(true);
    setHasTouchedEnergy(false);
    mutateChangeEnergy();
  }

  function handleConfirmReject(reasonString) {
    setRejectModal(false);
    rejectTaskSubmission(reasonString);
  }
};

export default Component;
