import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import CircularProgress from "@mui/material/CircularProgress";
import { getMissions, getTasksByPAId } from "../../../services/tasks";
import { authState } from "../../../state/atoms";
import TaskItemAssistant from "../../organisms/TaskItemAssistant";
import TaskStatusTemplate from "../../templates/TaskStatus";

import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";
import { getTotalEarningsByUserId } from "../../../services/users";
import useUserFetch from "../../hooks/useUserFetch";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const StyledPurpleButton = styled.span`
  background-color: #6f5acd;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  color: white;
  padding-bottom: 9px;
  padding-top: 9px;
  padding-left: 30px;
  padding-right: 30px;
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const ShadowedContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 80%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const LeftBox = styled(Box)`
  flex: 0.68;
  @media screen and (max-width: 760px) {
    flex: 1;
    row-gap: 16px;
    margin-bottom: 16px;
  }
`;

const RightBox = styled(Box)`
  flex: 0.3;
  @media screen and (max-width: 760px) {
    flex: 1;
    padding-bottom: 20px;
  }
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: space-between;
  margin: 38px 0 30px;

  @media only screen and (max-width: 760px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const TaskItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  > div {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const Component = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);
  const [taskLoading, setTaskLoading] = useState(true);
  const { user: userData, isLoading: isUserDataLoading } = useUserFetch(
    auth,
    "assistant",
    auth.user_id,
  );

  const { data: taskData, isLoading } = useQuery(
    ["fetchingTasks", auth.accessToken, auth.user_id],
    () => {
      if (auth.accessToken && auth.user_id) {
        setTaskLoading(false);
        return getTasksByPAId(auth.accessToken, auth.user_id);
      }
      setTaskLoading(false);
    },
  );

  const { data: userEarnings, isLoading: userEarningLoading } = useQuery(
    ["fetchUserEarning", auth.accessToken],
    () => {
      if (auth.accessToken && auth.user_id) {
        return getTotalEarningsByUserId(auth.accessToken);
      }
    },
    {
      onSuccess: (data) => {},
    },
  );

  const { data: missionData, isLoading: missionsLoading } = useQuery(
    ["fetchingMissions", auth.accessToken, auth.user_id],
    async () => {
      if (auth.accessToken && auth.user_id) {
        return await getMissions(auth.accessToken, auth.user_id);
      }
    },
  );

  const inProgressMissions = missionData?.tasks;
  const tasks =
    taskData?.tasks.filter((task) => {
      return !inProgressMissions?.find(
        (x) => String(task.task_id) == String(x[7]),
      );
    }) || [];

  const inProgressTasks = tasks.filter((task) => task.status === "in_progress");
  const needClarificationTasks = tasks.filter(
    (task) => task.status === "clarification_needed",
  );
  const completedTasks = taskData?.amount_completed || 0;
  const forReviewTasks = tasks.filter((task) => task.status === "submitted");
  const forReviewMissions = inProgressMissions?.filter(
    (x) => x[2] === "submitted",
  );
  const overallForReviewCount =
    forReviewTasks?.length + forReviewMissions?.length;
  if (isUserDataLoading || userEarningLoading || isLoading || missionsLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      <StyledContainer className="bg-mainBg">
        <StyledBoardWrapper>
          {!isUserDataLoading && userData && (
            <>
              <StyledHeader>
                <p
                  data-test-id="dashboard-header"
                  className="font-Inter text-4xl text-black font-bold mb-2 mt-10">
                  Dashboard
                </p>
              </StyledHeader>
              {missionData &&
                inProgressMissions?.filter((x) => x[2] !== "completed")
                  .length != 0 && (
                  <div className="pt-4 pb-4 px-4 w-full bg-[#FAAFB7]  rounded-lg">
                    <div className="w-full">
                      <span className="font-bold text-4xl">Missions</span>
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 pt-2">
                      {inProgressMissions
                        ?.filter((x) => x[2] !== "completed")
                        .map((mission, index) => {
                          return (
                            <div
                              className="bg-[#CE6F7A] rounded-xl relative"
                              key={index}>
                              <div className="p-4">
                                <div className="">
                                  <div className="text-xl lg:text-2xl font-extrabold text-white">
                                    {mission[1]}
                                  </div>
                                  <span className="mt-1 text-black bg-[#FEFFCE] text-xs rounded-full py-1 px-3">
                                    {allStatuses[mission[2]] || mission[2]}
                                  </span>
                                </div>

                                <div className="text-right pt-[3.75rem]">
                                  <div className="text-xl sm:text-2xl xl:text-3xl font-extrabold bg-[#D98892] absolute bottom-0 left-0 text-white p-3 rounded-bl-xl rounded-tr-xl">
                                    ${`${mission[4] * 2}`}
                                  </div>

                                  <button
                                    className="hover:opacity-90 bg-[#806AD2] rounded-full cursor-pointer text-white uppercase py-2 px-4 border-none font-bold"
                                    onClick={() => {
                                      navigate(`/task/${mission[7]}`);
                                    }}>
                                    Go to task
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

              <StyledCardsWrapper className="mt-[19px]">
                <LeftBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Grid style={{ flex: 0.7 }}>
                    <ShadowedContainer className="flex flex-col justify-start bg-tableGrey w-full h-full p-6">
                      <>
                        <div data-test-id="active-tasks-container">
                          <div className="flex flex-row font-inter text-2xl text-black font-bold my-2 mt-[5%]">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                className="w-8 h-8 py-1">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
                                />
                              </svg>
                            </div>
                            <div
                              className="task-title"
                              data-test-id="dashboard-tasks-header">
                              ACTIVE
                            </div>
                          </div>
                          {taskLoading || isLoading ? (
                            <CircularProgress
                              size="2rem"
                              sx={{ margin: "auto" }}
                            />
                          ) : (
                            <>
                              {inProgressTasks.length === 0 ? (
                                <TaskItemsContainer>
                                  <span
                                    className="font-medium text-lg"
                                    data-test-id="text-no-tasks">
                                    You have no tasks to work on at the moment.
                                    <br />
                                    <br />
                                    Find a new task to earn more!
                                  </span>
                                </TaskItemsContainer>
                              ) : (
                                <TaskItemsContainer data-test-id="assistant-tasks-list">
                                  {inProgressTasks?.map((task, index) => {
                                    return (
                                      <TaskItemAssistant
                                        key={index}
                                        title={task.title}
                                        energy={task.energy_assigned}
                                        earnings_boost={task.earnings_boost}
                                        onPenClick={() =>
                                          navigate(`/task/${task.task_id}`)
                                        }
                                      />
                                    );
                                  })}
                                </TaskItemsContainer>
                              )}
                            </>
                          )}
                        </div>
                        <div data-test-id="clarification-tasks-container">
                          <div className="flex flex-row font-inter text-2xl bg-purple-200 font-bold my-2 mt-[5%]">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                className="w-8 h-8 py-1">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                />
                              </svg>
                            </div>
                            <div data-test-id="dashboard-tasks-header">
                              CLARIFICATION
                            </div>
                          </div>
                          {taskLoading || isLoading ? (
                            <CircularProgress
                              size="2rem"
                              sx={{ margin: "auto" }}
                            />
                          ) : (
                            <>
                              {needClarificationTasks.length === 0 ? (
                                <TaskItemsContainer>
                                  <span
                                    className="font-medium text-lg"
                                    data-test-id="text-no-tasks">
                                    You have no tasks for clarification at the
                                    moment.
                                  </span>
                                </TaskItemsContainer>
                              ) : (
                                <TaskItemsContainer data-test-id="assistant-tasks-list">
                                  {needClarificationTasks?.map(
                                    (task, index) => {
                                      return (
                                        <TaskItemAssistant
                                          key={index}
                                          title={task.title}
                                          energy={task.energy_assigned}
                                          earnings_boost={task.earnings_boost}
                                          onPenClick={() =>
                                            navigate(`/task/${task.task_id}`)
                                          }
                                        />
                                      );
                                    },
                                  )}
                                </TaskItemsContainer>
                              )}
                            </>
                          )}
                        </div>
                        {forReviewTasks.length > 0 && (
                          <div data-test-id="under-review-tasks-container">
                            <div className="flex flex-row font-inter text-2xl text-black font-bold my-2 mt-[5%]">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-8 h-8 py-1">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                  />
                                </svg>
                              </div>
                              <div data-test-id="dashboard-tasks-header">
                                UNDER REVIEW
                              </div>
                            </div>
                            {taskLoading || isLoading ? (
                              <CircularProgress
                                size="2rem"
                                sx={{ margin: "auto" }}
                              />
                            ) : (
                              <TaskItemsContainer data-test-id="assistant-tasks-list">
                                {forReviewTasks?.map((task, index) => {
                                  return (
                                    <TaskItemAssistant
                                      key={index}
                                      title={task.title}
                                      energy={task.energy_assigned}
                                      earnings_boost={task.earnings_boost}
                                      onPenClick={() =>
                                        navigate(`/task/${task.task_id}`)
                                      }
                                    />
                                  );
                                })}
                              </TaskItemsContainer>
                            )}
                          </div>
                        )}
                        <div
                          data-test-id="dashboard-find-tasks-actions"
                          className="flex flex-row justify-end">
                          <div className="self-end my-6 mx-1">
                            <Link
                              data-test-id="find-missions-button"
                              reloadDocument
                              to="/missions"
                              className="no-underline">
                              <StyledPurpleButton className="hover:opacity-90 font-medium">
                                Find Missions
                              </StyledPurpleButton>
                            </Link>
                          </div>
                          <div className="self-end my-6 mx-1">
                            <Link
                              data-test-id="find-tasks-button"
                              reloadDocument={userData.qualifications.some(
                                (qual) => qual.name === "General",
                              )}
                              to={`/${
                                userData.qualifications.find(
                                  (qual) => qual.name === "General",
                                ) &&
                                (userData.is_eligible ||
                                  userData.is_eligible == null)
                                  ? "tasks"
                                  : "dashboard"
                              }`}
                              className="no-underline">
                              <StyledPurpleButton
                                className={`hover:opacity-90 font-medium ${
                                  !userData.qualifications.some(
                                    (qual) => qual.name === "General",
                                  ) || userData.is_eligible === false
                                    ? "cursor-not-allowed bg-[#808080]"
                                    : ""
                                }`}>
                                Find Tasks
                              </StyledPurpleButton>
                            </Link>
                          </div>
                        </div>
                      </>
                    </ShadowedContainer>
                  </Grid>
                </LeftBox>
                <RightBox>
                  <TaskStatusTemplate
                    needClarificationTasks={needClarificationTasks}
                    completedTasks={completedTasks}
                    forReviewTasks={overallForReviewCount}
                    userEarnings={userEarnings}
                  />
                </RightBox>
              </StyledCardsWrapper>
            </>
          )}
        </StyledBoardWrapper>
      </StyledContainer>
    </>
  );
};

export default Component;
