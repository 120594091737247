import React from "react";

const Component = (props) => {
  //Mode = 0 | Inactive
  //Mode = 1 | Active

  const { mode, place } = props;

  if (mode == 0 && place == 1) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5 14L5.83333 11.6667M5.83333 11.6667L14 3.5L22.1667 11.6667M5.83333 11.6667V23.3333C5.83333 23.9777 6.35567 24.5 7 24.5H10.5M22.1667 11.6667L24.5 14M22.1667 11.6667V23.3333C22.1667 23.9777 21.6443 24.5 21 24.5H17.5M10.5 24.5C11.1443 24.5 11.6667 23.9777 11.6667 23.3333V18.6667C11.6667 18.0223 12.189 17.5 12.8333 17.5H15.1667C15.811 17.5 16.3333 18.0223 16.3333 18.6667V23.3333C16.3333 23.9777 16.8557 24.5 17.5 24.5M10.5 24.5H17.5"
          stroke="#E0848D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (mode == 1 && place == 1) {
    return (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6839 1.16592C11.1958 0.677766 10.4043 0.677766 9.91617 1.16592L1.16617 9.91592C0.67801 10.4041 0.67801 11.1955 1.16617 11.6837C1.65432 12.1718 2.44578 12.1718 2.93393 11.6837L3.30005 11.3176V19.5498C3.30005 20.2402 3.85969 20.7998 4.55005 20.7998H7.05005C7.7404 20.7998 8.30005 20.2402 8.30005 19.5498V17.0498C8.30005 16.3594 8.85969 15.7998 9.55005 15.7998H12.05C12.7404 15.7998 13.3 16.3594 13.3 17.0498V19.5498C13.3 20.2402 13.8597 20.7998 14.55 20.7998H17.05C17.7404 20.7998 18.3 20.2402 18.3 19.5498V11.3176L18.6662 11.6837C19.1543 12.1718 19.9458 12.1718 20.4339 11.6837C20.9221 11.1955 20.9221 10.4041 20.4339 9.91592L11.6839 1.16592Z"
          fill="white"
        />
      </svg>
    );
  }
  if (mode == 0 && place == 2) {
    return (
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.83329 3.83334H3.99996C2.7113 3.83334 1.66663 4.87801 1.66663 6.16667V19C1.66663 20.2887 2.7113 21.3333 3.99996 21.3333H16.8333C18.122 21.3333 19.1666 20.2887 19.1666 19V13.1667M17.5167 2.18342C18.4279 1.27219 19.9053 1.27219 20.8165 2.18342C21.7278 3.09464 21.7278 4.57203 20.8165 5.48325L10.7998 15.5H7.49996L7.49996 12.2002L17.5167 2.18342Z"
          stroke="#E0848D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (mode == 1 && place == 2) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.3799 1.61991C21.2865 0.526438 19.5136 0.526438 18.4202 1.61991L7.80005 12.24V16.1998H11.7598L22.3799 5.5797C23.4734 4.48624 23.4734 2.71337 22.3799 1.61991Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.800049 6.3998C0.800049 4.85341 2.05365 3.5998 3.60005 3.5998H9.20005C9.97325 3.5998 10.6 4.22661 10.6 4.9998C10.6 5.773 9.97325 6.3998 9.20005 6.3998H3.60005V20.3998H17.6V14.7998C17.6 14.0266 18.2268 13.3998 19 13.3998C19.7732 13.3998 20.4 14.0266 20.4 14.7998V20.3998C20.4 21.9462 19.1464 23.1998 17.6 23.1998H3.60005C2.05365 23.1998 0.800049 21.9462 0.800049 20.3998V6.3998Z"
          fill="white"
        />
      </svg>
    );
  }
  if (mode == 0 && place == 3) {
    return (
      <svg
        width="22"
        height="24"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5 17.8333H20.3333L18.6942 16.1942C18.2497 15.7497 18 15.1468 18 14.5182V10.8333C18 7.78549 16.0521 5.1926 13.3333 4.23165V3.83333C13.3333 2.54467 12.2886 1.5 11 1.5C9.71129 1.5 8.66663 2.54467 8.66663 3.83333V4.23165C5.94784 5.1926 3.99996 7.78549 3.99996 10.8333V14.5182C3.99996 15.1468 3.75023 15.7497 3.30571 16.1942L1.66663 17.8333H7.49996M14.5 17.8333V19C14.5 20.933 12.933 22.5 11 22.5C9.06696 22.5 7.49996 20.933 7.49996 19V17.8333M14.5 17.8333H7.49996"
          stroke="#E0848D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (mode == 1 && place == 3) {
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.99999 0.799805C5.3608 0.799805 1.59999 4.56061 1.59999 9.1998V14.2199L0.610043 15.2099C0.209645 15.6103 0.0898673 16.2124 0.306561 16.7356C0.523254 17.2587 1.03374 17.5998 1.59999 17.5998H18.4C18.9662 17.5998 19.4767 17.2587 19.6934 16.7356C19.9101 16.2124 19.7903 15.6103 19.3899 15.2099L18.4 14.2199V9.1998C18.4 4.56061 14.6392 0.799805 9.99999 0.799805Z"
          fill="#F5F5F5"
        />
        <path
          d="M9.99995 23.1998C7.68036 23.1998 5.79995 21.3194 5.79995 18.9998H14.2C14.2 21.3194 12.3195 23.1998 9.99995 23.1998Z"
          fill="#F5F5F5"
        />
      </svg>
    );
  }
  if (mode == 0 && place == 4) {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.16667 6.33317H17.8333M6.16667 10.9998H10.8333M12 20.3332L7.33333 15.6665H3.83333C2.54467 15.6665 1.5 14.6218 1.5 13.3332V3.99984C1.5 2.71117 2.54467 1.6665 3.83333 1.6665H20.1667C21.4553 1.6665 22.5 2.71117 22.5 3.99984V13.3332C22.5 14.6218 21.4553 15.6665 20.1667 15.6665H16.6667L12 20.3332Z"
          stroke="#E0848D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  if (mode == 1 && place == 4) {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2 14.2002V3.0002C23.2 1.4538 21.9464 0.200195 20.4 0.200195H3.60005C2.05365 0.200195 0.800049 1.4538 0.800049 3.0002V14.2002C0.800049 15.7466 2.05365 17.0002 3.60005 17.0002H7.80005L12 21.2002L16.2 17.0002H20.4C21.9464 17.0002 23.2 15.7466 23.2 14.2002ZM5.00005 5.8002C5.00005 5.027 5.62685 4.4002 6.40005 4.4002H17.6C18.3732 4.4002 19 5.027 19 5.8002C19 6.57339 18.3732 7.2002 17.6 7.2002H6.40005C5.62685 7.2002 5.00005 6.57339 5.00005 5.8002ZM6.40005 10.0002C5.62685 10.0002 5.00005 10.627 5.00005 11.4002C5.00005 12.1734 5.62685 12.8002 6.40005 12.8002H10.6C11.3732 12.8002 12 12.1734 12 11.4002C12 10.627 11.3732 10.0002 10.6 10.0002H6.40005Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return <></>;
  }
};

export default Component;
