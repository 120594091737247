import { Box, Button, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  adminPocketAssistantState,
  adminTaskListState,
} from "../../../state/atoms";
import FormError from "../../organisms/FormError";
// import { DateRangePicker } from "rsuite";

import { Energy, LoadingIcon } from "../../../assets/svg";

import { CircularProgress, Rating } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { Dayjs } from "dayjs";
import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";

// import faker from "faker";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 40,
  borderRadius: 60,
  boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.1)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 60,
    backgroundColor: "#E0848D",
  },
}));

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 80%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
    padding: 0 2%;
  }
`;

const ChartBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  gap: 10px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  height: 332px;
  min-width: 540px;
`;

const ChartHeader = styled(Box)`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  /* primary/dark 1 */

  color: #242731;
  display: flex;
  width: 100%;
`;

const YearBox = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* gray/dark 3 */

  color: #72767c;
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 24px;
`;

const LeftBox = styled(Box)`
  flex: 0.68;
  @media screen and (max-width: 760px) {
    flex: 1;
    row-gap: 16px;
    margin-bottom: 16px;
  }
`;

const RightBox = styled(Box)`
  flex: 0.3;
  @media screen and (max-width: 760px) {
    flex: 1;
    padding-bottom: 20px;
  }
`;
const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: space-between;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

const ShadowedContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
`;

const EnergyContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;

  @media only screen and (max-width: 760px) {
    justify-content: flex-end !important;
    .eng-img {
      left: 10px !important;
      height: 120px;
      width: 80px;
    }
    .eng-div {
      width: 70% !important;

      .text-5xl {
        font-size: 24px !important;
      }
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  border-radius: 10px;
  width: 265px;
  color: white;
  background: #806ad2;
  padding: 28px 24px;

  > div:last-child {
    font-size: 36px;
    font-weight: 700;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #806ad2;
  border-radius: 50px;
  width: 45px;
  height: 45px;
`;

const FilterButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  width: 150px;
  height: 46px;
  padding: 0px 12px;
  color: #000;
  user-select: none;
  /* background-color: #806ad2; */
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? `
        background-color: #fff;
        color: #000;
        `
      : `  color: #000000;`}
`;
const OptionsButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 30px;
  min-width: 88px;

  border: 1px solid #806ad2;
  border-radius: 20px;

  ${({ selected }) =>
    selected
      ? `
        background-color: #806ad2;
        color: white;
        `
      : `  color: #000000;`}

  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
`;

const TaskItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  > div {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const TableHeader = styled.div`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 26px;
  /* or 65% */

  letter-spacing: 0.46px;

  color: #000000;
`;

const TableSearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 11px 17px 11px 21px;
  gap: 10px;

  background: #fff;
  border-radius: 8px;
  width: 262px;
`;

const SearchInput = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* gray/dark 4 */

  color: #000;
  border: unset;
  background: transparent;
  :focus {
    outline: none;
  }
`;

const StyledTable = styled.table`
  margin-top: 32px;
  background: #ffffff;
  width: 100%;
  text-align: left;
  /* gray/200 */

  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledThead = styled.thead`
  background: #f9fafb;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* gray/500 */

  color: #6b7280;
`;

const Styledth = styled.th`
  padding-left: 16px;
`;

const StyledTbody = styled.tbody`
  color: #111827;
`;

const Styledtr = styled.tr``;

const Styledtd = styled.td`
  padding: 16px;
  border-top: 1px solid #e5e7eb;
`;

const StyledName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/900 */

  color: #111827;
`;

const StyledEmail = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/500 */

  color: #6b7280;
`;

const StyledStatus = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  height: 21px;
  width: 83px;

  border-radius: 20px;
  background: ${(props) => props.color || "#6987F0"};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #ffffff;
  margin: auto;
`;

const StyledLink = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* indigo/600 */

  color: #4f46e5;
  cursor: pointer;
  text-align: center;
`;

const TesterModalOverlay = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const TesterModal = styled(Box)`
  background: #ffffff;
  border-radius: 16px;
  width: 70%;
  padding: 24px;
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const TesterModalTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  text-align: center;

  /* $dark-tone-100 */

  color: #1a1a25;
`;

const TesterModalBody = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 34px */

  padding: 32px;

  color: #1a1a25;
`;

const TesterModalFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const TesterModalBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 12px 14px 16px;
  width: 120px;
  height: 24px;
  background: #806ad2;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14.5px;
  line-height: 140%;
  /* or 20px */

  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #a08af2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const NewTesterBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 1px;
  gap: 10px;

  width: 160px;
  height: 32px;
  user-select: none;

  background: #806ad2;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #907ae2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const FormStyle = styled.div`
  justify-content: center;
  align-items: center;
  /* width: 960px; */

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

type Props = {
  setActive: (active: any) => void;
  tasks: Array<any>;
  tasks_count: any;
  after: any;
  before: any;
  handleNextPage: (after: any) => void;
  handlePrevPage: (before: any) => void;
  isLoading: boolean;
  assistants: Array<any>;
  assistantAfter: any;
  assistantBefore: any;
  handleAssistantNextPage: (assistantAfter: any) => void;
  handleAssistantPrevPage: (assistantBefore: any) => void;
  isAssistantLoading: boolean;
  createTester: (values: any) => void;
  isCreateTesterLoading: boolean;
};

const Component = ({
  setActive,
  tasks,
  tasks_count,
  after,
  before,
  handleNextPage,
  handlePrevPage,
  isLoading,
  assistants,
  assistantAfter,
  assistantBefore,
  handleAssistantNextPage,
  handleAssistantPrevPage,
  isAssistantLoading,
  createTester,
  isCreateTesterLoading,
}: Props) => {
  const navigate = useNavigate();
  const [adminTaskList, setAdminTaskList] = useRecoilState(adminTaskListState);
  const [adminPocketAssistant, setAdminPocketAssistant] = useRecoilState(
    adminPocketAssistantState,
  );

  const [value, setValue] = useState<DateRange<Dayjs>>([
    adminTaskList?.dates[0] || null,
    adminTaskList?.dates[1] || null,
  ]);
  const [swipes, setSwipes] = useState(adminTaskList?.swipes);
  const [rating, setRating] = useState(adminTaskList?.rating);
  const [status, setStatus] = useState(adminTaskList?.status);
  const [ratingStart, setRatingStart] = useState(
    adminPocketAssistant?.filters?.rating[0] || null,
  );
  const [ratingEnd, setRatingEnd] = useState(
    adminPocketAssistant?.filters?.rating[1]?.length
      ? null
      : adminPocketAssistant?.filters?.rating[1] || null,
  );
  const [rejectionStart, setRejectionStart] = useState(
    adminPocketAssistant?.filters?.rejections[0] || null,
  );
  const [rejectionEnd, setRejectionEnd] = useState(
    adminPocketAssistant?.filters?.rejections[1]?.length
      ? null
      : adminPocketAssistant?.filters?.rejections[1] || null,
  );

  const [testerModalOpened, setTesterModalOpened] = useState(false);

  const swipesList = [
    [null, null],
    [0, 14],
    [15, 29],
    [30, 44],
    [45, null],
  ];

  const colors = ["#40AD5F", "#FFCA0E", "#EF6775", "#E17719", "#000000"];
  const filters = [
    "published",
    "in_progress",
    "clarification_needed",
    "submitted",
    "completed",
  ];
  const filter_text = [
    "Published",
    "Active",
    "Clarification",
    "Review",
    "Completed",
  ];

  const handleSwipes = (event) => {
    setSwipes(event.target.value);
    setAdminTaskList({
      status: adminTaskList.status,
      rating: adminTaskList.rating,
      swipes: swipesList[event.target.value],
      dates: adminTaskList.dates,
      pageNum: 0,
      after: null,
      before: null,
    });
  };

  const handleRating = (event) => {
    setRating(event.target.value);
    if (event.target.value !== "") {
      setStatus("completed");
      setAdminTaskList({
        status: "completed",
        rating: event.target.value,
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    } else {
      setStatus("");
      setAdminTaskList({
        status: "",
        rating: event.target.value,
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    }
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
    if (event.target.value === "completed" || event.target.value === "") {
      setAdminTaskList({
        status: event.target.value,
        rating: adminTaskList.rating,
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    } else {
      setRating("");
      setAdminTaskList({
        status: event.target.value,
        rating: "",
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: 0,
        after: null,
        before: null,
      });
    }
  };

  const data = [
    {
      name: "JAN",
      active: 10,
    },
    {
      name: "FEB",
      active: 23,
    },
    {
      name: "MAR",
      active: 27,
    },
    {
      name: "APR",
      active: 12,
    },
    {
      name: "MAY",
      active: 36,
    },
    {
      name: "JUN",
      active: 2,
    },
    {
      name: "JUL",
      active: 33,
    },
    {
      name: "AUG",
      active: 4,
    },
    {
      name: "SEP",
      active: 10,
    },
    {
      name: "OCT",
      active: 12,
    },
    {
      name: "NOV",
      active: 17,
    },
    {
      name: "DEC",
      active: 22,
    },
  ];

  const nextPage = () => {
    if (after !== null) {
      setAdminTaskList({
        status: adminTaskList.status,
        rating: adminTaskList.rating,
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: adminTaskList.pageNum + 1,
        after: after,
        before: null,
      });
      handleNextPage(after);
    }
  };

  const prevPage = () => {
    if (before !== null) {
      setAdminTaskList({
        status: adminTaskList.status,
        rating: adminTaskList.rating,
        swipes: adminTaskList.swipes,
        dates: adminTaskList.dates,
        pageNum: adminTaskList.pageNum - 1,
        after: null,
        before: before,
      });
      handlePrevPage(before);
    }
  };

  const nextAssistantPage = () => {
    if (assistantAfter !== null) {
      setAdminPocketAssistant({
        filters: {
          ...adminPocketAssistant.filters,
        },
        pageNum: adminPocketAssistant.pageNum + 1,
        after: assistantAfter,
        before: null,
      });
      handleAssistantNextPage(after);
    }
  };

  const prevAssistantPage = () => {
    if (assistantBefore !== null) {
      setAdminPocketAssistant({
        filters: {
          ...adminPocketAssistant.filters,
        },
        pageNum: adminPocketAssistant.pageNum - 1,
        after: null,
        before: assistantBefore,
      });
      handleAssistantPrevPage(before);
    }
  };

  return (
    <StyledContainer className="bg-mainBg">
      <StyledBoardWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            width: "100%",
            flexWrap: "wrap",
          }}
          data-test-id="admin-stats-container">
          <StatusContainer>
            <div>ALL TASKS</div>
            <div>1924</div>
          </StatusContainer>
          <StatusContainer>
            <div>QUALIFIED POCKET ASSISTANTS</div>
            <div>1924</div>
          </StatusContainer>
          <StatusContainer>
            <div>PAs ABOVE 4 STARS</div>
            <div>1924</div>
          </StatusContainer>
          <StatusContainer>
            <div>PAs AVERAGE RATING</div>
            <div>1924</div>
          </StatusContainer>
        </div>
        <div
          style={{
            marginTop: "72px",
            display: "flex",
            gap: "12px",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
          }}>
          <div style={{ flex: 1, width: "0%", minWidth: "540px" }}>
            <ChartBox data-test-id="admin-chart-active-owners">
              <ChartHeader>Active Owners</ChartHeader>
              <YearBox>2022</YearBox>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 30,
                    bottom: 0,
                  }}>
                  <defs>
                    <linearGradient
                      id="colorActive"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1">
                      <stop offset="0%" stopColor="#FED5D9" stopOpacity={0.8} />
                      <stop
                        offset="100%"
                        stopColor="#FFB19F00"
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="name"
                    fontSize={14}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="active"
                    stroke="#FF9FA9"
                    strokeWidth="2"
                    fillOpacity={1}
                    fill="url(#colorActive)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </ChartBox>
          </div>
          <div style={{ flex: 1, width: "0%", minWidth: "540px" }}>
            <ChartBox data-test-id="admin-chart-tasks-completed">
              <ChartHeader>Tasks Completed per Month</ChartHeader>
              <YearBox>2022</YearBox>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 30,
                    bottom: 0,
                  }}>
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="name"
                    fontSize={14}
                  />
                  <Tooltip />
                  <Bar dataKey="active" fill="#FF9FA9" />
                </BarChart>
              </ResponsiveContainer>
            </ChartBox>
          </div>
        </div>
        <div
          style={{ marginTop: "75px", width: "100%" }}
          data-test-id="admin-all-tasks-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <TableHeader>All tasks</TableHeader>
            <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
              <div className="relative">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-select-small">Status</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={status}
                    label="Status"
                    onChange={handleStatus}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="published">Published</MenuItem>
                    <MenuItem value="in_progress">Active</MenuItem>
                    <MenuItem value="clarification_needed">
                      Clarification
                    </MenuItem>
                    <MenuItem value="submitted">Review</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="relative">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-select-small">Rating</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={rating}
                    label="Rating"
                    onChange={handleRating}>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="relative">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-select-small">Swipes</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={swipes}
                    label="Age"
                    onChange={handleSwipes}>
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>0-14</MenuItem>
                    <MenuItem value={2}>15-29</MenuItem>
                    <MenuItem value={3}>30-44</MenuItem>
                    <MenuItem value={4}>45+</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "Start Date", end: "End Date" }}>
                <DateRangePicker
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    setAdminTaskList({
                      status: adminTaskList.status,
                      rating: adminTaskList.rating,
                      swipes: adminTaskList.swipes,
                      dates: [
                        newValue[0]?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          ? newValue[0]?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          : null,
                        newValue[1]?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          ? newValue[1]?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          : null,
                      ],
                      pageNum: 0,
                      after: null,
                      before: null,
                    });
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 0 }}> - </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            <StyledTable>
              <StyledThead>
                <Styledtr>
                  <Styledth>Owner</Styledth>
                  <Styledth>PA ASSIGNED</Styledth>
                  <Styledth>TASK</Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    STATUS
                  </Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    <div>RATING</div>
                  </Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    <div>ENERGY</div>
                  </Styledth>
                  <Styledth style={{ paddingLeft: "0px", textAlign: "center" }}>
                    Status Changed
                  </Styledth>
                  <Styledth></Styledth>
                </Styledtr>
              </StyledThead>

              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          display: "flex",
                        }}>
                        <CircularProgress
                          color="inherit"
                          size="6rem"
                          sx={{ margin: "auto" }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <StyledTbody>
                  {tasks.length > 0 ? (
                    tasks.map((item, index) => (
                      <Styledtr key={index}>
                        <Styledtd>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <StyledName>{item.owner_name}</StyledName>
                            <StyledEmail>{item.short_address}</StyledEmail>
                          </div>
                        </Styledtd>
                        <Styledtd>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <StyledName>{item.assistant_name}</StyledName>
                            <StyledEmail>{item.email}</StyledEmail>
                          </div>
                        </Styledtd>
                        <Styledtd>{item.title}</Styledtd>
                        <Styledtd style={{ textAlign: "center" }}>
                          <StyledStatus
                            color={colors[filters.indexOf(item.status)]}>
                            {filter_text[filters.indexOf(item.status)]
                              ? filter_text[filters.indexOf(item.status)]
                              : "Draft"}
                          </StyledStatus>
                        </Styledtd>
                        <Styledtd
                          style={{
                            textAlign: "center",
                          }}>
                          <Rating
                            name="rating"
                            size="medium"
                            value={item.rating}
                            readOnly
                          />
                        </Styledtd>
                        <Styledtd
                          style={{
                            textAlign: "center",
                          }}>
                          <div className="flex flex-row items-center gap-1 justify-center">
                            <div className="font-bold">{item.energy}</div>
                            <Energy />
                          </div>
                        </Styledtd>
                        <Styledtd style={{ textAlign: "center" }}>
                          {item.status_changed ? (
                            <>
                              {new Date(
                                item.status_changed,
                              ).toLocaleDateString()}
                              ,
                              {new Date(
                                item.status_changed,
                              ).toLocaleTimeString()}
                            </>
                          ) : null}
                        </Styledtd>
                        <Styledtd>
                          <StyledLink
                            onClick={() => {
                              navigate(`/tasks/view/${item.task_id}`);
                            }}>
                            View
                          </StyledLink>
                        </Styledtd>
                      </Styledtr>
                    ))
                  ) : (
                    <td colSpan={10}>
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          display: "flex",
                        }}
                        className="flex items-center justify-center m-auto text-3xl select-none">
                        No Task
                      </div>
                    </td>
                  )}
                </StyledTbody>
              )}
            </StyledTable>
          </div>
          <div className="flex h-max items-center justify-end md:justify-end mt-5">
            <span className="mx-2 text-gray-500 font-medium">
              Total Count:{" "}
              {isLoading ? (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  sx={{ margin: "auto" }}
                />
              ) : tasks_count ? (
                tasks_count
              ) : (
                0
              )}
            </span>
            <span
              onClick={() => (before ? prevPage() : null)}
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                before === null ? "opacity-50 cursor-not-allowed" : ""
              }`}>
              Previous
            </span>
            <span className="mx-2 text-gray-500 font-medium">
              {adminTaskList.pageNum + 1}
            </span>
            <span
              onClick={() => (after ? nextPage() : null)}
              className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                after === null ? "opacity-50 cursor-not-allowed" : ""
              }`}>
              Next
            </span>
          </div>
        </div>
        <div style={{ marginTop: "75px", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TableHeader>Testers</TableHeader>
            <div></div>
          </div>
        </div>
        <div
          style={{
            marginTop: "30px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <NewTesterBtn onClick={() => setTesterModalOpened(true)}>
            New Tester
          </NewTesterBtn>
        </div>
        {testerModalOpened && (
          <>
            <TesterModalOverlay
              onClick={() => setTesterModalOpened(false)}></TesterModalOverlay>
            <TesterModal>
              <TesterModalTitle>New Tester</TesterModalTitle>
              <TesterModalBody>
                <FormContainer className="flex flex-col justify-center mt-10">
                  <FormStyle className="mx-auto bg-white">
                    <Formik
                      initialValues={{
                        address: "",
                        energy: "0",
                      }}
                      validate={
                        (values) => {
                          const errors = {};
                          if (!values.address) {
                            errors.address = "Address required";
                          } else if (
                            values.address.length !== 42 ||
                            values.address.substring(0, 2) !== "0x"
                          )
                            errors.address = "Wrong address";
                          if (Number(values.energy) <= 0) {
                            errors.energy =
                              "Please select energy to assign (1 energy = 30 mins)";
                          }
                          return errors;
                        }
                        // Yup.object().shape({
                        //   address: Yup.string().required("Title required"),
                        //   energy_assigned: Yup.number().min(
                        //     1,
                        //     "Please select energy to assign (1 energy = 30 mins)",
                        //   ),
                        // })
                      }
                      onSubmit={(values, { setSubmitting }) => {
                        createTester(values);

                        setTimeout(() => {
                          setSubmitting(false);
                          setTesterModalOpened(false);
                          clearInterval(timerId);
                        }, 1000);
                      }}>
                      {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        // handleReset,
                        isSubmitting,
                      }) => (
                        <Form className="mt-5">
                          <Box>
                            <Grid container rowGap={3}>
                              <Grid container columnGap={2}>
                                <div className="flex flex-col w-[100%]">
                                  <div className="text-base font-bold text-black">
                                    Address
                                  </div>
                                  <div className="w-[100%]">
                                    <TextField
                                      className="bg-[#fdfdfd] w-[100%] rounded-md"
                                      variant="filled"
                                      size="small"
                                      inputProps={{
                                        style: {
                                          padding: "10px 12px",
                                        },
                                      }}
                                      name="address"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.address}
                                      placeholder="Address"
                                    />
                                    <FormError
                                      top="-10px"
                                      isAbsolute
                                      sx={{ position: "relative" }}>
                                      <>{touched.address && errors.address}</>
                                    </FormError>
                                  </div>
                                </div>
                              </Grid>
                              <Grid container columnGap={2}>
                                <div className="flex flex-col w-[100%]">
                                  <div className="text-base font-bold text-black">
                                    Energy
                                  </div>
                                  <div className="w-[100%]">
                                    <TextField
                                      type="number"
                                      className="bg-[#fdfdfd] w-[100%] rounded-md"
                                      variant="filled"
                                      size="small"
                                      inputProps={{
                                        style: {
                                          padding: "10px 12px",
                                        },
                                        min: 1,
                                      }}
                                      name="energy"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.energy}
                                      placeholder="Energy"
                                    />
                                    <FormError
                                      top="-10px"
                                      isAbsolute
                                      sx={{ position: "relative" }}>
                                      <>{touched.energy && errors.energy}</>
                                    </FormError>
                                  </div>
                                </div>
                              </Grid>
                              {/* <div>
                                <div className="flex flex-col">
                                  {errors &&
                                    Object.keys(errors).map((object) => {
                                      return (
                                        <div>
                                          <FormError
                                            sx={{
                                              position: "relative",
                                              width: "400px",
                                            }}>
                                            <>
                                              {touched[object] &&
                                                errors[object]}
                                            </>
                                          </FormError>
                                        </div>
                                      );
                                    })}
                                </div>
                                <Stack
                                  className="mb-20 mt-5"
                                  spacing={2}
                                  direction="row">
                                  <TaskPageButton
                                    style={{ textTransform: "none" }}
                                    type="submit"
                                    variant="contained"
                                    disabled={isLoading}>
                                    <span>
                                      {isLoading ? (
                                        <LoadingIcon></LoadingIcon>
                                      ) : isSuccess ? (
                                        <CheckIcon></CheckIcon>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    <span>
                                      {isLoading
                                        ? getModeSaveLoadingLabel()
                                        : isSuccess
                                        ? getModeSaveSuccessLabel()
                                        : getModeSaveLabel()}
                                    </span>
                                  </TaskPageButton>
                                  <Button
                                    style={{ textTransform: "none" }}
                                    className={
                                      (isLoading || isSuccess
                                        ? "hidden "
                                        : "") + "text-black font-bold mt-4"
                                    }
                                    disabled={isLoading}
                                    variant="text"
                                    onClick={() => navigate(-1)}>
                                    Cancel
                                  </Button>
                                </Stack>
                              </div> */}
                            </Grid>
                          </Box>
                          <TesterModalFooter className="mt-[32px]">
                            <TesterModalBtn
                              onClick={handleSubmit}
                              disabled={isSubmitting}>
                              {isSubmitting ? (
                                <LoadingIcon></LoadingIcon>
                              ) : (
                                "Submit"
                              )}
                            </TesterModalBtn>

                            <TesterModalBtn
                              onClick={() => setTesterModalOpened(false)}>
                              Close
                            </TesterModalBtn>
                          </TesterModalFooter>
                        </Form>
                      )}
                    </Formik>
                  </FormStyle>
                </FormContainer>
              </TesterModalBody>
            </TesterModal>
          </>
        )}
      </StyledBoardWrapper>
    </StyledContainer>
  );
};

export default Component;
