import React from "react";

const Component = (props) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 8.73424V2.62024L4 12.228H11L11 18.3419L20 8.73424L13 8.73424Z"
        fill={color ?? "black"}
        stroke={color ?? "#111827"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Component;
