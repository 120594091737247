import { Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { getAssistantByAdmin, getTaskByAdmin } from "../../../services/tasks";
import {
  adminPocketAssistantState,
  adminTaskListState,
  authState,
  paginationState,
} from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import Modal from "../../organisms/Modal";
import AdminDashboardTemplate from "../../templates/AdminDashboard";

import { createTester } from "../../../services/users";
import { TemplateSchema } from "./validation";

const StyledContainer = styled.div`
  height: calc(100% - 64px);
`;

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

const StyledFormWrapper = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

type Props = {
  setActive: (active: any) => void;
};

const Component = ({ setActive }) => {
  const [auth, setAuth] = useRecoilState(authState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [adminTaskList, setAdminTaskList] = useRecoilState(adminTaskListState);
  const [adminPocketAssistant, setAdminPocketAssistant] = useRecoilState(
    adminPocketAssistantState,
  );
  const [fetchFavorites, setFetchFavorites] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFavoriteTemplate, setShowFavoriteTemplate] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState("");
  const [fetchType, setFetchType] = useState("");
  const [after, setAfter] = useState(null);
  const [before, setBefore] = useState(null);
  const [assistantAfter, setAssistantAfter] = useState(null);
  const [assistantBefore, setAssistantBefore] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [assistantLoading, setAssistantLoading] = useState(false);

  const [taskToTemplate, setTaskToTemplate] = useState({});
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);

  useEffect(() => {
    setTaskLoading(true);
    setAssistantLoading(true);
  }, []);

  const {
    data: tasksData,
    isLoading: isLoading,
    refetch,
  } = useQuery(
    [
      "fetchingAdminTasks",
      auth.accessToken,
      adminTaskList.status,
      adminTaskList.rating,
      adminTaskList.swipes,
      adminTaskList.dates,
      adminTaskList.after,
      adminTaskList.before,
    ],
    () => {
      if (auth.accessToken) {
        return getTaskByAdmin(auth.accessToken, adminTaskList);
      }
    },
    {
      onSuccess: () => {
        setTaskLoading(false);
      },
      onError: (e) => {},
    },
  );

  const { data: assistantData, isLoading: isAssistantLoading } = useQuery(
    [
      "fetchingAdminAssistants",
      auth.accessToken,
      adminPocketAssistant.text,
      adminPocketAssistant.rating,
      adminPocketAssistant.rejections,
      adminPocketAssistant.after,
      adminPocketAssistant.before,
    ],
    () => {
      if (auth.accessToken) {
        return getAssistantByAdmin(auth.accessToken, adminPocketAssistant);
      }
    },
    {
      onSuccess: () => {
        setAssistantLoading(false);
      },
      onError: (e) => {},
    },
  );

  const {
    mutate: mutateCreateTester,
    isLoading: isCreateTesterLoading,
    error,
  } = useMutation(
    "createTester",
    (values: any) => {
      return createTester(auth.accessToken, values.address, values.energy);
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully created new tester.",
          type: "success",
        });

        // setTimeout(() => {
        //   navigate("/tasks/view/" + resp.id);
        // }, 1500);
      },
      onError: (e: Error, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const tasks = tasksData?.tasks || [];
  const tasks_count = tasksData?.total || null;
  const after_ = tasksData?.after || null;
  const before_ = tasksData?.before || null;

  const assistants = assistantData?.tasks || [];
  const assistantAfter_ = assistantData?.after || null;
  const assistantBefore_ = assistantData?.before || null;

  return (
    <StyledContainer className="text-title bg-mainBg">
      <AdminDashboardTemplate
        setActive={setActive}
        tasks={tasks}
        tasks_count={tasks_count}
        after={after_}
        before={before_}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        isLoading={isLoading || taskLoading}
        assistants={assistants}
        assistantAfter={assistantAfter_}
        assistantBefore={assistantBefore_}
        handleAssistantNextPage={handleAssistantNextPage}
        handleAssistantPrevPage={handleAssistantPrevPage}
        isAssistantLoading={isAssistantLoading || assistantLoading}
        createTester={mutateCreateTester}
        isCreateTesterLoading={isCreateTesterLoading}
      />
      <Modal
        title="Delete Task"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showConfirmation}
        onClose={closeModal}>
        <div style={{ maxWidth: 450 }}>
          <div style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography fontWeight={700} fontFamily="Inter">
              Are you sure you want to delete this task?
            </Typography>
          </div>
          <ConfirmButton
            variant="contained"
            onClick={handleConfirmDelete}
            fullWidth>
            Yes, delete the task
          </ConfirmButton>
          <CancelButton variant="contained" onClick={closeModal} fullWidth>
            No, Cancel
          </CancelButton>
        </div>
      </Modal>
      <Modal
        title="Favorite Task"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showFavoriteTemplate}
        onClose={cancelFavorite}>
        <Formik
          initialValues={{ title: "" }}
          validationSchema={TemplateSchema}
          onSubmit={(values) => {
            mutateTemplateTask({ ...values, ...taskToTemplate });
          }}>
          {({ values, errors, handleChange, handleSubmit }) => {
            return (
              <div style={{ width: 450 }}>
                <div style={{ textAlign: "left" }}>
                  <StyledFormWrapper onSubmit={handleSubmit}>
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      variant="filled"
                      style={{
                        width: "80%",
                        paddingBottom: errors?.title ? 18 : 30,
                      }}
                      onChange={handleChange}
                      error={!!errors.title}
                      helperText={errors?.title}
                    />
                    <ConfirmButton
                      variant="contained"
                      type="submit"
                      onClick={() => {}}
                      fullWidth
                      disabled={isTemplatingTask}>
                      Set as Favorite
                    </ConfirmButton>
                    <CancelButton
                      variant="contained"
                      onClick={cancelFavorite}
                      fullWidth>
                      No, Cancel
                    </CancelButton>
                  </StyledFormWrapper>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </StyledContainer>
  );

  function cancelFavorite() {
    setShowFavoriteTemplate(false);
  }

  function closeModal() {
    setShowConfirmation(false);
  }

  function handleSwitchFetchedTasks(fetchtype: string) {
    if (!isLoading) {
      // setFetchFavorites(isFav);
      setFetchType(fetchtype);
      setAfter(null);
      setBefore(null);
      // refetch();
    }
  }

  function handleNextPage(after: any) {
    if (!isLoading) {
      setAfter(after);
      setBefore(null);
    }
  }

  function handlePrevPage(before: any) {
    if (!isLoading) {
      setAfter(null);
      setBefore(before);
    }
  }

  function handleAssistantNextPage(after: any) {
    if (!isAssistantLoading) {
      setAssistantAfter(after);
      setAssistantBefore(null);
    }
  }

  function handleAssistantPrevPage(before: any) {
    if (!isAssistantLoading) {
      setAssistantAfter(null);
      setAssistantBefore(before);
    }
  }

  function handleFavoriteTask(task: any) {
    const taskToTemplate: any = { ...task, is_template: true };
    delete taskToTemplate.delivery;
    delete taskToTemplate.comments;
    delete taskToTemplate.assistant_id;
    delete taskToTemplate.title;

    setTaskToTemplate(taskToTemplate);
    setShowFavoriteTemplate(true);
  }

  function handleDeleteTask(taskId: string) {
    setTaskToDelete(taskId);
    setShowConfirmation(true);
  }

  function handleConfirmDelete() {
    mutateDeleteTask(taskToDelete);
  }
};

export default Component;
