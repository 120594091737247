import { Button, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useContext } from "react";

import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { ForgotPasswordSchema } from "./validation";

import { forgotPasswordRequest } from "../../../services/auth";
import { SnackbarContext } from "../../../state/hooks";

const Component = () => {
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);
  const initialValues = {
    email: "",
  };

  const {
    mutate: mutateForgotPassword,
    isLoading,
    isSuccess,
    error,
  } = useMutation(
    "forgotPassword",
    async (values: typeof initialValues) => {
      const { code, data } = await forgotPasswordRequest(values);
      if (code !== 200) {
        console.log("datakiligan :>> ", data);
        setSnackbar({
          title: "Error",
          content: data.message ? data.message : data.reason,
          type: "error",
        });
        throw new Error(code.toString());
      } else return data;
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content:
            " We've sent an email with instructions to reset your password",
          type: "success",
        });
      },
      onError: (e, variables) => {
        console.log("error", e);
        if (e.message === "409") navigate("/register/confirm");
        else navigate("/login");
      },
    },
  );

  return (
    <div className="bg-mainBg h-full w-full flex items-center justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, helpers) => {
          mutateForgotPassword(values);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <form className="flex flex-col w-[380px]" onSubmit={handleSubmit}>
              <h3 className="text-center text-[40px] tracking-wide mb-[60px] font-gilroy font-bold">
                Forgot Password
              </h3>

              {isSuccess ? (
                <>
                  <p className="text-center font-medium leading-5 tracking-wide mb-14">
                    We have sent instructions on how to reset your password to
                    your email address. If doesn't appear within a few minutes,
                    check your spam folder.
                  </p>

                  <Button
                    onClick={() => {
                      navigate("/login");
                    }}
                    variant="contained"
                    type="button"
                    className="bg-primaryButton text-white w-full font-inter mb-14">
                    Return To Login
                  </Button>
                </>
              ) : (
                <>
                  <p className="font-medium leading-5 tracking-wide mb-14">
                    Enter your email to receive instructions on how to reset
                    your password.
                  </p>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="filled"
                    style={{
                      paddingBottom: errors?.email ? 18 : 40,
                    }}
                    className="w-full"
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors?.email}
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    className="bg-primaryButton text-white w-full font-inter mb-14 transform-none rounded-[50px]"
                    disabled={isLoading}>
                    Send instructions
                  </Button>
                </>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Component;
