export default (props) => (
  <svg
    id="view"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1 5V1M1 1H5M1 1L6 6M17 5V1M17 1H13M17 1L12 6M1 13V17M1 17H5M1 17L6 12M17 17L12 12M17 17V13M17 17H13"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
