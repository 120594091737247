export default (props) => {
  const { hover, ...svgProps } = props ?? {};

  return (
    <svg
      id="star"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}>
      <path
        d="M10.049 1.92664C10.3483 1.00537 11.6517 1.00538 11.951 1.92664L13.4699 6.60055C13.6038 7.01254 13.9877 7.29148 14.4209 7.29149L19.3354 7.29168C20.3041 7.29172 20.7068 8.53127 19.9232 9.10067L15.9474 11.9895C15.5969 12.2441 15.4503 12.6955 15.5841 13.1075L17.1026 17.7815C17.4019 18.7028 16.3475 19.4689 15.5638 18.8995L11.5878 16.011C11.2373 15.7564 10.7627 15.7564 10.4122 16.011L6.43622 18.8995C5.65252 19.4689 4.5981 18.7028 4.8974 17.7815L6.41589 13.1075C6.54974 12.6955 6.40309 12.2441 6.05263 11.9895L2.07683 9.10067C1.29317 8.53127 1.69592 7.29172 2.66461 7.29168L7.57911 7.29149C8.01231 7.29148 8.39623 7.01254 8.53011 6.60055L10.049 1.92664Z"
        stroke={hover ? "#111827" : "#111827"}
        strokeWidth={hover ? 2 : 2}
      />
    </svg>
  );
};
