import React, { useEffect, useRef, useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { useAccount, useDisconnect } from "wagmi";
import OwnerProfileView from "../components/pages/OwnerProfileView";

import Header from "../components/organisms/Header";
import AdminAssistantAccount from "../components/pages/AdminAssistantAccount";
import AdminDashboard from "../components/pages/AdminDashboard";
import AdminMission from "../components/pages/AdminMission";
import CreateMission from "../components/pages/CreateMission";
import EditMission from "../components/pages/EditMission";
import ViewMission from "../components/pages/ViewMission";
import ViewTask from "../components/pages/ViewTask";

import { Box } from "@mui/material";
import styled from "styled-components";
import { LogoutIcon, ProfileIcon } from "../assets/svg";
import AdminAccountsPage from "../components/pages/AdminAccounts";
import AdminOwnerAccountPage from "../components/pages/AdminOwnerAccount";
import AdminQualificationsPage from "../components/pages/AdminQualifications";
import OwnerAddress from "../components/pages/OwnerAddress";
import { authState, initialAuthState } from "../state/atoms";

// Note: Currently unused but might be helpful
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default () => {
  const navigate = useNavigate();
  const { pathname: locationPath, state } = useLocation();
  // const address = useAddress();
  const { address, connector, isConnected } = useAccount();
  // const disconnectWallet = useDisconnect();
  const { disconnect } = useDisconnect();
  const [auth, setAuth] = useRecoilState(authState);

  const [active, setActive] = useState(0);

  const logOutOwner = () => {
    disconnect();
    setAuth(initialAuthState);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <>
      <Header
        role="owner"
        dropdownButtonName={
          address
            ? `${address.substring(0, 5)}...${address.substring(
                address.length - 2,
              )}`
            : auth.user_name ?? auth.email ?? ""
        }
        dropdownOptions={[
          {
            name: "Profile",
            onClick: () => navigate("profile"),
            icon: <ProfileIcon />,
          },
          { name: "Disconnect", onClick: logOutOwner, icon: <LogoutIcon /> },
        ]}
      />
      {Boolean(
        window.location.href.indexOf("tasks") < 0 &&
          window.location.href.indexOf("profile") < 0 &&
          window.location.href.indexOf("updateAddress") < 0,
      ) && (
        <StyledContainer>
          <StyledBoardWrapper>
            <AdminMenu data-test-id="admin-menu-container">
              <AdminLink
                to="/"
                end
                onClick={() => setActive(0)}
                active={(active === 0).toString()}>
                Dashboard
              </AdminLink>
              <AdminLink
                to="/accounts"
                onClick={() => setActive(1)}
                active={(active === 1).toString()}>
                Accounts
              </AdminLink>
              {/* <AdminLink
                to="/earning"
                onClick={() => setActive(2)}
                active={(active === 2).toString()}>
                Earnings
              </AdminLink> */}
              <AdminLink
                to="/missions"
                onClick={() => setActive(3)}
                active={(active === 3).toString()}>
                Missions
              </AdminLink>
              <AdminLink
                to="/qualifications"
                onClick={() => setActive(4)}
                active={(active === 4).toString()}>
                Qualifications
              </AdminLink>
            </AdminMenu>
          </StyledBoardWrapper>
        </StyledContainer>
      )}
      <Routes>
        <Route
          exact="true"
          path="/"
          element={<AdminDashboard setActive={setActive} />}
        />
        <Route
          exact="true"
          path="/accounts"
          element={<AdminAccountsPage setActive={setActive} />}
        />
        <Route
          exact="true"
          path="/accounts/assistant/:id"
          element={<AdminAssistantAccount />}
        />
        <Route
          exact="true"
          path="/accounts/owner/:id"
          element={<AdminOwnerAccountPage />}
        />
        <Route
          exact="true"
          path="/tasks/view/:id"
          element={<ViewTask isAdmin={true} active={active} />}
        />
        <Route exact path="/missions/create" element={<CreateMission />} />
        <Route
          exact="true"
          path="/missions/view/:id"
          element={<ViewMission isAdmin={true} active={active} />}
        />
        <Route
          exact="true"
          path="/missions/edit/:id"
          element={<EditMission />}
        />
        {/* <Route path="tasks/create" element={<CreateTask />} />
        <Route path="tasks/view/:id" element={<ViewTask isAdmin={false} />} />
        <Route path="tasks/edit/:id" element={<EditTask />} /> */}
        <Route exact="true" path="/profile" element={<OwnerProfileView />} />
        <Route
          exact="true"
          path="/missions"
          element={<AdminMission setActive={setActive} />}
        />
        <Route
          exact="true"
          path="/Qualifications"
          element={<AdminQualificationsPage setActive={setActive} />}
        />
        <Route
          path="updateAddress"
          element={<OwnerAddress change={state?.change} />}
        />
      </Routes>
    </>
  );
};

const StyledBoardWrapper = styled.div`
  display: flex;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
    padding: 0 2%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const AdminLink = (props) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `w-[50%] font-gilroy h-[20px] font-semibold flex items-center justify-center text-center cursor-pointer text-[30px] leading-[20px] no-underline ${
          isActive ? "text-[#e8735a]" : "text-[#3b3e45]"
        }`
      }
      to={props.to ?? ""}
      exact="true"
      end={props.end ?? ""}
      onClick={props.onClick ?? (() => {})}>
      {props.children}
    </NavLink>
  );
};

const AdminMenu = styled(Box)`
  border-bottom: 1px solid #dcdfe3;
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
`;
