import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { CheckIcon, LoadingIcon, SubmitIcon } from "../../../assets/svg";
import { Task } from "../../../interfaces/Task";
import { MissionTaskTypes, taskState } from "../../../state/atoms";
import FileUpload from "../../organisms/FileUpload";
import HTMLTextRender from "../../organisms/HTMLTextRender";
import { CommentSchema } from "./validation";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import { useEffect } from "react";
import { CloseIcon, Energy, PaperClipIcon, Pen } from "../../../assets/svg";
import Modal from "../../organisms/Modal";

import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";
import statusColors from "../../../assets/statusColors";

import { Admin } from "../../../interfaces/User";
import {
  formatCommentDate,
  getLanguageNameById,
  isHTML,
  translationSources,
} from "../../../utils";
import {
  CONDITION_BitChute,
  CONDITION_DTube,
  REGEX_Vimeo,
  REGEX_Youtube,
} from "../../../utils/regex";
import TextEditor from "../../organisms/TextEditor";

const allStatuses = { ...ownerStatuses, ...paStatuses };

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 1100px;
  width: 100%;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0px;
  margin-right: 0px;
  color: #ffffff;
  font-weight: 700;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 9999px;
  background: #806ad2;

  @media (min-width: 768px) {
    margin-left: 1rem;
    width: 14rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;

const TaskWrapper = styled.div`
  min-width: 1100px;
  width: 75%;
  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const CreateTaskHeader = styled.div`
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1099px) {
    margin-left: 5%;
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const FormStyle = styled.div`
  justify-content: center;
  align-items: center;

  width: 960px;

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

const Header = styled(Box)`
  justify-content: center;
  align-items: center;
  /* height: 50px; */
  padding-top: 16px;
  padding-bottom: 16px;
`;

const CommentsContainer = styled.div`
  width: 960px;

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

const Body = styled.div``;

const StyledButton = styled(Button)`
  text-transform: none;
  border-radius: 50px;
`;

const URLContainer = styled.div`
  .MuiFilledInput-input {
    padding-top: 10px !important;
  }
`;

const HeaderStatus = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  > div:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #c5c5c5;
  }
  > div:last-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }
`;

const AcceptancesContainer = styled.div`
  width: 960px;

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
`;

const Status = styled.div<{ color: string }>`
  background: ${(props) => props.color || "black"};
  border-radius: 20px;
  border: 1px solid transparent;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 6rem;
  height: 22px;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  margin-right: 10px;

  @media only screen and (max-width: 780px) {
    margin-right: 10px;
  }
  padding: 0px 5px;
`;

type Props = {
  handleSubmit: (values: any) => void;
  addRating: (values: any) => void;
  submitComment: (values: any) => void;
  isSubmittingComment: boolean;
  holder_id: string | null;
  updateTask: (task: any) => void;
  completeTask: () => void;
  isTaskCompleting: boolean;
  rejectTaskSubmission: () => void;
  isTaskBeingRejected: boolean;
  submissionRejected: boolean;
  isCompleteTask: boolean;
  createTask: (task: any) => void;
  isLoading: boolean;
  isAdmin: boolean;
  active: any;
  isPublished: boolean;
  isChangingState: boolean;
  isDrafted: boolean;
  changeEnergy: () => void;
  changeEnergyStates: any;
  childTaskData: any;
  descriptionTranslationLoading: boolean;
  handleDescriptionTranslation: (values: any) => void;
  handleOutputTranslation: (values: any) => void;
  isOutputTranslating: boolean;
  userData: Admin;
  handleTranslateExistingComment: (values: {
    text: string;
    source: string;
    index: number;
    id: string;
  }) => void;
  translateExistingCommentLoading: boolean;
  translatedComment: string;
  resetTranslation: () => void;
  handleTranslateInputComment: (values: any) => void;
  isInputCommentTranslationLoading: boolean;
  isOutputTranslated: boolean;
  descriptionTranslated: boolean;
  translatedCommentIndexes: any[];
  resetTranslationState: any;
  loadingCommentsTranslationIndexes: any[];
};

const Component = ({
  handleSubmit,
  submitComment,
  isSubmittingComment,
  holder_id = "",
  updateTask,
  completeTask,
  createTask,
  isTaskCompleting,
  rejectTaskSubmission,
  isTaskBeingRejected,
  submissionRejected,
  addRating,
  isCompleteTask,
  isLoading,
  isAdmin,
  active,
  isPublished,
  isChangingState,
  isDrafted,
  changeEnergy,
  changeEnergyStates,
  childTaskData,
  descriptionTranslationLoading,
  handleDescriptionTranslation,
  handleOutputTranslation,
  isOutputTranslating,
  userData,
  handleTranslateExistingComment,
  translateExistingCommentLoading,
  translatedComment,
  resetTranslation,
  handleTranslateInputComment,
  isInputCommentTranslationLoading,
  isOutputTranslated,
  descriptionTranslated,
  translatedCommentIndexes,
  resetTranslationState,
  loadingCommentsTranslationIndexes,
}: Props) => {
  const navigate = useNavigate();
  //Datepicker
  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);
  const [showRating, setShowRating] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rating, setRating] = React.useState<number | null>(2);
  const [ratedescription, setRateDescription] = React.useState<String | null>(
    "",
  );

  //Button States
  //Request in Progress
  const [isMarking, setIsMarking] = React.useState(false);
  const [isPosting, setIsPosting] = React.useState(false);
  //Response Received
  const [isMarked, setIsMarked] = React.useState(false);
  const [isPosted, setIsPosted] = React.useState(false);

  const [isTranslated, setIsTranslated] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isEditAllowed = () => {
    return ["draft", "none", "published"].includes(activeTask.status);
  };
  const handleEditClick = function () {
    navigate("/missions/edit/" + activeTask.task_id);
  };

  // Upload file preview.
  const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} />;
  };

  const handleFinishUploading = (attachments: string[]) => {
    let task = Object.assign({}, activeTask);
    task.attachments = [...task.attachments, ...attachments];
    setActiveTask(task);
    // updateTask(task);
  };
  useEffect(() => {
    if (isCompleteTask) {
      setIsMarking(false);
      setIsMarked(true);
      setShowRating(true);
      let task = Object.assign({}, activeTask);
      task.status = "completed";
      setActiveTask(task);
    }
  }, [isCompleteTask]);
  const markAsComplete = () => {
    setIsMarking(true);
    setTimeout(() => {
      completeTask();
    }, 1000);
  };
  const addRate = () => {
    const ratingdata = { rating: rating, feedback: ratedescription };
    addRating(ratingdata);
  };
  const InnerComponent = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (translatedComment !== "") {
        setFieldValue("comment", translatedComment);
        resetTranslation();
      }
    }, [translatedComment, resetTranslation]);

    return null;
  };

  const isParent = activeTask.is_child === false;
  const isChild = activeTask.is_child === true;

  return (
    <StyledContainer className="antialiased bg-mainBg">
      <TaskWrapper>
        <CreateTaskHeader className="mb-10">
          <p className="font-Inter text-xs text-black">
            <span
              className=" text-black hover:cursor-pointer hover:underline"
              onClick={() => navigate("/")}>
              Admin Dashboard
            </span>

            {activeTask.is_child ? (
              <>
                {" > "}
                <span
                  className=" text-black hover:cursor-pointer hover:underline"
                  onClick={() =>
                    navigate(`/missions/view/${activeTask.parent_id}`)
                  }>
                  Parent Mission
                </span>
                <>{" > "}View Mission</>
              </>
            ) : (
              <>{" >"} View Mission</>
            )}
          </p>

          <p className="font-Inter text-4xl text-black font-bold my-2">
            View {activeTask.task_type === "mission" ? "Mission" : "Task"}
          </p>
        </CreateTaskHeader>

        <>
          <Modal
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            open={showRating}
            title="How was the quality of the PA’s work?"
            onClose={() => {
              setShowRating(false);
              // completeTask();
            }}>
            <div style={{ maxWidth: 450 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                  padding: "20px",
                }}>
                <Typography
                  fontWeight={700}
                  fontFamily="Inter"
                  fontSize="20px"></Typography>
                <Rating
                  name="size-large"
                  defaultValue={0}
                  sx={{ fontSize: "50px" }}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />

                <TextField
                  fullWidth
                  placeholder="Add description here"
                  multiline={true}
                  rows={4}
                  sx={{ height: "200px" }}
                  variant="filled"
                  onChange={(event) => {
                    setRateDescription(event.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={addRate}
                  fullWidth
                  style={{ backgroundColor: "#806AD2" }}>
                  Rate now
                </Button>
              </div>
            </div>
          </Modal>
          <FormContainer className="flex flex-col justify-center">
            {Boolean(isParent) ? (
              <>
                <FormStyle
                  className={`bg-white shadow-lg ${
                    activeTask.status === "draft" ||
                    childTaskData?.results?.length === 0 ||
                    !childTaskData?.results?.length
                      ? "mb-20"
                      : ""
                  }`}>
                  <Header
                    className="px-10 w-full"
                    borderBottom="1px solid rgba(163, 163, 163, 0.21)">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center">
                      <div className="text-[26px] font-bold justify-start w-fit">
                        {activeTask.title}
                      </div>
                      <div className="flex content-center items-center">
                        {isEditAllowed() ? (
                          <div
                            onClick={handleEditClick}
                            className="cursor-pointer mx-2">
                            <Pen></Pen>
                          </div>
                        ) : null}
                        <div></div>
                        <div
                          onClick={() => {
                            navigate("/missions");
                          }}
                          className="cursor-pointer mx-2">
                          <CloseIcon></CloseIcon>
                        </div>
                      </div>
                    </Box>
                    <Box display="flex" paddingY="16px" marginTop="32px">
                      <HeaderStatus>
                        <Box>
                          {isEditAllowed() ? "Last Edited" : "Published"}
                        </Box>
                        <Box>
                          {isEditAllowed()
                            ? new Date(
                                activeTask.modified_date || Date.now(),
                              ).toDateString()
                            : new Date(
                                activeTask.date || Date.now(),
                              ).toDateString()}
                        </Box>
                      </HeaderStatus>
                      <HeaderStatus>
                        <Box>Status</Box>
                        <Box>
                          <Status color={statusColors?.[activeTask.status]}>
                            {allStatuses?.[activeTask.status] ||
                              activeTask.status}
                          </Status>
                        </Box>
                      </HeaderStatus>
                      <HeaderStatus>
                        {isEditAllowed() ? (
                          <></>
                        ) : (
                          <>
                            <Box>Since</Box>
                            <Box>
                              {new Date(
                                activeTask.status_changed || Date.now(),
                              ).toLocaleString()}
                            </Box>
                          </>
                        )}
                      </HeaderStatus>
                      <HeaderStatus>
                        <Box>Energy Assigned Per Mission</Box>
                        <Box>
                          <span className="text-base flex flex-row items-center gap-1">
                            <div className="font-bold">
                              {activeTask?.energy_assigned}
                            </div>

                            <Energy />
                          </span>
                        </Box>
                      </HeaderStatus>
                    </Box>
                  </Header>

                  <Box ml="40px" mr="40px" paddingTop="26px">
                    <Grid container rowGap={2}>
                      <Grid container columnGap={2}>
                        <Grid item xs={2}>
                          <div className="text-base font-bold ">Tags</div>
                        </Grid>
                        <Grid item xs={7}>
                          <span className="text-base">
                            {[
                              ...activeTask?.category.map(
                                (type) =>
                                  MissionTaskTypes.find(
                                    (taskType) => taskType.value === type,
                                  )?.name,
                              ),
                            ].join(", ")}
                          </span>
                        </Grid>
                      </Grid>

                      {activeTask?.rewards_qualifications && (
                        <Grid container columnGap={2}>
                          <Grid item xs={2}>
                            <div className="text-base font-bold ">Reward</div>
                          </Grid>
                          {activeTask.rewards_qualifications.map((q) => (
                            <Grid item xs={7} key={q.id}>
                              <span className="text-base">{q.name}</span>
                            </Grid>
                          ))}
                        </Grid>
                      )}

                      {Boolean(
                        isEditAllowed() &&
                          new Date() < new Date(activeTask.date),
                      ) && (
                        <Grid container columnGap={2}>
                          <Grid item xs={2}>
                            <div className="text-base font-bold ">Schedule</div>
                          </Grid>
                          <Grid item xs={7}>
                            <span className="text-base">
                              {`Visible on: ${new Date(
                                activeTask.date,
                              ).toLocaleString()}`}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container columnGap={2}>
                        <Grid item xs={11}>
                          <div className="flex flex-row gap-2">
                            <div className="text-base font-bold flex flex-row gap-1">
                              Description
                            </div>
                            {activeTask.description &&
                              userData?.preferred_language !== "en" && (
                                <>
                                  <div
                                    onClick={async () => {
                                      if (
                                        descriptionTranslationLoading ||
                                        descriptionTranslated
                                      ) {
                                        return;
                                      }
                                      await handleDescriptionTranslation({
                                        description: activeTask?.description,
                                      });
                                    }}
                                    className={`${
                                      descriptionTranslationLoading ||
                                      descriptionTranslated
                                        ? "opacity-50"
                                        : "cursor-pointer underline"
                                    } flex items-center justify-center hover:opacity-50 text-xs`}>
                                    {descriptionTranslationLoading
                                      ? "Translating..."
                                      : `Translate to ${getLanguageNameById(
                                          userData?.preferred_language,
                                        )}`}
                                  </div>
                                  {descriptionTranslationLoading && (
                                    <div className="flex items-center justify-center">
                                      <LoadingIcon
                                        size={3}
                                        color={"primaryButton"}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <HTMLTextRender
                            key={activeTask.task_id}
                            htmlString={activeTask?.description?.replaceAll(
                              "<br/>",
                              "\n",
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="text-black text-sm font-bold font-inter mb-2">
                          Video URLs
                        </div>
                        {activeTask?.videos?.map((url, index) => (
                          <div className="mb-3 flex items-center" key={index}>
                            {REGEX_Youtube.test(url) ? (
                              <iframe
                                className="rounded-md mr-5"
                                width="344"
                                height="188"
                                src={
                                  "https://www.youtube.com/embed/" +
                                  url.substring(17)
                                }
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                            ) : REGEX_Vimeo.test(url) ? (
                              <iframe
                                className="rounded-md mr-5"
                                width="344"
                                height="188"
                                src={
                                  "https://player.vimeo.com/video/" +
                                  url.substring(18)
                                }
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                            ) : CONDITION_DTube(url) ? (
                              <iframe
                                className="rounded-md mr-5"
                                width="344"
                                height="188"
                                src={
                                  "https://emb.d.tube/#!/" + url.substring(21)
                                }
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                            ) : CONDITION_BitChute(url) ? (
                              <iframe
                                className="rounded-md mr-5"
                                width="344"
                                height="188"
                                src={
                                  "https://www.bitchute.com/embed/" +
                                  url.substring(31)
                                }
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                scrolling="no"
                                allowFullScreen></iframe>
                            ) : (
                              <iframe
                                className="rounded-md mr-5"
                                width="344"
                                height="188"
                                src={
                                  "https://vimeo.com/api/oembed.json?url=" +
                                  encodeURI(url)
                                }
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                            )}
                          </div>
                        ))}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div className="text-black text-sm font-bold font-inter mb-2">
                          Website URLs
                        </div>
                        {activeTask?.urls?.map((url, index) => (
                          <Grid item key={index}>
                            <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pt-[5] h-[35] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                              <a
                                href={
                                  url.includes("http") ? url : `https://${url}`
                                }
                                target="_blank"
                                className="whitespace-nowrap">
                                {url}
                              </a>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container rowGap={2} columnGap={2}>
                        <Grid item xs={11}>
                          <div className="text-base font-bold ">
                            Attachments
                          </div>
                        </Grid>
                        {activeTask.attachments?.map((file, index) => {
                          let fileName = file.substr(file.lastIndexOf("/") + 1);
                          return (
                            <Grid item key={index}>
                              <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pl-[30] pt-[5] h-[35] w-[155] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                                <a
                                  href={file}
                                  target="_blank"
                                  className="whitespace-nowrap">
                                  {fileName}
                                </a>
                                <div className="absolute left-[10] top-[8]">
                                  <PaperClipIcon className="h-[14] w-[14]" />
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {!isAdmin && activeTask?.status === "draft" && (
                        <Grid container>
                          <Grid item xs={11}>
                            <FileUpload
                              onFinishUploading={handleFinishUploading}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Stack className="mb-10 mt-10 " spacing={2} direction="row">
                      {activeTask?.status === "draft" ? (
                        <TaskPageButton
                          style={{ textTransform: "none" }}
                          className="w-50 h-12 m-0 gap-1"
                          variant="contained"
                          onClick={async () => {
                            handleSubmit({ state: "published" });
                          }}>
                          <span className="flex items-center justify-center">
                            {isChangingState ? (
                              <LoadingIcon></LoadingIcon>
                            ) : isPublished ? (
                              <CheckIcon nomargin={true}></CheckIcon>
                            ) : (
                              <SubmitIcon nomargin={true}></SubmitIcon>
                            )}
                          </span>
                          <span>
                            {isChangingState
                              ? "Publishing"
                              : isPublished
                              ? "Published"
                              : "Publish"}
                          </span>
                        </TaskPageButton>
                      ) : null}
                      {Boolean(
                        activeTask?.status === "published" &&
                          childTaskData?.result?.length === 0,
                      ) ? (
                        !loading ? (
                          <TaskPageButton
                            className="m-0 w-50"
                            onClick={async () => {
                              setLoading(true);
                              handleSubmit({ state: "draft" });
                            }}>
                            <span>Set task to Draft</span>
                          </TaskPageButton>
                        ) : (
                          <TaskPageButton className="m-0 gap-1">
                            <span className="flex items-center justify-center">
                              {isChangingState ? (
                                <LoadingIcon></LoadingIcon>
                              ) : isDrafted ? (
                                <CheckIcon nomargin={true}></CheckIcon>
                              ) : (
                                <></>
                              )}
                            </span>
                            <span>
                              {isDrafted ? "Success" : "Setting task to draft"}
                            </span>
                          </TaskPageButton>
                        )
                      ) : null}
                    </Stack>
                  </Box>
                </FormStyle>
                {Boolean(activeTask.status !== "draft") && (
                  <AcceptancesContainer
                    className={`${
                      childTaskData?.results?.length === 0 ? "hidden " : ""
                    }flex flex-col w-[960] min-h-[120] bg-commentBg rounded h-50 mb-8 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] justify-center items-center`}
                    style={{
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }}>
                    <div className="flex w-full pl-[40px] pr-[40px] flex-col">
                      <span className="font-inter font-bold mb-3 mt-6 text-xl">
                        Acceptances:
                      </span>
                    </div>
                    <div
                      className="w-[95%] px-6  py-3 my-1 flex rounded-3xl bg-darkerBg mb-10 max-h-[400px] overflow-x-hidden overflow-y-auto"
                      id="parent-task-container">
                      <div className="flex flex-col mr-3 w-full">
                        {childTaskData?.results.map((val, index) => (
                          <div
                            role="button"
                            key={index}
                            onClick={() => {
                              resetTranslationState();
                              navigate(`../../missions/view/${val.task_id}`);
                            }}
                            className={` flex flex-col justify-center align-middle px-4 m-2 min-h-[66px] hover:scale-[1.02] ease-in-out duration-300 cursor-pointer bg-[#8d8d8d81] w-full rounded-xl`}>
                            <div id="top-layer" className="font-bold">
                              {val.assistant_email}
                            </div>
                            <div id="bottom-layer">
                              <Status color={statusColors?.[val.status]}>
                                {allStatuses?.[val.status] || val.status}
                              </Status>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AcceptancesContainer>
                )}
              </>
            ) : (
              <>
                <FormStyle
                  className={`bg-white shadow-lg ${
                    activeTask.status === "draft" &&
                    activeTask.comments.length === 0
                      ? "mb-20"
                      : ""
                  }`}>
                  <Header
                    className="px-10 w-full"
                    borderBottom="1px solid rgba(163, 163, 163, 0.21)">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center">
                      <div className="text-[26px] font-bold justify-start w-fit">
                        {activeTask.title}
                      </div>
                      <div className="flex content-center items-center">
                        {Boolean(
                          activeTask.status === "published" ||
                            activeTask.status === "draft",
                        ) && (
                          <>
                            {Boolean(activeTask.status === "draft") && (
                              <div
                                onClick={handleEditClick}
                                className="cursor-pointer mx-2">
                                <Pen></Pen>
                              </div>
                            )}
                          </>
                        )}
                        <div
                          onClick={() => {
                            navigate(`/missions/view/${activeTask.parent_id}`);
                          }}
                          className="cursor-pointer mx-2">
                          <CloseIcon></CloseIcon>
                        </div>
                      </div>
                    </Box>
                    <Box display="flex" paddingY="16px" marginTop="32px">
                      <HeaderStatus>
                        <Box>
                          {isEditAllowed() ? "Last Edited" : "Published"}
                        </Box>
                        <Box>
                          {isEditAllowed()
                            ? new Date(
                                activeTask.modified_date || Date.now(),
                              ).toDateString()
                            : new Date(
                                activeTask.date || Date.now(),
                              ).toDateString()}
                        </Box>
                      </HeaderStatus>
                      <HeaderStatus>
                        <Box>Status</Box>
                        <Box>
                          <Status color={statusColors?.[activeTask.status]}>
                            {allStatuses?.[activeTask.status] ||
                              activeTask.status}
                          </Status>
                        </Box>
                      </HeaderStatus>
                      <HeaderStatus>
                        {isEditAllowed() ? (
                          <></>
                        ) : (
                          <>
                            <Box>Since</Box>
                            <Box>
                              {new Date(
                                activeTask.status_changed || Date.now(),
                              ).toLocaleString()}
                            </Box>
                          </>
                        )}
                      </HeaderStatus>
                      <HeaderStatus>
                        <Box>Energy</Box>
                        <Box>
                          <span className="text-base flex flex-row items-center gap-1">
                            <div className="font-bold">
                              {activeTask?.energy_assigned}
                            </div>

                            <Energy />
                          </span>
                        </Box>
                      </HeaderStatus>
                    </Box>
                  </Header>
                  {/* <Divider light className="w-full" /> */}
                  <Box ml="40px" mr="40px" paddingTop="26px">
                    <Grid container rowGap={2}>
                      <Grid container columnGap={2}>
                        <Grid item xs={2}>
                          <div className="text-base font-bold ">Tags</div>
                        </Grid>
                        <Grid item xs={7}>
                          <span className="text-base">
                            {[
                              ...activeTask?.category.map(
                                (type) =>
                                  MissionTaskTypes.find(
                                    (taskType) => taskType.value === type,
                                  )?.name,
                              ),
                            ].join(", ")}
                          </span>
                        </Grid>
                      </Grid>
                      {Boolean(
                        isEditAllowed() &&
                          new Date() < new Date(activeTask.date),
                      ) && (
                        <Grid container columnGap={2}>
                          <Grid item xs={2}>
                            <div className="text-base font-bold ">Schedule</div>
                          </Grid>
                          <Grid item xs={7}>
                            <span className="text-base">
                              {`Visible on: ${new Date(
                                activeTask.date,
                              ).toLocaleString()}`}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container columnGap={2}>
                        <Grid item xs={11}>
                          <div className="flex flex-row gap-2">
                            <div className="text-base font-bold">
                              Description
                            </div>
                            {activeTask.description &&
                              userData?.preferred_language !== "en" && (
                                <>
                                  <div
                                    onClick={async () => {
                                      if (descriptionTranslationLoading) {
                                        return;
                                      }
                                      await handleDescriptionTranslation({
                                        description: activeTask?.description,
                                      });
                                    }}
                                    className={`${
                                      descriptionTranslationLoading ||
                                      descriptionTranslated
                                        ? "opacity-50"
                                        : "cursor-pointer underline"
                                    } flex items-center justify-center text-xs hover:opacity-50`}>
                                    {descriptionTranslationLoading
                                      ? "Translating..."
                                      : `Translate to ${getLanguageNameById(
                                          userData.preferred_language,
                                        )}`}
                                  </div>
                                  {descriptionTranslationLoading && (
                                    <div className="flex items-center justify-center">
                                      <LoadingIcon
                                        size={3}
                                        color={"primaryButton"}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <HTMLTextRender
                            key={activeTask.task_id}
                            htmlString={activeTask?.description?.replaceAll(
                              "<br/>",
                              "\n",
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="text-black text-sm font-bold font-inter mb-2">
                          Video URLs
                        </div>
                        {activeTask?.videos?.map((url, index) => (
                          <div className="mb-3 flex items-center" key={index}>
                            <iframe
                              className="rounded-md mr-5 w-full h-[180] md:w-[344] md:h-[180]"
                              src={
                                "https://www.youtube.com/embed/" +
                                url.substring(17)
                              }
                              title="YouTube video player"
                              frameBorder={"0"}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                          </div>
                        ))}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div className="text-black text-sm font-bold font-inter mb-2">
                          Website URLs
                        </div>
                        {activeTask?.urls?.map((url, index) => (
                          <Grid item key={index}>
                            <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pt-[5] h-[35] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                              <a
                                href={
                                  url.includes("http") ? url : `https://${url}`
                                }
                                target="_blank"
                                className="whitespace-nowrap">
                                {url}
                              </a>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container rowGap={2} columnGap={2}>
                        <Grid item xs={11}>
                          <div className="text-base font-bold ">
                            Attachments
                          </div>
                        </Grid>
                        {activeTask.attachments?.map((file, index) => {
                          let fileName = file.substr(file.lastIndexOf("/") + 1);
                          return (
                            <Grid item key={index}>
                              <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pl-[30] pt-[5] h-[35] w-[155] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                                <a
                                  href={file}
                                  target="_blank"
                                  className="whitespace-nowrap">
                                  {fileName}
                                </a>
                                <div className="absolute left-[10] top-[8]">
                                  <PaperClipIcon className="h-[14] w-[14]" />
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {activeTask?.status === "draft" && (
                        <Grid container>
                          <Grid item xs={11}>
                            <FileUpload
                              onFinishUploading={handleFinishUploading}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Stack className="mb-10 mt-10 " spacing={2} direction="row">
                      {activeTask?.status === "draft" ? (
                        <TaskPageButton
                          style={{ textTransform: "none" }}
                          className="w-50 h-12 gap-1"
                          variant="contained"
                          onClick={async () => {
                            handleSubmit({ state: "published" });
                          }}>
                          <span className="flex items-center justify-center">
                            {isChangingState ? (
                              <LoadingIcon></LoadingIcon>
                            ) : isPublished ? (
                              <CheckIcon nomargin={true}></CheckIcon>
                            ) : (
                              <SubmitIcon nomargin={true}></SubmitIcon>
                            )}
                          </span>
                          <span className="">
                            {isChangingState
                              ? "Publishing"
                              : isPublished
                              ? "Published"
                              : "Publish"}
                          </span>
                        </TaskPageButton>
                      ) : null}
                      {activeTask?.status === "published" ? (
                        !loading ? (
                          <TaskPageButton
                            className="m-0 w-50"
                            onClick={async () => {
                              setLoading(true);
                              handleSubmit({ state: "draft" });
                            }}>
                            {/* <span></span> */}
                            <span>Set task to Draft</span>
                          </TaskPageButton>
                        ) : (
                          <TaskPageButton className="m-0 gap-1">
                            <span className="flex items-center justify-center">
                              {isChangingState ? (
                                <LoadingIcon></LoadingIcon>
                              ) : isDrafted ? (
                                <CheckIcon nomargin={true}></CheckIcon>
                              ) : (
                                <></>
                              )}
                            </span>
                            <span>
                              {isDrafted
                                ? "Success"
                                : "Setting mission to draft"}
                            </span>
                          </TaskPageButton>
                        )
                      ) : null}
                    </Stack>
                  </Box>
                </FormStyle>

                {Boolean(isChild) ? (
                  <CommentsContainer
                    className="flex flex-col w-[960] min-h-[120] bg-commentBg rounded h-50 mb-8 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] justify-center items-center"
                    style={{
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }}>
                    <div className="flex w-full pl-[40px] pr-[40px] flex-col">
                      <span className="font-inter font-bold text-base mb-3 mt-6">
                        Comments:
                      </span>

                      <Formik
                        initialValues={{ comment: "" }}
                        validationSchema={CommentSchema}
                        onSubmit={(values, formikHelpers) => {
                          setIsPosting(true);

                          setTimeout(() => {
                            submitComment({
                              text: values.comment,
                              role: "nft",
                              user_id: holder_id,
                            });
                            setIsPosting(false);
                            setIsPosted(true);

                            setTimeout(() => {
                              setIsPosted(false);
                              formikHelpers.resetForm();
                            }, 1500);
                          }, 1500);
                        }}>
                        {({
                          values,
                          errors,
                          setFieldValue,
                          resetForm,
                          handleSubmit,
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <InnerComponent />
                              <div className="flex flex-col gap-1">
                                <div className="w-full">
                                  <TextEditor
                                    onEditorChange={(string) => {
                                      setFieldValue("comment", string);
                                      setIsTranslated(false);
                                    }}
                                    htmlString={values?.comment}
                                    placeholder={"Input comment"}
                                    isTranslated={isTranslated}
                                    editorStyle={{
                                      backgroundColor: "white",
                                    }}
                                  />
                                </div>

                                <div className="flex flex-row gap-1">
                                  <div
                                    onClick={async () => {
                                      if (
                                        isInputCommentTranslationLoading ||
                                        !values.comment
                                      ) {
                                        return;
                                      }

                                      await handleTranslateInputComment({
                                        comment: values.comment,
                                      });
                                      setIsTranslated(true);
                                    }}
                                    className={` text-xs hover:opacity-50 ${
                                      isInputCommentTranslationLoading
                                        ? "opacity-50"
                                        : "underline cursor-pointer"
                                    }`}>
                                    {isInputCommentTranslationLoading
                                      ? "Translating..."
                                      : `Translate to English`}
                                  </div>
                                  {isInputCommentTranslationLoading && (
                                    <div className="flex items-center justify-center">
                                      <LoadingIcon
                                        color={"primaryButton"}
                                        size={3}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex justify-end">
                                <TaskPageButton
                                  className="w-28 gap-1"
                                  variant="contained"
                                  type="submit"
                                  disabled={
                                    isSubmittingComment ||
                                    isPosted ||
                                    isPosting ||
                                    submissionRejected ||
                                    isTaskBeingRejected
                                  }>
                                  <span className="flex items-center justify-center">
                                    {isPosting ? (
                                      <LoadingIcon></LoadingIcon>
                                    ) : isPosted ? (
                                      <CheckIcon nomargin={true}></CheckIcon>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                  <span>
                                    {isPosted
                                      ? "Posted"
                                      : isPosting
                                      ? "Posting"
                                      : "Post"}
                                  </span>
                                </TaskPageButton>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>

                    <div className="flex w-4/5 flex-col mt-10">
                      {activeTask.comments &&
                        activeTask.comments.length > 0 &&
                        activeTask.comments
                          .map((cmt, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  "w-full" + `${index === 0 ? " mb-6" : ""}`
                                }>
                                {index !== activeTask.comments.length - 1 && (
                                  <hr className="h-px my-2 mb-4 bg-gray-200 border-1 dark:bg-gray-700" />
                                )}
                                <div>
                                  <div className="w-full flex-col">
                                    <span className="font-bold font-inter text-xs mr-3">
                                      {cmt.role === "nft"
                                        ? "Mission Owner"
                                        : "Personal Assistant"}
                                    </span>
                                    <span className="font-inter text-xs">
                                      {formatCommentDate(cmt.created_date)}
                                    </span>
                                  </div>
                                  <div>
                                    <div>
                                      {isHTML(cmt.text) ? (
                                        <div className="flex flex-col">
                                          <div className=" -mt-[5]">
                                            <HTMLTextRender
                                              htmlString={cmt.text?.replaceAll(
                                                "<br/>",
                                                "\n",
                                              )}
                                            />
                                          </div>
                                          <div
                                            className="flex flex-row gap-2"
                                            onClick={async () => {
                                              if (
                                                loadingCommentsTranslationIndexes.includes(
                                                  cmt.comment_id,
                                                )
                                              ) {
                                                return;
                                              }

                                              await handleTranslateExistingComment(
                                                {
                                                  text: cmt.text,
                                                  source:
                                                    translationSources.EDITOR,
                                                  index,
                                                  id: cmt.comment_id,
                                                },
                                              );
                                            }}>
                                            <div
                                              role="button"
                                              className={`${
                                                translatedCommentIndexes.includes(
                                                  cmt.comment_id,
                                                ) ||
                                                loadingCommentsTranslationIndexes.includes(
                                                  cmt.comment_id,
                                                )
                                                  ? "opacity-50"
                                                  : "cursor-pointer hover:opacity-50 underline"
                                              } text-color-gray text-xs`}>
                                              {loadingCommentsTranslationIndexes.includes(
                                                cmt.comment_id,
                                              )
                                                ? "Translating..."
                                                : `Translate to ${getLanguageNameById(
                                                    userData.preferred_language,
                                                  )}`}
                                            </div>
                                            {loadingCommentsTranslationIndexes.includes(
                                              cmt.comment_id,
                                            ) && (
                                              <div className="flex items-center justify-center">
                                                <LoadingIcon
                                                  size={3}
                                                  color={"primaryButton"}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex flex-col">
                                          <span>{cmt.text}</span>
                                          <div
                                            className="flex flex-row"
                                            onClick={async () => {
                                              if (
                                                loadingCommentsTranslationIndexes.includes(
                                                  cmt.comment_id,
                                                )
                                              ) {
                                                return;
                                              }

                                              await handleTranslateExistingComment(
                                                {
                                                  text: cmt.text,
                                                  source:
                                                    translationSources.BASIC,
                                                  index,
                                                  id: cmt.comment_id,
                                                },
                                              );
                                            }}>
                                            <div
                                              className={`${
                                                translatedCommentIndexes.includes(
                                                  cmt.comment_id,
                                                ) ||
                                                loadingCommentsTranslationIndexes.includes(
                                                  cmt.comment_id,
                                                )
                                                  ? "opacity-50"
                                                  : "cursor-pointer hover:opacity-50 underline"
                                              } text-color-gray text-xs`}>
                                              {loadingCommentsTranslationIndexes.includes(
                                                cmt.comment_id,
                                              )
                                                ? "Translating..."
                                                : `Translate to ${getLanguageNameById(
                                                    userData.preferred_language,
                                                  )}`}
                                            </div>
                                            {loadingCommentsTranslationIndexes.includes(
                                              cmt.comment_id,
                                            ) && (
                                              <div className="flex items-center justify-center">
                                                <LoadingIcon
                                                  size={3}
                                                  color={"primaryButton"}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          .reverse()}
                    </div>
                  </CommentsContainer>
                ) : (
                  <div></div>
                )}

                {["submitted", "completed"].includes(activeTask?.status) && (
                  <FormStyle className="drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] bg-white shadow-lg mb-8">
                    <Header className="px-10 flex justify-between bg-[#ffdea0]">
                      <div className="text-xl font-bold">Output</div>
                    </Header>
                    {/* <Divider light className="w-full" /> */}
                    <Box className="m-8">
                      <Grid container>
                        <Grid item xs={22} md={12}>
                          <Grid container rowGap={2}>
                            <Grid item xs={24}>
                              <div className="flex flex-row gap-2">
                                <div className="font-bold text-base">
                                  Progress
                                </div>
                                {activeTask.delivery.description &&
                                  userData?.preferred_language !== "en" && (
                                    <>
                                      <div
                                        role="button"
                                        title={`${
                                          isOutputTranslated
                                            ? "Output is already translated to " +
                                              getLanguageNameById(
                                                userData.preferred_language,
                                              )
                                            : isOutputTranslating
                                            ? "Output is translating"
                                            : ""
                                        }`}
                                        className={`${
                                          isOutputTranslating ||
                                          isOutputTranslated
                                            ? "opacity-50"
                                            : "cursor-pointer underline"
                                        } flex items-center justify-center text-xs hover:opacity-50`}
                                        onClick={async () => {
                                          if (isOutputTranslating) {
                                            return;
                                          }
                                          await handleOutputTranslation({
                                            description:
                                              activeTask.delivery.description,
                                          });
                                        }}>
                                        {isOutputTranslating
                                          ? "Translating..."
                                          : `Translate to ${getLanguageNameById(
                                              userData.preferred_language,
                                            )}`}
                                      </div>
                                      {isOutputTranslating && (
                                        <div className="flex items-center justify-center">
                                          <LoadingIcon
                                            size={3}
                                            color={"primaryButton"}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                              </div>
                            </Grid>
                            {!!activeTask?.delivery?.description && (
                              <Grid item xs={12}>
                                {!isHTML(activeTask.delivery.description) ? (
                                  <div className="text-base">
                                    {activeTask?.delivery?.description}
                                  </div>
                                ) : (
                                  <div className="text-base">
                                    <HTMLTextRender
                                      htmlString={activeTask.delivery.description?.replaceAll(
                                        "<br/>",
                                        "\n",
                                      )}
                                    />
                                  </div>
                                )}
                              </Grid>
                            )}

                            <Grid
                              item
                              xs={12}
                              className="bg-mainBg px-2 py-3 rounded mb-11">
                              <Grid container rowGap={2}>
                                <Grid
                                  container
                                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                                  rowSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className="pl-[24px]">
                                    <div className="text-black text-sm font-bold font-inter mb-2">
                                      Video URLs
                                    </div>
                                    {activeTask?.delivery?.videos?.map(
                                      (url, index) => (
                                        <div
                                          className="mb-3 flex items-center"
                                          key={index}>
                                          <iframe
                                            className="rounded-md mr-5 w-3/4 h-[180] md:w-[200] md:h-[140]"
                                            src={
                                              "https://www.youtube.com/embed/" +
                                              url.substring(17)
                                            }
                                            title="YouTube video player"
                                            frameBorder={"0"}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                        </div>
                                      ),
                                    )}
                                    {!activeTask?.delivery?.videos ||
                                    activeTask?.delivery?.videos?.length ===
                                      0 ? (
                                      <div className="mb-3 ml-[12px] flex items-center">
                                        Empty
                                      </div>
                                    ) : null}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className="pl-0 md:pl-[24px] sm:pl-[24px]">
                                    <div className="text-black text-sm font-bold font-inter mb-2">
                                      Website URLs
                                    </div>
                                    {activeTask?.delivery?.urls?.map(
                                      (url, index) => (
                                        <Grid item key={index}>
                                          <div className="relative bg-[#fdfdfd] text-sm mb-3 p-[9] pt-[5] h-[35] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                                            <a
                                              href={url}
                                              target="_blank"
                                              className="whitespace-nowrap">
                                              {url}
                                            </a>
                                          </div>
                                        </Grid>
                                      ),
                                    )}
                                    {!activeTask?.delivery?.urls ||
                                    activeTask?.delivery?.urls?.length === 0 ? (
                                      <div className="mb-3 ml-[12px] flex items-center">
                                        Empty
                                      </div>
                                    ) : null}
                                  </Grid>

                                  <Grid item xs={24}>
                                    {activeTask.status !== "completed" && (
                                      <TaskPageButton
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                          markAsComplete();
                                        }}
                                        disabled={
                                          // activeTask.status !== "submitted" ||
                                          isTaskCompleting ||
                                          isTaskBeingRejected ||
                                          submissionRejected ||
                                          isMarking ||
                                          isMarked
                                        }
                                        className={
                                          (submissionRejected ||
                                          isTaskBeingRejected
                                            ? "hidden "
                                            : "") + "ml-2 sm:ml-0 md:ml-2 gap-1"
                                        }>
                                        <span className="flex items-center justify-center">
                                          {isMarking ? (
                                            <LoadingIcon></LoadingIcon>
                                          ) : isMarked ? (
                                            <CheckIcon
                                              nomargin={true}></CheckIcon>
                                          ) : (
                                            <></>
                                          )}
                                        </span>

                                        <span className="pl-[4px]">
                                          {isMarking
                                            ? "Marking"
                                            : isMarked
                                            ? "Marked"
                                            : "Mark As Complete"}
                                        </span>
                                      </TaskPageButton>
                                    )}
                                    {activeTask.status !== "completed" && (
                                      <TaskPageButton
                                        variant="contained"
                                        type="submit"
                                        onClick={rejectTaskSubmission}
                                        disabled={
                                          isTaskBeingRejected ||
                                          isTaskCompleting ||
                                          submissionRejected
                                        }
                                        className={
                                          (isMarking || isMarked
                                            ? "hidden "
                                            : "") + "ml-2 sm:ml-0 md:ml-2 gap-1"
                                        }>
                                        <span className="items-center justify-center">
                                          {isTaskBeingRejected ? (
                                            <LoadingIcon></LoadingIcon>
                                          ) : submissionRejected ? (
                                            <CheckIcon
                                              nomargin={true}></CheckIcon>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                        <span>
                                          {isTaskBeingRejected
                                            ? "Rejecting"
                                            : submissionRejected
                                            ? "Rejected"
                                            : "Reject the Submission"}
                                        </span>
                                      </TaskPageButton>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container rowGap={2} columnGap={2}>
                                  {activeTask.delivery?.attachments?.map(
                                    (file, index) => {
                                      let fileName = file.substr(
                                        file.lastIndexOf("/") + 1,
                                      );
                                      return (
                                        <Grid item key={index}>
                                          <div className="relative bg-[#fdfdfd] text-sm mb-1 p-[9] pl-[30] pt-[5] h-[35] w-[155] text-ellipsis overflow-hidden cursor-pointer rounded-[5px]">
                                            <a
                                              href={file}
                                              target="_blank"
                                              className="whitespace-nowrap">
                                              {fileName}
                                            </a>
                                            <div className="absolute left-[10] top-[8]">
                                              <PaperClipIcon className="h-[14] w-[14]" />
                                            </div>
                                          </div>
                                        </Grid>
                                      );
                                    },
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={22} md={12}>
                          <Grid container>
                            <Grid item>
                              <div className="font-bold text-base"> Rating</div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className="bg-mainBg px-2 py-3 rounded flex flex-col items-center justify-center">
                              {activeTask.status !== "completed" ? (
                                <div>Mark as complete first</div>
                              ) : !isAdmin &&
                                (activeTask.rating === 0 ||
                                  !activeTask.rating) ? (
                                <Button
                                  style={{ textTransform: "none" }}
                                  className="text-black font-bold"
                                  variant="text"
                                  onClick={() => setShowRating(true)}>
                                  Give Feedback
                                </Button>
                              ) : (
                                <div>
                                  <Rating
                                    name="size-large"
                                    readOnly
                                    defaultValue={activeTask.rating}
                                    sx={{ fontSize: "50px" }}
                                    onChange={(event, newValue) => {
                                      setRating(newValue);
                                    }}
                                  />
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormStyle>
                )}
              </>
            )}
          </FormContainer>
        </>
      </TaskWrapper>
    </StyledContainer>
  );
};

export default Component;
