import { Box, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { DiscordIcon, DotsVertical } from "../../../assets/svg";
import { Qualification } from "../../../interfaces/Qualification";
import {
  earningPeriodState,
  earningState,
  feedbackState,
  userTaskListState,
} from "../../../state/atoms";
import { ensureStartsWithAt } from "../../../utils/strings";
// import { DateRangePicker as RDateRangePicker } from "rsuite";
import John from "../../../assets/images/John1.png";
import Star from "../../../assets/images/star.png";

// import { PlusIcon, Star } from "../../../assets/svg";
import { CircularProgress, Rating } from "@mui/material";
import {
  DatePicker,
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CountUp from "react-countup";
import { Assistant } from "../../../interfaces/User";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media only screen and (max-width: 1099px) {
    width: 100%;
  }
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 80%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 38px 0 30px;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
    padding: 0 2%;
  }
`;

const FilterButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  width: 200px;
  height: 30px;
  padding: 0px 12px;
  color: #fff;
  user-select: none;
  background-color: #806ad2;
  cursor: pointer;
  /* ${({ selected }) =>
    selected
      ? `
        background-color: #fff;
        color: #000;
        `
      : `  color: #000000;`} */
`;

const TaskItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  > div {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const TableHeader = styled.div`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 26px;
  /* or 65% */

  letter-spacing: 0.46px;

  color: #000000;
`;

const TableSearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 11px 17px 11px 21px;
  gap: 10px;

  background: #fff;
  border-radius: 8px;
  width: 262px;
`;

const SearchInput = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* gray/dark 4 */

  color: #000;
  border: unset;
  background: transparent;
  :focus {
    outline: none;
  }
`;

const StyledTable = styled.table`
  margin-top: 32px;
  background: #ffffff;
  width: 100%;
  text-align: left;
  /* gray/200 */

  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const StyledThead = styled.thead`
  background: #f9fafb;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* gray/500 */

  color: #6b7280;
`;

const Styledth = styled.th`
  padding-left: 16px;
`;

const StyledTbody = styled.tbody`
  color: #111827;
`;

const Styledtr = styled.tr``;

const Styledtd = styled.td`
  padding: 16px;
  border-top: 1px solid #e5e7eb;
`;

const StyledTfoot = styled.tfoot`
  background: #f9fafb;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* gray/500 */

  color: #6b7280;
`;

const StyledName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/900 */

  color: #111827;
`;

const StyledEmail = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* gray/500 */

  color: #6b7280;
`;

const StyledStatus = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  height: 21px;
  width: 83px;

  border-radius: 20px;
  background: ${(props) => props.color || "#6987F0"};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #ffffff;
  margin: auto;
`;

const StyledLink = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* indigo/600 */

  color: #4f46e5;
  cursor: pointer;
  text-align: center;
`;

const ProfileBox = styled(Box)`
  background: #f1f1f1;
  border-radius: 10px;
  padding: 16px 32px 20px 32px;
  margin-top: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ProfileTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 26px;
  /* identical to box height, or 54% */

  letter-spacing: 0.46px;

  color: #000000;
`;

const ProfileDesc = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height, or 108% */

  letter-spacing: 0.46px;

  color: #000000;
`;

const ProfileStar = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 26px;
  /* identical to box height, or 54% */

  letter-spacing: 0.46px;

  color: #d9d9d9;
`;

const ProfileStatus = styled(Box)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  background: #f1f1f1;
  border-radius: 10px;
  flex: 1;
  > div:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-indent: 5px;

    color: #000000;
  }
  > div:last-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 26px;
    /* identical to box height, or 81% */

    /* text-align: center; */
    letter-spacing: 0.46px;

    color: #000000;
  }
`;

const FeedbackModalBack = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const FeedbackModal = styled(Box)`
  background: #ffffff;
  border-radius: 16px;
  width: 70%;
  padding: 24px;
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
`;

const FeedbackTitle = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  text-align: center;

  /* $dark-tone-100 */

  color: #1a1a25;
`;

const FeedbackBody = styled(Box)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 34px */

  padding: 32px;

  color: #1a1a25;
`;

const FeedbackBtn = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 12px 14px 16px;
  width: 568px;
  height: 48px;
  background: #806ad2;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14.5px;
  line-height: 140%;
  /* or 20px */

  color: #ffffff;
  cursor: pointer;
`;

const PaidBtn = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 1px;
  gap: 10px;

  width: 102px;
  height: 31px;

  background: #806ad2;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  cursor: pointer;
`;

const PaidStatus = styled.div<{ paid?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  background: ${({ paid }) => (paid ? "#40AD5F" : "#F78419")};
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #ffffff;
`;

type Props = {
  assistant: Assistant | undefined;
  tasks: Array<any>;
  tasks_count: any;
  after: any;
  before: any;
  handleNextPage: (after: any) => void;
  handlePrevPage: (before: any) => void;
  isLoading: boolean;
  UpdateEarning: (values: any) => void;
  earnings: any;
  totalEarning: any;
  earnings_count: any;
  earningAfter: any;
  earningBefore: any;
  handleEarningNextPage: any;
  handleEarningPrevPage: any;
  isEarningLoading: any;
  isTotalEarningLearning: any;
  feedbacks: Array<any>;
  feedbacks_count: any;
  feedbackAfter: any;
  feedbackBefore: any;
  handleFeedbackNextPage: (feedbackAfter: any) => void;
  handleFeedbackPrevPage: (feedbackBefore: any) => void;
  isFeedbackLoading: boolean;
  addQualifications: any;
  qualificationsList: Qualification[];
  userLoading: boolean;
  openUpdateUserModal: () => void;
  openRemoveQualificationModal: (id: any) => void;
};

const Component = ({
  assistant,
  tasks,
  tasks_count,
  after,
  before,
  handleNextPage,
  handlePrevPage,
  isLoading,
  earnings,
  totalEarning,
  earnings_count,
  earningAfter,
  earningBefore,
  handleEarningNextPage,
  handleEarningPrevPage,
  isEarningLoading,
  isTotalEarningLearning,
  UpdateEarning,
  feedbacks,
  feedbacks_count,
  feedbackAfter,
  feedbackBefore,
  handleFeedbackNextPage,
  handleFeedbackPrevPage,
  isFeedbackLoading,
  addQualifications,
  qualificationsList,
  userLoading,
  openUpdateUserModal,
  openRemoveQualificationModal,
}: Props) => {
  const navigate = useNavigate();
  const [userTaskList, setTaskList] = useRecoilState(userTaskListState);
  const [feedback, setFeedback] = useRecoilState(feedbackState);
  const [earning, setEarning] = useRecoilState(earningState);
  const [earningPeriod, setEarningPeriod] = useRecoilState(earningPeriodState);

  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [value, setValue] = useState<DateRange<Dayjs>>([
    userTaskList?.filters?.dates[0] || null,
    userTaskList?.filters?.dates[1] || null,
  ]);
  console.log("Assistant:>>", assistant);

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const lastday = new Date(y, m, dayjs(today).daysInMonth());
  const firstDay = new Date(y, m, 1);

  const [earningDate, setEarningDate] = useState<DateRange<Dayjs>>([
    dayjs(firstDay.toLocaleDateString("sv-SE")),
    dayjs(lastday.toLocaleDateString("sv-SE")),
  ]);
  const [rating, setRating] = useState(userTaskList?.filters?.rating);
  const [status, setStatus] = useState(userTaskList?.filters?.status);

  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [paidOut, setPaidOut] = useState(false);
  const [earningChecked, setEarningChecked] = useState([]);

  const [period, setPeriod] = useState(m);
  const [totalPeriodChecked, setTotalPeriodChecked] = useState(false);
  const [countupStart, setCountupStart] = useState([0, 0, 0]);
  const [countupEnd, setCountupEnd] = useState([0, 0, 0]);

  const [, setRemount] = useState(false);

  const forceRemount = () => {
    setRemount((p) => !p);
  };

  const processMonth = (month) => {
    return (month <= m ? y : y - 1) + " - " + monthNames[month];
  };

  useEffect(() => {
    setEarning({
      dates: [
        earningDate[0]?.format("YYYY-MM-DD") ?? "",
        earningDate[1]?.format("YYYY-MM-DD") ?? "",
      ],
      paid_out: paidOut,
      pageNum: 0,
      after: null,
      before: null,
    });
    console.log(earning);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const colors = ["#40AD5F", "#FFCA0E", "#EF6775", "#E17719", "#000000"];
  const filters = [
    "published",
    "in_progress",
    "clarification_needed",
    "submitted",
    "completed",
  ];

  const filter_text = [
    "Published",
    "Active",
    "Clarification",
    "Review",
    "Completed",
  ];

  const handleRating = (event) => {
    setRating(event.target.value);
    if (event.target.value !== "") {
      setStatus("completed");
      setTaskList({
        filters: {
          rating: event.target.value,
          status: "completed",
          dates: userTaskList.filters.dates,
        },
        pageNum: 0,
        after: null,
        before: null,
      });
    } else {
      setStatus("");
      setTaskList({
        filters: {
          status: "",
          rating: event.target.value,
          dates: userTaskList.filters.dates,
        },
        pageNum: 0,
        after: null,
        before: null,
      });
    }
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
    if (event.target.value === "completed" || event.target.value === "") {
      setTaskList({
        filters: {
          status: event.target.value,
          rating: userTaskList.filters.rating,
          dates: userTaskList.filters.dates,
        },
        pageNum: 0,
        after: null,
        before: null,
      });
    } else {
      console.log(event.target.value);
      setRating("");
      setTaskList({
        filters: {
          status: event.target.value,
          rating: "",
          dates: userTaskList.filters.dates,
        },
        pageNum: 0,
        after: null,
        before: null,
      });
    }
  };

  const onClickFilterItem = (period) => {
    setPeriod(period);
    setIsOpenFilterMenu(!isOpenFilterMenu);
  };

  useEffect(() => {
    setEarningPeriod({
      from: (totalPeriodChecked
        ? new Date("2022-01-02")
        : new Date(period <= m ? y : y - 1, period, 1)
      )
        .toLocaleDateString("sv-SV")
        .substring(0, 7),
      to: (totalPeriodChecked
        ? new Date()
        : new Date(period <= m ? y : y - 1, period, 1)
      )
        .toLocaleDateString("sv-SV")
        .substring(0, 7),
    });
  }, [period, totalPeriodChecked]);

  useEffect(() => {
    setCountupEnd((prev) => {
      setCountupStart(prev);
      const next = [
        totalEarning?.totals.total_usd ?? 0,
        totalEarning?.totals.total_energy ?? 0,
        0, //totalEarning?.totals.total_boosted ?? 0,
      ];
      return next;
    });
  }, [totalEarning]);

  const nextPage = () => {
    if (after !== null) {
      setTaskList({
        filters: {
          status: userTaskList.filters.status,
          rating: userTaskList.filters.rating,
          dates: userTaskList.filters.dates,
        },
        pageNum: userTaskList.pageNum + 1,
        after: after,
        before: null,
      });
      handleNextPage(after);
    }
  };

  const prevPage = () => {
    if (before !== null) {
      setTaskList({
        filters: {
          status: userTaskList.filters.status,
          rating: userTaskList.filters.rating,
          dates: userTaskList.filters.dates,
        },
        pageNum: userTaskList.pageNum - 1,
        after: null,
        before: before,
      });
      handlePrevPage(before);
    }
  };

  const nextEarningPage = () => {
    if (earningAfter !== null) {
      setEarning({
        dates: earning.dates,
        paid_out: earning.paid_out,
        pageNum: earning.pageNum + 1,
        after: earningAfter,
        before: null,
      });
      handleEarningNextPage(after);
      setEarningChecked([]);
    }
  };

  const prevEarningPage = () => {
    if (earningBefore !== null) {
      setEarning({
        dates: earning.dates,
        paid_out: earning.paid_out,
        pageNum: earning.pageNum - 1,
        after: null,
        before: earningBefore,
      });
      handleEarningPrevPage(before);
      setEarningChecked([]);
    }
  };

  const nextFeedbackPage = () => {
    if (feedbackAfter !== null) {
      setFeedback({
        pageNum: feedback.pageNum + 1,
        after: feedbackAfter,
        before: null,
      });
      handleFeedbackNextPage(after);
    }
  };

  const prevFeedbackPage = () => {
    if (feedbackBefore !== null) {
      setFeedback({
        pageNum: feedback.pageNum - 1,
        after: null,
        before: feedbackBefore,
      });
      handleFeedbackPrevPage(before);
    }
  };

  return (
    <>
      {userLoading ? (
        <div className="flex items-center justify-center">
          <CircularProgress color="inherit" size="6rem" />
        </div>
      ) : (
        assistant && (
          <StyledContainer className="bg-mainBg">
            <StyledBoardWrapper>
              <TableHeader>Assistant</TableHeader>
              <div className="bg-[#f1f1f1] rounded-lg px-5 py-4 mt-7 w-full flex justify-between relative">
                <button
                  onClick={openUpdateUserModal}
                  className="absolute top-2.5 right-1 border-none cursor-pointer bg-none hover:scale-[1.15] hover:opacity-75 ease-in-out duration-300"
                  title="Update Assistant Settings">
                  <DotsVertical />
                </button>
                {/* <div
                  id="socials"
                  className="flex *:hover:opacity-80 *:h-8 *:w-8 flex-row *:cursor-pointer items-center justify-center absolute bottom-3 right-10 gap-6">
                  <div
                    id="discord"
                    className="flex items-center justify-center">
                    <DiscordIcon />
                  </div>
                </div> */}
                <Box display="flex" alignItems="center">
                  <img
                    alt="John"
                    src={John}
                    className="w-40 h-40 self-center rounded-3xl"
                  />
                  <Box display="flex" flexDirection="column" marginLeft="20px">
                    <ProfileTitle>{assistant?.user_name}</ProfileTitle>
                    <ProfileDesc marginTop="16px">
                      {assistant?.email}
                    </ProfileDesc>
                    <div className="font-inter mt-5 flex flex-row gap-2 items-center text-base text-black">
                      <div className="h-5 w-5 flex items-center justify-center hover:opacity-80 cursor-pointer">
                        <DiscordIcon />
                      </div>
                      <div>
                        {assistant?.socials?.discord_username
                          ? ensureStartsWithAt(
                              assistant?.socials?.discord_username,
                            )
                          : "Not set"}
                      </div>
                    </div>
                    <div className="font-inter mt-5 text-base text-black font-bold">
                      Assigned Qualifications
                    </div>
                    <div className="grid grid-cols-4 gap-x-3 gap-y-2 text-center mt-1">
                      {assistant.qualifications
                        .sort((a, b) =>
                          a.is_global === b.is_global
                            ? 0
                            : a.is_global
                            ? -1
                            : 1,
                        )
                        .map((value, key) => {
                          return (
                            <div
                              className={`group relative px-2 py-1 border rounded-full text-sm h-full min-w-[100px] ${
                                value.is_global ? "bg-buttonBorder" : "bg-title"
                              } text-white hover:opacity-80 flex items-center justify-center`}
                              key={key}>
                              {value.name}
                              {value.name !== "General" && (
                                <div
                                  onClick={() => {
                                    openRemoveQualificationModal(value.id);
                                  }}
                                  className="absolute flex top-0 p-[1.5px] right-0 rounded-full bg-[#00000054] border border-white opacity-0 hover:scale-150 ease-in-out duration-300 group-hover:opacity-100 cursor-pointer">
                                  <svg
                                    className="h-3 w-3 text-gray-700"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      <div
                        title={`${
                          !assistant.qualifications.find(
                            (x) => x.name === "General",
                          )
                            ? "Assistant must be Onboarded"
                            : ""
                        }`}
                        onClick={async () => {
                          if (
                            !assistant.qualifications.find(
                              (x) => x.name === "General",
                            )
                          ) {
                            return;
                          }
                          await addQualifications();
                        }}
                        className={`px-2 py-1 border rounded-full text-sm font-extrabold bg-buttonBorder text-white hover:opacity-90 hover:scale-[1.1] ease-in-out duration-300 ${
                          !assistant.qualifications.find(
                            (x) => x.name === "General",
                          )
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } flex items-center justify-center`}>
                        +
                      </div>
                    </div>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <img alt="Star" src={Star} className="mr-[10]" />
                  <ProfileStar>
                    <span className="text-[#000000]">
                      {assistant?.rating_average || 0}
                    </span>
                    <span>/5</span>
                  </ProfileStar>
                </Box>
              </div>
              <Box
                marginTop="32px"
                display="flex"
                flexDirection="row"
                width="100%">
                <Box
                  display="flex"
                  color="#000"
                  fontWeight="500"
                  fontSize="16px"
                  fontFamily="Inter"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="12px"
                  width="100%">
                  <div>EARNING PERIOD</div>
                  <div className="relative">
                    <FilterButton
                      className="hover:opacity-90"
                      style={{
                        backgroundColor: totalPeriodChecked ? "#888" : "",
                      }}
                      selected={true}
                      onClick={() => {
                        if (!totalPeriodChecked)
                          setIsOpenFilterMenu(!isOpenFilterMenu);
                      }}>
                      <span>{processMonth(period)}</span>
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </FilterButton>
                    {isOpenFilterMenu && (
                      <div className="bg-[#fdfdfd] text-black rounded-[5px] mt-[5px] absolute z-10 w-[100%]">
                        {[...new Array(12)].map((el, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              onClickFilterItem(index);
                            }}
                            className="cursor-pointer hover:bg-[#dddddd] pl-[12px] p-[5px] hover:rounded-t-[5px] select-none">
                            {processMonth(index)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Box>
                <FormControlLabel
                  style={{
                    alignSelf: "flex-end",
                    marginLeft: "12px",
                    whiteSpace: "nowrap",
                  }}
                  control={
                    <Checkbox
                      checked={totalPeriodChecked}
                      onChange={(e) => {
                        setTotalPeriodChecked(e.target.checked);
                      }}
                    />
                  }
                  label="Total Period"
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="24px"
                gap="24px"
                width="100%">
                <ProfileStatus>
                  <Box>TOTAL EARNING</Box>
                  <Box>
                    <CountUp
                      duration={0.6}
                      start={countupStart[0]}
                      end={countupEnd[0]}
                    />{" "}
                    USDC
                  </Box>
                </ProfileStatus>
                <ProfileStatus>
                  <Box>ENERGY BARS USED</Box>
                  <Box>
                    <CountUp
                      duration={0.6}
                      start={countupStart[1]}
                      end={countupEnd[1]}
                    />{" "}
                    ENERGY
                  </Box>
                </ProfileStatus>
                <ProfileStatus>
                  <Box>TASKS ACCOMPLISHED</Box>
                  {countupEnd[2] ? (
                    <Box>
                      <CountUp
                        duration={0.6}
                        start={countupStart[2]}
                        end={countupEnd[2]}
                      />
                      {/*assistant?.tasks_completed || 0*/} TASKS
                    </Box>
                  ) : (
                    <Box>Not yet</Box>
                  )}
                </ProfileStatus>
              </Box>
              <div className="mt-[75px] w-full">
                <div className="flex justify-between items-center">
                  <TableHeader>Tasks</TableHeader>
                  <div className="flex gap-6 items-center">
                    <div className="relative">
                      <FormControl className="m-4 min-w-[120px]">
                        <InputLabel id="demo-select-small">Status</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={status}
                          label="Status"
                          onChange={handleStatus}>
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="published">Published</MenuItem>
                          <MenuItem value="in_progress">Active</MenuItem>
                          <MenuItem value="clarification_needed">
                            Clarification
                          </MenuItem>
                          <MenuItem value="submitted">Review</MenuItem>
                          <MenuItem value="completed">Completed</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="relative">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-select-small">Rating</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={rating}
                          label="Rating"
                          onChange={handleRating}>
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{ start: "Start Date", end: "End Date" }}>
                      <DateRangePicker
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                          setTaskList({
                            filters: {
                              status: userTaskList.filters.status,
                              rating: userTaskList.filters.rating,
                              // swipes: userTaskList.swipes,
                              dates: [
                                newValue[0]?.format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                                )
                                  ? newValue[0]?.format(
                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                                    )
                                  : null,
                                newValue[1]?.format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                                )
                                  ? newValue[1]?.format(
                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                                    )
                                  : null,
                              ],
                            },
                            pageNum: 0,
                            after: null,
                            before: null,
                          });
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 0 }}> - </Box>
                            <TextField {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <StyledTable>
                    <StyledThead>
                      <Styledtr>
                        <Styledth>TITLE</Styledth>
                        <Styledth>TITLE OWNER</Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          STATUS
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          SINCE
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          BOOST SCORE
                        </Styledth>
                        <Styledth></Styledth>
                      </Styledtr>
                    </StyledThead>

                    {isLoading ? (
                      <tbody>
                        <tr>
                          <td colSpan={10}>
                            <div className="h-[400px] w-full flex">
                              <CircularProgress
                                color="inherit"
                                size="6rem"
                                sx={{ margin: "auto" }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <StyledTbody>
                        {tasks.length > 0 ? (
                          tasks.map((item, index) => (
                            <Styledtr key={index}>
                              <Styledtd>{item.title}</Styledtd>
                              <Styledtd>
                                <div className="flex flex-col">
                                  <StyledName>
                                    {assistant?.user_name}
                                  </StyledName>
                                  <StyledEmail>{assistant?.email}</StyledEmail>
                                </div>
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                <StyledStatus
                                  color={colors[filters.indexOf(item.status)]}>
                                  {filter_text[filters.indexOf(item.status)]
                                    ? filter_text[filters.indexOf(item.status)]
                                    : "Draft"}
                                </StyledStatus>
                              </Styledtd>

                              <Styledtd style={{ textAlign: "center" }}>
                                {item.status_changed ? (
                                  <>
                                    {new Date(
                                      item.status_changed,
                                    ).toLocaleDateString()}
                                    ,
                                    {new Date(
                                      item.status_changed,
                                    ).toLocaleTimeString()}
                                  </>
                                ) : null}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                {item.earnings_boost || 0}
                              </Styledtd>
                              <Styledtd>
                                <StyledLink
                                  onClick={() => {
                                    navigate(`/tasks/view/${item.task_id}`);
                                  }}>
                                  View Task
                                </StyledLink>
                              </Styledtd>
                            </Styledtr>
                          ))
                        ) : (
                          <td colSpan={10}>
                            <div className="flex h-[400px] w-full items-center justify-center m-auto text-3xl select-none">
                              No Task
                            </div>
                          </td>
                        )}
                      </StyledTbody>
                    )}
                  </StyledTable>
                </div>
                <div className="flex h-max items-center justify-end md:justify-end mt-5">
                  <span className="mx-2 text-gray-500 font-medium">
                    Total Count:{" "}
                    {isLoading ? (
                      <CircularProgress
                        color="inherit"
                        size="1rem"
                        sx={{ margin: "auto" }}
                      />
                    ) : tasks_count ? (
                      tasks_count
                    ) : (
                      0
                    )}
                  </span>
                  <span
                    onClick={() => (before ? prevPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      before === null ? "opacity-50 cursor-not-allowed" : ""
                    }`}>
                    Previous
                  </span>
                  <span className="mx-2 text-gray-500 font-medium">
                    {userTaskList.pageNum + 1}
                  </span>
                  <span
                    onClick={() => (after ? nextPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      after === null ? "opacity-50 cursor-not-allowed" : ""
                    }`}>
                    Next
                  </span>
                </div>
              </div>
              <div className="mt-[75px] w-full">
                <div className="flex justify-between">
                  <TableHeader>Earnings</TableHeader>
                  <div className="flex flex-col">
                    <div className="flex gap-[4px] items-center mt-[20px]">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: "Start Date", end: "End Date" }}>
                        <DatePicker
                          inputFormat="YYYY-MMMM"
                          views={["year", "month"]}
                          label="Start Date"
                          minDate={new Date("2022-01-02")}
                          maxDate={new Date(earningDate[1]?.toString() ?? "")}
                          value={new Date(earningDate[0]?.toString() ?? "")}
                          onChange={(newValue) => {
                            if (newValue !== null) {
                              newValue = newValue.date(1);
                              setEarningDate((prev) => [newValue, prev[1]]);
                              setEarning({
                                dates: [
                                  newValue?.format("YYYY-MM-DD")
                                    ? newValue.format("YYYY-MM-DD")
                                    : firstDay.toLocaleDateString("sv-SE"),
                                  earningDate[1]?.format("YYYY-MM-DD")
                                    ? earningDate[1]?.format("YYYY-MM-DD")
                                    : new Date().toLocaleDateString("sv-SE"),
                                ],
                                paid_out: earning.paid_out,
                                pageNum: 0,
                                after: null,
                                before: null,
                              });
                              setEarningChecked([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        />
                        {"-"}
                        <DatePicker
                          inputFormat="YYYY-MMMM"
                          views={["year", "month"]}
                          label="End Date"
                          minDate={new Date(earningDate[0]?.toString()) ?? ""}
                          maxDate={lastday}
                          value={new Date(earningDate[1]?.toString()) ?? ""}
                          onChange={(newValue) => {
                            newValue = newValue.date(newValue.daysInMonth());
                            setEarningDate((prev) => [prev[0], newValue]);
                            setEarning({
                              dates: [
                                earningDate[0]?.format("YYYY-MM-DD")
                                  ? earningDate[0]?.format("YYYY-MM-DD")
                                  : firstDay.toLocaleDateString("sv-SE"),
                                newValue?.format("YYYY-MM-DD")
                                  ? newValue.format("YYYY-MM-DD")
                                  : new Date().toLocaleDateString("sv-SE"),
                              ],
                              paid_out: earning.paid_out,
                              pageNum: 0,
                              after: null,
                              before: null,
                            });
                            setEarningChecked([]);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        />
                      </LocalizationProvider>
                      <div className="relative ml-10">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={paidOut}
                                onChange={(e) => {
                                  setPaidOut(e.target.checked);
                                  setEarning({
                                    dates: earning.dates,
                                    paid_out: e.target.checked,
                                    pageNum: 0,
                                    after: null,
                                    before: null,
                                  });
                                  setEarningChecked([]);
                                }}
                              />
                            }
                            label="Paid Out"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <StyledTable>
                    <StyledThead>
                      <Styledtr>
                        <Styledth>
                          <Checkbox
                            checked={
                              earnings.reduce(
                                (res, item) =>
                                  res +
                                  (earningChecked[item.earnings_id] === true
                                    ? 1
                                    : 0),
                                0,
                              ) == earnings.length && earnings.length > 0
                            }
                            indeterminate={
                              earnings.reduce(
                                (res, item) =>
                                  res +
                                  (earningChecked[item.earnings_id] === true
                                    ? 1
                                    : 0),
                                0,
                              ) > 0 &&
                              earnings.reduce(
                                (res, item) =>
                                  res +
                                  (earningChecked[item.earnings_id] === true
                                    ? 1
                                    : 0),
                                0,
                              ) < earnings.length
                            }
                            onChange={(e) => {
                              setEarningChecked(() => {
                                let next = [];
                                if (e.target.checked) {
                                  earnings.forEach((item) => {
                                    next[item.earnings_id] = true;
                                  });
                                }
                                return next;
                              });
                            }}
                          />
                        </Styledth>
                        <Styledth>TITLE</Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          COMPLETED ON
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          ENERGY
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          BOOST
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          TOTAL ENERGY EARNED
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          TOTAL USD EARNED
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          STATUS
                        </Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          ACTION
                        </Styledth>
                      </Styledtr>
                    </StyledThead>
                    {isEarningLoading ? (
                      <tbody>
                        <tr>
                          <td colSpan={10}>
                            <div className="h-[400px] w-full flex">
                              <CircularProgress
                                color="inherit"
                                size="6rem"
                                sx={{ margin: "auto" }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <StyledTbody>
                        {earnings.length > 0 ? (
                          earnings.map((item, index) => (
                            <Styledtr key={index}>
                              <Styledtd>
                                <Checkbox
                                  checked={
                                    earningChecked[item.earnings_id] ?? false
                                  }
                                  onChange={(e) => {
                                    setEarningChecked((prev) => {
                                      let next = prev;
                                      next[item.earnings_id] =
                                        !next[item.earnings_id] ?? false;
                                      return next;
                                    });
                                    forceRemount();
                                  }}
                                />
                              </Styledtd>
                              <Styledtd>{item.title}</Styledtd>
                              <Styledtd>
                                {new Date(
                                  item.date_earned,
                                ).toLocaleDateString()}
                                ,
                                {new Date(
                                  item.date_earned,
                                ).toLocaleTimeString()}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                {item.energy_earned}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                {item.earnings_boost}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                {item.total}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                {item.total * 2}
                              </Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                <PaidStatus paid={item.paid_out}>
                                  {item.paid_out ? "Paid" : "Unpaid"}
                                </PaidStatus>
                              </Styledtd>
                              <Styledtd>
                                <PaidBtn
                                  onClick={() => {
                                    UpdateEarning({
                                      earnings_ids: [item.earnings_id],
                                      paid_out: !item.paid_out,
                                    });
                                  }}>
                                  {!item.paid_out
                                    ? "Set as paid"
                                    : "Set as unpaid"}
                                </PaidBtn>
                              </Styledtd>
                            </Styledtr>
                          ))
                        ) : (
                          <td colSpan={10}>
                            <div className="flex h-[400px] w-full items-center justify-center m-auto text-3xl select-none">
                              No Earning
                            </div>
                          </td>
                        )}
                      </StyledTbody>
                    )}
                    {earnings.length > 0 ? (
                      <StyledTfoot>
                        <Styledtr>
                          <Styledth>
                            <Checkbox
                              checked={
                                earnings.reduce(
                                  (res, item) =>
                                    res +
                                    (earningChecked[item.earnings_id] === true
                                      ? 1
                                      : 0),
                                  0,
                                ) == earnings.length && earnings.length > 0
                              }
                              indeterminate={
                                earnings.reduce(
                                  (res, item) =>
                                    res +
                                    (earningChecked[item.earnings_id] === true
                                      ? 1
                                      : 0),
                                  0,
                                ) > 0 &&
                                earnings.reduce(
                                  (res, item) =>
                                    res +
                                    (earningChecked[item.earnings_id] === true
                                      ? 1
                                      : 0),
                                  0,
                                ) < earnings.length
                              }
                              onChange={(e) => {
                                setEarningChecked(() => {
                                  let next = [];
                                  if (e.target.checked) {
                                    earnings.forEach((item) => {
                                      next[item.earnings_id] = true;
                                    });
                                  }
                                  return next;
                                });
                              }}
                            />
                          </Styledth>
                          <Styledth>{/*TITLE*/}</Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {/* COMPLETED ON */}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {Number(
                              earnings.reduce(
                                (res, item) => res + Number(item.energy_earned),
                                0,
                              ),
                            ).toFixed(2)}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {Number(
                              earnings.reduce(
                                (res, item) => res + item.earnings_boost,
                                0,
                              ),
                            ).toFixed(2)}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {Number(
                              earnings.reduce(
                                (res, item) => res + item.total,
                                0,
                              ),
                            ).toFixed(2)}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {Number(
                              earnings.reduce(
                                (res, item) => res + item.total * 2,
                                0,
                              ),
                            ).toFixed(2)}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {/* STATUS */}
                          </Styledth>
                          <Styledth
                            style={{ paddingLeft: "0px", textAlign: "center" }}>
                            {/* ACTION */}
                          </Styledth>
                        </Styledtr>
                      </StyledTfoot>
                    ) : (
                      ""
                    )}
                  </StyledTable>
                </div>
                <div className="flex h-max items-center justify-center md:justify-center mt-5">
                  <PaidBtn
                    width={"400px !important"}
                    height={"50px !important"}
                    fontSize={"18px !important"}
                    onClick={() => {
                      UpdateEarning({
                        earnings_ids: earnings
                          .filter(
                            (e) =>
                              (earningChecked[e.earnings_id] ?? false) === true,
                          )
                          .map((e) => e.earnings_id),
                        paid_out: !paidOut,
                      });
                      setEarningChecked([]);
                    }}>
                    {!paidOut ? "Set checked as paid" : "Set checked as unpaid"}
                  </PaidBtn>
                </div>
                <div className="flex h-max items-center justify-end md:justify-end mt-5">
                  <span className="mx-2 text-gray-500 font-medium">
                    Total Count:{" "}
                    {isEarningLoading ? (
                      <CircularProgress
                        color="inherit"
                        size="1rem"
                        sx={{ margin: "auto" }}
                      />
                    ) : earnings_count ? (
                      earnings_count
                    ) : (
                      0
                    )}
                  </span>
                  <span
                    onClick={() => (earningBefore ? prevEarningPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      earningBefore === null
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}>
                    Previous
                  </span>
                  <span className="mx-2 text-gray-500 font-medium">
                    {earning.pageNum + 1}
                  </span>
                  <span
                    onClick={() => (earningAfter ? nextEarningPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      earningAfter === null
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}>
                    Next
                  </span>
                </div>
              </div>
              <div className="mt-[75px] w-full">
                <div className="flex justify-between">
                  <TableHeader>Reviews</TableHeader>
                </div>
                <div className="overflow-x-auto">
                  <StyledTable>
                    <StyledThead>
                      <Styledtr>
                        <Styledth>DATE</Styledth>
                        <Styledth>TITLE</Styledth>
                        <Styledth
                          style={{ paddingLeft: "0px", textAlign: "center" }}>
                          RATING
                        </Styledth>
                        <Styledth>REVIEW</Styledth>
                        <Styledth></Styledth>
                      </Styledtr>
                    </StyledThead>
                    {isFeedbackLoading ? (
                      <tbody>
                        <tr>
                          <td colSpan={10}>
                            <div className="h-[400px] w-full flex">
                              <CircularProgress
                                color="inherit"
                                size="6rem"
                                sx={{ margin: "auto" }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <StyledTbody>
                        {feedbacks.length > 0 ? (
                          feedbacks.map((item, index) => (
                            <Styledtr key={index}>
                              <Styledtd>
                                {new Date(
                                  item.created_date,
                                ).toLocaleDateString()}
                              </Styledtd>
                              <Styledtd>{item.task_title}</Styledtd>
                              <Styledtd style={{ textAlign: "center" }}>
                                <Rating
                                  name="read-only"
                                  value={item.rating}
                                  readOnly
                                />
                              </Styledtd>
                              <Styledtd>{item.feedback}</Styledtd>
                              <Styledtd>
                                <StyledLink
                                  onClick={() => {
                                    setOpenFeedback(true);
                                    setFeedbackText(item.feedback);
                                  }}>
                                  Expand
                                </StyledLink>
                              </Styledtd>
                            </Styledtr>
                          ))
                        ) : (
                          <td colSpan={10}>
                            <div className="flex items-center justify-center m-auto text-3xl select-none h-[400px] w-full">
                              No Feedback
                            </div>
                          </td>
                        )}
                      </StyledTbody>
                    )}
                  </StyledTable>
                </div>
                <div className="flex h-max items-center justify-end md:justify-end mt-5">
                  <span className="mx-2 text-gray-500 font-medium">
                    Total Count:{" "}
                    {isFeedbackLoading ? (
                      <CircularProgress
                        color="inherit"
                        size="1rem"
                        sx={{ margin: "auto" }}
                      />
                    ) : feedbacks_count ? (
                      feedbacks_count
                    ) : (
                      0
                    )}
                  </span>
                  <span
                    onClick={() => (feedbackBefore ? prevFeedbackPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      feedbackBefore === null
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}>
                    Previous
                  </span>
                  <span className="mx-2 text-gray-500 font-medium">
                    {feedback.pageNum + 1}
                  </span>
                  <span
                    onClick={() => (feedbackAfter ? nextFeedbackPage() : null)}
                    className={`cursor-pointer inline-flex items-center no-underline py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                      feedbackAfter === null
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}>
                    Next
                  </span>
                </div>
              </div>
            </StyledBoardWrapper>
            {openFeedback && (
              <>
                <FeedbackModalBack
                  onClick={() => setOpenFeedback(false)}></FeedbackModalBack>
                <FeedbackModal>
                  <FeedbackTitle>Reviews</FeedbackTitle>
                  <FeedbackBody>{feedbackText}</FeedbackBody>
                  <FeedbackBtn onClick={() => setOpenFeedback(false)}>
                    Go Back
                  </FeedbackBtn>
                </FeedbackModal>
              </>
            )}
          </StyledContainer>
        )
      )}
    </>
  );
};

export default Component;
