import React from "react";
import styled from "styled-components";

import ownerStatuses from "../../../assets/ownerStatuses";
import paStatuses from "../../../assets/paStatuses";
import statusColors from "../../../assets/statusColors";
import { Energy, Star, Trash } from "../../../assets/svg";

const allStatuses = { ...ownerStatuses, ...paStatuses };

type Props = {
  title: string;
  energy?: number;
  status: string;
  isTrashDisabled?: boolean;
  latestComment?: string;
  taskType?: string;
  onItemClick?: () => void;
  onStarClick?: () => void;
  onPenClick?: () => void;
  onTrashClick?: () => void;
};

const StyledItemContainer = styled.div`
  padding: 0px 10px;
  height: 75px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #d9d9d9;
  border-radius: 10px;
  border: 1px solid transparent;

  color: #000;
`;

const Status = styled.div<{ color: string }>`
  background: ${(props) => props.color || "black"};
  border-radius: 20px;
  border: 1px solid transparent;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 6rem;
  height: 22px;
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  margin-right: 10px;

  @media only screen and (max-width: 780px) {
    margin-right: 10px;
  }
  padding: 0px 5px;
`;

const StyledTitle = styled.div`
  width: 500px;
  @media only screen and (max-width: 780px) {
    width: 300px;
  }
  @media only screen and (max-width: 500px) {
    width: 250px;
  }
  @media only screen and (max-width: 420px) {
    width: 250px;
  }
  @media only screen and (max-width: 400px) {
    width: 150px;
  }
`;

const Component = ({
  title,
  energy = 0,
  status,
  isTrashDisabled,
  latestComment,
  taskType,
  onItemClick = () => {},
  onStarClick = () => {},
  onPenClick = () => {},
  onTrashClick = () => {},
}: Props) => {
  const [isHoveringStar, setIsHoveringStar] = React.useState(false);
  const [hasNotif, setHasNotif] = React.useState(false);

  /*
  // was not working well because sometimes ID would be null
  const handleClick = (e) => {
    e.stopPropagation();
    switch (e.target.id) {
      case "view":
        onPenClick();
        break;
      case "star":
        onStarClick();
        break;
      case "trash":
        onTrashClick();
        break;
      case "title":
        onPenClick();
        break;
    }
  };
  */

  return (
    <StyledItemContainer
      className="hover:opacity-80 hover:scale-[1.02] ease-in-out duration-300 p-2  min-h-[78px]"
      data-test-id="dashboard-tasks-item">
      <div
        id="title"
        onClick={onPenClick}
        className="flex flex-col justify-evenly  overflow-ellipsis  whitespace-nowrap pl-2 w-4/5 cursor-pointer">
        <StyledTitle className="font-inter font-bold text-md md:text-xl overflow-ellipsis overflow-hidden lg:w-full flex flex-row">
          <div
            className="pr-5 text-[16px]"
            data-test-id="dashboard-tasks-item-title">
            {title}
          </div>
          <div className={`${latestComment == "pa" ? "" : "hidden"}`}>
            <div className="inline-block relative">
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.00333343 4.88355L7.99995 8.88186L15.9967 4.8835C15.9363 3.83315 15.0655 3 14 3H2C0.934518 3 0.0636347 3.83318 0.00333343 4.88355Z"
                  fill="#DF8B3F"
                />
                <path
                  d="M16 7.1179L7.99995 11.1179L0 7.11796V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V7.1179Z"
                  fill="#DF8B3F"
                />
              </svg>
              <span className="absolute top-1 right-[.100rem] block h-1 w-1 animate-ping  ring-pingEffect bg-ping rounded-full ring-2"></span>
            </div>
          </div>
        </StyledTitle>
        <div className="flex flex-row">
          <Status color={statusColors?.[status]}>
            {allStatuses?.[status] || status}
          </Status>
          <div className="text-base flex flex-row items-center gap-1">
            <div className="font-bold">{energy && energy}</div>
            <Energy />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-3 lg:gap-4 md:gap-6 items-center md:mr-6 w-2/4 md:w-1/5">
        {status !== "none" ? (
          <Star
            data-test-id="dashboard-favorite-button"
            id="star"
            className="cursor-pointer hover:scale-[1.3] ease-in-out duration-300"
            onClick={onStarClick}
            onMouseEnter={() => {
              setIsHoveringStar(true);
            }}
            hover={isHoveringStar}
            onMouseLeave={() => {
              setIsHoveringStar(false);
            }}
          />
        ) : (
          <></>
        )}
        <Trash
          data-test-id="dashboard-trash-button"
          id="trash"
          className={
            !isTrashDisabled
              ? "cursor-pointer hover:scale-[1.3] ease-in-out duration-300"
              : "hidden"
          }
          pathColor={!isTrashDisabled ? "#111827" : "#D3D3D3"}
          onClick={onTrashClick}
        />
      </div>
    </StyledItemContainer>
  );
};

export default Component;
