const getNonceAddressUpdate = async (token) => {
  const response = await fetch(
    process.env.API_URL + `/api/user/changeAddress`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getSubscription = async (token) => {
  const response = await fetch(
    process.env.API_URL + `/api/user/push_subscribe`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};
const addressChangeWithSignedMessage = async (token, address, message) => {
  const response = await fetch(
    process.env.API_URL + `/api/user/changeAddress`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ address, message }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }
  const data = response.body.toString();
  throw new Error(`${(await response.json()).message}`);
};
const getNonceForUser = async function (address) {
  const response = await fetch(process.env.API_URL + `/api/user/${address}`, {
    method: "GET",
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const subscribeNotifs = async function (token, user_id, subscription) {
  const response = await fetch(
    process.env.API_URL + `/api/user/push_subscribe`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user_id, subscription }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const confirmUser = async function (token) {
  const response = await fetch(
    process.env.API_URL + `/api/user/confirm?token=${token}`,
    {
      method: "GET",
    },
  );
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const authenticateUserWithSignedMessage = async function (address, message) {
  const response = await fetch(process.env.API_URL + `/api/auth/`, {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: JSON.stringify({ address, message }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};
const authenticateUserWithAddress = async function (address) {
  const response = await fetch(process.env.API_URL + `/api/auth/`, {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: JSON.stringify({ address }),
  });
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};
const getUserById = async (token, userId) => {
  const response = await fetch(process.env.API_URL + `/api/user?id=${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const setTasksTimeout = async (token) => {
  const response = await fetch(process.env.API_URL + `/api/user/timeout`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const acceptMission = async (token, body) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/assistant/mission`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTasksTimeout = async (token) => {
  const response = await fetch(process.env.API_URL + `/api/user/timeout`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const updateOwnerProfile = async (token, body) => {
  const response = await fetch(process.env.API_URL + `/api/user`, {
    method: "PUT",
    "Content-Type": "application/json",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const changeWeb2Email = async (token, body) => {
  const response = await fetch(process.env.API_URL + `/api/user/addmail`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (response && response.ok) {
    return await response.json();
  }
  if (response.status === 500) {
    throw new Error((await response.json()).message);
  } else {
    throw new Error(
      `We are having some technical issues, please try again later.`,
    );
  }
};

const addWeb2LoginRequest = async (token, body) => {
  const response = await fetch(process.env.API_URL + `/api/user/addmail`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const data = await response.json();

  if (response && response.ok) {
    return data;
  }
  throw new Error(data.message ?? data.reason ?? `Code: ${response.status}`);
};

const addMail = async function (token) {
  const response = await fetch(
    process.env.API_URL + `/api/user/addmail?token=${token}`,
    {
      method: "GET",
    },
  );
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const updateUserOnboarding = async (token, payload) => {
  const response = await fetch(process.env.API_URL + `/api/user/onboarding`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const resetPassword = async (token, values) => {
  const body = {
    current_password: values.old_password,
    password: values.password,
  };

  const response = await fetch(process.env.API_URL + `/api/user/reset`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response && response.ok) {
    return await response.json();
  }
  let abc = await response.json();
  throw new Error(abc.reason);
};
const updateProfile = async (token, id, values) => {
  const response = await fetch(process.env.API_URL + `/api/user`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user_id: id,
      user: {
        user_name: values.name,
        country: values.country,
        payout_address: values.payout,
        notification_email: values.notif_email,
        email_notif: values.checkedNotif,
        preferred_language: values.preferred_language,
        socials: values.socials,
      },
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const updateAssistant = async (token, values) => {
  const response = await fetch(
    process.env.API_URL + "/api/admin/assistant/user",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const createTester = async (token, address, energy) => {
  const response = await fetch(process.env.API_URL + `/api/user/${address}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      energy,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`${(await response.json()).reason}`);
};

/**
 *
 * @param {string} token
 * @returns {Promise<{total_usd:number, total_boosted: number, total_energy:number}>}
 */

const getTotalEarningsByUserId = async (token) => {
  const path = "/api/user/earnings";
  const response = await fetch(process.env.API_URL + path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`${await response.json()}`);
};

export {
  acceptMission,
  addMail,
  addWeb2LoginRequest,
  addressChangeWithSignedMessage,
  authenticateUserWithAddress,
  authenticateUserWithSignedMessage,
  changeWeb2Email,
  confirmUser,
  createTester,
  getNonceAddressUpdate,
  getNonceForUser,
  getSubscription,
  getTasksTimeout,
  getTotalEarningsByUserId,
  getUserById,
  resetPassword,
  setTasksTimeout,
  subscribeNotifs,
  updateAssistant,
  updateOwnerProfile,
  updateProfile,
  updateUserOnboarding,
};
