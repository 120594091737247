const uploadFiles = async (files, token) => {
  var formData = new FormData();
  if (!files.length) {
    return { success: false, message: "Please, select files for upload." };
  }
  for (let i = 0; i < files.length; i++) {
    formData.append("attach", files[i]);
  }
  const promise = new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.API_URL + "/api/task/file");
    xhr.onload = function () {
      var status = xhr.status;
      if (status == 200) {
        resolve(xhr.response);
      } else {
        reject(status);
      }
    };
    xhr.send(formData);
  });
  return promise
    .then((response) => {
      return JSON.parse(response);
    })
    .catch((error) => {
      return {
        success: "false",
        message: "Upload did not succeed. Status returned: " + error,
      };
    });
};

export { uploadFiles };
