import { Button, MenuItem, Select, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Qualification } from "../../../interfaces/Qualification";
import {
  addQualificationToUser,
  getAllQualifications,
  removeQualificationToUser,
} from "../../../services/qualification";
import { getOwnerTaskList } from "../../../services/tasks";
import { authState, ownerTaskListState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import useUserFetch from "../../hooks/useUserFetch";
import Modal from "../../organisms/Modal";
import AdminOwnerAccountTemplate from "../../templates/AdminOwnerAccount";

type Props = {
  setActive: (active: any) => void;
};

const IS_GLOBAL = false; // for private / non-global qualifications

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

export default function AdminOwnerAccountPage({ setActive }: Props) {
  const [auth, setAuth] = useRecoilState(authState);
  const [ownerTaskList, setOwnerTaskList] = useRecoilState(ownerTaskListState);
  const [showAddQualificationsModal, setShowAddQualificationsModal] =
    useState(false);
  const [listOfQualification, setListOfQualification] = useState<
    Qualification[]
  >([]);
  const [showRemoveQualificationModal, setRemoveQualificationModal] =
    useState<Boolean>();
  const [qualificationIdToRemove, setQualificationIdToRemove] = useState();
  const setSnackbar = useContext(SnackbarContext);

  let { id } = useParams();
  console.log("🚀 ~ AdminOwnerAccountPage ~ id:", id);

  const [ownerId, setOwnerId] = useState(id);

  useEffect(() => {
    if (id) {
      setOwnerId(id);
    }
  }, [id]);

  const {
    user: ownerData,
    refetch: userFetch,
    isLoading: isOwnerLoading,
    isRefetching: userRefetching,
  } = useUserFetch(auth, "nft", ownerId);

  useEffect(() => {
    setOwnerTaskList({
      filters: {
        status: "",
        dates: [],
      },
      pageNum: 0,
      after: null,
      before: null,
    });
  }, []);

  const { data: ownerTasks, isLoading: isOwnerTasksLoading } = useQuery(
    [
      "fetchOwnerTaskList",
      ownerTaskList.after,
      ownerTaskList.before,
      ownerTaskList.filters,
    ],
    () => {
      if (auth.accessToken) {
        return getOwnerTaskList(auth.accessToken, id, ownerTaskList);
      }
    },
    {
      onSuccess: () => {},
      onError: (e) => {},
    },
  );

  const { data: qualifications } = useQuery(
    ["fetchingQualification", auth.accessToken],
    () => {
      return getAllQualifications(auth.accessToken, IS_GLOBAL);
    },
    {
      onSuccess: (message) => {
        setListOfQualification(message.qualifications);
      },
      onError: (e) => {},
      enabled: showAddQualificationsModal,
    },
  );

  const { mutate: addQualification } = useMutation(
    "addQualifications",
    (values: any) => {
      if (auth.accessToken) {
        values.id = id;
        return addQualificationToUser(auth.accessToken, values);
      }
    },
    {
      onSuccess: (resp) => {
        closeAddQualificationsModal();
        setSnackbar({
          title: "Success",
          content: "Successfully Added a qualification",
          type: "success",
        });
        userFetch();
      },
      onError: (e, variables) => {
        closeAddQualificationsModal();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        userFetch();
      },
    },
  );
  const { mutate: removeQualification } = useMutation(
    "removeQualification",
    (values: any) => {
      if (auth.accessToken) {
        values.id = id;
        return removeQualificationToUser(auth.accessToken, values);
      }
    },
    {
      onSuccess: (resp) => {
        userFetch();
        closeRemoveQualificationModal();
        setSnackbar({
          title: "Success",
          content: "Successfully Removed a qualification",
          type: "success",
        });
      },
      onError: (e, variables) => {
        userFetch();
        closeRemoveQualificationModal();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const tasksBefore = ownerTasks?.before || null;
  const tasksAfter = ownerTasks?.after || null;

  return (
    listOfQualification &&
    ownerData &&
    !isOwnerLoading &&(
      <>
        <div className="text-title bg-mainBg h-[calc(100%-64px)]">
          <AdminOwnerAccountTemplate
            ownerData={ownerData}
            isOwnerLoading={isOwnerLoading}
            qualificationsList={listOfQualification}
            addQualifications={handleAddQualifications}
            ownerTasks={ownerTasks}
            isOwnerTasksLoading={isOwnerTasksLoading}
            tasksBefore={tasksBefore}
            tasksAfter={tasksAfter}
            userRefetching={userRefetching}
            openRemoveQualificationModal={openRemoveQualificationModal}
          />
        </div>
        <Modal
          title="Add Authorized Qualification"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showAddQualificationsModal}
          onClose={closeAddQualificationsModal}>
          <Formik
            initialValues={{ qualifications: [] }}
            onSubmit={(values) => {
              const payload = {
                qualifications: [
                  ...values.qualifications,
                  ...ownerData.authorized_qualifications?.map(
                    (qual) => qual.id,
                  ),
                ],
                id: ownerId,
              };

              addQualification(payload);
            }}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <div style={{ width: 450 }}>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <form onSubmit={handleSubmit}>
                      <Select
                        style={{ marginBottom: "1rem", marginTop: "1rem" }}
                        className="bg-white border-0 rounded w-[20%] text-gray-700 leading-tight "
                        sx={{
                          height: "45px",
                        }}
                        id="qualifications"
                        defaultValue="Select a qualification"
                        displayEmpty
                        renderValue={
                          values.qualifications?.length !== 0
                            ? undefined
                            : () => "Select a qualification"
                        }
                        onChange={(event) => {
                          setFieldValue("qualifications", [event.target.value]);
                        }}>
                        {listOfQualification
                          .filter((qualification: Qualification) => {
                            return !ownerData.authorized_qualifications
                              ?.map((qual) => qual.id)
                              .includes(qualification.id);
                          })
                          .map((qualification: Qualification) => {
                            return (
                              <MenuItem
                                key={qualification.id}
                                value={qualification.id}>
                                {qualification.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <ConfirmButton
                        variant="contained"
                        disabled={
                          values.qualifications.length === 0 || isSubmitting
                        }
                        type="submit"
                        fullWidth>
                        Submit
                      </ConfirmButton>
                      <CancelButton
                        variant="contained"
                        onClick={closeAddQualificationsModal}
                        fullWidth>
                        No, Cancel
                      </CancelButton>
                    </form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          title="Remove Qualification"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showRemoveQualificationModal}
          onClose={closeRemoveQualificationModal}>
          <Formik
            initialValues={{ qualifications: [] }}
            onSubmit={(values) => {
              const payload = {
                qualifications: [
                  ...ownerData.authorized_qualifications
                    ?.filter((x) => x.id !== qualificationIdToRemove)
                    .map((qual) => qual.id),
                ],
                id: id,
              };

              removeQualification(payload);
            }}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <div style={{ width: 450 }}>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <form onSubmit={handleSubmit}>
                      <Typography
                        fontWeight={700}
                        fontFamily="Inter"
                        style={{ marginBottom: "0.5rem" }}>
                        Are you sure you want to remove the qualification?
                      </Typography>
                      <ConfirmButton
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={isSubmitting}>
                        Yes
                      </ConfirmButton>
                      <CancelButton
                        variant="contained"
                        onClick={closeRemoveQualificationModal}
                        fullWidth>
                        No, Cancel
                      </CancelButton>
                    </form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
      </>,
    )
  );

  function handleAddQualifications() {
    setShowAddQualificationsModal(true);
  }

  function closeAddQualificationsModal() {
    setShowAddQualificationsModal(false);
  }
  function openRemoveQualificationModal(id) {
    setQualificationIdToRemove(id);
    setRemoveQualificationModal(true);
  }
  function closeRemoveQualificationModal() {
    setRemoveQualificationModal(false);
  }
}
