import React, { useCallback, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import CreateTaskTemplate from "../../templates/CreateTask";

import { CircularProgress } from "@mui/material";
import { Qualification } from "../../../interfaces/Qualification";
import { Task } from "../../../interfaces/Task";
import { getAllQualificationsInTasks } from "../../../services/qualification";
import {
  getTaskById,
  getTranslation,
  getTranslationCheck,
  updateTask,
} from "../../../services/tasks";
import { authState, paginationState, taskState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import { translationSources } from "../../../utils";
import useUserFetch from "../../hooks/useUserFetch";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [translatedTexts, setTranslatedTexts] = useState({});
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const setSnackbar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const resetTranslation = useCallback(() => {
    setTranslatedTexts({});
  }, []);

  const [qualificationsDropdownData, setQualificationsDropdownData] =
    React.useState<Qualification[]>([]);

  const [activeTask, setActiveTask] = useRecoilState<Task>(taskState);

  let { id } = useParams();

  const handleSuccess = (data) => {
    setQualificationsDropdownData((oldArr) => [
      ...oldArr,
      ...data.user.authorized_qualifications,
    ]);
  };

  const { user: userData, isLoading: isUserDataLoading } = useUserFetch(
    auth,
    "nft",
    auth.user_id,
    [handleSuccess],
  );

  const { data: tasksData, isLoading: isValidatingUser } = useQuery(
    ["fetchingTask", auth.accessToken],
    () => {
      if (auth.accessToken) {
        return getTaskById(auth.accessToken, id);
      }
    },
    {
      onSuccess: (resp) => {
        if (!Array.isArray(resp.task.category)) {
          let categoryList: string[] = [];
          categoryList.push(resp.task.category);
          resp.task.category = categoryList;
        }
        resp.task.videos = !resp.task.videos ? [] : resp.task.videos;
        resp.task.urls = !resp.task.urls ? [] : resp.task.urls;

        setActiveTask(resp.task);
      },
      onError: (e) => {},
    },
  );

  const {
    mutate: mutateCreateTask,
    isLoading: isLoading,
    error,
  } = useMutation(
    "updateTask",
    (values: any) => {
      return updateTask(auth.accessToken, values.task, id);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);

        setPagination({
          fetchType: pagination.fetchType,
          pageNum: 0,
          after: null,
          before: null,
        });

        setSnackbar({
          title: "Success",
          content: "Successfully updated the task.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/tasks/view/" + id);
        }, 1500);
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { mutate: mutateDraftTask } = useMutation(
    "updateTask",
    (values: any) => {
      return updateTask(auth.accessToken, values.task, id);
    },
    {
      onSuccess: (resp) => {
        setIsSuccess(true);

        setPagination({
          fetchType: pagination.fetchType,
          pageNum: 0,
          after: null,
          before: null,
        });

        setSnackbar({
          title: "Success",
          content: "Successfully updated the draft.",
          type: "success",
        });

        setTimeout(() => {
          setIsSuccess(false);
          navigate("/tasks/view/" + id);
        }, 1500);
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const { data: publicQualifications, isLoading: isQualificationsLoading } =
    useQuery(
      "fetchPublicQualifications",
      () => {
        return getAllQualificationsInTasks(auth.accessToken);
      },
      {
        onSuccess: (resp) => {
          setQualificationsDropdownData((oldArray) => [
            ...oldArray,
            ...resp.qualifications,
          ]);
        },
        onError: (resp) => {},
      },
    );

  const {
    mutate: mutateTranslateDescription,
    isLoading: isTranslating,
    error: isTranslationError,
  } = useMutation(
    "translate",
    (values: any) => {
      return getTranslation(
        auth.accessToken,
        values.description,
        translationSources.EDITOR,
      );
    },
    {
      onSuccess: (resp, variables) => {
        if (resp) {
          const propertyName = Object.keys(variables)[0];
          setTranslatedTexts((prevState) => {
            return {
              ...prevState,
              [propertyName]: resp.message,
            };
          });
          setSnackbar({
            title: "Success",
            content: `Successfully translated the task ${propertyName}.`,
            type: "success",
          });
        }
      },
      onError: (e, variables) => {
        console.log("error", e);
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
      },
    },
  );

  const {
    mutate: isDescriptionTranslated,
    isLoading: checkTranslatedLoading,
    error: errorTranslatedLoading,
  } = useMutation(
    "checkDescriptionTranslated",
    (values: any) => {
      return getTranslationCheck(auth.accessToken, values.task.description);
    },
    {
      onSuccess: (resp, data) => {
        if (resp.message.translated) {
          mutateCreateTask(data);
        } else {
          setSnackbar({
            title: "Error",
            content:
              "Task description is not translated to english, please click the translate to english button",
            type: "error",
          });
        }
      },
      onError: (e, data) => {
        mutateCreateTask(data);
        // setSnackbar({
        //   title: "Error",
        //   content: "Something went wrong",
        //   type: "error",
        // });
      },
    },
  );

  if (isUserDataLoading && isQualificationsLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <StyledContainer className="bg-mainBg flex justify-center items-center">
      <CreateTaskTemplate
        handleSubmit={mutateCreateTask}
        handleTranslate={mutateTranslateDescription}
        mode="edit"
        isLoading={isLoading}
        isSuccess={isSuccess}
        handleDraftSubmit={mutateDraftTask}
        qualificationsDropdownData={qualificationsDropdownData}
        isUserDataLoading={isUserDataLoading}
        isQualificationsLoading={isQualificationsLoading}
        translatedTexts={translatedTexts}
        resetTranslation={resetTranslation}
        userData={userData}
        isTranslating={isTranslating}
        isDescriptionTranslated={isDescriptionTranslated}
      />
    </StyledContainer>
  );
};

export default Component;
