import { Typography } from "@mui/material";
import React from "react";
import useCounter from "../../../components/hooks/useCounter";

type Props = {
  time: string;
  hasTask: boolean;
  taskDataRefetch: any;
  hasTimeoutEnded: any;
};
const Component = ({ ...props }: Props) => {
  const timer = useCounter(props.time);
  let min,
    sec = 0;
  if (timer > 0) {
    min = Math.floor(timer / 60);
    sec = timer % 60;
  } else {
    props.taskDataRefetch();
  }

  if (props.hasTask && timer <= 0) {
    props.hasTimeoutEnded(true);
  }
  return (
    <div className="text-white pt-12">
      {timer ? (
        <Typography>
          Remaining Time : {`${min ? ` ${min} min ` : ""}` + sec + " sec"}
        </Typography>
      ) : props.hasTask ? (
        <>Tasks are available again</>
      ) : (
        <>Currently, there's no task available</>
      )}
    </div>
  );
};

export default Component;
