import * as Yup from "yup";

export const CommentSchema = Yup.object().shape({
  comment: Yup.string().min(1, "Comment is required.").required("Required."),
});

export const FormSchema = (values) => {
  const errors = {};
  if (
    values.delivery?.urls.length <= 1 &&
    values.delivery.videos.length <= 1 &&
    values.delivery.description.length < 1
  ) {
    errors.delivery = "No Delivery";
  }
  return errors;
};
