import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { OnboardingButtonIcons, OnboardingDotIcon } from "../../../assets/svg";
import { authState } from "../../../state/atoms";

const ARCADE_LINKS = [
  "https://demo.arcade.software/upkR0j31KCy0sbeqjrrI?embed",
  "https://demo.arcade.software/DcXSvwT9xk7gVexuy1d6?embed",
  "https://demo.arcade.software/iSmIEGwfoLq8z6P4L1KN?embed",
  "https://demo.arcade.software/5uyaI5qrIM6oUjBdevna?embed",
];

const SVG_ACTIVE = [];
const SVG_INACTIVE = [];

type Props = {
  user;
};

const StyledContainerForOnboarding = styled.div`
  display: flex;
  height: 100%;

  @media only screen and (max-width: 1099px) {
    width: 80%;
  }
`;

const StyledBoardWrapper = styled.div`
  display: flex;
  min-height: 800px;
  height: 100%;
  min-width: 1100px;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media only screen and (max-width: 1099px) {
    min-width: 100%;
    width: 100%;
  }
`;

const ActiveButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  color: #ffffff;
  font-weight: 1000;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  background: #806ad2;

  @media (min-width: 768px) {
    width: 18.1rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    opacity: 90%;
  }
`;

const TaskPageButton = styled(Button)`
  display: inline-flex;
  padding: 1rem;
  color: #ffffff;
  font-weight: 1000;
  text-transform: none;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  background: #806ad2;

  @media (min-width: 768px) {
    width: 10rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #806ad2;
    opacity: 90%;
  }
`;

const Component = ({ user }: Props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);

  const [isArcadeLoading, setIsArcadeLoading] = useState(false);

  const [isFirstPhase, setIsFirstPhase] = useState(true);
  const [isSecondPhase, setIsSecondPhase] = useState(false);
  const [isThirdPhase, setIsThirdPhase] = useState(false);
  const [isLastPhase, setIsLastPhase] = useState(false);

  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
  const [isSecondButtonActive, setIsSecondButtonActive] = useState(false);
  const [isThirdButtonActive, setIsThirdButtonActive] = useState(false);
  const [isLastButtonActive, setIsLastButtonActive] = useState(false);

  const [phaseTitle, setPhaseTitle] = useState("");
  const [phaseSubtitle, setPhaseSubtitle] = useState("");

  function handleFirstPhase() {
    setIsArcadeLoading(true);
    setPhaseSubtitle(
      "You can start by connecting your wallet in and filling out your email address in the Task Profile. This allows you to see your wallet's assets and get notified for any updates on your task.",
    );
    setPhaseTitle("Set up your account");

    setIsFirstPhase(true);
    setIsSecondPhase(false);
    setIsThirdPhase(false);
    setIsLastPhase(false);

    //Button highlight logic
    setIsSecondButtonActive(false);
    setIsThirdButtonActive(false);
    setIsLastButtonActive(false);
  }
  function handleSecondPhase() {
    setIsArcadeLoading(true);
    setPhaseSubtitle(
      "Describe the requirements of your task, and assign the number of energy credits it will take to complete the task. Each energy will provide you with up to 30 mins of PA work.",
    );
    setPhaseTitle("Publish your first task");

    setIsFirstPhase(false);
    setIsSecondPhase(true);
    setIsThirdPhase(false);
    setIsLastPhase(false);

    //Button highlight logic
    setIsSecondButtonActive(true);
    setIsThirdButtonActive(false);
    setIsLastButtonActive(false);
  }
  function handleThirdPhase() {
    setIsArcadeLoading(true);
    setPhaseSubtitle(
      "You can get updates on your task by viewing notifications on the dashboard, or by simply checking your email for new messages. ",
    );
    setPhaseTitle("Get notified on status changes");

    setIsFirstPhase(false);
    setIsSecondPhase(false);
    setIsThirdPhase(true);
    setIsLastPhase(false);

    //Button highlight logic
    setIsSecondButtonActive(true);
    setIsThirdButtonActive(true);
    setIsLastButtonActive(false);
  }
  function handleFourthPhase() {
    setIsArcadeLoading(true);
    setPhaseSubtitle(
      "Tasks for review appear on top of the task list. You'll find PA outputs in the form of an attachment or a link, which you can choose to mark as complete or reject by the end of your review.",
    );
    setPhaseTitle("Review submissions from a PA");

    setIsFirstPhase(false);
    setIsSecondPhase(false);
    setIsThirdPhase(false);
    setIsLastPhase(true);

    //Button highlight logic
    setIsSecondButtonActive(true);
    setIsThirdButtonActive(true);
    setIsLastButtonActive(true);
  }

  return (
    <StyledContainerForOnboarding className="bg-mainBg justify-center mx-[5%]">
      <StyledBoardWrapper>
        <div className="flex flex-col w-full align-middle items-center text-black mt-[65px] mb-[50px]">
          <div className="text-5xl font-[1000] pb-4">
            Welcome to PANFT, {user?.user_name}
          </div>
          <div className="text-2xl pb-2">
            Let’s discover some of the things you can do with the Pocket
            Assistant app
          </div>
        </div>

        {/* Button Layer */}
        <div className="flex align-middle items-center justify-center  w-full pb-3">
          <div className="flex flex-row text-black">
            <ActiveButton
              onClick={handleFirstPhase}
              className="bg-onboardingButtonColor">
              <div className="flex align-middle items-center justify-center flex-row">
                <div className="pr-2 h-[24px]">
                  {!isFirstButtonActive ? (
                    <OnboardingButtonIcons
                      mode={0}
                      place={1}></OnboardingButtonIcons>
                  ) : (
                    <OnboardingButtonIcons
                      mode={1}
                      place={1}></OnboardingButtonIcons>
                  )}
                </div>
                <div className="text-lg tracking-wider">Get notified</div>
              </div>
            </ActiveButton>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <ActiveButton
              onClick={handleSecondPhase}
              className={`${
                isSecondButtonActive
                  ? "bg-onboardingButtonColor"
                  : "bg-white border-onboardingButtonColor border-solid border-2"
              } `}>
              <div className="pr-2 h-[24px]">
                {!isSecondButtonActive ? (
                  <OnboardingButtonIcons
                    mode={0}
                    place={2}></OnboardingButtonIcons>
                ) : (
                  <OnboardingButtonIcons
                    mode={1}
                    place={2}></OnboardingButtonIcons>
                )}
              </div>
              <div
                className={`${
                  !isSecondButtonActive
                    ? "text-onboardingButtonColor text-lg tracking-wider	"
                    : "text-lg tracking-wider	"
                }`}>
                Task Creation
              </div>
            </ActiveButton>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <ActiveButton
              onClick={handleThirdPhase}
              className={`${
                isThirdButtonActive
                  ? "bg-onboardingButtonColor"
                  : "bg-white border-onboardingButtonColor border-solid border-2"
              } `}>
              <div className="pr-2 h-[24px]">
                {!isThirdButtonActive ? (
                  <OnboardingButtonIcons
                    mode={0}
                    place={3}></OnboardingButtonIcons>
                ) : (
                  <OnboardingButtonIcons
                    mode={1}
                    place={3}></OnboardingButtonIcons>
                )}
              </div>
              <div
                className={`${
                  !isThirdButtonActive
                    ? "text-onboardingButtonColor text-lg tracking-wider	"
                    : "text-lg tracking-wider	"
                }`}>
                Task Updates
              </div>
            </ActiveButton>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <div className="p-1 m-auto">
              <OnboardingDotIcon></OnboardingDotIcon>
            </div>
            <ActiveButton
              onClick={handleFourthPhase}
              className={`${
                isLastButtonActive
                  ? "bg-onboardingButtonColor"
                  : "bg-white border-onboardingButtonColor border-solid border-2"
              } `}>
              <div className="pr-2 h-[24px]">
                {!isLastButtonActive ? (
                  <OnboardingButtonIcons
                    mode={0}
                    place={4}></OnboardingButtonIcons>
                ) : (
                  <OnboardingButtonIcons
                    mode={1}
                    place={4}></OnboardingButtonIcons>
                )}
              </div>
              <div
                className={`${
                  !isLastButtonActive
                    ? "text-onboardingButtonColor text-lg tracking-wider	"
                    : "text-lg tracking-wider	"
                }`}>
                Task Review
              </div>
            </ActiveButton>
          </div>
        </div>

        {/* Arcade Layer */}
        <div className="flex flex-col align-top w-full pb-2">
          <div>
            <div
              style={{
                position: "relative",
                paddingBottom: "calc(51.25661375661375% + 41px)",
                height: "0px",
              }}>
              <iframe
                src={
                  isFirstPhase
                    ? ARCADE_LINKS[0]
                    : isSecondPhase
                    ? ARCADE_LINKS[1]
                    : isThirdPhase
                    ? ARCADE_LINKS[2]
                    : ARCADE_LINKS[3]
                }
                onLoad={() => {
                  setIsArcadeLoading(false);
                }}
                loading="lazy"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  borderLeftWidth: "0px",
                  borderTopWidth: "0px",
                  borderRadius: "16px",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))",
                }}></iframe>
              {isArcadeLoading && (
                <div
                  style={{
                    width: "100%",
                    height: "698px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <div style={{ margin: "auto" }}>
                    <CircularProgress color="inherit" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Text Layer */}
        <div className="flex flex-col w-[50%] text-center align-middle items-center m-auto">
          <div className="text-3xl font-[1000] pb-4 text-black pt-4">
            {isFirstPhase ? "Receive notifications" : phaseTitle}
          </div>
          <div className="text-xl text-black pb-5 font-[500]">
            {isFirstPhase
              ? "We suggest that you go to your profile page and choose to receive email notifications and/or desktop/mobile notifications (coming soon). In this way you will be notified outside of the app when there are any updates to your task."
              : phaseSubtitle}
          </div>

          {isLastPhase ? (
            <>
              <div className="text-xl pb-2 font-[500] text-black ">
                Please note that the task will be automatically accepted if
                you’re not able to review it after 48 hours.
              </div>
              <ActiveButton
                className="rounded-full text-lg font-[500] mt-[30px] mb-[60px]"
                onClick={() => {
                  navigate("/");
                }}>
                Go to dashboard
              </ActiveButton>
            </>
          ) : (
            <></>
          )}
        </div>
      </StyledBoardWrapper>
    </StyledContainerForOnboarding>
  );
};

export default Component;
