import { Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  createQualification,
  deleteQualificationByid,
  getAllPaginatedQualifications,
  getQualificationById,
  updateQualificationById,
} from "../../../services/qualification";
import { authState, qualificationsListState } from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import Modal from "../../organisms/Modal";
import AdminQualificationsTemplate from "../../templates/AdminQualifications";

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

export default function AdminQualificationsPage() {
  const [auth, setAuth] = useRecoilState(authState);
  const [showCreateQualificationsModal, setShowCreateQualificationsModal] =
    useState<boolean>(false);
  const [showEditQualification, setShowEditQualification] =
    useState<boolean>(false);
  const [qualificationId, setQualificationId] = useState<string>("");
  const [qualificationsState, setQualificationState] = useRecoilState(
    qualificationsListState,
  );
  const [showDeleteQualificationModal, setShowDeleteQualificationModal] =
    useState<boolean>(false);

  // TODO: to be used for tooltip
  // const [openMaxInProgressTooltip, setOpenMaxInProgressTooltip] =
  //   useState(false);
  // const [
  //   openMaxClarificationNeededTooltip,
  //   setOpenMaxClarificationNeededTooltip,
  // ] = useState(false);

  const setSnackbar = useContext(SnackbarContext);

  const {
    isLoading: isQualificationsLoading,
    data,
    refetch: qualificationsRefetch,
  } = useQuery(
    [
      "getQualifications",
      auth.accessToken,
      qualificationsState.after,
      qualificationsState.before,
      qualificationsState.filters,
    ],
    () => {
      return getAllPaginatedQualifications(
        auth.accessToken,
        qualificationsState,
      );
    },
    {
      onSuccess: (data) => {},
      onError: (err) => {},
    },
  );

  const {
    isLoading: isQualificationByIdLoading,
    data: qualification,
    refetch: refetchQualification,
  } = useQuery(
    ["getQualificationsById", auth.accessToken, qualificationId],
    (values) => {
      return getQualificationById(auth.accessToken, values.queryKey[2]);
    },
    {
      onSuccess: (data) => {},
      onError: (err) => {},
      enabled: showEditQualification || showDeleteQualificationModal,
    },
  );

  const { mutate: createQualifications } = useMutation(
    ["createQualification"],
    (values: any) => {
      return createQualification(auth.accessToken, values);
    },
    {
      onSuccess: (data) => {
        // refetch
        qualificationsRefetch();

        setSnackbar({
          title: "Success",
          content: "Successfully created a qualification",
          type: "success",
        });
        setShowCreateQualificationsModal(false);
      },
      onError: (err) => {
        setShowCreateQualificationsModal(false);
      },
    },
  );

  const { mutate: editQualification } = useMutation(
    ["editQualification"],
    (values: any) => {
      return updateQualificationById(auth.accessToken, values);
    },
    {
      onSuccess: (data) => {
        setSnackbar({
          title: "Success",
          content: "Successfully edited a qualification",
          type: "success",
        });
        refetchQualification();
        closeEditQualificationModal();
        qualificationsRefetch();
      },
      onError: (err) => {
        closeEditQualificationModal();
        qualificationsRefetch();
      },
    },
  );

  const { mutate: deleteQualification } = useMutation(
    ["deleteQualification"],
    (values: any) => {
      return deleteQualificationByid(auth.accessToken, values.id);
    },
    {
      onSuccess: (data) => {
        setSnackbar({
          title: "Success",
          content: "Successfully deleted a qualification",
          type: "success",
        });
        closeDeleteQualificationModal();
        qualificationsRefetch();
      },
      onError: (err) => {
        closeDeleteQualificationModal();
        qualificationsRefetch();
      },
    },
  );

  const qualifications = data?.qualifications || [];
  const qualificationsBefore = data?.before || null;
  const qualfiicationsAfter = data?.after || null;

  return (
    <>
      <div
        className=" text-title bg-mainBg"
        style={{ height: "calc(100% - 64px)" }}>
        <AdminQualificationsTemplate
          qualificationsList={qualifications}
          qualificationsLoading={isQualificationsLoading}
          qualificationsBefore={qualificationsBefore}
          qualificationsAfter={qualfiicationsAfter}
          openCreateQualificationsModal={openCreateQualificationsModal}
          openEditQualificationModal={openEditQualificationModal}
          openDeleteQualificationModal={openDeleteQualificationModal}
        />
      </div>
      <Modal
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showCreateQualificationsModal}
        onClose={closeCreateQualificationsModal}
        title="Create a Qualification">
        <Formik
          initialValues={{
            name: "",
            is_global: false,
            max_in_progress: 2,
            max_clarification: 3,
            // TODO: Add bulk add qualif for users
            // task_owners: [],
            // assistants: [],
          }}
          onSubmit={(values) => {
            createQualifications(values);
          }}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <form onSubmit={handleSubmit}>
                    <Grid container columnGap={1} rowGap={1}>
                      <Grid item xs={12}>
                        <div style={{ fontWeight: "800" }}>Name</div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.name}
                          type="text"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue("name", event.target.value)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <div style={{ fontWeight: "800", marginRight: "5px" }}>
                          Private:
                        </div>
                        <Checkbox
                          checked={!values.is_global}
                          onChange={() => {
                            setFieldValue("is_global", !values.is_global);
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <p style={{ fontWeight: "800" }}>Max In Progress</p>

                        <Checkbox
                          title="If checked, use max value"
                          checked={values.max_in_progress !== -1}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("max_in_progress", 2);
                            } else {
                              setFieldValue("max_in_progress", -1);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={values.max_in_progress === -1}
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.max_in_progress}
                          type="number"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue(
                              "max_in_progress",
                              Number(event.target.value),
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <p style={{ fontWeight: "800" }}>
                          Max Clarification Needed
                        </p>
                        <Checkbox
                          title="If checked, use max value"
                          checked={values.max_clarification !== -1}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("max_clarification", 3);
                            } else {
                              setFieldValue("max_clarification", -1);
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "20px",
                        }}>
                        <TextField
                          disabled={values.max_clarification === -1}
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.max_clarification}
                          type="number"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue(
                              "max_clarification",
                              Number(event.target.value),
                            )
                          }
                        />
                      </Grid>

                      {/* TODO: Bulk user update attaching personas */}
                      {/* If private, select persona, if public, assistant */}
                      {/* <Grid item xs={10} className="flex items-center mt-3">
                          <div style={{ fontWeight: "800" }}>Select a persona</div>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            sx={{ width: "100%", height: "45px" }}
                            variant="filled"
                            size="small"
                            value={values.old_password}
                            type="password"
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: "0.375rem",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "10px 12px",
                                textIndent: "15px",
                              },
                            }}
                            onChange={(event) =>
                              setFieldValue("old_password", event.target.value)
                            }
                          />
                        </Grid> */}

                      {/* Drop down list */}
                      {/* <Grid item xs={10} className="flex items-center mt-3">
                          <div style={{ fontWeight: "800" }}>Select a {"persona"}</div>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            sx={{ width: "100%", height: "45px" }}
                            variant="filled"
                            size="small"
                            value={values.old_password}
                            type="password"
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: "0.375rem",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "10px 12px",
                                textIndent: "15px",
                              },
                            }}
                            onChange={(event) =>
                              setFieldValue("old_password", event.target.value)
                            }
                          />
                        </Grid> */}
                    </Grid>

                    <ConfirmButton
                      variant="contained"
                      type="submit"
                      disabled={values.name === ""}
                      fullWidth>
                      Submit
                    </ConfirmButton>
                    <CancelButton
                      variant="contained"
                      onClick={closeCreateQualificationsModal}
                      fullWidth>
                      No, Cancel
                    </CancelButton>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>

      {!isQualificationByIdLoading && qualification && (
        <Modal
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showDeleteQualificationModal}
          onClose={closeDeleteQualificationModal}
          title="Delete Qualification">
          <Formik
            initialValues={{
              id: qualificationId,
              name: qualification?.result.name,
            }}
            onSubmit={(values) => {
              deleteQualification(values);
            }}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <div style={{ width: 450 }}>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <Typography
                      fontWeight={500}
                      fontFamily="Inter"
                      style={{
                        margin: "10px 0px",
                        marginBottom: "25px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Are you sure you want to delete</div>
                        <div style={{ fontWeight: "700" }}>{values.name}?</div>
                      </div>
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <ConfirmButton
                        variant="contained"
                        type="submit"
                        fullWidth>
                        Confirm
                      </ConfirmButton>
                      <CancelButton
                        variant="contained"
                        onClick={closeDeleteQualificationModal}
                        fullWidth>
                        No, Cancel
                      </CancelButton>
                    </form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
      )}

      {!isQualificationByIdLoading && qualification && (
        <Modal
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          open={showEditQualification && qualificationId !== ""}
          onClose={closeEditQualificationModal}
          title="Edit Qualification">
          <Formik
            initialValues={{
              name: qualification?.result.name,
              id: qualificationId,
              max_in_progress: qualification?.result.max_in_progress,
              max_clarification: qualification?.result.max_clarification,
              // TODO: Add bulk update for users
              // task_owners: [],
              // assistants: [],
            }}
            onSubmit={(values) => {
              editQualification(values);
            }}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <div style={{ width: 450 }}>
                  <div
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <form onSubmit={handleSubmit}>
                      <Grid container columnGap={1} rowGap={1}>
                        <Grid item xs={12}>
                          <div style={{ fontWeight: "800" }}>Name</div>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "100%", height: "45px" }}
                            variant="filled"
                            size="small"
                            value={values.name}
                            type="text"
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: "0.375rem",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "10px 12px",
                                textIndent: "15px",
                              },
                            }}
                            onChange={(event) =>
                              setFieldValue("name", event.target.value)
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <div style={{ fontWeight: "800" }}>
                            Max In Progress
                          </div>
                          <Checkbox
                            title="If checked, use max value"
                            checked={values.max_in_progress !== -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("max_in_progress", 2);
                              } else {
                                setFieldValue("max_in_progress", -1);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled={values.max_in_progress === -1}
                            sx={{ width: "100%", height: "45px" }}
                            variant="filled"
                            size="small"
                            value={values.max_in_progress}
                            type="number"
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: "0.375rem",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "10px 12px",
                                textIndent: "15px",
                              },
                            }}
                            onChange={(event) =>
                              setFieldValue(
                                "max_in_progress",
                                Number(event.target.value),
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <div style={{ fontWeight: "800" }}>
                            Max Clarification Needed
                          </div>
                          <Checkbox
                            title="If checked, use max value"
                            checked={values.max_clarification !== -1}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("max_clarification", 3);
                              } else {
                                setFieldValue("max_clarification", -1);
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBottom: "20px",
                          }}>
                          <TextField
                            disabled={values.max_clarification === -1}
                            sx={{ width: "100%", height: "45px" }}
                            variant="filled"
                            size="small"
                            value={values.max_clarification}
                            type="number"
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                borderRadius: "0.375rem",
                              },
                            }}
                            inputProps={{
                              style: {
                                padding: "10px 12px",
                                textIndent: "15px",
                              },
                            }}
                            onChange={(event) =>
                              setFieldValue(
                                "max_clarification",
                                Number(event.target.value),
                              )
                            }
                          />
                        </Grid>

                        {/* TODO: Bulk user update attaching personas */}
                        {/* If private, select persona, if public, assistant */}
                        {/* <Grid item xs={10} className="flex items-center mt-3">
                        <div style={{ fontWeight: "800" }}>Select a persona</div>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.old_password}
                          type="password"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue("old_password", event.target.value)
                          }
                        />
                      </Grid> */}

                        {/* Drop down list */}
                        {/* <Grid item xs={10} className="flex items-center mt-3">
                        <div style={{ fontWeight: "800" }}>Select a {"persona"}</div>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          sx={{ width: "100%", height: "45px" }}
                          variant="filled"
                          size="small"
                          value={values.old_password}
                          type="password"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              borderRadius: "0.375rem",
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: "10px 12px",
                              textIndent: "15px",
                            },
                          }}
                          onChange={(event) =>
                            setFieldValue("old_password", event.target.value)
                          }
                        />
                      </Grid> */}
                      </Grid>

                      <ConfirmButton
                        variant="contained"
                        type="submit"
                        disabled={values.name === ""}
                        fullWidth>
                        Submit
                      </ConfirmButton>
                      <CancelButton
                        variant="contained"
                        onClick={closeEditQualificationModal}
                        fullWidth>
                        No, Cancel
                      </CancelButton>
                    </form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );

  function openCreateQualificationsModal() {
    setShowCreateQualificationsModal(true);
  }
  function closeCreateQualificationsModal() {
    setShowCreateQualificationsModal(false);
  }

  function closeEditQualificationModal() {
    setShowEditQualification(false);
    setQualificationId("");
  }

  function openEditQualificationModal(id: string) {
    setShowEditQualification(true);
    setQualificationId(id);
  }

  function openDeleteQualificationModal(id: string) {
    setShowDeleteQualificationModal(true);
    setQualificationId(id);
  }

  function closeDeleteQualificationModal() {
    setShowDeleteQualificationModal(false);
    setQualificationId("");
  }
}
