import { Button, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { Qualification } from "../../../interfaces/Qualification";
import {
  getEarningById,
  getFeedBackById,
  getTaskByUserId,
  getTotalEarningById,
  updateEarningById,
} from "../../../services/tasks";
import { updateAssistant } from "../../../services/users";
import {
  authState,
  earningPeriodState,
  earningState,
  feedbackState,
  userTaskListState,
} from "../../../state/atoms";
import { SnackbarContext } from "../../../state/hooks";
import Modal from "../../organisms/Modal";
import AdminAssistantAccountTemplate from "../../templates/AdminAssistantAccount";

import {
  addQualificationToUser,
  getAllQualifications,
  removeQualificationToUser,
} from "../../../services/qualification";
import useUserFetch from "../../hooks/useUserFetch";

const StyledContainer = styled.div`
  height: calc(100%-64px);
`;

const IS_GLOBAL = null; // fetch all qualifications

const ConfirmButton = styled(Button)`
  background-color: #e8735a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: white;

  &:hover {
    background-color: #e8735a;
  }
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #1a1a25;
  margin-top: 10px;

  &:hover {
    background: #e8e8e8;
  }
`;

const StyledFormWrapper = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const Component = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [userTaskList, setUserTaskList] = useRecoilState(userTaskListState);
  const [feedback, setFeedBack] = useRecoilState(feedbackState);
  const [earning, setEarning] = useRecoilState(earningState);
  const [earningPeriod, setEarningPeriod] = useRecoilState(earningPeriodState);
  const [taskToDelete, setTaskToDelete] = useState("");
  const [after, setAfter] = useState(null);
  const [before, setBefore] = useState(null);
  const [feedbackAfter, setFeedbackAfter] = useState(null);
  const [feedbackBefore, setFeedbackBefore] = useState(null);
  const [earningAfter, setEarningAfter] = useState(null);
  const [earningBefore, setEarningBefore] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [assistantLoading, setAssistantLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [earningLoading, setEarningLoading] = useState(false);
  const [totalEarningLoadig, setTotalEarningLoading] = useState(false);
  const [showAddQualificationsModal, setShowAddQualificationsModal] =
    useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [listOfQualification, setListOfQualification] = useState([]);
  const [showRemoveQualificationModal, setShowRemoveQualificationModal] =
    useState<boolean>(false);
  const [deleteQualificationId, setDeleteQualificationId] = useState();
  const navigate = useNavigate();
  const setSnackbar = useContext(SnackbarContext);

  let { id: assistantUserId } = useParams();

  const {
    user: assistantUser,
    refetch: userFetch,
    isLoading: userLoading,
    isRefetching: assistantRefetching,
  } = useUserFetch(auth, "assistant", assistantUserId);

  useEffect(() => {
    setTaskLoading(true);
    setFeedbackLoading(true);
    userFetch();
    refetch();
  }, []);

  useEffect(() => {
    setFeedbackLoading(true);
    userFetch();
    refetch();
  }, []);

  const { data: qualifications } = useQuery(
    ["fetchingQualification", auth.accessToken],
    () => {
      return getAllQualifications(auth.accessToken, IS_GLOBAL);
    },
    {
      onSuccess: (message) => {
        setListOfQualification(message.qualifications);
      },
      onError: (e) => {},
      enabled: showAddQualificationsModal,
    },
  );

  const {
    data: tasksData,
    isLoading: isLoading,
    refetch,
  } = useQuery(
    [
      "fetchingUserTasks",
      auth.accessToken,
      userTaskList.filters,
      userTaskList.after,
      userTaskList.before,
    ],
    () => {
      if (auth.accessToken) {
        return getTaskByUserId(auth.accessToken, assistantUserId, userTaskList);
      }
    },
    {
      onSuccess: () => {
        setTaskLoading(false);
      },
      onError: (e) => {},
    },
  );

  const { data: feedbackData, isLoading: isLoadingFeedback } = useQuery(
    ["fetchingFeedback", auth.accessToken, feedback.after, feedback.before],
    () => {
      if (auth.accessToken) {
        return getFeedBackById(auth.accessToken, assistantUserId, feedback);
      }
    },
    {
      onSuccess: () => {
        setFeedbackLoading(false);
      },
      onError: (e) => {},
    },
  );

  const {
    data: totalEarning,
    isLoading: isLoadingTotalEarning,
    refetch: totalEarningFetch,
  } = useQuery(
    ["fetchingTotalEarning", auth.accessToken, earningPeriod],
    () => {
      if (auth.accessToken) {
        return getTotalEarningById(
          auth.accessToken,
          assistantUserId,
          earningPeriod,
        );
      }
    },
    {
      onSuccess: () => {
        setTotalEarningLoading(false);
      },
      onError: (e) => {},
    },
  );

  const {
    data: earningData,
    isLoading: isLoadingEarning,
    refetch: earningFetch,
  } = useQuery(
    [
      "fetchingEarning",
      auth.accessToken,
      earning.paid_out,
      earning.dates,
      earning.after,
      earning.before,
    ],
    () => {
      if (auth.accessToken) {
        return getEarningById(auth.accessToken, assistantUserId, earning);
      }
    },
    {
      onSuccess: () => {
        setEarningLoading(false);
      },
      onError: (e) => {},
    },
  );

  const {
    mutate: mutateUpdateEarning,
    isLoading: isLoadingUpdate,
    error,
  } = useMutation(
    "updateEarning",
    (values: any) => {
      if (!auth.accessToken) {
        // When access token is not available, reject the Promise with an error message.
        return Promise.reject(new Error("No access token"));
      }

      return updateEarningById(
        auth.accessToken,
        values.earnings_ids,
        values.paid_out,
      );
    },
    {
      onSuccess: (resp) => {
        setSnackbar({
          title: "Success",
          content: "Successfully Update Earning",
          type: "success",
        });
        earningFetch();
      },
      onError: (e, variables) => {
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        earningFetch();
      },
    },
  );

  const { mutate: addQualification } = useMutation(
    "addQualifications",
    (values: any) => {
      if (!auth.accessToken) {
        // When access token is not available, reject the Promise with an error message.
        return Promise.reject(new Error("No access token"));
      }
      values.id = assistantUserId;
      return addQualificationToUser(auth.accessToken, values);
    },
    {
      onSuccess: (resp) => {
        userFetch();
        closeAddQualificationsModal();
        setSnackbar({
          title: "Success",
          content: "Successfully Added a qualification",
          type: "success",
        });
      },
      onError: (e, variables) => {
        closeAddQualificationsModal();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        userFetch();
      },
    },
  );
  const { mutate: removeQualification } = useMutation(
    "removeQualification",
    (values: any) => {
      if (!auth.accessToken) {
        // When access token is not available, reject the Promise with an error message.
        return Promise.reject(new Error("No access token"));
      }
      values.id = assistantUserId;
      return removeQualificationToUser(auth.accessToken, values);
    },
    {
      onSuccess: (resp) => {
        userFetch();
        closeRemoveQualificationModal();
        setSnackbar({
          title: "Success",
          content: "Successfully removed a qualification",
          type: "success",
        });
      },
      onError: (e, variables) => {
        closeAddQualificationsModal();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        userFetch();
      },
    },
  );

  const { mutate: updateAssistantUser } = useMutation(
    "updateAssistantUser",
    (values: any) => {
      if (!auth.accessToken) {
        // When access token is not available, reject the Promise with an error message.
        return Promise.reject(new Error("No access token"));
      }
      values.assistantId = assistantUserId;
      return updateAssistant(auth.accessToken, values);
    },
    {
      onSuccess: (resp) => {
        closeUpdateUserModal();
        setSnackbar({
          title: "Success",
          content: "Successfully updated the assistant user.",
          type: "success",
        });
        userFetch();
      },
      onError: (e, variables) => {
        closeUpdateUserModal();
        setSnackbar({
          title: "Error",
          content:
            "We are having some technical issues, please try again later.",
          type: "error",
        });
        userFetch();
      },
    },
  );

  const tasks = tasksData?.tasks || [];
  const tasks_count = tasksData?.total || null;
  const after_ = tasksData?.after || null;
  const before_ = tasksData?.before || null;

  const feedbacks = feedbackData?.feedback || [];
  const feedbacks_count = feedbackData?.total || null;
  const feedbackAfter_ = feedbackData?.after || null;
  const feedbackBefore_ = feedbackData?.before || null;

  const earnings = earningData?.earnings || [];
  const earnings_count = earningData?.total || null;
  const earningAfter_ = earningData?.after || null;
  const earningBefore_ = earningData?.before || null;

  return (
    <StyledContainer className="text-title bg-mainBg">
      {listOfQualification && (
        <AdminAssistantAccountTemplate
          assistant={assistantUser}
          tasks={tasks}
          tasks_count={tasks_count}
          after={after_}
          before={before_}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          isLoading={isLoading || taskLoading}
          earnings={earnings}
          totalEarning={totalEarning}
          earnings_count={earnings_count}
          earningAfter={earningAfter_}
          earningBefore={earningBefore_}
          handleEarningNextPage={handleEarningNextPage}
          handleEarningPrevPage={handleEarningPrevPage}
          isEarningLoading={isLoadingEarning || earningLoading}
          isTotalEarningLearning={isLoadingTotalEarning}
          UpdateEarning={mutateUpdateEarning}
          feedbacks={feedbacks}
          feedbacks_count={feedbacks_count}
          feedbackAfter={feedbackAfter_}
          feedbackBefore={feedbackBefore_}
          handleFeedbackNextPage={handleFeedbackNextpage}
          handleFeedbackPrevPage={handleFeedbackPrevPage}
          isFeedbackLoading={isLoadingFeedback || feedbackLoading}
          openUpdateUserModal={openUpdateUserModal}
          addQualifications={handleAddQualifications}
          qualificationsList={listOfQualification}
          userLoading={userLoading}
          openRemoveQualificationModal={openRemoveQualificationModal}
        />
      )}

      <Modal
        title="Add Qualification"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showAddQualificationsModal}
        onClose={closeAddQualificationsModal}>
        <Formik
          initialValues={{ qualifications: [] }}
          onSubmit={(values) => {
            const payload = {
              qualifications: [
                ...values.qualifications,
                ...(assistantUser?.qualifications?.map((qual) => qual.id) ||
                  []),
              ],
              id: assistantUserId || -1,
            };

            addQualification(payload);
          }}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <div style={{ width: 450 }}>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <form onSubmit={handleSubmit}>
                    <Select
                      style={{ marginBottom: "1rem", marginTop: "1rem" }}
                      className="bg-white border-0 rounded w-[20%] text-gray-700 leading-tight "
                      sx={{
                        height: "45px",
                      }}
                      id="qualifications"
                      defaultValue="Select a qualification"
                      displayEmpty
                      renderValue={
                        values.qualifications?.length !== 0
                          ? undefined
                          : () => "Select a qualification"
                      }
                      onChange={(event) => {
                        setFieldValue("qualifications", [event.target.value]);
                      }}>
                      {listOfQualification
                        .filter((qualification: Qualification) => {
                          return !assistantUser?.qualifications
                            ?.map((qual) => qual.id)
                            .includes(qualification.id);
                        })
                        .filter(
                          (qualification: Qualification) =>
                            qualification.name !== "General",
                        )
                        .map((qualification: Qualification) => {
                          return (
                            <MenuItem
                              key={qualification.id}
                              value={qualification.id}>
                              {qualification.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <ConfirmButton
                      variant="contained"
                      disabled={
                        values.qualifications.length === 0 || isSubmitting
                      }
                      type="submit"
                      fullWidth>
                      Submit
                    </ConfirmButton>
                    <CancelButton
                      variant="contained"
                      onClick={closeAddQualificationsModal}
                      fullWidth>
                      No, Cancel
                    </CancelButton>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        title="Update Assistant Settings"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showUpdateUserModal}
        onClose={closeUpdateUserModal}>
        <Formik
          initialValues={{
            isSuspended:
              assistantUser?.suspended != null
                ? assistantUser.suspended
                : false,
            isEligible:
              assistantUser?.is_eligible != null
                ? assistantUser.is_eligible
                : true,
            assistantId: assistantUserId,
          }}
          onSubmit={(values) => {
            console.log(values);

            // do not update the isELigible prop
            values.isEligible = null;

            updateAssistantUser(values);
          }}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <div style={{ width: 450 }}>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "30px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          title="If checked, it will set suspended of user to false"
                          checked={!values.isSuspended}
                          onChange={() => {
                            setFieldValue("isSuspended", !values.isSuspended);
                          }}
                        />
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Approve user
                          </span>{" "}
                          (set suspended to false)
                        </p>
                      </div>

                      {assistantUser?.is_eligible === false ? (
                        <CancelButton
                          title="Remove the account's under review status"
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            values.isEligible = true;

                            // do not update the isSuspended prop
                            values.isSuspended = null;

                            updateAssistantUser(values);
                          }}>
                          Revert Account to Active
                        </CancelButton>
                      ) : (
                        <></>
                      )}
                    </div>

                    <ConfirmButton variant="contained" type="submit" fullWidth>
                      Submit
                    </ConfirmButton>
                    <CancelButton
                      variant="contained"
                      onClick={closeUpdateUserModal}
                      fullWidth>
                      No, Cancel
                    </CancelButton>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        title="Remove Qualification"
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        open={showRemoveQualificationModal}
        onClose={closeRemoveQualificationModal}>
        <Formik
          initialValues={{ qualifications: [] }}
          onSubmit={(values) => {
            const payload = {
              qualifications: [
                ...(assistantUser?.qualifications
                  ?.filter((x) => x.id !== deleteQualificationId)
                  .map((qual) => qual.id) || []),
              ],
              id: assistantUserId,
            };

            removeQualification(payload);
          }}>
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <div style={{ width: 450 }}>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <form onSubmit={handleSubmit}>
                    <Typography
                      fontWeight={700}
                      fontFamily="Inter"
                      style={{ marginBottom: "0.5rem" }}>
                      Are you sure you want to remove the qualification?
                    </Typography>
                    <ConfirmButton
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={isSubmitting}>
                      Yes
                    </ConfirmButton>
                    <CancelButton
                      variant="contained"
                      onClick={closeRemoveQualificationModal}
                      fullWidth>
                      No, Cancel
                    </CancelButton>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </StyledContainer>
  );

  function handleNextPage(after: any) {
    if (!isLoading) {
      setAfter(after);
      setBefore(null);
    }
  }

  function handlePrevPage(before: any) {
    if (!isLoading) {
      setAfter(null);
      setBefore(before);
    }
  }

  function handleEarningNextPage(after: any) {
    if (!isLoadingEarning) {
      setEarningAfter(after);
      setEarningBefore(null);
    }
  }

  function handleEarningPrevPage(before: any) {
    if (!isLoadingEarning) {
      setEarningAfter(null);
      setEarningBefore(before);
    }
  }

  function handleFeedbackNextpage(after: any) {
    if (!isLoadingFeedback) {
      setFeedbackAfter(after);
      setFeedbackBefore(null);
    }
  }

  function handleFeedbackPrevPage(before: any) {
    if (!isLoadingFeedback) {
      setFeedbackAfter(null);
      setFeedbackBefore(before);
    }
  }

  function handleAddQualifications() {
    setShowAddQualificationsModal(true);
  }

  function closeAddQualificationsModal() {
    setShowAddQualificationsModal(false);
  }

  function closeRemoveQualificationModal() {
    setShowRemoveQualificationModal(false);
  }

  function openUpdateUserModal() {
    setShowUpdateUserModal(true);
  }

  function openRemoveQualificationModal(id) {
    setDeleteQualificationId(id);
    setShowRemoveQualificationModal(true);
  }

  function closeUpdateUserModal() {
    setShowUpdateUserModal(false);
  }
};

export default Component;
