import React, { useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  getAllOwnersByAdmin,
  getAssistantByAdmin,
} from "../../../services/tasks";
import {
  adminPocketAssistantState,
  adminTaskOwnersState,
  authState,
} from "../../../state/atoms";
import AdminAccountsTemplate from "../../templates/AdminAccounts";

type Props = {
  setActive: (active: any) => void;
};

const StyledContainer = styled.div`
  height: calc(100% - 64px);
`;

export default function AdminAccountsPage({ setActive }: Props) {
  const [auth, setAuth] = useRecoilState(authState);
  const [adminPocketAssistant, setAdminPocketAssistant] = useRecoilState(
    adminPocketAssistantState,
  );
  const adminTaskOwners = useRecoilValue(adminTaskOwnersState);
  const [assistantAfter, setAssistantAfter] = useState(null);
  const [assistantBefore, setAssistantBefore] = useState(null);
  const [assistantLoading, setAssistantLoading] = useState(false);
  const [ownerLoading, setOwnerLoading] = useState(false);

  const { data: assistantData, isLoading: isAssistantLoading } = useQuery(
    [
      "fetchingAdminAssistants",
      auth.accessToken,
      adminPocketAssistant.filters,
      adminPocketAssistant.after,
      adminPocketAssistant.before,
    ],
    () => {
      if (auth.accessToken) {
        return getAssistantByAdmin(auth.accessToken, adminPocketAssistant);
      }
    },
    {
      onSuccess: () => {
        setAssistantLoading(false);
      },
      onError: (e) => {},
    },
  );

  const { data: ownerData, isLoading: isOwnerDataLoading } = useQuery(
    [
      "fetchingAdminOwners",
      auth.accessToken,
      adminTaskOwners.filters,
      adminTaskOwners.after,
      adminTaskOwners.before,
    ],
    () => {
      if (auth.accessToken) {
        return getAllOwnersByAdmin(auth.accessToken, adminTaskOwners);
      }
    },
    {
      onSuccess: () => {
        setOwnerLoading(false);
      },
      onError: (e) => {},
    },
  );

  const assistants = assistantData?.tasks || [];
  const assistantAfter_ = assistantData?.after || null;
  const assistantBefore_ = assistantData?.before || null;

  const owners = ownerData?.owners || [];
  const ownersBefore_ = ownerData?.before || null;
  const ownersAfter_ = ownerData?.after || null;

  return (
    <StyledContainer className="text-title bg-mainBg">
      <AdminAccountsTemplate
        setActive={setActive}
        assistants={assistants}
        assistantAfter={assistantAfter_}
        assistantBefore={assistantBefore_}
        isAssistantLoading={isAssistantLoading || assistantLoading}
        owners={owners}
        ownersAfter={ownersAfter_}
        ownersBefore={ownersBefore_}
        isOwnersLoading={isOwnerDataLoading || ownerLoading}
      />
    </StyledContainer>
  );
}
