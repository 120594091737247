const getTasksByOwnerId = async (
  token,
  userId,
  withTemplate = false,
  fetchType = "none",
) => {
  var endpoint = `/api/task/holder/${userId}`;
  if (fetchType === "fav") {
    endpoint = `/api/task/holder/${userId}?type=template`;
  } else if (fetchType !== "none") {
    endpoint = `/api/task/status/${fetchType}`;
  }

  const response = await fetch(process.env.API_URL + endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTaskByHolder = async (
  token,
  fetchType = "all",
  after = null,
  before = null,
) => {
  let response;
  if (after !== null) {
    response = await fetch(process.env.API_URL + `/api/task/holder/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: fetchType, after: after }),
    });
  } else if (before !== null) {
    response = await fetch(process.env.API_URL + `/api/task/holder/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: fetchType, before: before }),
    });
  } else {
    response = await fetch(process.env.API_URL + `/api/task/holder/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: fetchType }),
    });
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTaskByHolderDashboard = async (
  token,
  user_id,
  fetchType = "open",
  after = null,
  before = null,
) => {
  let response;
  if (after !== null) {
    response = await fetch(process.env.API_URL + `/api/task/holder/dashboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user_id,
        status: fetchType,
        after: after,
      }),
    });
  } else if (before !== null) {
    response = await fetch(process.env.API_URL + `/api/task/holder/dashboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user_id,
        status: fetchType,
        before: before,
      }),
    });
  } else {
    response = await fetch(process.env.API_URL + `/api/task/holder/dashboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user_id: user_id, status: fetchType }),
    });
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTaskByAdmin = async (token, adminTaskList) => {
  let response;
  let filters = {
    dates: adminTaskList.dates,
    times_declined: adminTaskList.swipes,
    status: adminTaskList.status,
    rating: adminTaskList.rating,
  };
  if (adminTaskList.after !== null) {
    response = await fetch(process.env.API_URL + `/api/admin/task/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filters: filters, after: adminTaskList.after }),
    });
  } else if (adminTaskList.before !== null) {
    response = await fetch(process.env.API_URL + `/api/admin/task/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filters: filters, before: adminTaskList.before }),
    });
  } else {
    response = await fetch(process.env.API_URL + `/api/admin/task/`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filters: filters }),
    });
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTaskByUserId = async (token, id, userTaskList) => {
  // if (userTaskList.after !== null) {
  //   response = await fetch(process.env.API_URL + `/api/admin/assistant/tasks`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify({
  //       filters: filters,
  //       assistant_id: id,
  //       after: userTaskList.after,
  //     }),
  //   });
  // } else if (userTaskList.before !== null) {
  //   response = await fetch(process.env.API_URL + `/api/admin/assistant/tasks`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify({
  //       filters: filters,
  //       assistant_id: id,
  //       before: userTaskList.before,
  //     }),
  //   });
  // } else {
  //   response = await fetch(process.env.API_URL + `/api/admin/assistant/tasks`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify({ filters: filters, assistant_id: id }),
  //   });
  // }
  let response = await fetch(
    process.env.API_URL + `/api/admin/assistant/tasks`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assistant_id: id, ...userTaskList }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTotalEarningById = async (token, id, period) => {
  let response;
  response = await fetch(
    process.env.API_URL + `/api/admin/stats/totals_by_assistant`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assistant_id: id,
        year_month_from: period.from,
        year_month_to: period.to,
      }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getEarningById = async (token, id, earning) => {
  let response;
  let filters = {
    dates: [earning.dates[0], earning.dates[1]],
    paid_out: earning.paid_out,
  };

  if (earning.after !== null) {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/earnings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          assistant_id: id,
          dates: [earning?.dates[0], earning?.dates[1]],
          paid_out: earning.paid_out,
          // after: earning.after,
        }),
      },
    );
  } else if (earning.before !== null) {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/earnings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          assistant_id: id,
          dates: [earning?.dates[0], earning?.dates[1]],
          paid_out: earning.paid_out,
          before: earning.before,
        }),
      },
    );
  } else {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/earnings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          assistant_id: id,
          dates: [earning?.dates[0], earning?.dates[1]],
          paid_out: earning.paid_out,
        }),
      },
    );
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const updateEarningById = async (token, earnings_ids, paid_out) => {
  const response = await fetch(
    process.env.API_URL + `/api/admin/assistant/earnings`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ earnings_ids: earnings_ids, paid_out: paid_out }),
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getFeedBackById = async (token, id, feedback) => {
  let response;
  if (feedback.after !== null) {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/feedback`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          assistant_id: id,
          after: feedback.after,
        }),
      },
    );
  } else if (feedback.before !== null) {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/feedback`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          assistant_id: id,
          before: feedback.before,
        }),
      },
    );
  } else {
    response = await fetch(
      process.env.API_URL + `/api/admin/assistant/feedback`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ assistant_id: id }),
      },
    );
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAllOwnersByAdmin = async (token, body) => {
  const response = await fetch(process.env.API_URL + "/api/admin/owner/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`Error with code ${response.status}`);
  }

  return await response.json();
};

const getAssistantByAdmin = async (token, adminPocketAssistant) => {
  let response = await fetch(process.env.API_URL + `/api/admin/assistant/`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(adminPocketAssistant),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAllAssistantByAdmin = async (token, adminPocketAssistant) => {
  adminPocketAssistant.size = 1000;
  let response = await fetch(process.env.API_URL + `/api/admin/assistant/`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(adminPocketAssistant),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAssistantByEmail = async (token, adminPocketAssistantSelection) => {
  let response;
  let filters = {
    email: adminPocketAssistantSelection.email,
  };
  response = await fetch(process.env.API_URL + `/api/admin/assistant/`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      filters: filters,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTasksByPAId = async (token, userId) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/assistant/dashboard/${userId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getMissions = async (token, userId) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/assistant/missions?id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getAllTasks = async (token, userId) => {
  const response = await fetch(process.env.API_URL + `/api/task/assistant/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const createTask = async (token, task, isPublish) => {
  if (Array.isArray(task.urls)) {
    if (task.urls[task.urls.length - 1] === "") {
      task.urls = task.urls.slice(0, -1);
    }
  }

  if (Array.isArray(task.videos)) {
    if (task.videos[task.videos.length - 1] === "") {
      task.videos = task.videos.slice(0, -1);
    }
  }

  if (!task.hasOwnProperty("task_type")) {
    task.task_type = "standard";
  }

  if (task.task_type === "multiplicity") {
    task.is_child = false;
  }

  const response = await fetch(process.env.API_URL + `/api/task/`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ task, do_publish: isPublish }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const createMission = async (token, task, isPublish) => {
  if (task.urls[task.urls.length - 1] === "") {
    task.urls = task.urls.slice(0, -1);
  }
  if (task.videos[task.videos.length - 1] === "") {
    task.videos = task.videos.slice(0, -1);
  }
  const response = await fetch(process.env.API_URL + `/api/task/`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      task: {
        ...task,
        task_type: "mission",
        is_child: false,
      },
      do_publish: isPublish,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTasksByStatus = async (token, status) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/status/${status}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTasksForPool = async (token) => {
  const response = await fetch(process.env.API_URL + `/api/task/assistant`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();

  if (response && response.ok) {
    return data;
  }

  throw new Error(
    data.message ?? data.reason ?? `Error with code ${response?.status}`,
  );
};

const getMissionsForPool = async (token) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/assistant/mission`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const reassignTask = async (token, task) => {
  if (task.urls[task.urls.length - 1] === "") {
    task.urls = task.urls.slice(0, -1);
  }
  if (task.videos[task.videos.length - 1] === "") {
    task.videos = task.videos.slice(0, -1);
  }
  const response = await fetch(process.env.API_URL + `/api/task/recreate`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ task: task }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getTaskById = async (token, taskId, withComments = false) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/${taskId}?comments=${withComments}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const changeTaskStatus = async (token, values) => {
  const payload = {
    task_id: values.taskId,
    status: values.status,
  };
  if (values.reason) {
    payload.reason = values.reason;
  }
  const response = await fetch(process.env.API_URL + `/api/task/status`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (response && response.ok) {
    return data;
  }

  throw new Error(
    data.message ? data.message : `Error with code ${response?.status}`,
  );
};

const getChildTaskByParentId = async (token, parentId) => {
  const response = await fetch(
    process.env.API_URL + `/api/task/parent/${parentId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const updateTask = async (token, _task, taskId) => {
  // making a copy so as not to affect the original (plus got strange results whereby the original arrays were not affected when assigned sliced values)
  let task = Object.assign({}, _task);
  if (task.urls) {
    task.urls = [...task.urls];
  }

  if (_task.delivery) {
    task.delivery = Object.assign({}, _task.delivery);
    task.delivery.videos = [...task.delivery.videos]; // important to create new copies of the arrays that are not references to original arrays - otherwise sometimes the manipulation below was not having any effect when assigning the sliced values
    task.delivery.videos.pop();
    task.delivery.urls = [...task.delivery.urls];
  }
  // removing empty last items (lat itmes are often empty as that is needed in edit mode to show an empty input field)
  if (task.urls?.[task.urls?.length - 1] == "") {
    task.urls = task.urls.slice(0, -1);
  }
  if (task.videos?.[task.videos?.length - 1] == "") {
    task.videos = task.videos.slice(0, -1);
  }

  if (task.delivery?.urls.length) {
    task.delivery.urls = task.delivery?.urls?.slice(0, -1);
  }

  // always check qualifications and default to string array
  if (task.required_qualifications?.length > 0) {
    if (typeof task.required_qualifications[0] !== "string") {
      let xQualifs = task.required_qualifications.map((x) => x.id);

      task.required_qualifications = xQualifs;
    }
  }
  if (task.rewards_qualifications?.length > 0) {
    if (typeof task.rewards_qualifications[0] !== "string") {
      let xQualifs = task.rewards_qualifications.map((x) => x.id);

      task.rewards_qualifications = xQualifs;
    }
  }

  const response = await fetch(process.env.API_URL + `/api/task/`, {
    method: "PUT",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      task: task,
      task_id: taskId,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const setTaskStatus = async (token, values) => {
  var response;

  if (values.reason) {
    response = await fetch(process.env.API_URL + `/api/task/status`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        task_id: values.id,
        status: values.status,
        reason: values.reason,
      }),
    });
  } else {
    response = await fetch(process.env.API_URL + `/api/task/status`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ task_id: values.id, status: values.status }),
    });
  }

  if (response && response.ok) {
    return await response.json();
  } else if (response && response.status === 400) {
    let result = await response.json();
    return { success: false, message: result.message };
  }

  throw new Error(`Error with code ${response?.status}`);
};

const addTaskComment = async (token, comment, taskId) => {
  const response = await fetch(process.env.API_URL + `/api/comment/`, {
    method: "POST",
    headers: { "Content-Type": "text/plain", Authorization: `Bearer ${token}` },
    body: JSON.stringify({ comment, task_id: taskId }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const changeEnergy = async (token, energyValue, taskId) => {
  const response = await fetch(process.env.API_URL + `/api/task/energy`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      task_id: taskId,
      new_energy_amount: energyValue,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const addRatingData = async (token, ratingdata, taskId) => {
  const response = await fetch(process.env.API_URL + `/api/rating`, {
    method: "POST",
    headers: { "Content-Type": "text/plain", Authorization: `Bearer ${token}` },
    body: JSON.stringify({
      feedback: ratingdata.feedback,
      rating: ratingdata.rating,
      task_id: taskId,
    }),
  });
  if (response && response.ok) {
    return await response.json();
  }
  throw new Error(`Error with code ${response?.status}`);
};

const deleteTask = async (token, taskId) => {
  const response = await fetch(process.env.API_URL + `/api/task?id=${taskId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (response && response.ok) {
    return data;
  }

  throw new Error(
    data.message ?? data.reason ?? `Error with code ${response?.status}`,
  );
};

const getMissionByAdmin = async (token, adminMissionList) => {
  let response;
  let filters = {
    dates: adminMissionList.dates,
    times_declined: adminMissionList.swipes,
    status: adminMissionList.status,
    rating: adminMissionList.rating,
  };
  if (adminMissionList.after !== null) {
    response = await fetch(process.env.API_URL + `/api/admin/missions/`, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({ filters: filters, after: adminMissionList.after }),
    });
  } else if (adminMissionList.before !== null) {
    response = await fetch(process.env.API_URL + `/api/admin/missions/`, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({
      //   filters: filters,
      //   before: adminMissionList.before,
      // }),
    });
  } else {
    response = await fetch(process.env.API_URL + `/api/admin/missions/`, {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({ filters: filters }),
    });
  }

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getMissionByAssistantId = async (
  token,
  adminPocketAssistantSelection,
) => {
  let response;
  response = await fetch(
    process.env.API_URL +
      `/api/admin/missions?id=${adminPocketAssistantSelection.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const assignMission = async (token, values) => {
  let response;
  response = await fetch(process.env.API_URL + `/api/admin/missions`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      task_id: values.task_id,
      assistant_id: values.assistant_id,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const changeMaxAssignments = async (token, values) => {
  let response;
  response = await fetch(process.env.API_URL + `/api/task/parent`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      id: values.task_id,
      max_assignment: values.assignment_value,
    }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getChildMissionsByParentId = async (token, id) => {
  let response;
  response = await fetch(
    process.env.API_URL + `/api/admin/missions/parent/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "text/plain",
      },
    },
  );

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

const getOwnerTaskList = async (token, id, payload) => {
  let response;
  response = await fetch(process.env.API_URL + `/api/admin/owner/tasks`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ owner_id: id, ...payload }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};
const updateTaskByFileUpload = async (token, taskPayload, taskId) => {
  let response;
  response = await fetch(process.env.API_URL + `/api/task`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({ task: taskPayload, task_id: taskId }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

//this function translate the task description into english
const getTranslation = async (token, text, source, isPreferred = false) => {
  let response;
  response = await fetch(process.env.API_URL + `/api/ai/translate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message: text, source, isPreferred }),
  });

  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

/**
 *
 * @param {string | null} token
 * @param {string} text
 * @returns {Promise<{message:{translated:boolean}}>}
 */
const getTranslationCheck = async (token, text) => {
  console.log("🚀 ~ file: tasks.js:1083 ~ getTranslationCheck ~ text:", text);
  const path = "/api/ai/translate/check";

  const response = await fetch(process.env.API_URL + path, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
    body: JSON.stringify({ text }),
  });
  if (response && response.ok) {
    return await response.json();
  }

  throw new Error(`Error with code ${response?.status}`);
};

export {
  addRatingData,
  addTaskComment,
  assignMission,
  changeEnergy,
  changeMaxAssignments,
  changeTaskStatus,
  createMission,
  createTask,
  deleteTask,
  getAllAssistantByAdmin,
  getAllOwnersByAdmin,
  getAllTasks,
  getAssistantByAdmin,
  getAssistantByEmail,
  getChildMissionsByParentId,
  getChildTaskByParentId,
  getEarningById,
  getFeedBackById,
  getMissionByAdmin,
  getMissionByAssistantId,
  getMissions,
  getMissionsForPool,
  getOwnerTaskList,
  getTaskByAdmin,
  getTaskByHolder,
  getTaskByHolderDashboard,
  getTaskById,
  getTaskByUserId,
  getTasksByOwnerId,
  getTasksByPAId,
  getTasksByStatus,
  getTasksForPool,
  getTotalEarningById,
  getTranslation,
  getTranslationCheck,
  reassignTask,
  setTaskStatus,
  updateEarningById,
  updateTask,
  updateTaskByFileUpload,
};
