import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/organisms/Header";
import AddMail from "../components/pages/AddMail";
import ChangePassword from "../components/pages/ChangePassword";
import Confirmation from "../components/pages/Confirmation";
import Confirmed from "../components/pages/Confirmed";
import ForgotPassword from "../components/pages/ForgotPassword";
import Home from "../components/pages/Home";
import Login from "../components/pages/Login";
import OwnerLogin from "../components/pages/OwnerLogin";
import RegistrationOwner from "../components/pages/RegistrationOwner";
import RegistrationPA from "../components/pages/RegistrationPA";

export default () => (
  <>
    <Header />
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="register/assistant" element={<RegistrationPA />} />
      <Route path="register/user" element={<RegistrationOwner />} />
      <Route path="register/confirm" element={<Confirmation />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="owner-login" element={<OwnerLogin />} />
      <Route path="addmail" element={<AddMail />} />
      <Route path="confirm-account" element={<Confirmed />} />
    </Routes>
  </>
);
